@import '../../../Style/variable.scss';

.FeaturecardlistStyles {
  // your styles here
}
.bookmarked {
  border-color:$primary !important
}
.countSpan {
  svg {
    margin-right: 4px !important
  }
}

.loggedout{
  .countSpan{
    visibility: hidden;
  }
  .Livespan{
    left: 10px;
    position: absolute;
  }
}