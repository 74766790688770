@import '../../../../Style/variable.scss';

.feature-title {
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  @media(max-width:767px) {
    padding-top: 0;
  }

  .feature-ttitletext {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.01em;
    color: $peach;
    font-weight: 600;

    @media(max-width:768px) {
      font-size: 32px;
      line-height: 42px;
    }

    @media(max-width:768px) {
      font-size: 24px;
      line-height: 32px;
    }

  }

  .anticon.ant-input-clear-icon,
  .anticon.ant-input-clear-icon:hover {
    color: $white;
  }

  .title-btn {
    .ant-input-group {
      border: 2px solid rgba(255, 255, 255, 0.21);
      box-sizing: border-box;
      border-radius: 6px;

      .ant-input {
        background: transparent;
        border: 0;
        height: 40px;
        max-width: 352px;
        width: 100%;
        color: $peach;
      }
      .ant-input-suffix {
        position: absolute;
        top: 2px;
        right: -30px;
        display: inline-block;
        z-index: 1;
        cursor: text;
        .anticon.ant-input-clear-icon{
          background: #202C47;
          display: inline-block;
          padding: 15px 10px;
          cursor: pointer;
        }
      }
      .ant-input-group-addon {
        background: transparent;
        border: 0;

        .ant-btn {
          background: transparent;
          border: 0;
          padding: 0;
          color: $gray;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-right: 16px;
          padding: 0;
          &::after{
            content: none;
          }
          cursor: text;
          &:focus,&:active{
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
  }
}