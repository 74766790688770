@import './variable.scss';

.ant-btn {
    padding: 12px 20px;
    font-weight: 600;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    border-radius: 6px;
    height: 40px;
    line-height: 1;
    outline: none;
}

.ant-btn-background-ghost, .ant-btn-ghost {
    border-color: $themegreen  !important;
    color: $themegreen  !important;
    background-color: transparent !important;
    display: flex;

    &:hover {
        background-color: rgba(20, 173, 168, 0.2) !important;
        color: $themegreen  !important;
        border-color: rgba(20, 173, 168, 0.2) !important;
    }

    &:focus {
        background-color: rgba(20, 173, 168, 0.2);
        color: $themegreen  !important;
        border-color: $themegreen  !important;
    }

    svg {
        margin-right: 10px;
        color: $themegreen;
    }
}

.ant-btn-primary {
    border-color: $themegreen  !important;
    background-color: $themegreen  !important;
    color: $darkPurple  !important;

    &:hover {
        background-color: $hovered  !important;
    }

    &:focus {
        background-color: $themegreen  !important;
    }
}

.large {
    height: 48px;
    font-size: 16px;
}

.btn-full {
    width: 100%;
}

.ant-btn-primary[disabled] {
    border-color: $hovered  !important;
    color: $darkPurple  !important;
    background-color: $themegreen  !important;
    cursor: not-allowed;
    opacity: 0.5;
}