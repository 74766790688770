@import '../../../../Style/variable.scss';

.PaymentPageStyle{
    .SavedCards_wrapper{
        display: flex;
        flex-direction: column;
        @media(max-width:575px){
            display: none;
        }
        .Card_Block{
            background: $Headerbg;
            border-radius: 12px;
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }
            .Card_Header{
                padding: 20px 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #273656;
                @media(max-width:767px){
                    flex-direction: column;
                    align-items: flex-start;
                }
                @media(max-width:352px){
                  flex-wrap: wrap;
                }
                .Header_left{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    @media(max-width:991px){
                        flex-direction: column;
                        align-items: flex-start;
                    }

                }
                .Header_right{
                 .ant-btn{
                    @media(max-width:651px){
                        padding: 10px;
                        height: auto;
                    }
                 }
                }
                .Card_title{
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 16px;
                    color: $peach;
                    margin-right: 23px;
                    @media(max-width:991px){
                        white-space: nowrap;
                        margin-bottom: 10px;
                    }
                    @media(max-width:767px){
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
                .Card_logo{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    @media(max-width:991px){
                      flex-wrap: wrap;
                    }
                    @media(max-width:767px){
                        margin-bottom: 16px;
                     }
                    img{
                        width: 46px;
                        height: 30px;
                        margin-right: 10px;
                        @media(max-width:991px){
                           margin: 4px 5px;
                          }
                    }
                    .giropay{
                        width: 70px;
                    }
                }
            }
            .Card_detail_wrapper{
                padding: 20px;
                @media(max-width:420px){
                    padding: 15px 10px;
                }
                .Card_detail{
                    padding: 19px 25px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: #0E1E42;
                    border-radius: 12px;
                    @media(max-width: 991px){
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                    }
                    p{
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: $offwhite;
                        max-width: 533px;
                        @media(max-width:767px){
                            font-size: 15px;
                            line-height: 20px;
                            span{
                                font-size: 18px !important;
                            }
                        }
                        @media(max-width: 991px){
                            width: 100%;
                         }
                        span{
                            margin-right: 10px;
                            font-size: 24px;
                            line-height: 28px;
                            letter-spacing: 0.2px
                        }
                    }
                }
                .cardList{
                    .savedcard{
                        background: #0F2240;
                        border-radius: 6px;
                        padding: 15px 20px;
                        min-height: 113px;
                        width: 100%;
                        min-width: 380px;
                        @media(max-width:1200px){
                            min-width: 300px;
                        }
                        @media(max-width:661px){
                            min-width: 260px;
                        }
                        @media(max-width:575px){
                            min-width: 300px;
                        }
                        @media(max-width:420px){
                            min-width: 190px;
                            padding: 10px 15px;
                        }
                        .Img_Primary{
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-between;
                            margin-bottom: 15px;
                            .CardImg{
                                width: 40px;
                                height: 12px;
                                @media(max-width:351px){
                                    width: 30px;
                                }
                            }
                            .Primary{
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 24px;
                                color: $offwhite;
                                @media(max-width:351px){
                                    line-height: 14px;
                                }
                            }
                        }
                        .Cardnumber{
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 26px;
                            text-align: start;
                            letter-spacing: 2px;
                            color: $peach;
                            margin-bottom: 2px;
                            @media(max-width:351px){
                                font-size: 16px;
                            }
                        }
                        .CardNameDelet{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .Cardname{
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 18px;
                                letter-spacing: 0.2px;
                                color: rgba($color: $peach, $alpha: 0.7);
                            }
                            .DeletBtn{
                                color: $peach;
                                padding: 2px;
                                height: auto;
                                min-width: auto;
                                svg{
                                    path{
                                        stroke: $peach;
                                        transition: 0.3s all ease;
                                    }
                                }
                                &:hover{
                                    svg{
                                        path{
                                            stroke: rgba($color: $peach, $alpha: 0.7);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .mastercard{
                        background: #0F2240;
                        .CardImg{
                            width: 35px !important;
                            height: 23px !important;
                        }
                    }
                    .activeCard {
                        background: #B2A8EE;
                        .Primary{
                            color: $black !important;
                        }
                        .Cardnumber{
                            color: $black;
                        }
                        .CardNameDelet{
                            .Cardname{
                                color: rgba($color: $black, $alpha: 0.7);
                            }
                            .DeletBtn{
                                color: $black;
                                svg{
                                    path{
                                        stroke: $black;
                                    }
                                }
                                &:hover{
                                    svg{
                                        path{
                                            stroke:  rgba($color: $black, $alpha: 0.7);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .ant-radio-group{
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 20px;
                        @media(max-width:991px){
                           display: flex;
                           flex-wrap: wrap;
                        }
                        .ant-radio-wrapper{
                            margin: 0;
                            max-width: 380px;
                            @media(max-width:1200px){
                                max-width: 300px;
                            }
                            @media(max-width:661px){
                                max-width: 280px;
                            }
                            @media(max-width:575px){
                                max-width: 300px;
                            }
                            span{
                                padding: 0;
                            }
                            .ant-radio{
                                position: absolute;
                                top: 17px;
                                right: 78px;
                                @media(max-width:351px){
                                    top: 8px;
                                    right: 70px;
                                }
                                .ant-radio-inner{
                                    background: rgba(142, 134, 190, 0.25);
                                    width: 18px;
                                    height: 18px;
                                    @media(max-width:351px){
                                        width: 14px;
                                        height: 14px;
                                    }
                                }
                            }
                            .ant-radio-checked .ant-radio-inner{
                                background-color: $themegreen;
                            }
                        }
                    }
                }
                .fundwithdrawal{
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-top: 15px;
                    .RequestfundBtn{
                        color: $primary;
                        padding: 4px 0;
                        height: auto;
                        &:disabled {
                            opacity: 0.5;
                        }
                    }
                }
                .ShippingAddressList{
                    display: flex;
                    .ShippingAddressContent{
                        padding: 16px;
                        background: #0F2240;
                        border-radius: 6.68376px;
                        min-width: 380px;
                        max-width: 382px;
                        width: 100%;
                        min-height: 180px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        @media(max-width:1200px){
                            min-width: auto;
                        }
                        .addressHeding{
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-between;
                            margin-bottom: 8px;
                            .title{
                                font-weight: 600;
                                font-size: 17.8234px;
                                line-height: 27px;
                                color: $peach;
                                @media(max-width:767px){
                                    font-size: 16px;
                                    line-height: 20px;
                                }
                            }
                            .Default{
                                font-weight: 600;
                                font-size: 13px;
                                line-height: 26px;
                                color: $peach;
                            }
                        }
                        .AddressText{
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 24px;
                            color: $peach;
                            margin-bottom: 19px;
                            max-width: 300px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: initial;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            height: 48px;
                        }
                        .ValidatedAddress{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .Validated{
                                display: flex;
                                align-items: center;
                                font-weight: 400;
                                font-size: 13.3675px;
                                line-height: 26px;
                                letter-spacing: 0.001em;
                                color: #2ABC01;
                                @media(max-width: 424px){
                                   align-items: flex-start;
                                   line-height: 15px;
                                 }
                                span{
                                    border: 2px solid #2ABC01;
                                    margin-right: 5px;
                                    display: flex;
                                    border-radius: 50%;
                                    width: 20px;
                                    height: 20px;
                                    padding: 3.9px;
                                    img{
                                        width: 100%;
                                        // height: 100%;
                                    }
                                }
                            }
                            .red{
                                color: #EC000F;
                                span{
                                    border-color:#EC000F;
                                    align-items: center;
                                    justify-content: center;
                                    padding:2px 1px;
                                    img{
                                       width: auto;
                                       height: 100%;
                                    }
                                }
                            }
                            .EditDeleteBtn{
                                display: flex;
                                .DeletBtn{
                                    color: $black;
                                    padding: 2px;
                                    height: auto;
                                    margin-right: 20px;
                                    min-width: auto;
                                    @media(max-width: 991px){
                                        margin-right: 10px;
                                     }
                                    @media(max-width: 424px){
                                        margin-right: 2px;
                                     }
                                    &:last-child{
                                        margin: 0;
                                    }
                                    svg{
                                        path{
                                            stroke: $peach;
                                            transition: 0.3s all ease;
                                        }
                                    }
                                    &:hover{
                                        svg{
                                            path{
                                                stroke:  rgb(250 232 220 / 70%);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .ant-radio-group{
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 20px;
                        @media(max-width:991px){
                           display: flex;
                           flex-wrap: wrap;
                        }
                        .ant-radio-wrapper{
                            margin: 0;
                            span{
                                padding: 0;
                            }
                            .ant-radio{
                                position: absolute;
                                top: 20px;
                                right: 75px;
                                .ant-radio-inner{
                                    background: rgba(142, 134, 190, 0.25);
                                    width: 18px;
                                    height: 18px;
                                }
                            }
                            .ant-radio-checked .ant-radio-inner{
                                background-color: $themegreen;
                            }
                        }
                    }
                }
            }

        }
        .Outstandingpayment{
            background: #3D4568;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 22px 15px;
            margin-bottom: 20px;
            @media(max-width:1213px){
                flex-wrap: wrap;
            }
             .OutstandingText-left{
                margin-right: 21px;
                @media(max-width:1213px){
                   margin-bottom: 15px;
                   margin-right: 0;
                }
                .text{
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: $peach;
                }
                p{
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 18px;
                    letter-spacing: 0.001em;
                    color: $peach;
                    margin: 0;
                    max-width: 326px;
                    @media(max-width:1213px){
                       max-width: 100%;
                    }
                }
             }
             .OutstandingText-right{
                display: flex;
                @media(max-width:1213px){
                    justify-content: space-between;
                }
                @media(max-width:991px){
                    flex-wrap: wrap;
                }
                .Outstanding{
                    margin-right: 20px;
                    @media(max-width:991px){
                        margin: 0 10px 10px 0;
                        width: 100%;
                    }
                    .OutstandingText{
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        text-align: left;
                        color: $offwhite;
                        margin-bottom: 5px;
                        @media(max-width:991px){
                           text-align: left;
                        }
                        span{
                            font-weight: 600;
                            color: $themegreen;
                        }
                    }
                    .Outstandingsubtext{
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                        color: $themegreen;
                        text-align: left;
                        @media(max-width:991px){
                            text-align: left;
                         }
                    }
                }
            }
        }
    }
    .LoadingSpinner{
        width: 100%;
    }
}
.AddNewCard{
    .ant-modal-title{
        font-size: 28px !important;
        @media(max-width:767px){
            font-size: 24px !important;
        }
    }
    .ant-modal-body{
        padding-top: 50px !important;
    }
    .AddnewcardModalStyle,.TopUpModalStyle,.ShippingAddressModalStyle{
        .ant-input-affix-wrapper {
            background: transparent;
            border: 1px solid rgba(250, 232, 220, 0.2) !important;
            border-radius: 12px;
            .ant-input {
            background-color: transparent;
            height: 48px;
            color: #B4C4DC;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            &::placeholder{
                color: #B4C4DC;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
            }
            }
        }
        .ant-input-affix-wrapper:focus-within, .ant-input-affix-wrapper-focused, textarea:focus, .ant-picker:focus-within{
            border: 1px solid $themegreen !important;
            box-shadow: none !important;
        }
        .payments_detail{
            display: flex;
            background: #0F2240;
            border: 1px solid #273656;
            border-radius: 6px;
            padding: 14px 15px;
            margin-bottom: 10px;
            .Icon{
                width: 30px;
                flex: 0 0 30px;
                height: 40px;
                margin-right: 17px;
                img{
                    width: 100%;
                }
            }
            .Content{
                h5{
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;
                    color: $offwhite;
                    margin-bottom: 5px;
                }
                p{
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: $offwhite;
                    opacity: 0.8;
                    margin: 0;
                }
            }
        }
        .ORText{
            text-align: center;
            position: relative;
            margin: 30px 0;
            span{
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: $offwhite;
                background-color: $Headerbg;
                position: relative;
                z-index: 1;
                padding: 0 3px;
            }
            &::after{
                content: "";
                border-bottom:1px solid #415068;
                display: inline-block;
                width: 100%;
                position: absolute;
                top: 10px;
                left: 0;
                right: 0;
            }
        }
        .otherpayment{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 30px;
            a{
                margin-right: 10px;
                img{
                    width: 42px;
                    height: 26px;
                    margin-right: 10px;
                    &:last-child{
                        margin: 0;
                    }
                }
            }
            .giropay{
                img{
                    width: 60px;
                }
            }
        }
        .topup{
            height: 48px;
            align-items: center;
            span{
                width: 100%;
            }
        }
        .StripeElement{
            background: transparent;
            border: 1px solid rgba(250, 232, 220, 0.2) !important;
            border-radius: 12px;
            padding: 4px 11px;
            height: 58px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.3s all ease-in-out;
            .__PrivateStripeElement{
                width: 100%;
            }
        }
        .StripeElement--focus{
            border-color: $themegreen !important;
        }
    }
    .TopUpModalStyle{
        .currentbalance{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            background: #0F2240;
            border: 1px solid #273656;
            border-radius: 6px;
            padding: 20px;
            margin-bottom: 28px;
            @media(max-width: 375px){
                flex-direction: column-reverse;
                padding: 15px;
            }
            .Content{
                .Title{
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 24px;
                    color: $offwhite;
                }
                span{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: 0.001em;
                    color: $offwhite;
                    opacity: 0.8;
                }
            }
            .value{
                font-weight: 500;
                font-size: 22px;
                line-height: 24px;
                text-align: center;
                letter-spacing: 0.374px;
                color: $themegreen;
                @media(max-width: 375px){
                    margin-bottom: 10px;
                }
            }
        }
        .TopUpBalanceBtn{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 20px;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 45px;
            @media(max-width:424px){
                grid-template-columns: 1fr 1fr;
            }
            @media(max-width:319px){
                grid-template-columns: 1fr;
            }
            .ant-btn{
                font-size: 18px;
                line-height: 21px;
                height: 44px;

                span{
                    width: 100%;
                }
                &:hover{
                    background-color: $themegreen !important;
                    color: $black  !important;
                    border-color: $themegreen  !important;
                }
            }
            .selected-ghost {
                background-color: $themegreen !important;
                color: $black  !important;
                border-color: $themegreen  !important;
            }
        }
        .Topupwith{
            .Title{
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                color: $offwhite;
                margin-bottom: 14px;
            }
            .Topupwith_Detail{
                display: block;
                grid-template-columns: 1fr 1fr;
                grid-gap: 20px;
                @media(max-width:424px){
                    grid-template-columns: 1fr ;
                }
                .TopupOptionButtons{
                    display: flex;
                    flex-wrap: wrap;
                    .googlePay{
                        width: 200px;
                        object-fit: contain;
                        height: 100%;
                    }
                    .StripeElement {
                        width: 42%;
                        margin: 0 6px 6px 0;
                        border: none !important;
                        padding: 0;
                        height: 45px;
                        .GooglePayButton {
                            min-height: 45px;
                        }
                    }
                }
                button {
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #fff;
                    overflow: hidden;
                    height: 45px;
                    border: 1px solid #fff;
                    margin-right: 6px;
                    width: 48%;
                    margin-bottom: 6px;
                    @media(max-width:424px){
                        max-width: 213px;
                        height: 44px;
                    }
                    img{
                        width: 200px;
                        object-fit: contain;
                        height: 100%;
                    }
                }
                a {
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media(max-width:424px){
                        max-width: 213px;
                        height: 44px;
                    }
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .ORText{
            margin-top: 50px;
            margin-bottom: 28px;
        }
        .otherpayment{
            a{
                img{
                    width: 57px;
                    height: 38px;
                }
            }
        }
    }
    .ShippingAddressModalStyle{
        .ant-input-affix-wrapper {
            .ant-input {
                color: $offwhite;
                &::placeholder{
                    color: rgba(250, 232 ,220 ,0.5);
                }
            }
        }
        .ant-select {
            height: 58px;
            .ant-select-selector{
                background: transparent;
                height: 100%;
                border-radius: 12px;
                border: 1px solid rgba(250, 232, 220, 0.2);
            }
            .ant-select-selection-item{
                display: flex;
                align-items: center;
            }
            .ant-select-selection-search-input{
                height: 58px;
            }
        }
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
            border-color:$themegreen ;
        }
        .ant-radio-wrapper{
            .ant-radio{
                margin-right: 5px;
            }
            span{
                text-transform: capitalize;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: $offwhite;
            }
        }
        .ant-btn{
            height: 48px;
            align-items: center;
            justify-content: center;
        }
        .shippingAddress {
            .Content{
                h5{
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;
                    color: $offwhite;
                    margin-bottom: 5px;
                }
                p{
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: $offwhite;
                    opacity: 0.8;
                    margin: 0;
                }
            }
        }
        .ShippingAddNote{
            background: #B7A8E9;
            border-radius: 10px;
            padding: 12px 20px;
            display: flex;
            align-items: flex-start;
            margin-bottom: 11px;
             .icon{
                display: inline-block;
                width: 20px;
                height: 20px;
                flex: 0 0 20px;
                margin-right: 16px;
                border: 2px solid ;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
             }
             .green{
                border-color: #2ABC01;
                img{
                    width: 100%;
                    padding: 2px;
                    height: 100%;
                }
             }
             .red{
                 border-color: #D63028;
                svg{
                    color: #D63028;
                }
                img{
                    width: auto;
                    padding: 2px;
                    height: 100%;
                }
             }
            p{
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: $black00;
            }
        }
    }
    .Unsuccesssfulpayment{
        padding: 0 35px;
        .Payment_img{
            width: 120px;
            height: 118px;
            margin: 36px auto;
            img{
                width: 100%;
            }
        }
        .Payment_Content{
            margin-bottom: 45px;
            .title{
                font-weight: 500;
                font-size: 22px;
                line-height: 26px;
                text-align: center;
                letter-spacing: 0.374px;
                color: $peach;
                margin-bottom: 10px;
            }
            p{
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                opacity: 0.8;
                color: $peach;
                margin: 0 auto;
                max-width: 394px;
            }
        }
        .Payment_btn{
            .ant-btn{
                justify-content: center;
                align-items: center;
                height: 48px;
                margin-bottom: 16px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}
.TopUpHistoryModalStyle{
    .TransactionhistoryStyles{
        max-height: 650px;
        overflow: auto;
        position: relative;
        @media(max-width:600px){
            display: none;
        }
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
          }
          &::-webkit-scrollbar-track {
            background: transparent;
          }
          &::-webkit-scrollbar-thumb {
            background: rgb(250 232 220 / 50%);
            border-radius: 10px;
          }
          .ant-table-wrapper{
            min-width: 600px;
            width: 100%;
          }
          .ant-table-thead{
            position: sticky;
            top: 0;
            left: 0;
            background: $darkblack;
            z-index: 99;
            th{
                border-bottom: none;
            }
          }
    }
}

.PaymentPageStyle{
    .ant-btn{
        min-width: 154px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}



.No_massge_wrapper{
    @media(max-width:600px){
        display: none;
    }
}