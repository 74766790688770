.TransactionhistoryStyles {
  h3{
    margin-bottom: 25px;
    font-size: 28px;
    @media(max-width:767px){
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }
  .ant-table table{
    .ant-table-thead{
      tr{
        th{
          color: rgba($color: #FAE8DC, $alpha: 0.5);
          font-weight: 500;
          font-size: 13px;
          line-height: 18px;
          &:last-child{
            text-align: right;
          }
        }
      }
    }
    .ant-table-tbody{
      tr{
        td{
          font-weight: 500;
          font-size: 13px;
          line-height: 18px;
          color: #FAE8DC;
          .shopname{
            p{
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              letter-spacing: -0.2px;
              color: #FAE8DC;
              display: flex;
              align-items: center;
              margin-bottom: 0;
              svg{
                margin: 0 0 0 10px ;
                width: 14px;
                height: 14px;
              }
            }
          }
        }
      }
    }
  }
  .AmountSpanWrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
}