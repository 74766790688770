@import'../../Style/variable.scss';



.imgtop{
  align-items: flex-start;
}
.HasTag{
  display: block;
  color: $primary;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 600;
}

.discordBtndiv{
  text-align: center;
  padding:  10px 0;
  .discordLink {
    background: $white;
    border-radius: 18px;
    display: inline-flex;
    align-items: center;
    padding: 44px 60px;
    font-weight: 700;
    font-size: 42px;
    line-height: 24px;
    letter-spacing: 0.374px;
    color: $darkPurple;
    font-family: "Atrament";
    @media(max-width:600px){
      font-size: 22px;
      line-height: 24px;
      padding: 18px 22px;
      }
    .spantext{
      >span{
        color:#7289DA;
      }
    }
    svg{
      margin-left: 20px;
    }
  }
}
