@import '../../Style/variable.scss';

.body-wrapper {
	padding: 0 0 30px 0;
}

.welcomeNote {
	font-size: 13px;
	line-height: 15px;
	margin-bottom: 25px;
	color: #777E90;
}

.Livestreamingbox {
	max-width: 700px;
	background: $secondary-strock;
	border-radius: 16px;
	overflow: hidden;
	position: relative;
	min-height: 100%;
	min-height: 738px;
	max-height: 738px;
	@media(max-width:768px) {
		margin-bottom: 30px;
		margin-right: 0;
	}
	#audience{
		height: 748px !important;
		div{
			height: 748px !important;
		}
	}
	.showBackground {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		// height: calc(100vh - 210px) !important;
		background-repeat: no-repeat;
		background-size: cover;
		z-index: 0;
		box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.6);
		background-position: center;
	}

	// .player {
	// 	height: calc(100vh - 210px) !important;
	// 	z-index: 1;
	// }

	.audience-player {
		height: calc(100vh - 245px) !important;
	}

	z-index: 1;

	.layers {
		display: inline-block;
		background: rgba($LightBlck, 0.3);
		// border: 2px solid $borderColor;
		box-sizing: border-box;
		border-radius: 90px;
		padding: 12px 16px;
		font-weight: bold;
		font-size: 15px;
		line-height: 16px;
		display: inline-flex;
		align-items: center;
		margin-right: 12px;
		transition: 0.5s ease-in-out;
		margin-bottom: 10px;

		&:last-child {
			margin-right: 0;
		}

		&:hover {
			background-color: $pink;
			border-color: $pink;
		}

		svg {
			margin-right: 14px;
		}

		@media(max-width:319px) {
			padding: 8px 10px;

		}
	}

	.filled {
		background-color: $pink;
		border-color: $pink;

		&:hover {
			background: rgba($LightBlck, 0.3);
			border-color: $peach;
		}
	}

	&--top {
		position: absolute;
		display: flex;
		justify-content: space-between;
		width: 100%;
		z-index: 1;
		padding: 16px;
		top: 0;
	}

	.avtar-profile {
		z-index: 1;
		border-radius: 100px;
		background: rgba($LightBlck, 0.62);
		backdrop-filter: blur(4px);
		padding: 10px;
		display: flex;

		img {
			width: 30px;
			margin-right: 8px;
			height: 30px;
			border-radius: 50%;
			object-fit: cover;
			object-position: center;
		}

		.avtar-profile--text {
			font-size: 16px;
			line-height: 24px;
			color: $peach;

			span {
				color: $green;
			}
		}
	}

	.celebrate-icon {
		background: $secondary;
		box-shadow: 16px 16px 30px rgb(0 0 0 / 45%);
		border-radius: 27.36px;
		display: inline-block;
		width: 74px;
		height: 74px;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;

		@media(max-width:768px) {
			width: 64px;
			height: 64px;
		}

		img {
			width: 30px;

			@media(max-width:768px) {
				width: 20px;
			}
		}
	}

	.Livestreamingbox-bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: absolute;
		bottom: 0;
		width: 100%;
		z-index: 1;
		padding: 20px;

		&__left {
			width: calc(100% - 74px);
			justify-content: center;
			margin-left: 74px;
			display: flex
		}

	}
}


.userfollow-list {
	background: $Headerbg;
	border: 1px solid $borderblue;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 50px 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	flex-direction: column;
	min-height: 300px;
	&--left {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;

		@media(max-width:767px) {
			margin-bottom: 10px;
		}

		.userfollow-list-profile {
			width: 60px;
			height: 60px;
			overflow: hidden;
			border-radius: 50%;
			margin-bottom: 16px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}

		.userInfo {
			margin-bottom: 10px;

			h4 {
				margin-bottom: 4px;
				font-size: 16px;
				line-height: 20px;
				font-weight: 500;

				svg {
					margin-left: 5px;
				}
			}

			p {
				margin-bottom: 0;
				color: $peach2;
				opacity: 0.7;
			}
		}
	}

	&--right {
		.ant-btn {
			@media(max-width:961px) {
				padding: 10px;
				height: auto;
				font-size: 14px;
			}

			@media(max-width:374px) {
				font-size: 12px;
			}

			@media(max-width:319px) {
				font-size: 11px;
				padding: 10px 5px;

			}
		}
	}

	.Follow {
		border-radius: 100px;
	}

}

.bid-box {
	box-sizing: border-box;
	margin-bottom: 10px;

	.ant-col {
		width: 100%;
	}

	.bid-fixed {
		text-align: center;
		padding: 20px 0;

		.bid-fixed-span {
			text-transform: uppercase;
			font-weight: 600;
		}

		p {
			margin-bottom: 0;
			font-size: 12px;
			line-height: 130%;
			color: $peach;
			letter-spacing: 0.374px;
		}

		span {
			font-weight: 600;
			font-size: 24px;
			line-height: 28px;
			padding: 4px 0;
			display: block;
			color: $peach;
		}
	}

	.bid-shipping {
		padding: 20px 60px;
		border-top: 1px solid $darkPurple;
		cursor: pointer;

		@media(max-width:1140px) {
			padding: 20px 40px;
		}

		@media(max-width:991px) {
			padding: 20px 20px;
		}

		.bid-shopping-inner {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 0;
			color: $themegreen;
			font-size: 14px;
			font-weight: 500;
			line-height: 24px;

			.show-price {
				padding-left: 18px;
			}

			@media(max-width:991px) {
				justify-content: center;
			}

			svg {
				margin: 0 2px 0 0;
				width: 23px;
			}
		}
	}

	.bid-btn {
		background: $primary;
		border-radius: 10px;
		border: 0;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 600;
		width: 100%;
		font-size: 20px;
		line-height: 125%;
		text-align: center;
		letter-spacing: 0.374px;
		color: $darkPurple;
		flex-direction: column;
		padding: 20px 4px;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		outline: none;

		@media(max-width:600px) {
			height: auto;
		}

		span {
			display: block;
			font-size: 32px;
			line-height: 32px;
			font-weight: 700;
			margin-top: 10px;
			display: inline-block;

			@media(max-width:600px) {
				font-size: 22px;
				line-height: 22px;
			}
		}
	}

	.bid-right {
		background: $Headerbg;
		border: 1px solid $borderblue;
		border-radius: 8px;
		margin-bottom: 12px;
		width: 100%;
	}

	.bid-left {
		display: flex;
		align-items: center;
		margin-bottom: 16px;
		width: 100%;

		h3 {
			color: $white;
			width: 100%;
			background: #2ABC01;
			border-radius: 8px;
			padding: 9px 4px;
			font-size: 18px;
			line-height: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 0 0 0;
			cursor: pointer;
			svg {
				width: 20px;
				margin-right: 10px;
			}
			&:hover{
				cursor: pointer;
			}
		}

		font-size: 16px;
		font-weight: 700;
		color:$gray;

	}
}

.video-newsfeed {
	display: flex;
	flex-direction: column;
	height: 100%;

	.leftTop {
		@media(max-width:767px) {
			margin-top: 40px;
		}

		h1 {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;

			@media(max-width:991px) {
				font-size: 24px !important;
				line-height: 32px;
			}
		}

	}

	.leftBottom {
		background: $Headerbg;
		border: 1px solid $borderblue;
		box-sizing: border-box;
		border-radius: 16px;
		padding: 10px 0;
		flex: 1;
		max-height: 738px;
	}

	.chatListing {
		padding: 20px 15px;
		padding-bottom: 0;
		flex-grow: 1;
		max-height: 630px;
		min-height: 600px;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-track {
			background: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background: #2B3A5F;
			border-radius: 10px;
		}

		.userbid-item {
			align-items: center;
		}

		.list-item {
			display: flex;
			margin-bottom: 12px;
			align-items: center;

			.userProfile {
				height: 26px;
				width: 26px;
				overflow: hidden;
				border-radius: 50%;
				margin-right: 10px;
				cursor: pointer;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}

			.usercgat {
				flex: 1;

				p {
					font-weight: 600;
					letter-spacing: -0.3px;
					margin-bottom: 0;
					font-size: 12px;
					line-height: 12px;
					color: $peach;
				}

				span {
					font-size: 12px;
					font-weight: 400;
					line-height: 18px;
					color: $peach2;
					display: inline-block;
					margin: 2px 0;

					.TagText {
						background: rgba(255, 255, 255, 0.4);
						border-radius: 2px;
						font-weight: 500;
						font-size: 11px;
						line-height: 15px;
						color: #FAE8DC;
						padding: 0 5px;
						margin-right: 5px;
					}
				}

				.Userbid {
					font-weight: 600;
					font-size: 12px;
					line-height: 12px;
					display: flex;
					align-items: center;
					color: #0DFDE4;
					margin: 2px 0;
				}
			}
		}
	}

	.chatbox {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;
		border-radius: 0 16px 0 0;
	}

	.chatinput {
		position: relative;

		.Recent_message {
			position: absolute;
			top: -45px;
			right: 10px;

			.Recent_message_btn {
				background-color: $Headerbg;
				border-color: rgba(255, 255, 255, 0.19) !important;
				color: $white  !important;

				svg {
					transition: 0.3s all ease;

					path {
						transition: 0.3s all ease;
					}
				}

				&:hover {
					background-color: $Headerbg  !important;
					color: $themegreen  !important;

					svg {
						path {
							stroke: $themegreen;
						}
					}
				}
			}
		}

		.chatInput-box {
			background: transparent;
			display: flex;
			padding: 0 10px 0 15px;
			margin-top: 20px;
			align-items: center;

			.react-emoji {
				border: 1px solid $white;
				border-radius: 100px;
				max-width: 83%;

				.react-input-emoji--container {
					margin: 0 !important;
				}

				.react-input-emoji--wrapper {
					padding: 10px 0 10px 21px;

					.react-input-emoji--placeholder {
						padding: 2px 5px;
						height: auto;
						line-height: 0%;
					}

					.react-input-emoji--input {
						padding: 0;
						max-height: 80px;
						background: transparent;
						border: none;
						flex: 1;
						outline: none !important;
						width: 100%;
						color: $peach;

						&::-webkit-scrollbar {
							width: 4px;
						}

						&::-webkit-scrollbar-track {
							background: transparent;
						}

						&::-webkit-scrollbar-thumb {
							background: $borderColor;
							border-radius: 10px;
						}
					}
				}

				.react-input-emoji--button {
					svg {
						fill: $white;
					}

					&:focus {
						outline: none;
						box-shadow: none;
					}
				}

				.react-input-emoji--button__show svg {
					fill: $themegreen ;
				}
			}

			input.textinput {
				background: transparent;
				border: none;
				flex: 1;
				outline: none !important;
				padding: 4px 20px;
				width: 100%;
			}

			.emojiBtn {
				width: 44px;
				height: 44px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				background: rgba(15, 18, 29, 0.4);
				border: 0;
				margin-left: 10px;
				cursor: pointer;
			}

			.sendbtn {
				width: 44px;
				height: 44px;
				flex: 0 0 44px;
				border-radius: 50%;
				color: $darkPurple;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 0;
				background: rgba(32, 44, 71, 0.4);
				border: 0.900305px solid rgba(255, 255, 255, 0.19);
				cursor: pointer;
				transition: 0.5s ease-in-out;
				margin-left: 10px;
				&:hover{
					border-color: $white;
				}
				&:focus {
					outline: none;
					box-shadow: none;
				}
			}
		}
	}

}

.livevodeo-block {
	padding-top: 30px;

	h6 {
		font-style: normal;
		font-weight: 600;
		font-size: 22px;
		line-height: 26px;
		color: $peach;
	}

	.order-three {
		@media(max-width:767px) {
			margin-top: 20px;
			flex: 0 0 100%;
		}
	}
	.left_bidSection{
		@media(max-width:767px) {
			flex: 0 0 100%;
		}
	}
}

.sideBtns {
	display: flex;
	flex-direction: column;

	@media(max-width:569px) {
		display: flex;
		flex-direction: row;
		position: relative;
		margin-top: 20px;
	}

	.iconBtn {
		width: 48px;
		height: 48px;
		background: $secondary;
		border: 2px solid $secondary-strock;
		box-sizing: border-box;
		border-radius: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 15px;
		flex-direction: column;
		cursor: pointer;
		transition: 0.5s ease-in-out;

		@media(max-width:768px) {
			margin-right: 15px;
		}

		&:hover {
			background-color: $peach;
			color: $black;
		}
	}

	.order-two {
		@media(max-width:767px) {
			order: 2;
		}
	}

	.order-three {
		@media(max-width:767px) {
			order: 3;
		}
	}
}


.suggesion-tags {
	display: flex;
	margin: 15px 0;
	padding: 0 10px;
	overflow: hidden;

	&::-webkit-scrollbar {
		height: 4px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: #dadada;
		border-radius: 10px;
	}

	@media(max-width:767px) {
		overflow-x: auto;
		margin: 10px;
	}

	.tags-item {
		padding: 4px 5px;
		background: rgba(0, 0, 0, 0.3);
		border-radius: 100px;
		margin: 0px 2px;
		display: inline-block;
		font-size: 14px;
		line-height: 24px;
		display: flex;
		align-items: center;
		cursor: pointer;
		min-width: 67px;
		text-align: center;
		justify-content: center;
	}
}

.counter {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 80px;
	z-index: 1;
}

.otpform {
	.ant-form-item-control-input-content {
		display: flex;
		justify-content: space-between;

		.ant-input {
			flex: 1;
			margin-right: 10px;
			text-align: center;

			@media(max-width:767px) {
				padding: 6px;
			}

			&:last-child {
				margin-right: 0;
			}

			&:hover,
			&:focus {
				border-right-width: 2px !important;
			}
		}
	}
}

#register {
	margin-top: 20px;
}

.otpform {
	padding-top: 20px;

	.ant-form-item {
		flex-direction: column;
		align-items: flex-start;
		width: 100%;

		.ant-form-item-control {
			width: 100%;
		}
	}

	#register_otp {
		justify-content: center;

		.otpInput {
			max-width: 55px;

			&:focus {
				border-color: $themegreen;
			}
		}
	}

	.numberInput {
		width: 100%;

		.ant-input {
			text-align: left;
		}
	}
}

.breakpointModal {
	.ant-modal-body {
		padding-top: 0 !important;
		text-align: center;
	}

	p {
		color: $peach;
		margin-bottom: 20px;
	}

	.ant-btn {
		height: auto;
		padding: 3px 19px;
		margin: 0 4px;
	}
}

.react-input-emoji--container {
	background-color: transparent !important;
	border: 1px solid transparent !important;
}

.leftTop {
	h6 {
		margin-bottom: 0;
	}
	.showBtns {
		display: flex;
		align-items: center;
		margin-bottom: 15px;
	}
	.info_btn, .reportBtn {
		margin-top: 6px;
		background: rgba(0, 0, 0, 0.4);
		backdrop-filter: blur(2px);
		border-radius: 100px;
		padding: 5px 10px;
		font-size: 13px;
		height: auto;
		display: flex;
		align-items: center;
		border: none;
		outline: none;
		color: $white;
		font-weight: 600;
		line-height: 1;
		margin-right: 4px;

		&::after {
			content: none;
		}

		&:hover {
			color: $white;
		}

		&:focus {
			outline: none;
			box-shadow: none;
		}

		svg {
			width: 10px;
			height: 10px;
			margin-right: 5px;
		}
	}
}

.react-emoji-picker--wrapper {
	width: 355px !important;

	@media(max-width:460px) {
		left: 5px;
		max-width: 320px !important;
		width: 100% !important;
		overflow: inherit !important;

		.emoji-mart {
			width: 100% !important;
		}
	}

}

.emoji-mart-search {
	padding: 0 !important;
	margin: 0 !important;
}

video {
	height: 100% !important;
	object-fit: unset !important;
}

.wallet-icon {
	.wallet_btn {
		background: transparent;
		width: 44px;
		height: 44px;
		border-radius: 50%;
		border: 1.00869px solid rgba(255, 255, 255, 0.19);
		padding: 2px;
		justify-content: center;
		margin: 0;

		svg {
			width: 22px;
			height: 22px;
			margin: 0;
		}
	}
}

.react-emoji-picker--wrapper {
	right: 1px !important;
	left: -1px;

	.emoji-mart {
		width: 100% !important;
		min-width: 338px;
		background: $darkPurple;
		border-color: $darkPurple;

		.emoji-mart-bar {
			.emoji-mart-anchor-selected {
				color: $themegreen  !important;

				.emoji-mart-anchor-bar {
					background-color: $themegreen  !important;
				}
			}
		}

		.emoji-mart-search-icon {
			svg {
				fill: $white;
			}
		}

		.emoji-mart-scroll {
			padding: 0;

			&::-webkit-scrollbar {
				width: 4px;
			}

			&::-webkit-scrollbar-track {
				background: transparent;
			}

			&::-webkit-scrollbar-thumb {
				background: #dadada;
				border-radius: 10px;
			}

			.emoji-mart-category-label {
				span {
					background: $Headerbg;
					color: $peach2;
					padding: 5px 12px;
				}
			}

			.emoji-mart-category-list {
				padding: 0 7px;
			}

			.emoji-mart-emoji {
				&:hover {
					&::before {
						background: $Headerbg;
					}
				}

				span {
					cursor: pointer;
				}

				&:focus {
					outline: none;
					box-shadow: none;
				}
			}
		}

		.emoji-mart-search {
			padding: 0;
			margin: 0;

			input {
				border: none;
				border-bottom: 1px solid #d9d9d9;
				border-radius: 0;
				background-color: transparent;
				color: $peach2;
			}
		}

		.emoji-mart-search-icon {
			top: 2px;
			right: 2px;
		}
	}
}

.agora_video_player {
	// height: calc(100vh - -90px) !important;
	margin-bottom: -6px;
}

.h100 {
	height: calc(100vh - 100px) !important;
}

.mutebtn {
	background: transparent;
	border: 0;
	outline: none !important;
	box-shadow: none;
	width: 40px;
}


// payment
.cardsPopup {
	background: $blueInput;

	border-radius: 16px;
}

.AddnewcardModalStyle,
.TopUpModalStyle,
.ShippingAddressModalStyle {
	.ant-input-affix-wrapper {
		background: transparent;
		border: 1px solid rgba(250, 232, 220, 0.2) !important;
		border-radius: 12px;
		.ant-input {
			background-color: transparent;
			height: 48px;
			color: #B4C4DC;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;

			&::placeholder {
				color: #B4C4DC;
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
			}
		}
	}

	.ant-input-affix-wrapper:focus-within,
	.ant-input-affix-wrapper-focused,
	textarea:focus,
	.ant-picker:focus-within {
		border: 1px solid $themegreen  !important;
		box-shadow: none !important;
	}

	.payments_detail {
		display: flex;
		background: #0F2240;
		border: 1px solid #273656;
		border-radius: 6px;
		padding: 14px 15px;
		margin-bottom: 10px;

		.Icon {
			width: 30px;
			flex: 0 0 30px;
			height: 40px;
			margin-right: 17px;

			img {
				width: 100%;
			}
		}

		.Content {
			h5 {
				font-weight: 600;
				font-size: 14px;
				line-height: 24px;
				color: $offwhite;
				margin-bottom: 5px;
			}

			p {
				font-weight: 400;
				font-size: 13px;
				line-height: 20px;
				color: $offwhite;
				opacity: 0.8;
				margin: 0;
			}
		}
	}

	.ORText {
		text-align: center;
		position: relative;
		margin: 30px 0;

		span {
			font-weight: 600;
			font-size: 12px;
			line-height: 16px;
			color: $offwhite;
			background-color: $Headerbg;
			position: relative;
			z-index: 1;
			padding: 0 3px;
		}

		&::after {
			content: "";
			border-bottom: 1px solid #415068;
			display: inline-block;
			width: 100%;
			position: absolute;
			top: 10px;
			left: 0;
			right: 0;
		}
	}

	.otherpayment {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 30px;

		a {
			margin-right: 10px;

			img {
				width: 42px;
				height: 26px;
				margin-right: 10px;

				&:last-child {
					margin: 0;
				}
			}
		}

		.giropay {
			img {
				width: 60px;
			}
		}
	}

	.topup {
		height: 48px;
		align-items: center;

		span {
			width: 100%;
		}
	}

	.StripeElement {
		background: transparent;
		border: 1px solid rgba(250, 232, 220, 0.2) !important;
		border-radius: 12px;
		padding: 4px 11px;
		height: 58px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.3s all ease-in-out;

		.__PrivateStripeElement {
			width: 100%;
		}
	}

	.StripeElement--focus {
		border-color: $themegreen  !important;
	}
}


.TopUpModalStyle {
	padding: 20px;
	.currentbalance {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		background: #0F2240;
		border: 1px solid #273656;
		border-radius: 6px;
		padding: 20px;
		margin-bottom: 28px;

		@media(max-width: 375px) {
			flex-direction: column-reverse;
			padding: 15px;
		}

		.Content {
			.Title {
				font-weight: 600;
				font-size: 18px;
				line-height: 24px;
				color: $offwhite;
			}

			span {
				font-weight: 400;
				font-size: 14px;
				line-height: 24px;
				letter-spacing: 0.001em;
				color: $offwhite;
				opacity: 0.8;
			}
		}

		.value {
			font-weight: 500;
			font-size: 22px;
			line-height: 24px;
			text-align: center;
			letter-spacing: 0.374px;
			color: $themegreen;
			padding-left: 8px;

			@media(max-width: 375px) {
				margin-bottom: 10px;
			}
		}
	}

	.TopUpBalanceBtn {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 20px;
		align-items: center;
		flex-wrap: wrap;
		margin-bottom: 45px;

		@media(max-width:424px) {
			grid-template-columns: 1fr 1fr;
		}

		@media(max-width:319px) {
			grid-template-columns: 1fr;
		}

		.ant-btn {
			font-size: 14px;
			line-height: 1;
			height: 38px;


			span {
				width: 100%;
			}

			&:hover {
				background-color: $themegreen  !important;
				color: $black  !important;
				border-color: $themegreen  !important;
			}
		}

		.selected-ghost {
			background-color: $themegreen  !important;
			color: $black  !important;
			border-color: $themegreen  !important;
		}
	}

	.Topupwith {
		.Title {
			font-weight: 600;
			font-size: 18px;
			line-height: 24px;
			color: $offwhite;
			margin-bottom: 14px;
		}

		.Topupwith_Detail {
			display: block;
			grid-template-columns: 1fr 1fr;
			grid-gap: 20px;

			@media(max-width:424px) {
				grid-template-columns: 1fr;
			}

			a, button {
				border-radius: 6px;
				display: flex;
				align-items: center;
				justify-content: center;

				@media(max-width:424px) {
					max-width: 213px;
					height: 44px;
				}

				img {
					width: 200px;
					object-fit: contain;
					height: 100%;
				}
			}
		}
	}

	.ORText {
		margin-top: 50px;
		margin-bottom: 28px;
	}

	.otherpayment {
		a {
			img {
				width: 57px;
				height: 38px;
			}
		}
	}
}

.showScreen-topupmodal{
	.currentbalance {
		padding: 14px;
		.value {
			font-size: 19px;
			line-height: 22px;
			white-space: nowrap;
		}
	}
	.TopUpBalanceBtn{
		grid-template-columns: 1fr 1fr;
		@media(max-width: 991px) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
		@media(max-width:514px) {
			grid-template-columns: 1fr 1fr 1fr;
		}
		@media(max-width:399px) {
			grid-template-columns: 1fr 1fr ;
		}
	}
	.Topupwith{
		.Topupwith_Detail{
			@media(max-width: 991px) {
				grid-template-columns: 1fr 1fr 1fr 1fr;
			}
			@media(max-width: 767px) {
				grid-template-columns: 1fr 1fr;
			}
			a, button{
				max-width: 190px;
				border-radius: 6px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #fff;
				overflow: hidden;
				height: 45px;
				border: 1px solid #fff;
				margin-right: 6px;
				width: 48%;
				margin-bottom: 6px;
			}
		}
		.TopupOptionButtons{
                    display: flex;
                    flex-wrap: wrap;
                    .StripeElement {
						background: transparent;
            border: 1px solid rgba(250, 232, 220, 0.2) !important;
            border-radius: 12px;
            padding: 4px 11px;
            height: 58px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.3s all ease-in-out;
                        width: 48%;
                        max-width: 212px;
                        margin: 0 6px 6px 0;
                        border: none !important;
                        padding: 0;
                        height: 45px;
                        .GooglePayButton {
                            min-height: 45px;
                        }
                    }
                }
	}
}
.ShippingAddressModalStyle {
	padding: 20px;
	label {
		color: $offwhite;
	}

	.ant-input-affix-wrapper {
		.ant-input {
			color: $offwhite;

			&::placeholder {
				color: rgba(250, 232, 220, 0.5);
			}
		}
	}

	.ant-select {
		height: 58px;

		.ant-select-selector {
			background: transparent;
			height: 100%;
			border-radius: 12px;
			border: 1px solid rgba(250, 232, 220, 0.2) !important;

			&:focus {
				border-color: $themegreen  !important;
			}
		}

		.ant-select-selection-item {
			display: flex;
			align-items: center;
		}

		.ant-select-selection-search-input {
			height: 58px;
		}
	}

	.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border-color: $themegreen  !important;
	}

	.ant-radio-wrapper {
		.ant-radio {
			margin-right: 5px;
		}

		span {
			text-transform: capitalize;
			font-weight: 400;
			font-size: 14px;
			line-height: 24px;
			color: $offwhite;
		}
	}

	.ant-btn {
		height: 48px;
		align-items: center;
		justify-content: center;
	}

	.shippingAddress {
		.Content {
			h5 {
				font-weight: 600;
				font-size: 14px;
				line-height: 24px;
				color: $offwhite;
				margin-bottom: 5px;
			}

			p {
				font-weight: 400;
				font-size: 13px;
				line-height: 20px;
				color: $offwhite;
				opacity: 0.8;
				margin: 0;
			}
		}
	}

	.ShippingAddNote {
		background: $primary;
		border-radius: 10px;
		padding: 12px 20px;
		display: flex;
		align-items: flex-start;
		margin-bottom: 11px;

		.icon {
			display: inline-block;
			width: 20px;
			height: 20px;
			flex: 0 0 20px;
			margin-right: 16px;
			border: 2px solid;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.green {
			border-color: #2ABC01;

			img {
				width: 100%;
				padding: 2px;
				height: 100%;
			}
		}

		.red {
			border-color: #D63028;

			svg {
				color: #D63028;
			}

			img {
				width: auto;
				padding: 2px;
				height: 100%;
			}
		}

		p {
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
			color: $black00;
		}
	}
}

.paymentcarsTitle{
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	border-bottom: 1px solid $borderblue;
	padding: 20px;
	margin-bottom:10px;
	h3{
		font-weight: 600;
		font-size: 16px;
		line-height: 22px;
		color: $offwhite;
		margin-bottom: 0;
	}
	button{
		background-color: transparent;
		outline: none !important;
		border: 0 !important;
		svg{
			width: 23px;
			height: 23px;
		}
	}
}


// payment end



// giveaway modal
.giveawayModal{
	padding-top: 40px;
	.profileWraper{
	  display: flex;
	  align-items: flex-start;
	  flex-wrap: wrap;
	  justify-content: center;
	  margin: 0 -12px;
	  min-height: 550px;
	  .giveaway-user {
		display: flex;
		align-items: center;
		flex-direction: column;
		flex: 1;
		max-width: 25%;
		padding: 0 12px;
		margin-bottom: 20px;
		text-align: center;
		@media(max-width:767px){
			max-width: inherit;
		}
		.giveaway-userImg {
		  width: 88px;
		  height: 88px;
		  margin-bottom: 12px;
		  border-radius: 50%;
		  border: 3px solid transparent;
		  overflow: hidden;
		  img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		  }
		}

		.giveaway-userName{
		  p{
			margin-bottom: 0;
			color: $white;
			text-align: center;
		  }
		}
	  }
	  .user-running{

		.giveaway-userImg{
		  border: 3px solid $borderColor;
		}
	  }
	  .user-active{

		.giveaway-userImg{
		  border: 3px solid $primary;
		}
	  }
	}
	.acceptButton {
		text-align: center;
		margin-top: 20px;
	}
  }
  .fixedPricemodal{
	.giveawayModal {
	  padding-top: 0;
	  .profileWraper{
		min-height:550px;
		.giveaway-userImg{
		  width: 125px;
		  height: 125px;
		}
	  }
	}

  }
// payment end

.ant-spin-dot-item {
    background-color: #0B1836;
}

.show-container{
	margin: 0 auto;
    width: 100%;
    max-width: 1400px;
    padding: 0 40px;
	@media(max-width:767px){
		padding: 0 20px;
	}
}


.paymentFailed{
padding:16px 4px;
background:#EC000F;
display: flex;
align-items: center;
justify-content: center;
}

.CardImg{
	width: 40px;
	height: 12px;
	@media(max-width:351px){
		width: 30px;
	}
}

.mastercard{
	width: 35px !important;
	height: 23px !important;
}
.errorMsg{
	font-weight: 600;
	margin-bottom: 15px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 100%;
}
.CardImg{
	width: 40px;
	height: 12px;
	@media(max-width:351px){
		width: 30px;
	}
}

.mastercard{
	width: 35px !important;
	height: 23px !important;
}

.infoIcon-text {
	display: flex;
    align-items: center;
}

.infoIcon-text > svg {
	line-height: 24px;
    height: 12px;
}

.infoIcon-text > svg > path {
	fill: $themegreen;
}
.walltetIcon{
	padding-left: 10px;
}
.cardIcon{
	display: flex;
	align-items: center;
	color: rgb(216 219 215 / 75%);
	img{
		margin-right: 10px;
	}
}

.ant-tooltip-inner{
	text-align: justify;
	margin-left: 10px;
}

.sendbtn{
	svg{
		width: 22px;
    	margin-left: 4px;
		transition: 0.5s ease-in-out;
		color: $offwhite;
	}
}

.bid-box .congratsBtn{
	cursor: auto;
}

.test-shipping-size-modal {
	color: white;
	font-size: 20px;
}
