@import '../../../../Style/variable.scss';

.ant-modal-body {
    padding-top: 0 !important;
}

.shipPop-sub-heading {
    color: $themegreen;
    font-size: 16px;
    line-height: 20px;
    padding: 0;
    margin: 0;
}

.shipPop-alt-sub-heading {
    color: $primary;
    font-size: 16px;
    padding: 10px 0 0 0;
    margin: 0;
    line-height: 20px;
}

.ship-status-box {
    padding-bottom: 20px;
}

