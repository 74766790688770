
@import '../../../../Style/variable.scss';

.Settingsmenu {
  background: $Headerbg;
  box-shadow: 0px 16px 64px -48px rgb(31 47 70 / 40%);
  border-radius: 12px;
  padding: 32px 16px;
  @media(max-width:767px){
    margin-bottom: 30px;
  }
  .menuListing{
    margin-bottom: 30px;
    &:last-child{
      margin-bottom: 0;
    }
    h5{
      color: $white;
      opacity: 0.5;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      padding-bottom: 12px;
      border-bottom: 1px solid $secondary-strock;
      margin-bottom: 0;
    }
    ul{
      li{
        list-style: none;
        a{
          display: flex;
          align-items: center;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: $peach;
          padding: 18px 0;
          border-bottom: 1px solid #273656;
          &.active{
            color: $themegreen;
          }
          &:hover{
            color: $themegreen;
          }
          svg{
            margin-right: 10px;
            width: 20px;
          }
        }
      }
    }
  }
}