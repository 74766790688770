@import '../../Style/variable.scss';

.prevent-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-carousel .slick-next::before,
.ant-carousel .slick-prev::before {
  font-size: 20px;
  color: $primary;
  line-height: 1px;
}

.ant-carousel .slick-prev {
  left: -52px;

  @media(max-width:1200px) {
    left: -34px;
  }

  @media(max-width:768px) {
    left: -30px;
  }
}

.ant-carousel .slick-next {
  top: 0;
  z-index: 1;
  width: 28%;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: auto;

  @media(max-width:600px) {
    width: 15%;
  }
}

.ant-carousel .slick-prev {
  top: 0;
  z-index: 1;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: auto;
}

.nextarrow {
  top: 40%;
  cursor: pointer;
  z-index: 1;
  border: 1px solid #0CFDE4;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex !important;
  right: -2px;
  align-items: center;
  justify-content: center;
  transform: translateY(-40%);
  right: 0;
  position: absolute;
  @media(max-width:400px) {
    top: 30%;
    transform: inherit;
  }
  @media(max-width:768px){
    top: 31%;
    transform: translateY(-31%);
  }
}

.prevarrow {
  top: 40%;
  cursor: pointer;
  z-index: 1;
  border: 1px solid #0CFDE4;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex !important;
  left: -2px;
  align-items: center;
  justify-content: center;
  transform: translateY(-40%);
  right: 0;
  position: absolute;
  @media(max-width:400px) {
    top: 30%;
    transform: inherit;
  }
}

.ant-carousel .slick-next::before,
.ant-carousel .slick-prev::before {
  display: none;
}

.prevarrow::before {
  content: '';
  background-image: url('../../Assets/Images/SliderArrow.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
  transform: rotate(180deg);
}

.ant-carousel .slick-next {
  background: linear-gradient(270deg, #202C47 22.22%, rgba(32, 44, 71, 0) 100%) !important;

  @media(max-width:767px) {
    right: 0;
    z-index: 1;
    // background: transparent !important;

  }
}

.ant-carousel .slick-prev {
  @media(max-width:767px) {
    left: 0;
    z-index: 1;
  }
}

.langInner {
  margin-top: 20px;
  border: 1px solid $themegreen;
  border-radius: 6px;
  display: inline-block;

  @media(max-width:480px) {
    display: flex;
    flex-direction: column;
  }

  .spanLangtoggle {
    padding: 7px 14px;
    display: inline-block;
    border: 0;
    border-radius: 0;
    border-right: 1px solid $themegreen;
    color: $white !important;
    cursor: pointer;
    background-color: transparent !important;

    @media(max-width:480px) {
      border-right: 0;
    }

    &:last-child {
      border-right: 0;
    }

    &:hover,
    &:focus {
      background-color: $primary  !important;
      color: $darkPurple  !important;
    }
  }

  .active {
    background-color: $primary  !important;
    color: $darkPurple  !important;
  }
}





.mb100 {
  margin-bottom: 100px;
}

.ant-carousel .slick-next div::before {
  content: '';
  background-image: url('../../Assets/Images/SliderArrow.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
}

.interestTag {
  background: #202C47;
  border: 1px solid #2E3954;
  box-shadow: 0px 40px 32px -24px rgb(15 15 15 / 12%);
  border-radius: 6px;
  padding: 30px 30px 10px 30px;
  max-width: 540px;

  .tagsSpans {
    // background: $grayBg;
    color: $primary;
    font-weight: 500;
    display: inline-block;
    padding: 9px 12px;
    margin: 0 10px 20px 0;
    border: 1px solid #3F4E72;
    box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
    border-radius: 6px;
  }
}
.suggestedSection{
  .max-500{
    max-width: 500px;
    padding-right: 10px;
  }
}
.interestTitle {
  max-width: 540px;

  .EditLink {
    color: $primary;
    width: 40px;
    height: 40px;
    border: 1px solid $themegreen;
    display: inline-block;
    border-radius: 50%;
    background-color: transparent !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;

    svg {
      width: 13px;
    }
  }
}