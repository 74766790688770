@import '../../../../Style/variable.scss';
.responsiveDrawer{
  display: none;

  span {
    width: 24px;
    height: 17px;
    background-image: none;
    position: relative;
    border-bottom: 2px solid $secondary-strock;
    transition: all 0.2s linear;
    &:before,
    &:after {
      width: 24px;
      position: absolute;
      height: 2px;
      background-color: $peach;
      top: 0;
      left: 0;
      content: '';
      z-index: 2;
      transition: all 0.2s linear;
    }
    &:after {
      top: 8px;
    }
  }
  @media(max-width:768px){
    display: inline-block;
    color: $secondary-strock;
  }

}
.ant-drawer-right .ant-drawer-content-wrapper{
  width: 70% !important;

}
.ant-drawer-content{
  background-color: $black;
}