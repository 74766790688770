@import '../../../../Style/variable.scss';

.FollowingFollowersList {

    .anticon.ant-input-clear-icon,
    .anticon.ant-input-clear-icon:hover {
        color: $white;
    }

}
.filterright{
    .ant-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        span{
            display: inline-block;
        }
    }
}
.themeModal .ant-modal-content  .ant-modal-body{
    padding-top: 15px;
}
.themeModal .modalFilter{
    padding-bottom: 15px;
    padding-top: 15px;
}
.modalFilter .filterLeft label{
    margin-bottom: 0;
}