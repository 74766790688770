@import '../../../../Style/variable.scss';

.EditProfile{
	display: flex;
	.Namedetail{
	  p{
		font-weight: 600;
	  }
	  span{
		font-weight: 400;
	  font-size: 12px;
	  line-height: 20px;
	  display: flex;
	  align-items: center;
	  color: $primary;
	  }
	}

  .userpicWrapper {
	position: relative;
	width: 128px;
	height: 128px;
	margin-right: 30px;
	margin-bottom: 30px;
	.userpic {
	  border: 2px solid $primary;
	  box-sizing: border-box;
	  border-radius: 100px;
	  width: 128px;
	  height: 128px;
	  overflow: hidden;

	  .LoadingSpinner {
        position: relative;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
      }

	  img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	  }
	}
	.EditIcon{
	  position: absolute;
	  background: $black;
	  border: 2px solid $themegreen;
	  box-sizing: border-box;
	  width: 30px;
	  height: 30px;
	  border-radius: 50%;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  right: 10px;
	  bottom: 0;
	  color: $primary;
	  input{
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;

	  }
	}
  }
	.anticon.ant-input-clear-icon,
	.anticon.ant-input-clear-icon:hover {
		color: #fff;
	}
}

.ant-picker-date-panel{
	background-color: $black;
	border: 0 !important;
	.ant-picker-header {
		color: rgba(0, 0, 0, 0.85);
		border-bottom: 1px solid $secondary-strock;
		button{
			color: $peach;
		}
		.ant-picker-header-view{
			color: $peach;
		}
	}
	.ant-picker-cell{
		color: $peach;
	}
	.ant-picker-footer {
		border-top: 1px solid #363030;
		background: #000;
		color: #fff;
		border: 0;
	}

}
.ant-picker-footer{
	border-top: 0 !important;
	background-color: $black;
}
.ant-picker-panel{
	border: 0;
}
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner
{
	background: #1531e8;
}
a.forgetLink {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #3772FF;
}

.ant-picker-content th, .ant-picker-today-btn {
	color: $peach !important;
}
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: $primary;
}

.deleteuserBtn {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
	color: $primary !important;
}
.deleteUserModal{
	padding-bottom: 30px;
	text-align: center;
	.delteuserImg{
		width: 131px;
		height: 144px;
		margin: 0 auto;
		margin-bottom: 40px;
		img{
			width: 100%;
		}
	}
	p{
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		color: $peach;
	}
	h5{
		font-weight: 600;
		font-size: 22px;
		line-height: 26px;
		text-align: center;
		letter-spacing: 0.374px;
	}
	.deleteuserBtns{
		margin-top: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		.ant-btn{
			color:$themegreen;
			min-width: 150px;
			// margin-right: 15px;
			margin: 7px 8px;
			@media(max-width:380px){
				min-width: 100px;
			}
			&:hover, &:focus{
				color: $darkPurple;
			}
		}
		.Delete_Btn{
			background-color: #FF3364 !important;
			border-color: #FF3364 !important;
			&:hover{
				background-color: rgba($color: #FF3364, $alpha: 0.8) !important;
				border-color: rgba($color: #FF3364, $alpha: 0.8) !important;
			}
		}
	}
}
.filledbtn{
	background-color: $themegreen;
	color: $darkPurple;
}