@import '../../../Style/variable.scss';

.CardEmpty {
  height: 300px;
  width: 250px;
  background-color: #2A3859;
border-radius:16px;
box-sizing: border-box;
display: flex;
flex-direction: column;
margin-bottom: 30px;
margin-left: 0px;
margin-right: 0px;
margin-top: 0px;
overflow-x: hidden;
overflow-y: hidden;
padding-bottom: 0px;
padding-left: 15px;
padding-right: 15px;
padding-top: 0px;
justify-content: center;
align-items: center;
margin-bottom: 80px;
@media(max-width:992px){
  width: 46%;
}
@media(max-width:600px){
  margin-bottom: 40px;
  max-width: 100%;
  width: 100%;
}
@media(max-width:375px){
  margin: 0 auto;
}
}

#noMatchText{
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color:white;
}
#pickInterestText{
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color:$primary;
  cursor: pointer;
}
hr{
  margin-top: 10px;
  margin-bottom: 50px;
  border: 1px solid white;
  width: 60%;
}
.pt-20 {
  padding-top: 20px;
}