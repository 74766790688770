@import '../../Style/variable.scss';

.BreadCrumbs {
  padding: 20px 0;
  background: $black;
  border-bottom: 1px solid $secondary-strock;

  .pagetopheader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin-bottom: 0;
      line-height: normal;
    }
  }
}
.featurebox {
  margin-top: 42px;

  .feature-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .feature-ttitletext {
      font-size: 40px;
      line-height: 48px;
      letter-spacing: -0.01em;
      color: $peach;
      font-weight: 600;

      @media(max-width:768px) {
        font-size: 32px;
        line-height: 42px;
      }

      @media(max-width:768px) {
        font-size: 24px;
        line-height: 32px;
      }
    }
    .ant-btn{
      @media(max-width:374px) {
        padding: 10px;
        height: auto;
        font-size: 12px;
      }
    }
  }
}

.feature-title {
  .title-btn {
    .ant-input-search {
      .ant-input {
        &::-webkit-input-placeholder {
          color: #777E91;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
        }

        &:-ms-input-placeholder {
          color: #777E91;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
        }

        &::placeholder {
          color: #777E91;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
        }
      }

      .anticon-search {
        height: 100%;
        background-image: url('../../Assets/Images/search.svg') !important;
        background-repeat: no-repeat;
        background-position: center center;
        width: 40px;

        svg {
          display: none;
        }
      }
    }
  }
}

.featureCrad {
  margin-bottom: 30px;
  position: relative;

  .featureImagebox {
    position: relative;
    margin-bottom: 10px;
    border-radius: 16px;
    overflow: hidden;

    .featureImg {
      border-radius: 16px;
      overflow: hidden;
      height: 300px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .imgtop {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;

    .viewCount {
      background: rgba($black, 0.44);
      backdrop-filter: blur(24px);
      border-radius: 65.0602px;
      padding: 8.6747px 11.5663px;
      font-size: 12px;
      line-height: 12px;
      color: $peach;
      display: flex;
      align-items: center;

      svg {
        width: 9px;
        margin-right: 5px;
      }
    }

    .rightAlign {
      position: absolute;
      right: 10px;

      svg {
        width: auto;
      }
    }

    .Livespan {
      background: $pink;
      border-radius: 65.0602px;
      padding: 8.6747px 11.5663px;
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 12px;

      svg {
        width: 11px;
        margin-right: 5px;
      }
    }
  }

  .featureProfile {
    position: absolute;
    border: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, rgba(28, 39, 61, 0) 0.29%, rgba(28, 39, 61, 0.53) 16.01%, #1C273D 54.81%);
    border-radius: 0px;
    width: 100%;
    left: 0;
    align-items: center;
    padding: 8px 8px 8px 12px;

    .profile-left {
      display: flex;
      min-width: 116px;
      cursor: pointer;

      @media(max-width:1280px) {
        width: calc(100% - 63px);
      }

      .profileImg {
        width: 30px;
        height: 30px;
        flex: 0 0 30px;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 8px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .userName {
        width: calc(100% - 34px);

        span {
          font-size: 12px;
          line-height: 17px;
        }

        p {
          font-size: 14px;
          line-height: 17px;
          color: $peach;
          margin: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 154px;
          padding-right: 5px;
        }
      }
    }

    .profile-right {
      .followBtn {
        z-index: 2;
        cursor: pointer;
        outline: none;
        font-weight: bold;
        transition: 0.5s ease-in-out;
        color: $peach;
        width: 32px;
        height: 32px;
        background: $darkPurple;
        border: 1px solid $themegreen;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media(max-width:1280px) {
          padding: 2px 5px;
          font-size: 11px;
        }

        &:hover {
          background-color: $hovered;
          color: $darkPurple;
        }
      }


    }
  }

  .Cardtextbox {
    min-height: 110px;
    @media(max-width:600px){
      min-height: auto;
    }
    p {
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      color: $peach;
      -webkit-line-clamp: 2;
      max-height: 78px;

      @media(max-width:767px) {
        min-height: auto;
      }
    }

    .Cardsbtns {
      margin-top: 10px;
      border-top: 1px solid $secondary-strock;
      padding-top: 10px;
      display: flex;

      .notifybtn {
        background: transparent;
        border: 0;
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 20px;
        color: $gray;
        font-weight: 600;
        margin-right: 25px;
        cursor: pointer;

        .notifyicon {
          width: 32px;
          height: 32px;
          background: $secondary;
          border: 1px solid $secondary-strock;
          box-sizing: border-box;
          border-radius: 50%;
          display: inline-block;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          transition: 0.5s ease-in-out;
          color: $peach;
        }

        &:hover {
          .notifyicon {
            background: $primary;
            border: 1px solid $primary;

          }
        }
      }
    }

    .hastagspan {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: $primary;
      @media(max-width:767px) {
        min-height: auto;
      }
    }

    span {
      color: $primary;
      font-weight: 500;
    }

    .gameHeighlight{
      background: rgb(144 139 199 / 22%);
      padding: 4px 11px;
      border-radius: 20px;
      display: inline-block;
      margin: 0 5px;
      &:first-child{
        margin-left: 0;
      }
    }
  }
}

.ant-carousel {
  color: $peach;

  .slick-slide {
    padding: 0 15px;

    @media(max-width:600px) {
      padding: 0 0;
    }
  }

  .slick-track {
    margin: 0 -15px;

    @media(max-width:600px) {
      margin: 0 0;
    }
  }
}


.featureCardlist {
  position: relative;
  z-index: 0;

}

.Featureoverlay {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  cursor: pointer;

  @media(max-width:767px) {
    background-color: #202c4769;
  }
}

.imgtop {
  align-items: flex-start;

  .rightImgtop {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    >span,
    a {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .viewCount {
      position: absolute;
      left: 10px;
      right: auto;
    }

    .cardShare {
      width: 32px;
      height: 33px;
      background: $darkPurple;
      border: 1px solid $themegreen;
      box-sizing: border-box;
      border-radius: 16px;
      display: flex;
      align-items: center;
      color: $primary;
      justify-content: center;
    }

    .markiocn {
      background-color: transparent;
      border: none;
    }
  }
}

.HasTag {
  display: block;
  color: $primary;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 600;
}


.featurebox {
  .slick-list {
    position: relative;
    z-index: 0;

    // &::after {
    //   content: "";
    //   position: absolute;
    //   background: linear-gradient(270deg, #202C47 16.22%, rgba(32, 44, 71, 0) 100%);
    //   right: 0;
    //   top: 0;
    //   width: 25%;
    //   height: 100%;
    //   transition: 0.5s ease-in-out;
    // }

    // &::before {
    //   content: "";
    //   position: absolute;
    //   background: linear-gradient(-270deg, #202C47 16.22%, rgba(32, 44, 71, 0) 100%);
    //   left: 0;
    //   top: 0;
    //   width: 25%;
    //   height: 100%;
    //   z-index: 1;
    // }
    @media(max-width:600px){
      height: auto !important;
    }
  }

  .shadowRemove {
    .slick-list {

      &::after {
        display: none;
      }
    }
  }
}

.featurebox .slick-arrow.slick-prev.slick-disabled+.slick-list::after {
  opacity: 1;
}


.shadowRemove {
  .featurebox {
    .slick-list {
      &::after {
        display: none;
      }
    }
  }
}