@import '../../Style/variable.scss';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,800&display=swap');
@font-face {
  font-family: "Atrament";
  src: url("../../Assets/fonts/Atrament Bold.woff.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Atrament";
  src: url("../../Assets/fonts/Atrament_font_family/AtramentStdMedium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Atrament";
  src: url("../../Assets/fonts/Atrament_font_family/AtramentStd-Light.otf");
  font-weight: lighter;
}
@font-face {
  font-family: "Oswald";
  src: url("../../Assets/fonts/Oswald-VariableFont_wght.ttf");
}

::selection{
  background-color: $darkPurple;
  color: #fff;
}

.b-container {
  margin: 0 auto;
  width: 100%;
  max-width: 1310px;
  padding: 0 20px;
}
.BirthdayhomeStyles {
  background-color: $darkPurple;
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;
  .b-nav{
    text-align: center;
    padding: 30px 0;
    position: relative;
    // without the language picker, keep it centered
    // @media(max-width:767px){
    //   text-align: left;
    // }
    .b-logo{
      display: inline-block;
      img{
        max-width: 124px;
        @media(max-width:767px){
          max-width: 82px;
        }
      }
    }
  }

  .greeting-inner {
      border: 6px solid $themegreen;
      border-radius: 40px;
      padding: 0 0 0 35px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1100px;
      position: relative;
      margin: 90px auto 0 auto;
      @media(max-width:1024px){
        max-width: 900px;
      }
      @media(max-width:1024px){
        max-width: 900px;
      }
      @media(max-width:991px){
        border-radius: 30px;
      }
      @media(max-width:767px){
        border-radius: 16px;
        padding: 0 0 0 35px;
        margin: 34px auto 0 auto;
      }
      .celebrationimg {
        position: absolute;
        top: -110px;
        left: -90px;
        background: $darkPurple;
        padding: 11px;
        @media(max-width:1281px){
          left: 15px;
        }
        @media(max-width:991px){
          left: -10px;
          top: -28px;
        }

        img{
          max-width: 145px;
          @media(max-width:1281px){
            max-width: 120px;
          }
          @media(max-width:991px){
            max-width: 50px;
          }
          @media(max-width:767px){
            max-width: 25px;
          }
        }
      }
      .greeting-left{
        display: flex;
        align-items: center;
        img{
          width: 274px;
          @media(max-width:1281px){
            width: 200px;
          }
          @media(max-width:991px){
            width: 80px;
          }
          @media(max-width:767px){
            width: 53px;
          }
        }
        .turn{
          font-size: 60px;
          color: $themegreen;
          padding-left: 34px;
          top: 17px;
          position: relative;
          @media(max-width:1281px){
            font-size: 44px;
          }
          @media(max-width:1281px){
            font-size: 23px;
            padding-left: 34px;
            top: 5px;
          }
          @media(max-width:767px){
            font-size: 16px;
            padding-left: 12px;
            top: 3px;
          }
          @media(max-width:386px){
            font-size: 12px;
            top: 5px;
          }
        }
      }
      .greeting-center{
        width: 100px;
        height: 200px;
        display: block;
        position: relative;
        z-index: 1;
        @media(max-width:991px){
          width: 70px;
          height: 88px;
        }
        @media(max-width:767px){
          width: 41px;
          height: 56px;
        }
        .number{
          font-size: 200px;
          position: absolute;
          top: -20px;
          display: inline-block;
          color: $offwhite;
          left: 50%;
          font-weight: bold;
          transform: translateX(-50%);
          font-family: "Oswald";
          @media(max-width:991px){
            font-size: 100px;
            top: -20px;
          }
          @media(max-width:767px){
            font-size: 44px;
            top: 15px;
          }
        }
        &::after{
          position: absolute;
          content: "";
          background: $darkPurple;
          width: 50px;
          height: 20px;
          left: 54%;
          bottom: -6px;
          z-index: -1;
          transform: translateX(-50%);
        }
      }
      .greeting-right {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 0;
        >img{
          max-width: 154px;
          @media(max-width:1281px){
            max-width: 133px;
          }
          @media(max-width:991px){
            max-width: 50px;
          }
          @media(max-width:767px){
            max-width: 43px;
          }
          @media(max-width:386px){
            max-width: 35px;
          }
        }
        .date-span{
          font-size: 64px;
          line-height: 75px;
          background: $primary;
          padding: 17px 22px;
          border-radius: 30px;
          color: $darkPurple;
          position: relative;
          right: -60px;
          font-weight: 500;
          font-family: "Atrament";
          @media(max-width:1281px){
            font-size: 54px;
            line-height: 64px;
            padding: 7px 22px;
            border-radius: 20px;
            right: -34px;
          }
          @media(max-width:991px){
            font-size: 16px;
            line-height: 22px;
            padding: 7px 22px;
            border-radius: 8px;
            right: -16px;
          }
          @media(max-width:767px){
            font-size: 16px;
            line-height: 14px;
            padding: 4px 10px;
            border-radius: 8px;
            right: -15px;
          }
          @media(max-width:386px){
            font-size: 12px;

          }
          &::after{
            position: absolute;
            content: "";
            width: 100%;
            height: 150px;
            left: 0;
            top: -21px;
            background: $darkPurple;
            z-index: -1;
            @media(max-width:1281px){
              height: 130px;
              top: -27px;
            }
            @media(max-width:991px){
              height: 48px;
              top: -5px;
            }
            @media(max-width:767px){
              height: 33px;
              top: -5px;
            }
          }
        }
    }
  }
  .sellwatch-block {
    margin-top: 100px;
    position: relative;
    z-index: 0;
    @media(max-width:991px){
      margin-top: 50px;
    }
    .sellwatch-inner {
      display: flex;
      @media(max-width:991px){
        flex-direction: column;
      }
      .sellwatch-left {
        width: 50%;
        display: flex;
        flex: 1;
        padding-right:20px;
        padding-left:10px;

        @media(max-width:991px){
          width: 100%;
          padding: 0 10px;
        }
        .sellwatchBox{
          width: 100%;
          height: 100%;
          border: 2px solid $primary;
          border-radius: 11px;
          @media(max-width:991px){
            margin-bottom: 18px;
          }

          .sellwatchBox-top{
            align-items: center;
            padding: 36px;
            display: flex;
            justify-content: space-between;
            background: $primary;
            border-radius: 8px;
            min-height: 350px;
            @media(max-width:767px){
              align-items: center;
              padding: 18px;
              border-radius: 11px;
              min-height: auto;
            }
            h2{
              font-size: 128px;
              line-height: 122px;
              letter-spacing: 0.005em;
              font-family: "Atrament";
              margin: 0;
              font-weight: bold;
              @media(max-width:1440px){
                font-size:64px;
              line-height:76px;
              }
              @media(max-width:1024px){
                font-size:66px;
                line-height: 90px;
              }
              @media(max-width:767px){
                font-size: 40px;
                line-height: 45px;
                font-weight: bold;
              }
            }
            .titleImg {
              img{
                max-width: 174px;
                @media(max-width:1024px){
                  max-width: 100px;
                }
                @media(max-width:767px){
                  max-width: 62px;
                }
              }
            }
          }
        }
        .sellwatchBox-bottom{
          padding: 36px;
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          border-top: 0;
          min-height: 298px;
          @media(max-width:767px){
            padding: 20px;
          }
          .featuredImg{
            max-width:650px;
            width: 100%;
            img{
              width: 100%;
            }
            .featureCardlist{
              .featureImg{
                height: 150px;
              }
              .featureProfile{
                border-bottom-right-radius: 15px;
                border-bottom-left-radius: 15px;

                .cardShare{
                  width: 18px;
                  height: 18px;
                  svg{
                    width: 9px;
                  }

                  background: #23262F;
                  border: 1px solid #353945;
                  box-sizing: border-box;
                  border-radius: 16px;
                  display: flex;
                  align-items: center;
                  color: #FCFCFD;
                  justify-content: center;

                  position: absolute;
                  right: 20px;
                  top: 50%;
                }
              }
            }
            .ant-row{
              margin: 0 -4px !important;
              .ant-col{
                padding: 0 4px !important;
                .profile-left{
                  @media(max-width:1440px){
                    // flex-direction: column;
                  }
                }
                .cardShare{
                  z-index: 1;
                }
              }
            }

            .viewCount{
              font-size: 11px;
              padding: 6.6747px 5.5663px;
            }
            .userName{
              .profileBadge {
                display: flex;
                align-items: center;
              }
              p{
                font-size: 15px;
                font-weight: 600;
              }
              span{
                font-size: 11px;
              }
            }
            .Cardtextbox{
              p{
                font-size: 15px;
              }
              span{
                font-size: 13px;
                color: $primary;
              }
            }


          }

        }

        .featuredshow{
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media(max-width:1200px){
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }
      .sellwatch-right {
        width: 50%;
        flex: 1;
        padding-left:20px;
        padding-right:10px;

        @media(max-width:991px){
          width: 100%;
          padding: 0 10px;
        }
        .sellwatchBox{
          height: 100%;
          border-radius: 11px;
          border: 2px solid $offwhite;
        }
        .sellwatchBox-top {
          display: flex;
          align-items: center;
          padding: 36px;
          display: flex;
          justify-content: space-between;
          background: $offwhite;
          border-radius: 8px;
          min-height: 350px;
          @media(max-width:767px){
            align-items: center;
            padding: 18px;
            border-radius: 11px;
            min-height: auto;
          }
          .titleImg {
            img{
              max-width: 250px;
              @media(max-width:1200px){
                max-width: 192px;
              }
              @media(max-width:767px){
                max-width: 116px;
              }
            }
          }
          h2{
            font-size: 128px;
            line-height: 122px;
            letter-spacing: 0.005em;
            color: $darkPurple;
            font-family: "Atrament";
            margin: 0;
            text-align: right;
            font-weight: bold;
            @media(max-width:1440px){
              font-size: 64px;
              line-height: 76px;
            }
            @media(max-width:1024px){
              font-size: 56px;
            }
            @media(max-width:767px){
              font-size: 40px;
              line-height: 45px;
              font-weight: bold;
            }
          }
        }
        .sellwatchBox-bottom{
          padding: 36px;

          border-top-right-radius: 0;
          border-top-left-radius: 0;
          border-top: 0;
          min-height: 284px;
          @media(max-width:767px){
            padding: 20px;
          }
        }
      }
      .featured-text{
        // max-width: 208px;
        margin-left: 0;

        @media(max-width:1200px){
          margin-left: 0;
        }
        @media(max-width:767px){
          max-width: 100%;
          margin-left: 0;
          // text-align: center;
        }
        p{
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 19px;
          letter-spacing: 0.374px;
          color:$primary;
          font-weight: 600;
          span{
            color:$primary;
          }
        }
      }
    }
    .sellwatchBox-title{
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      h5{
        font-family: 'Atrament';
        font-style: normal;
        font-weight: 600;
        font-size: 27px;
        line-height: 24px;
        letter-spacing: 0.374px;
        color: $offwhite;
        margin-bottom: 0;
      }
      a{
        color: $themegreen;
        display: flex;
        align-items: center;
        svg{
          margin-left: 5px;
          transition: 0.5s ease-in-out;
          position: relative;
          right: 0;
        }
        &:hover{
          svg{
            right: -4px;
          }
        }
      }
    }
  }
  .service-section{
    margin-top: 122px;
    @media(max-width:767px){
      margin-top: 60px;
    }
    .service-column {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media(max-width:767px){
        flex-direction: column;
      }
      .service-block {
        text-align: center;
        padding: 0 10px;
        max-width: 260px;
        @media(max-width:767px){
          margin-bottom: 50px;
        }
        .s-content {
          margin-top: 30px;
          h4 {
            font-size: 27px;
            font-family: "Atrament";
            line-height: 31.64px;
            color: $offwhite;
          }
          p{
            font-size: 19px;
            line-height: 24px;
            color: $offwhite;
          }
        }
      }
    }
  }
  .b-getStarted {
    margin-top: 50px;
    position: relative;
    padding: 290px;
    background-image: url('../../Assets/Images/bdayImg/Vector.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    background-size: 100% 100%;
    background-position: center;

    @media(max-width:1366px){
      padding: 180px;
    }
    @media(max-width:1024px){
      padding: 150px 57px;
      background-size: cover;
    }
    @media(max-width:991px){
      background-position: right;
    }
    @media(max-width:767px){
      background-image: url('../../Assets/Images/bdayImg/mobileShape.png');
      padding: 130px 0 50px 0;
      background-position: top;
      background-size: contain;
      margin-top: 0;
    }
    @media(max-width:446px){
      padding: 92px 0 50px 0;
    }

    .contentRight {
      max-width: 600px;
      position: absolute;
      right: 30px;
      bottom: 0;
      @media(max-width:1280px){
        max-width: 500px;
        bottom: 72px;
      }
      @media(max-width:1024px){
        max-width: 400px;
      }
      @media(max-width:991px){
        position: relative;
        right: 0;
        bottom: 0;
        margin-top: 20px;
      }
      img{
        width: 100%;
      }

    }
    .b-getStarted-content {
      display: flex;
      align-items: center;
      position: relative;
      .contentLeft {
        max-width: 600px;
        @media(max-width:1366px){
          max-width: 500px;
        }
        @media(max-width:768px){
         text-align: center;
         max-width: 100%;
        }
        h4{
          font-size: 53px;
          line-height: 60px;
          letter-spacing: 0.374px;
          color: $darkPurple;
          font-family: "Atrament";
          margin-bottom: 12px;
          @media(max-width:375px){
            font-size: 38px;
            line-height: 40px;
           }
        }
        .subitalic{
          font-style: italic;
          color: $offwhite;
          font-weight: 700;
          margin-bottom: 30px;
          @media(max-width:375px){
            margin-bottom: 12px;
           }
        }
        p{

          font-size: 19px;
          line-height: 24px;
          letter-spacing: 0.374px;
          color: $darkPurple;
        }
        .downloadsLink {
          display: flex;
          align-items: center;
          margin-top: 40px;
          img{
            @media(max-width:425px){
              max-width: 125px !important;
            }
          }
          @media(max-width:768px){
            justify-content: center;
           }
          @media(max-width:767px){
            align-items: flex-start;
            margin-top: 80px;
          }
          @media(max-width:365px){
            align-items: flex-start;
          }
          a{
            display: inline-block;
            margin-right: 20px;
            &:last-child{
              margin-right: 0;

            }
            svg{
              width: 200px;
              @media(max-width:767px){
                width: 150px;
              }
              @media(max-width:385px){
                width: 100px;
              }
            }
          }
        }
      }
    }
    .bottomLeftShape {
      position: absolute;
      border: 0;
      left: 0;
      bottom: 0;
      @media(max-width:1024px){
        bottom: -122px;
      }
      @media(max-width:991px){
        display: none;
      }
      max-width: 175px;
      img{
        width: 100%;
      }
    }
  }

  .bottomfooter {
    padding: 200px 0 74px 0;
    @media(max-width:767px){
      padding: 100px 0 30px 0;
    }
    @media(max-width:767px){
      padding: 20px 0 30px 0;
    }
    .finner {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      @media(max-width:991px){
        flex-direction: column;
      }
      .footerlogo {
        max-width: 200px;
        @media(max-width:991px){
          margin-bottom: 40px;
        }
        img{
          max-width: 185px;
          @media(max-width:767px){
           max-width: 100px;
          }
        }
      }
      .footerright {
        display: flex;
        justify-content: space-between;
        flex: 1;
        padding-left: 200px;
        padding-top: 29px;
       @media(max-width:1024px){
        padding-left: 0;
       }
        @media(max-width:991px){
          flex-direction: column;
        }
      }
      .linkssec{
        display: flex;
        width: calc(100% - 300px);
        justify-content: space-between;
        align-items: flex-start;
        @media(max-width:768px){
          width: 100%;
        }
      }
      .emailblock{
        label{
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: $darkPurple;
        }
        .ant-input{
          background-color: $Headerbg;
          border-radius: 11px;
          height: 44.13px;
          width: 266.11px;
          border-color: $Headerbg;
          font-style: normal;
          font-weight: 300;
          font-size: 15px;
          line-height: 36px;
          color: $primary;
          cursor: pointer;
          &::placeholder{
            color: $primary;
          }
          @media(max-width:320px){
            width: 206.11px;
          }
        }
      }
        .f-downloadlogo {
          display: flex;
          flex-direction: column;
          top: 5px;
          position: relative;
          @media(max-width:768px){
            display: none;
          }
          // a{
          //   display: inline-block;
          //   margin-bottom: 8px;
          //   svg{
          //     width: 99px;
          //   }
          //   &:last-child{
          //     position: relative;
          //     top: -32px;
          //   }
          // }
       }
       .footerlinks {
        display: flex;
        flex-direction: column;
        @media(max-width:767px){
          padding-right: 12px;
        }
          a {
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 169.9%;
            color: $primary;
            padding-bottom: 10px;
            transition: 0.5s ease-in-out;
            &:hover{
              color: $themegreen;
            }
          }
        }
        .socials {
          border: 4px solid $themegreen;
          display: flex;
          flex-direction: column;
          padding: 6px;
          border-radius: 16px;
          align-items: center;
          position: relative;
          top: -28px;
          @media(max-width:991px){
            width: 50px;
            position: absolute;
            top: 16px;
            right: 0;

          }
          @media(max-width:375px){
            top: 0;
          }
          a{
            display: inline-block;
            padding-bottom: 2px;
            transition: 0.5s ease-in-out;
            color: $primary;
            &:hover{
              color: $themegreen;
            }
          }
        }
    }
  }
  .lan-switch .switch {
    position: absolute;
    width: 80px;
    height: 38px;
    display: block;
    margin: auto;
    top: 52%;
    margin-bottom: auto;
    right: 0px;
    transform: translateY(-52%);
    @media(max-width:767px){
      width: 66px;
      height: 30px;
    }
  }

  .lan-switch .switch input {
    display: none;
  }




  .lan-switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s;
    border: 3px solid $themegreen;
    border-radius: 8px;
  }
  /*Moving SLider Section*/

  .lan-switch .slider::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 50%;
    left: 0;
    bottom: 0;
    background-color: $primary;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 5px;
  }

  /*Slider Text*/

  .slider_text {
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    font-size: 10px;
    width: 100%;
    text-align: center;
    > span {
      width: 50%;
      display: block;
      float: left;
      transition: 0.4s;
      font-weight: 400;
      font-size: 27px;
      line-height: 32px;
      color: $offwhite;
      font-family: "Atrament";
      @media(max-width:767px){
        font-size: 18px;
        line-height: 24px;
      }
    }

  }



  /*Changes on Slide*/


  .lan-switch input:checked + .slider::before {
    -webkit-transform: translateX(38px);
    -ms-transform: translateX(38px);
    transform: translateX(38px);
    @media(max-width:767px){
      -webkit-transform: translateX(30px);
      -ms-transform: translateX(30px);
      transform: translateX(30px);
    }

  }

  .lan-switch input:checked + .slider .off {
    color: $offwhite;
  }

  .lan-switch input:checked + .slider .on {
    color: $darkPurple;
  }

  .lan-switch .slider .slider_text .on {
    color: $offwhite;
  }

}




.checkedbox{
  position: relative;
  margin-bottom: 12px;
}
.checkedbox input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
  z-index: 1;
}

.checklabel > .designcheck {
	width: 26px;
	height: 26px;
	display: flex;
	justify-content: center;
	border: 3px solid $primary;
	margin-right: 15px;
	border-radius: 3px;
	transition: all .3s;
}

.checkedbox input:checked + .checklabel > .designcheck {
	border: 13px solid $themegreen;
	animation: bounce 250ms;
}
.checkedbox  .checklabel > .designcheck::before {
	content: '';
  position: absolute;
  top: 9px;
  left: -1px;
  animation: checked-box 125ms 250ms forwards;
  width: 26px;
  height: 26px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../Assets/Images/bdayImg/Vectorplus.png');

}
@media(max-width:1800px){
  .checkedbox  .checklabel > .designcheck::before {
    top: 8px;
    left: 0px;

  }
}
.checkedbox input:checked + .checklabel > .designcheck::before {
	content: '';
  position: absolute;
  top: 9px;
  left: 1px;
  animation: checked-box 125ms 250ms forwards;
  width: 26px;
  height: 26px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../Assets/Images/bdayImg/Vectortrue.png');
}

.checkedbox input:checked + .checklabel > .textinputbox{
  background: $primary;
  color: $darkPurple;

}
.checkedbox  .textinputbox{
  border-radius: 11px;
  height: 44px;
  padding: 16px;
  color:$primary;
  font-weight: 400;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-weight: 700;
  background: $darkblack;
  transition: 0.5s ease-in-out;
  width: calc(100% - 50px);
}
.checklabel {
  display: flex;
  align-items: center;
}


.formoutbox {
  display: flex;
  margin: 0 -19px;
  @media(max-width:1200px){
    flex-direction: column;
  }
  .formleft {
    flex: 1;
    padding: 0 19px;
    @media(max-width:1200px){
      margin-bottom: 30px;
    }
  }
  .formright {
    flex: 1;
    padding: 0 19px;
    .twin{
      input{
        &:first-child{
          margin-right: 10px;
        }
      }
    }
    .formInputs {
      margin-bottom: 12px;
      display: flex;
      input {
        border-radius: 11px;
        height: 44px;
        padding: 16px;
        color: $primary;
        font-weight: 400;
        font-size: 15px;
        display: flex;
        align-items: center;
        font-weight: 700;
        background: $darkblack;
        transition: 0.5s ease-in-out;
        width: 100%;
        border: 0;
      }
    }
    .formBtngreen{
      .grenbtn {
        border: 3px solid $themegreen;
        border-radius: 11px;
        width: 100%;
        background: transparent;
        padding: 9px 22px;
        font-weight: 600;
        font-size: 15px;
        line-height: 19px;
        letter-spacing: 0.374px;
        color: $themegreen;
        transition: 0.5s ease-in-out;
        .hmmerspan{
          margin-right: 20px;
          top: -3px;
        }
        // &:hover{
        //   background-color: #0CFDE4;
        //   color: $darkblack;
        // }
      }
    }

  }
}




.hmmerspan {
  position: relative;
  display: inline-block;

  span{
    display: inline-block;
  }
  .hammerleft {
    position: absolute;
    left: 16px;
    bottom: -11px;
    transition: 0.5s ease-in-out;
  }
  .hammerright {
    position: absolute;
    bottom: -11px;
    left: 19px;
    transition: 0.5s ease-in-out;
  }
  .hammerplat {
    position: absolute;
    left: 16px;
    bottom: -11px;
  }
}
.grenbtn{
  &:hover{
    .mainHmmer{
        animation: hammer  0.5s ease-in-out;
    }
    .hammerright {
      animation: right  0.5s ease-in-out;
    }
    .hammerleft {
      animation: left  0.5s ease-in-out;
    }
  }
}
.mainHmmer{
  transition: 0.5s ease-in-out;
}

@keyframes right {
	0% {
    bottom: -11px;
    left: 19px;
    opacity: 1;
	}

	20% {
    bottom: -10px;
    left: 22px;
    opacity: 1;
	}

	40% {
    bottom: -9px;
    left: 24px;
    opacity: 1;
	}

	50% {
    bottom: -7px;
    left: 27px;
	}
	60% {
    bottom: -9px;
    left: 24px;
    opacity: 0;
	}

	72% {
    bottom: -10px;
    left: 22px;
    opacity: 0;
	}
	80% {
    bottom: -10px;
    left: 22px;
    opacity: 0;
	}
	100% {
    bottom: -11px;
    left: 19px;
    opacity: 0;
	}
}


@keyframes left {
	0% {
    left: 16px;
    bottom: -11px;
    opacity: 1;
	}

	20% {
    left: 15px;
    bottom: -10px;
    opacity: 1;
	}

	40% {
    left: 11px;
    bottom: -9px;
    opacity: 1;
	}

	50% {
    left:7px;
    bottom: -8px;
    opacity: 1;
	}
	60% {
    left: 11px;
    bottom: -9px;
    opacity: 0;
	}

	72% {
    left: 15px;
    bottom: -10px;
    opacity: 0;
	}
	80% {
    left: 15px;
    bottom: -10px;
    opacity: 0;
	}
	100% {
    left: 16px;
    bottom: -11px;
    opacity: 0;
	}
}


@keyframes hammer {
	0% {
		transform: translateY(1px) rotate(0deg);
	}
	10% {
		transform:translateY(3px) rotate(10deg);
	}
	20% {
		transform:translateY(4px) rotate(20deg);
	}
	30% {
		transform:translateY(6px) rotate(30deg);
	}
	40% {
		transform:translateY(7px) rotate(40deg);
	}

	50% {
		transform:translateY(8px) rotate(50deg);
	}
	60% {
		transform:translateY(7px) rotate(40deg);
	}
	70% {
		transform:translateY(6px)  rotate(30deg);
	}
	72% {
		transform:translateY(4px) rotate(30deg);
	}
	80% {
		transform:translateY(3px) rotate(20deg);

	}
	100% {
		transform:translateY(1px) rotate(0deg);
	}
}




// 16june
.greeting-block{
  display: none;
}