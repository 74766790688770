@import '../../../../Style/variable.scss';
.terms-content {
  padding-left: 15px;
  li{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #777E91;
    margin-bottom: 25px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
.privacy-box{
  border-top: 1px solid $secondary-strock;
  padding: 16px 0 20px 0;
  h5{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FCFCFD;
  }
  p{
    color: #777E91;
    margin-bottom: 8px;
  }
}