$white:#fff;
$peach:#FAE8DC;
$primary:#B2A8EE;
$secondary:#1C2740;
$secondary-strock:#1B325B;
$borderColor: #E6E8EC;
$black:#202C47;
$black00:#000;
$borderblue:#2B3A5F;

$green:#C0EE3C;
$pink:#EB184B;
$gray: #777E90;

$LightBlck:#0e0e0e;
$Headerbg: #0B1836;

$borderGreen:#45B36B;
$darkgray:#B1B5C4;
$purple:#6C63FF;
$purpleLight:#1B325B;


$grayBg:#353B48;
$grayWhite:#B5C4DC;

$darkPurple:#202C47;

$themegreen:#0CFDE4;
$hovered:#0BC5B1;
$offwhite:#FAE8DC;
$darkblack:#0B1836;

$blueInput:#0C172E;
$peach2:#FFE3D6;

$hovered2: rgba(20, 173, 168, 0.2);
