@import'../../../../Style/variable.scss';

.Profileaside {
  box-sizing: border-box;
  border-radius: 16px;
  position: relative;
  top: -112px;
  background: $darkPurple;
  border: 2px solid $themegreen;
  border-radius: 17px;

  @media(max-width:767px) {
    top: -60px;
    margin-bottom: 30px;
  }

  .user-profile {
    display: flex;
    padding: 30px 40px;

    @media(max-width:768px) {
      flex-direction: column;
      padding: 20px;
    }

    .user-name {
      text-align: left;
      margin-bottom: 20px;

      h4 {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: left;
        color: $offwhite;
        margin-bottom: 2px;
      }

      p {
        font-weight: 500;
        font-size: 13px;
        line-height: 17px;
        color: $offwhite;
      }
    }

    .userImg {
      width: 115px;
      height: 115px;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid $primary;
      box-sizing: border-box;
      margin-bottom: 12px;
      margin-right: 24px;

      .LoadingSpinner {
        position: relative;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .user-follower {
    display: flex;
    align-items: center;

    .count-div {
      text-align: left;
      padding-right: 20px;
      border-right: 1px solid $themegreen;
      margin-right: 20px;
      cursor: pointer;

      &:last-child {
        padding-right: 0;
        margin-right: 0;
        border-right: 0;
      }

      p {
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: #FAE8DC;
        margin-bottom: 0;
        text-align: center;
      }

      span {
        font-weight: 400;
        font-size: 13px;
        line-height: 17px;
        letter-spacing: -0.2px;
        color: $primary;
      }
    }
  }

  .Profile-about {


    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 20px;
    }

    >span {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: $peach;
    }



    .Profile-about {
      padding-top: 10px;

      a {
        display: inline-block;
        padding-right: 16px;
      }
    }

    .follow-block {
      .about-link {
        border: 2px solid $secondary-strock;
        box-sizing: border-box;
        border-radius: 40px;
        display: inline-flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        transition: 0.5s ease-in-out;

        &:hover {
          background: $primary;
          color: $peach;
          border-color: $primary;
        }
      }
    }


  }

  .border-top {
    border-top: 1px solid $secondary-strock  !important;
    padding-top: 26px;
  }

  .bor0 {
    border-top: 0 solid $secondary-strock  !important;
    padding-top: 0;
  }
}

.ant-dropdown-arrow {
  width: 32px;
  height: 32px;
  border-width: 10.242641px;
  border-radius: 7px;
  border-color: $primary transparent transparent $primary  !important;
  top: -16px;

  &:before {
    background: $primary;
  }
}

.ant-dropdown-menu {
  box-shadow: 0px 16px 64px -48px rgb(31 47 70 / 40%);
  border-radius: 12px;
  min-width: 235px;
  padding: 14px 20px 24px 20px;

  .ant-dropdown-menu-item {
    padding: 14px 0;
    border-bottom: 1px solid #424A5A;
    background-color: $primary  !important;
    font-weight: 500;

    &:hover {
      color: $darkPurple;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }

    &:hover {
      background-color: transparent;
    }
  }

  .ant-dropdown-menu-title-content {
    a {
      color: $darkPurple;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;

      &:hover {
        color: $peach;
      }

      >span {
        display: flex;
        align-items: center;
        margin-right: 5px;
      }


    }
  }
}

.followerModal {
  .ant-modal-content {
    padding: 0;

    .ant-modal-header {
      border-bottom: 1px solid $secondary-strock  !important;
      padding: 32px 22px !important;
    }
  }
}

.title-btn {
  .ant-input-group {
    border: 2px solid rgba(255, 255, 255, 0.21);
    box-sizing: border-box;
    border-radius: 6px;

    .ant-input {
      background: transparent;
      border: 0;
      height: 40px;
      max-width: 352px;
      width: 100%;
      color: $gray;
      min-width: 300px;

      @media(max-width:767px) {
        width: 100%;
        min-width: 100%;
      }
    }
    .ant-input-suffix {
      position: absolute;
      top: 2px;
      right: -30px;
      display: inline-block;
      z-index: 1;
      cursor: text;
      .anticon.ant-input-clear-icon{
        background: #0B1836;
        display: inline-block;
        padding: 15px 10px;
        cursor: pointer;
      }
    }
    .ant-input-group-addon {
      background: transparent;
      border: 0;

      .ant-btn {
        background: transparent;
        border: 0;
        color: $gray;
        padding: 0;
        &::after{
          content: none;
        }
        cursor: text;
        &:focus,&:active{
          outline: none;
          box-shadow: none;
        }
        svg {
          // color: $peach;
        }
      }
    }
  }
}

.modalFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px 32px 32px;
  border-bottom: 1px solid $secondary-strock  !important;

  @media(max-width:767px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 15px 32px 15px;
  }

  .filterLeft {
    label {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-transform: capitalize;
    }

    @media(max-width:767px) {
      margin-bottom: 10px;
    }
  }
}

.hastags {
  span {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    display: inline-block;
    padding-right: 10px;
    padding-bottom: 4px;
  }
}

.ant-radio-inner {
  background: #173058;
  border: 1px solid #334D76;
}

.ant-radio-inner::after {
  background-color: $darkPurple;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $themegreen;
  background-color: $themegreen;
}

.ant-radio-input:focus+.ant-radio-inner {
  box-shadow: 0 0 0 0 $primary;
  outline: none;
  border: none;
}

.followerModal {
  .ant-radio-wrapper.label {
    color: $gray  !important;
  }
}

.followerModal {
  .ant-radio-wrapper-checked.label {
    color: $peach  !important;
  }
}


.followList {
  padding: 32px;
  min-height: 650px;

  @media(max-width:767px) {
    padding: 32px 15px 15px 15px;
  }

  .follow-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .follow-left {
      display: flex;
      align-items: center;

      .follower-name {
        @media(max-width:425px) {
          max-width: 95px;
          margin-right: 10px;
        }
      }

      p {
        color: $peach;
        font-weight: 500;
      }

      span {
        color: $primary;
      }

      .follower-img {
        width: 54px;
        height: 54px;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 14px;
        @media(max-width:374px) {
          width: 34px;
          height: 34px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .follow-btn {
    background: transparent;
    border-radius: 100px;
    border: 1px solid $themegreen;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $themegreen;
    height: 42px;
    min-width: 114px;
    border-radius: 100px;
    font-weight: bold;
    transition: 0.5s ease-in-out;
    @media(max-width:767px) {
      min-width: 100px;
    }
    @media(max-width:374px) {
      min-width: 90px;
      height: auto;
    }
    @media(max-width:319px) {
      min-width: 80px;
      font-size: 12px;
      padding: 8px;
    }
    &:hover {
      background: $hovered2;
      border-color: $hovered2;
    }
  }

  .following {
    background: $themegreen;
    color: $darkPurple;
    border-color: $themegreen;
    &:hover{
      background-color: $hovered !important;
      color: $darkPurple;
    }
  }
}




.userprofile-details {
  padding-right: 38px;
  border-right: 1px solid rgba(255, 255, 255, 0.14);
  margin-right: 40px;
  flex: 1;

  @media(max-width:768px) {
    padding-right: 0;
    border-right: 0;
    margin-right: 0;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:last-child {
    padding-right: 0;
    border-right: 0;
    margin-right: 0;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: $offwhite;
  }

  span {
    color: $primary;
  }

  .soicialIocns {
    margin-top: 30px;

    p {
      margin-bottom: 18px;
    }

    a {
      color: $primary;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .DetailInner {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .host-content {
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    h6 {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: $offwhite;
      margin-bottom: 4px;
    }
  }
}

.follow-block {
  margin-top: 20px;

  .ant-btn {
    span {
      color: $darkPurple  !important;
    }

    &:hover {
      span {
        color: $darkPurple  !important;
      }
    }
  }
}

.follow-block {
  display: flex;
  align-items: center;

  .about-link {
    width: 40px;
    height: 40px;
    border: 1px solid $themegreen;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    color: $primary ;
    margin-left: 14px;

    &:hover {
      background: $themegreen;
      color: $darkPurple;
    }
  }
  .chat-link{
    padding: 0;
    text-align: center;
    display: flex;
    align-items: center;
    color: $primary !important;
    justify-content: center;
    background-color: transparent;
    svg{
      margin-right: 0;
    }
  }

}

.blockUserImg {
  width: 115px;
  height: 115px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid $primary;
  box-sizing: border-box;
  margin: 0 auto 30px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


.blockUserModal {
  padding-bottom: 30px;
  text-align: center;

  p {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $peach;
  }

  h5 {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.374px;
  }

  .blockUserBtns {
    margin-top: 34px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .ant-btn {
      min-width: 166px;
      margin-right: 15px;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      @media(max-width:380px) {
        min-width: 100px;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover,
      &:focus {
        color: $darkPurple;
      }

      span {
        margin-left: 10px;
      }
    }

  }
}

.deleteuserBtns {
  text-align: right;
  margin-top: 40px;

  .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
    @media(max-width:767px) {
      top: -60px;
      margin-bottom: 30px;
    }
  }

  .ant-btn {
    min-width: 160px;
    height: 48px;
    color: $themegreen;
    margin-left: 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
    @media(max-width:767px) {
      min-width: auto;
    }

    &:last-first {
      margin-left: 0;
    }

    &:hover {
      color: $darkPurple;
    }

    @media(max-width:425px) {
      margin-top: 20px;
    }
  }

  .filled {
    background-color: $themegreen;
    color: $darkPurple;
  }
}

#register .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
#register .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 48px;
  align-items: center;
}



.userprofile-details {
  .ant-btn {
    &:focus {
      background-color: $themegreen  !important;
      color: $darkPurple  !important;
    }

    &:hover,
    &:active {
      background-color: $hovered  !important;
      color: $darkPurple  !important;

      span {
        color: $darkPurple  !important;
      }
    }
  }
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
}

.title-btn {
  .ant-input-suffix {
    margin-left: 0;
  }
}

#register {
  .ant-select-selector {
    border: 2px solid rgba(255, 255, 255, 0.21);
    background-color: $blueInput;
    &:focus-within{
      border-color: $themegreen;
    }
  }

  .ant-input {
    border: 2px solid rgba(255, 255, 255, 0.21);
    background-color: $blueInput  !important;
  }
}

.host-content {
  div {
    color: $primary;
  }
}

.selectDropdown {
  input{
    cursor: pointer !important;
  }
  .ant-select-arrow {
    color: $white;
    pointer-events: inherit;
  }
}

.profile-badge{
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  .badge-span{
    display: flex;
    align-items: center;
    font-size: 12px;
    border: 1px solid $themegreen;
    border-radius: 16px;
    padding: 4px 7px;
    margin-right: 8px;
    margin-bottom: 8px;
    svg{
      margin-right: 4px;
    }
  }
}