@import '../../../../Style/variable.scss';
.NavmenuStyles{
  .menu {
    display: flex;
    > div {
      > div &:first-child {
        height: auto !important;
      }
    }
    .ant-anchor-wrapper {
      background-color: transparent !important;
      margin-left: 0 !important;
      padding-left: 0 !important;
      .ant-anchor {
        color: $black;
      }
    }
    .ant-anchor-link {
      padding: 0;
    }
    .ant-affix {
      position: relative !important;
      width: 100% !important;
      height: auto !important;
      top: auto !important;
    }
    .ant-anchor-ink {
      display: none;
    }
    ul {
      display: flex;
      margin-bottom: 0;
      @media(max-width:768px){
        flex-direction: column;
      }
      li {
        list-style: none;
        padding: 0 30px;
        @media(max-width:991px){
          padding: 0 10px;
        }
       @media(max-width:768px){
         padding: 0 10px;
       }
       @media(max-width:768px){
        padding: 10px 0;
      }
        .ant-btn-link {
          background: transparent;
          border-color: transparent;
          box-shadow: none;
          font-size: 14px;
          color: #0CFDE4 ;
          padding: 0;
          display: flex;
          align-items: center;
          position: relative;
         svg{
           margin-right: 15px;
         }
         &::after{
          content: "";
          position: absolute;
          height: 4px;
          left: 50%;
          transform: translateX(-50%);
          background: #0CFDE4;
          bottom: -28px;
          width: 0;
          transition: 0.5s ease-in-out;
         }
         &:hover{
           color:#0CFDE4;
           &::after{
            width: 100%;
           }
         }
        }
        .active-link{
          position: relative;
          color: #0CFDE4;
          &::after{
            content: "";
            position: absolute;
            height: 4px;
            left: 50%;
            transform: translateX(-50%);
            background: #0CFDE4;
            bottom: -28px;
            width: 103%;
            transition: 0.5s ease-in-out;
           }
           @media(max-width:768px){
            &::after{
              display: none;
             }
          }
        }
        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
}


.headerLinks{
  display: flex;
  align-items: center;
  @media(max-width:767px){
    align-items: flex-start;
   }
  a{
    width: 130px;
    margin-left: 10px;
    display: inline-block;
    img{
      max-width: 100%;
    }
    &:first-child{
      width: 121px;
      img{
       @media(max-width:767px){
        width: 107px;
        height: 33px;
       }
      }
     }
    &:last-child{
     img{
      @media(max-width:767px){
        height: 33px;
        width: auto;
      }
     }
    }
  }
}