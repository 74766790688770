@import'../../../Style/variable.scss';

.Create_GroupModal {
  .ant-modal-content{
    background: $darkblack;
    .createbtn{
      right: 90px;
      .ant-btn{
        border-radius: 50px;
        height: 44px;
        align-items: center;
        background-color: transparent;
      }
      @media(max-width:560px) {
        left:24px;
        right: auto;
        top: 80px;
      }
    }
    .ant-modal-close{
      top: 32px;
      border: 2px solid $offwhite;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      color: $themegreen;
    }
    .ant-modal-header {
      text-align: start;
      border-bottom: none !important;
      @media(max-width:560px) {
        margin-bottom: 50px;
      }
    }
    .ant-modal-body{
      padding: 0 24px;
      .ant-form{
        padding: 0;
      }
    }
  }
  .chatgroup-body{
    padding-bottom: 20px;
    .anticon.ant-input-clear-icon,
    .anticon.ant-input-clear-icon:hover {
      color: $white;
    }
    .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input{
        border-color: #ff4d4f;
    }
    .ant-input{
      background-color: $blueInput !important;
    }
    .AddUser_Wrapper{
      .ant-select-selector{
        background:transparent;
        span.ant-select-selection-search,.ant-select-selection-placeholder {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector{
        background: #121F3C;
      }
      .ant-select-clear{
        color: $white;
        background-color: transparent;
        :hover{
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
    .followList{
      padding: 10px;
      // padding-bottom: 25px;
      min-height: 540px;
      max-height: 540px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: $primary;
        border-radius: 10px;
      }
      // .Link_btn {
      //   display: flex;
      //   align-items: center;
      //   border: none;
      //   border-color: transparent;
      //   color: $primary;
      //   padding: 3px;
      //   width: 22px;
      //   height: 22px;
      //   &:hover{
      //     background: transparent;
      //     color: $primary;
      //   }
      // }
    }
    .modle-btn{
      padding:10px 10px 0 0;
      .ant-btn{
        border-radius: 48px;
      }
    }
  }
  .ant-input-affix-wrapper{
    padding: 0 11px !important;
    input{
      padding-right: 10px;
    }
  }
}
.create_group_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px !important;
  &:last-child{
    margin-bottom: 0 !important;
  }
  .follow-left {
    display: flex;
    align-items: center;
    .follower-name {
      @media(max-width:425px) {
        max-width: 95px;
        margin-right: 10px;
      }
    }
    p {
      color: $offwhite !important;
      font-size: 13px;
      line-height: 15px;
      font-weight: 500;
      margin-bottom: 0;
    }
    span {
      color: $primary;
    }
    .follower-img {
      width: 44px !important;
      height: 44px !important;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 14px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .follower-right{
    display: flex;
    .Link_btn{
      border: 1px solid rgba(12, 253, 228, 0.5);
      color: $primary;
      border-radius: 6px;
      padding: 0px;
      width: 24px;
      height: 24px;
    }
    .linl_img_btn{
      // margin-left: 16px;
      img{
        width: 20px;
        height: 18px;
      }
    }
    .ml-16 {
      margin-left: 16px;
    }
  }
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled), .rc-virtual-list-holder-inner,.ant-select-dropdown, .ant-select-item:hover{
  background-color: $black;
}
#Adduser_list .ant-select-dropdown{
  border-radius: 6px;
  padding-bottom: 20PX;
}
.rc-virtual-list{
  .rc-virtual-list-holder{
    .rc-virtual-list-holder-inner{
      height: 100%;
      .follower-right{
        .ant-btn{

          padding: 0;
          color: $primary;
          height: 24px;
          width: 24px;
         svg{
          padding: 1px;
          // transform: rotate(45deg);
         }
        }
      }
    }
  }
}
.Input_search_modal{
  .ant-input-group-wrapper {
    border: 2px solid rgba(255, 255, 255, 0.21);
    box-sizing: border-box;
    border-radius: 6px;
  .ant-input {
    background: transparent;
    border: 0;
    height: 40px;
    width: 100%;
    &:focus,&:active{
      outline: none;
      box-shadow: none;
    }
  }
  .ant-input-group-addon {
    background: transparent;
    border: 0;
    .ant-btn {
      background: transparent;
      border: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      &::after{
        content: none;
      }
      &:focus,&:active{
        outline: none;
        box-shadow: none;
      }
    }
  }
  }
}