
@import '../../../../Style/variable.scss';

.OrderhistoryStyle{
	h3{
		margin-bottom: 25px;
		font-size: 28px;
		@media(max-width:767px){
			font-size: 18px;
			line-height: 24px;
			margin-bottom: 16px;
		  }
	  }
	.ant-table table{
		.ant-table-thead{
		  tr{
			th{
			  color: rgba($color: #FAE8DC, $alpha: 0.5);
			  font-weight: 500;
			  font-size: 13px;
			  line-height: 18px;
			  &:last-child{
				text-align: right;
				padding-right: 34px;
			  }
			}
		  }
		}
		.ant-table-tbody{
		  tr{
			td{
			  font-weight: 500;
			  font-size: 13px;
			  line-height: 18px;
			  color: rgba($color: #FAE8DC, $alpha: 0.5);
			  .shopname{
				p{
				  font-weight: 500;
				  font-size: 16px;
				  line-height: 20px;
				  letter-spacing: -0.2px;
				  color: #FAE8DC;
				  display: flex;
				  align-items: center;
				  margin-bottom: 0;
				  svg{
					margin: 0 0 0 10px ;
					width: 14px;
					height: 14px;
				  }
				}
			  }
			}
		  }
		}
	  }
	  .orderProfile{
		.orderImg{
			width: 75px;
			height: 70px;
			border-radius: 6px;
			overflow: hidden;
			margin-right: 24px;
			flex: 0 0 75px;
		}
		.ordername{
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			p{
				font-weight: 400;
				font-size: 16px;
				line-height: 20px;
				color: $peach;
				margin-bottom: 1px;
			}
			span{
				font-weight: 500;
				font-size: 13px;
				line-height: 18px;
				color: rgba($color: $peach, $alpha: 0.5);
				margin-bottom: 8px;
			}
			.Payment_failed{
				font-weight: 500;
				font-size: 10px;
				line-height: 12px;
				color: #EC000F;
			}
			.Payment_success {
				font-weight: 500;
				font-size: 10px;
				line-height: 12px;
				color: $themegreen;
			}
		}
	  }
	  .Retry_payment_Wrapper{
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-end;
		.amountSpan{
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: center;
			padding-right: 18px;
			margin-bottom: 20px;
		  }
		.Retry_payment{
			border-radius: 100px;
			background-color: #EC000F !important;
			border-color: #EC000F !important;
			padding: 0;
			width: 119px;
			height: 32px;
			font-weight: 500;
			font-size: 10px;
			line-height: 12px;
			text-align: center;
			color: #202C47;
		}
	  }
}
.orderModal{
	.ant-modal-content{
		padding: 0 0 30px 0;
		.ant-modal-header{
			padding:30px 35px ;
			.ant-modal-title{
				font-size: 26px;
				line-height: 30px;
				margin-top: 10px;
			}
		}
		.ant-modal-body{
			padding-top: 0 !important;
			.orderProfile {
				padding: 22px 35px;
				border-top: 1px solid rgba(228, 228, 228, 0.1);;
				justify-content: space-between;
				&-left{
					display: flex;
					.orderProfile-img {
						width: 94px;
						height: 94px;
						overflow: hidden;
						border-radius: 100px;
						margin-right:18px;
						flex: 0 0 94px;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
					.orderProfile-details{
						p{
							font-weight: 500;
							font-size: 16px;
							line-height: 20px;
							color: #FAE8DC;
							margin: 0;
							margin-bottom: 4px;
						}
						>span{
							font-weight: 500;
							font-size: 12px;
							line-height: 16px;
							color:rgba($color: $peach, $alpha: 0.5);
						}
						.item-date{
							font-weight: 400;
							font-size: 16px;
							line-height: 20px;
							color:rgba($color: $peach, $alpha: 0.5);
							margin-top: 5px;

						}
						.item-date{
							.itemnumber {
								border: 1px solid #808191;
								box-sizing: border-box;
								border-radius: 6px;
								padding: 10px;
								margin-right: 20px;
								font-weight: 500;
								font-size: 16px;
								color: #808191;
								line-height: 24px;
								display: inline-block;
							}
							.itemdate {
								font-weight: 500;
								font-size: 16px;
								line-height: 24px;
								color: #808191;
							}
						}
					}
				}
				&-right{
					.ant-btn{
						border-radius: 100px;
					}
				}
			}
			.order-summarybox {
				padding: 0 35px;
				margin-bottom: 25px;
				.ordersummary {
					padding-top: 5px;
					p{
						font-weight: 600;
						font-size: 16px;
						line-height: 24px;
						margin-bottom: 13px;
						display: flex;
						flex-direction: column;
						span{
							font-weight: 500;
							font-size: 13px;
							line-height: 24px;
							color: #777E90;
						}
					}
					.summary-table{
						border: 1px solid #415068;
						box-sizing: border-box;
						border-radius: 6px;
						padding:  0;
						.summaryrow{
							padding: 17px 18px;
							border-bottom: 1px solid #415068;
							display: flex;
							justify-content: space-between;
							align-items: center;
							.summary-cell-right{
								display: flex;
								flex-direction: column;
								align-items: flex-start;
								justify-content: flex-start;
								font-weight: 500;
								font-size: 14px;
								line-height: 17px;
								color: $peach;
								span{
									font-weight: 400;
									font-size: 12px;
									line-height: 15px;
									color:rgba($color: $peach, $alpha: 0.5);
								}
							}
							.summary-cell-left{
								font-weight: 500;
								font-size: 13px;
								line-height: 24px;
								text-align: right;
								color: $themegreen;
							}
						}
						.totla{
							.summary-cell{
								color: $themegreen;
								font-weight: 600;
							}
						}
					}
				}
				.ShippingStatusWrapper{
					border: 1px solid #415068;
					border-radius: 6px;
					padding: 20px;
					margin-bottom: 16px;
					p{
						font-weight: 600;
						font-size: 16px;
						line-height: 24px;
						letter-spacing: 0.374px;
						color: $peach;
						margin-bottom: 15px;
					}
					.AddressStyle{
						span{
							font-weight: 400;
							font-size: 14px;
							line-height: 17px;
							margin-bottom: 2px;
						}
						p{
							font-weight: 400;
							font-size: 13px;
							line-height: 22px;
							color: rgba($color: $peach, $alpha: 0.5);
						}
					}
					.ant-steps{
						.ant-steps-item{
							.ant-steps-item-tail{
								padding: 0;
								width: auto;
								top: 21px;
								left: 10px;
								z-index: 1;
								&::after{
									content: none;
								}
								&::before{
									content: "";
									height: 100%;
									width: 2px;
									background: $white;
									position: relative;
									display: inline-block;
								}
							}
							.ant-steps-item-icon{
								margin: 2px 11px 0 0;
								width: 22px;
								height: 22px;
								position: relative;
    							z-index: 99;
								.ant-steps-icon{
									.ant-steps-icon-dot{
										background: #0B1836;
										border: 4px solid #415068;
										border-radius: 50%;
										width: 22px;
										height: 22px;
										&::after{
											content: none;
										}
									}
								}
							}
							.ant-steps-item-content{
								.ant-steps-item-title{
									font-weight: 500;
									font-size: 14px;
									line-height: 17px;
									color: $peach;
									margin-bottom: 4px;
								}
								.ant-steps-item-description{
									font-weight: 400;
									font-size: 12px;
									line-height: 15px;
									color: rgba($color: $peach, $alpha: 0.5);
									margin-bottom: 35px;
								}
							}
							&:last-child{
								.ant-steps-item-content{
									.ant-steps-item-description{
										margin-bottom: 0;
									}
								}
							}
						}
						.ant-steps-item-finish{
							.ant-steps-item-tail{
								&::before{
									background: #0CFDE4;
								}
							}
							.ant-steps-item-icon{
								.ant-steps-icon{
									.ant-steps-icon-dot{
										border-color: #0CFDE4;
										background: #202C47;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}