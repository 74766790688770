@import '../../../Style/variable.scss';

.empty-msg {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  height: 100%;
  text-align: center;
  .empty-msg-content{
    img{
      width: 140px;
      height: 120px;
    }
  }
  .img-des{
    margin-top: 25px;
    p{
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0.374px;
      margin-bottom: 6px;
      color: $offwhite;
    }
    span{
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: rgba(250, 232, 220, 0.5);
    }
  }
}