@import '../../../Style/variable.scss';

.Hostpublic {
  &-block {
    &__banner {
      // background-image: url('../../../Assets/Images/Shows-img/bannerImg.png');
      // background-position: center;
      // background-size: cover;
      // min-height: 230px;
    }
  }
}

.startchatbox {
  background: $secondary;
  border-radius: 16px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 600px;
  margin-top: 40px;

  h4 {
    margin-bottom: 5px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    max-width: 564px;
  }

  p {
    max-width: 564px;
    font-weight: 500;
    color: $offwhite;
    margin-bottom: 20px;
  }

  .ant-btn {
    min-width: 249px;
    border-radius: 100px;
    font-weight: 700;
  }

  .startchat-img {
    width: 220px;
    overflow: hidden;
    height: 220px;
    border-radius: 50%;
    margin: 0 auto 20px auto;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.Profileshowlist {
  padding-top: 0 !important;
  position: relative;
  top: -50px;
}