@import '../../../../Style/variable.scss';

.Profileshowlist{
  padding-top: 50px;
  .ant-tabs-nav {
    margin-bottom: 30px !important;
}
  .ant-tabs-top {
    >.ant-tabs-nav{
      &::before{
        display: none;
      }
      .ant-tabs-tab{
        color: $peach;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        padding: 12px 16px;
        border-radius: 100px;
        &.ant-tabs-tab-active{
          background: $themegreen;
          color: $darkPurple;
          .ant-tabs-tab-btn{
            color: $secondary;
            text-shadow: none;
          }
        }
      }
      .ant-tabs-ink-bar{
        display: none;
      }
    }
  }
}
.ant-tabs{
  color: $white;
}
.mt0{
  margin-top: 0;
}
.Hostpublic-block {
  margin-bottom: 40px;
}

