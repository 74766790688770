.otpInput {
    // width: 3rem !important;
    // height: 3rem;
    // margin: 0 1rem;
    // font-size: 2rem;
    // text-align: center;
    // border-radius: 4px;
    // border: 1px solid rgba(0, 0, 0, 0.3);
    // color: black;

    flex: 1 1;
    margin-right: 10px;
    text-align: center;
    border: 2px solid #B1B5C4;
    box-sizing: border-box;
    padding: 12px 16px;
    color: #FCFCFD;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    border-radius: 12px;
    background-color: transparent !important;
    width: 54px;
    &:focus{
      border-color: #0CFDE4 !important;
    }
    &:focus-visible{
      border-color: #0CFDE4 !important;
      outline: none !important;
    }
  }

.otpContainer {
  display: flex;
  justify-content: space-between;
  flex: auto;
  max-width: 100%;
}

  /*  Add breakpoint for iPhone */
  @media only screen and (max-width: 375px) {
    .otpInput {
      width: 1.5rem !important;
      height: 1.5rem;
      font-size: 1rem;
      padding: 8px;
    }
  }
