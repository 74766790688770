@import '../../Style/variable.scss';

.EditeProfile-block {
  padding: 50px 0 65px 0;
  @media(max-width: 575px) {
    padding: 20px 0 10px 0;
  }

  .ant-input-affix-wrapper {
    background: transparent;
    border: 1px solid rgba(250, 232, 220, 0.2) !important;
    border-radius: 12px;

    .ant-input {
      background-color: transparent;
    }
  }

  .ant-picker {
    background-color: transparent;
    border: 1px solid rgba(250, 232, 220, 0.2) !important;
    border-radius: 12px;
  }

  .selectedTopics {
    background-color: transparent;
    border: 1px solid rgba(250, 232, 220, 0.2) !important;
  }
}

.EditProfile-details {
@media(max-width:600px){
  display: none
}
  .ant-form-item-label {
    label {
      font-weight: 700;
      font-size: 12px;
      line-height: 12px;
      text-transform: uppercase;
      color: $peach;
    }
  }

  .ant-input,
  .ant-select-selector {
    border: 1px solid rgba(250, 232, 220, 0.2);
    box-sizing: border-box;
    padding: 12px 16px;
    border-radius: 12px;
    height: 48px;
    color: $primary;
    font-weight: 500;
    background: transparent;
  }

  textarea.ant-input,
  .ant-select-selector {
    height: auto;
  }

  .ant-picker .ant-picker-input>input {
    color: $primary;
    font-weight: 500;
  }
}

.ant-select-multiple .ant-select-selection-item {
  background: $blueInput;
  color: $primary;
  border-radius: 4px;
  border-color: $blueInput;
  height: 32px;
  line-height: 30px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-affix-wrapper {
  border: 1px solid $blueInput;
  box-sizing: border-box;
  border-radius: 12px;
  color: $peach;
}

.ant-select-multiple {
  .ant-select-selection-item-remove {
    svg {
      color: $peach;

    }
  }
}

.ant-input-prefix {
  svg {
    color: $grayWhite;
    margin-right: 10px;
  }
}

.site-form-item-icon {

  margin-bottom: 16px;
  border-color: $blueInput  !important;

}

.submitBtn {
  border-top: 1px solid $secondary-strock;
  padding-top: 30px;
  margin-top: 30px;
  .ant-btn{
    border-radius: 100px;
  }
}

.EditProfile {
  display: flex;

  .Namedetail {
    p {
      font-weight: 600;
      color: $peach;
    }

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: $primary;
    }
  }

  .userpicWrapper {
    position: relative;
    width: 128px;
    height: 128px;
    margin-right: 30px;
    margin-bottom: 30px;

    .userpic {
      border: 2px solid $primary;
      box-sizing: border-box;
      border-radius: 100px;
      width: 128px;
      height: 128px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .EditIcon {
      position: absolute;
      background: $black;
      border: 2px solid $themegreen;
      box-sizing: border-box;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 6px;
      bottom: 0;
      color: $primary;

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;

      }
    }
  }
}


.ant-table,
.ant-table-thead>tr>th {
  background-color: transparent;
  color: #B2B3BD;

}

.ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-tbody>tr>td.ant-table-cell-row-hover {
  background: $secondary;
}



.ant-table-tbody>tr>td,
.ant-table-thead>tr>th {
  border-color: rgba(228, 228, 228, 0.1);
  vertical-align: top;
}

.ant-table-thead>tr>th {
  padding-bottom: 26px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
}

.shopname {
  p {
    font-weight: 500;
    font-size: 16px;
    color: $peach;
    line-height: 19px;

    svg {
      margin: 3px 0 0 10px;
    }
  }

  span {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #808191;
  }
}

span.amountSpan {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #EC000F;

  &.white {
    color: $peach;
  }
}

.white {
  color: $peach;
}

.orderProfile {
  display: flex;

  .orderImg {
    width: 90px;
    height: 90px;
    border-radius: 6px;
    overflow: hidden;
    margin-right: 25px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  p {
    color: $peach;
  }
}

.accountsForm {
  width: 60%;

  @media(max-width:991px) {
    width: 100%;
  }

  .submitBtn {
    .ant-btn {
      min-width: 150px;
    }
  }
}

.ant-collapse-borderless {
  background-color: transparent;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  color: $peach;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.faq-texts {
  color: $gray;
}

.ant-collapse-borderless>.ant-collapse-item {
  border-top: 1px solid $secondary-strock;
  border-bottom: none;
  padding: 32px 0 26px 0;

}

.ant-collapse-header {
  padding: 0;
}

.collaps-accordian {
  .faq-texts {
    .ant-btn {
      margin-top: 24px;

    }
  }
}

.deleteUser {
  text-align: center;
  margin-top: 16px;

  a,
  .ant-btn,
  .ant-btn:hover,
  .ant-btn:focus {
    color: $primary;
    text-decoration: dashed;
    border: none;
    font-weight: 500;
    font-size: 16px;
  }

  .ant-btn:hover,
  .ant-btn:focus {
    background: none;
    border: none;
    border-color: none;
  }

  p {
    font-size: 14px;
    margin-bottom: 0;
    word-break: break-all;

    span {
      padding-right: 5px;
    }
  }
}

.anticon svg {
  color: $primary;
}

.ant-input-affix-wrapper:focus-within, .ant-input-affix-wrapper-focused, textarea:focus, .ant-picker:focus-within{
  border: 1px solid $themegreen !important;
  box-shadow: none !important;
}
.Settingsmenu{
  li{
    .mobile-settings{
      display: none;
      transition: 0.5s ease-in-out;
      padding-top: 20px;
      .SavedCards_wrapper, .TransactionhistoryStyles, .No_massge_wrapper{
        display: block !important;
      }
    }
  }
  .m-active{

    .mobile-settings, .EditProfile-details{

      @media(max-width:575px){
        display: block;
      }

    }
  }
}
.ant-table-content{
  overflow-x: auto;
}

.OrderhistoryStyle, .TransactionhistoryStyles{
  @media(max-width:600px){
    display: none;
  }
}
.mobile-settings{
  .OrderhistoryStyle, .TransactionhistoryStyles{
    @media(max-width:600px){
      display: block;
    }
  }
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
  border-color: rgba(250, 232, 220, 0.2) !important;
}