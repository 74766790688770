@import '../../../Style/variable.scss';

.Hostpublic {
  .edit_btn {
    position: absolute;
    right: 41px;
    top: -174px;
    display: flex;
    gap: 16px;

    @media(max-width:400px) {
      flex-direction: column;
      right: 26px;
    }

    .ant-btn {
      color: $peach  !important;
    }
  }

  .transparentBtn {
    background-color: rgba(32, 44, 71, 0.5) !important;
    border-color: $themegreen;
    display: flex;

    svg {
      margin-right: 10px;
      color: $themegreen;
    }

    &:hover,
    &:focus {
      background-color: $themegreen  !important;
      color: $darkPurple  !important;

      svg {
        color: $darkPurple;
      }
    }
  }
  .feature-title {
    padding-top: 0 !important;
    @media(max-width:374px) {
      flex-direction: column;
      justify-content: flex-start !important;
      align-items: flex-start !important;
      .feature-ttitletext{
        margin-bottom: 10px;
      }
    }
    .title-btn{
      @media(max-width:380px) {
        max-width: 200px;
      }
      @media(max-width:374px) {
        max-width: 100%;
      }
      .ant-btn{
        @media(max-width:374px) {
          padding: 10px;
          height: auto;
          font-size: 12px;
        }
      }
    }
  }
}

.container {
  position: relative;
}

.featureCrad .imgtop .viewCount svg {
  margin-right: 0;
  margin-left: 2px;
}


.featureCrad .imgtop .rightImgtop .viewCount svg {
  margin-right: 0;
  margin-right: 5px;
}



.profile-wrapper-right {
  position: relative;
  top: -50px;
}

.cover-banner {
  height: 230px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .LoadingSpinner {
    position: relative;
    top: 35px
  }
}