@import '../../../Style/variable.scss';

:root {
  --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  --msger-bg: #fff;
  --border: 2px solid #353945;

}

.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 867px;
  margin: 25px 10px;
  height: calc(100% - 50px);
  border: var(--border);
  border-radius: 5px;
  background: var(--msger-bg);
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
}

.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: var(--border);
  background: #eee;
  color: #000;
}

.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 7px 18px;
}

.msger-chat::-webkit-scrollbar {
  width: 6px;
}

.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}

.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

.msg {
  display: flex;
  margin-bottom: 40px;
}

.msg:last-of-type {
  margin: 0;
}

.msg-img {
  width: 34px;
  height: 34px;
  margin-right: 10px;
  background: #ddd;
  overflow: hidden;
  border-radius: 50%;
}

.msg-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.msg-bubble {
  max-width: 450px;
  padding: 10px 16px;
  border-radius: 17px;
  background: var(--left-msg-bg);
  margin-bottom: 6px;
  @media(max-width:425px){
    padding: 5px 10px;
   }
}

.msg-bubble:nth-last-child(2) {
  margin-bottom: 0;
}

.msg-info {
  display: flex;
  justify-content: space-between;
  color: $black;
  align-items: center;
  margin-bottom: 10px;
}

.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
  color: $black;
}

.msg-info-time {
  padding-top: 2px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $peach;
}

.left-msg .msg-bubble {
  background: $Headerbg;
  color: $white;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  border-radius: 17px 17px 17px 0px;
}

.left-msg .msg-bubble:nth-last-child(2) {
  border-bottom-left-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
}

.right-msg .msg-bubble {
  background: $primary;
  color: $peach;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: $black;

  :nth-last-child(2) {
    border-bottom-right-radius: 0;
  }

}

.right-msg .msg-bubble:nth-last-child(2) {
  border-bottom-right-radius: 0;
}

.right-msg .msg-img {
  margin: 0 0 0 7px;
}

.msg-status {
  // background: #303441;
  // border-radius: 8px;
  padding: 7px 20px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: $offwhite;
}

.msg-center {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;
}

.left-msg .msg-pop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.right-msg .msg-pop {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.chat-settings {
  cursor: pointer;
}