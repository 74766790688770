@import '../../../../Style/variable.scss';
p{
  color: #777E91;
  margin-bottom: 8px;
}
.privacy-content{
  .privacy-box{
    border-top: 1px solid $secondary-strock;
    padding: 16px 0 20px 0;
    word-break: break-word;
    h5{
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #FCFCFD;
    }
    p{
      color: #777E91;
      margin-bottom: 8px;
    }
  }
}