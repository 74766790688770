
@import '../../../Style/variable.scss';
.HeaderStyles{
  width: 100%;
  position: relative;
  left: 0;
  top: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  background-color: $Headerbg;
  padding-right: 30px;
  padding-left: 20px;
  @media(max-width:768px){
	padding-right: 10px;
  padding-left: 5px;
  }
  @media(max-width: 319px){
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .logo {
    display: flex;
	  flex: 1;
    flex-direction: row;
    align-items: end;
	  .logo-link{
		  &::after{
			  display: none;
		  }
	  }
    .ant-btn-link {
      height: auto;
      padding: 0;
    }
    img {
      max-width: 200px;
      margin: 20px 5px 20px 10px;
      @media(max-width:767px){
        max-width: 58px;
      }

     }
  }
  .NavmenuStyles {
    @media(max-width:768px){
			display: none;
		}

  }
  .loginBtn{
	  margin-left: 40px;
    display: flex;
    align-items: center;
	  @media(max-width:768px){
		margin-left: 10px;
	  }
    .notificationBell {
        margin-right: 40px;
        @media(max-width:768px){
          margin-right: 10px;
          }

        .ant-badge{
          color: $gray;
          transition: 0.5s ease-in-out;
          &:hover{
            color: $peach;
          }
        }
    }
    .ant-btn{
      padding: 8px 20px;
      line-height: 16px;
      span{
        @media(max-width:767px){
          max-width: 70px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
        }
      }
    }
  }
  .headerProfile{
    padding: 4px 12px 4px 4px;
    border: 2px solid $secondary-strock;
    box-sizing: border-box;
    border-radius: 90px;
    display: flex;
    align-items: center;
    @media(max-width:600px){
      padding: 2px;
    }
    .headerProfileImg {
      width: 32px;
      height: 32px;
      flex: 0 0 32px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 10px;
      @media(max-width:600px){
        margin-right: 0;
      }
       img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
      }
    }
    .username{
      font-size: 14px;
      line-height: 16px;
      @media(max-width:600px){
        display: none;
      }
      span{
        max-width: 70px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
      }
    }
  }
}



 .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: transparent !important;
}
.logintext{
  color: $offwhite;
  margin-top: 20px;
}
.loginModal{
  .ant-modal-body{
    padding: 0 !important;
  }
}
.popuplogin-icon {
  text-align: center;
  margin-bottom: 6px;
  margin-top: 60px;
  @media(max-width:767px){
    margin-top: 20px;
  }
  img{
    width: 165px;
    @media(max-width:767px){
      width: 115px;
    }
  }
}
.react-tel-input .selected-flag:after {
  content: "";
  position: absolute;
  right: -4px;
  width: 2px;
  height: 45%;
  top: 50%;
  background: rgba(250, 232, 220, 0.2);
  transform: translateY(-50%);
}

.react-tel-input .selected-flag {
  width: 48px;
  padding: 0 0 0 16px;
  &:hover,  &:focus{
    background-color: transparent;
  }
}
.react-tel-input .flag-dropdown.open, .react-tel-input .flag-dropdown.open .selected-flag{
  background: transparent;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: transparent;
  border: 0px solid #cacaca;
  border-radius: 0;
}

.phonmodal{
  p{
    color: $peach;
    margin-bottom: 30px;
  }
  .ant-input {
    border: 2px solid rgba(255, 255, 255, 0.21);
    background-color: $blueInput !important;
  &:focus{
    border-color: $themegreen !important;
  }
  }
}
.phoneInput {
  padding-left:60px !important;
  height: 48px !important;
  border: 2px solid rgba(250, 232, 220, 0.2) !important;
  border-radius: 12px !important;
  width: 100% !important;
}
.react-tel-input .country-list .search {
  background-color: $blueInput;
  border-bottom: 1px solid rgba(250, 232, 220, 0.2);
}
.react-tel-input .country-list .search-box{
  background-color: transparent;
  border: 0;
  color: $peach;
}
.react-tel-input .country-list{
  background-color: $blueInput;
}
.react-tel-input .country-list .country{
  color: $peach;
}
.react-tel-input .country-list .country:hover, .react-tel-input .country-list .country.highlight {
  background-color: transparent;
  color: $themegreen;
}
.react-tel-input .country-list .no-entries-message{
  color: $peach;
}
.react-tel-input .country-list{
  @media(max-width:600px){
    width: 250px;
  }
}

.beta-badge {
  display: flex;
  align-items: end;
  color: #0CFDE4;
  border-radius: 15px;
  border: #0CFDE4 1px solid;
  max-width: 70px;
  margin: 20px 0px 20px 0px;
  padding: 2.5px 10px;
  @media(max-width:767px){
    max-width: 65px;
    padding: 1px 8px;
  }
  @media(max-width:319px){
    max-width: 60px;
    padding: 0px 6px;
  }
}