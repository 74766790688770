.Appdownload-container{
  padding: 30px 0;
}
.blurry-bg{
  background-image: url('../../Assets/Images/Shows-img/bg-collectibles.jpeg');
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  background-size: cover;
  background-position: center center;
  background-color: #fff;
  z-index: -1;
}
.boxheader {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
}
.box-logo {
  max-width: 200px;
  margin-bottom: 20px;
  @media(max-width:767px){
   max-width: 150px;
  }
  img{
    max-width: 100%;
  }
}
.gavel-signate {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #c8a76a;
  margin: 0;
  overflow: hidden;
  margin-bottom: 10px;
  @media(max-width:767px){
    width: 80px;
    height: 80px;
  }
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.downloadbox{
  text-align: center;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  border: 2px solid #7f7f7f;
  padding: 30px;
  max-width: 700px;
  margin: 0 auto;
  &::after{
    content: "";
    background: linear-gradient(#00000030, #000);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
  }
  p{
    color: #fff;
  }
}
.download-content{
  h2{
    color: #fff;
    @media(max-width:767px){
      font-size: 24px;
    }
  }
.soonagain {
    background-color: #fd3a69;
    border-radius: 3px;
    padding: 6px 12px;
    color: #f3f3f3;
    font-size: 100%;
    font-weight: 500;
    margin-top: 20px;
    display: inline-block;
  }
}

.gavelFooter{
  .gavelLink {
    display: inline-block;
    max-width: 200px;
    @media(max-width:767px){
      max-width: 150px;
    }
    img{
      max-width: 100%;
    }
  }
  .downloadbts {
    display: flex;
    align-items: center;
    @media(max-width:767px){
      flex-direction: column;
    }
    .gavelLink{
      margin: 0 6px;
      @media(max-width:767px){
        margin-bottom: 10px;
      }
    }
  }
}
.linkgavel{
  margin: 30px 0;
}