
@import '../../../../Style/variable.scss';
.shownot-start {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  width: 100%;
  padding: 10px;
  text-align: center;
img{
  width: 190px;
  @media(max-width:768px){
    width: 100px;
  }
}
.ConnectingStyle{
  min-height: 32px;
  p{
    font-size: 16px;
    line-height: 24px;
    color: $peach;
    margin: 0;
  }
}
h1{
  margin-bottom: 0;
  text-align: center;
  @media(max-width:768px){
   font-size: 22px !important;
   line-height: 26px;
  }
}
p{
  color: $gray;
}
.squrebtn {
  background: transparent;
  border-radius: 12px;
  padding: 12px 20px;
  border: 1px solid $themegreen;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: $peach;
  min-width: 258px;
  margin-top: 30px;
  color: $primary;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  outline: none;
  @media(max-width:767px){
   min-width: auto
   }
  &:hover{
    background-color:$themegreen;
    color: $darkPurple;
  }
}
}