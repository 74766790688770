:root {
    --tmb-scrollbar-width: 0px;
  }
  .background-image img,
  .background-video,
  .card-blog .card-img img,
  .card-fullscreen .card-img img,
  .card.card-image-lg img,
  .card.card-image-md img,
  .card.card-image-sm img,
  .card.card-image-xl img,
  .gallery-item-lg img,
  .gallery-item-md img,
  .gallery-item-sm img,
  .gallery-item-xl img,
  .object-fit-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-family: "object-fit: cover";
    -o-object-fit: cover;
    object-fit: cover;
  }
  .icon-arrow,
  .swiper-button-next svg,
  .swiper-button-prev svg {
    will-change: transform;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    -o-transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  }
  .icon-arrow path:last-child,
  .swiper-button-next svg path:last-child,
  .swiper-button-prev svg path:last-child {
    will-change: transform;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    -o-transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  }
  .icon-arrow-left path:last-child,
  .swiper-button-prev svg path:last-child {
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center;
  }
  .icon-arrow-right path:last-child,
  .swiper-button-next svg path:last-child {
    -webkit-transform-origin: right center;
    -ms-transform-origin: right center;
    transform-origin: right center;
  }
  .btn:focus .icon-arrow-left,
  .btn:focus .swiper-button-prev svg,
  .btn:hover .icon-arrow-left,
  .btn:hover .swiper-button-prev svg,
  .icon-arrow-left-hover,
  .nav .nav-link.active .icon-arrow-left,
  .nav .nav-link.active .swiper-button-prev svg,
  .nav .nav-link:focus .icon-arrow-left,
  .nav .nav-link:focus .swiper-button-prev svg,
  .nav .nav-link:hover .icon-arrow-left,
  .nav .nav-link:hover .swiper-button-prev svg,
  .swiper-button-prev .btn:focus svg,
  .swiper-button-prev .btn:hover svg,
  .swiper-button-prev .nav .nav-link.active svg,
  .swiper-button-prev .nav .nav-link:focus svg,
  .swiper-button-prev .nav .nav-link:hover svg,
  .swiper-button-prev:hover svg {
    -webkit-transform: translateX(-22%);
    -ms-transform: translateX(-22%);
    transform: translateX(-22%);
  }
  .btn:focus .icon-arrow-left path:last-child,
  .btn:focus .swiper-button-prev svg path:last-child,
  .btn:hover .icon-arrow-left path:last-child,
  .btn:hover .swiper-button-prev svg path:last-child,
  .icon-arrow-left-hover path:last-child,
  .nav .nav-link.active .icon-arrow-left path:last-child,
  .nav .nav-link.active .swiper-button-prev svg path:last-child,
  .nav .nav-link:focus .icon-arrow-left path:last-child,
  .nav .nav-link:focus .swiper-button-prev svg path:last-child,
  .nav .nav-link:hover .icon-arrow-left path:last-child,
  .nav .nav-link:hover .swiper-button-prev svg path:last-child,
  .swiper-button-prev .btn:focus svg path:last-child,
  .swiper-button-prev .btn:hover svg path:last-child,
  .swiper-button-prev .nav .nav-link.active svg path:last-child,
  .swiper-button-prev .nav .nav-link:focus svg path:last-child,
  .swiper-button-prev .nav .nav-link:hover svg path:last-child,
  .swiper-button-prev:hover svg path:last-child {
    -webkit-transform: scaleX(1.3);
    -ms-transform: scaleX(1.3);
    transform: scaleX(1.3);
  }
  .btn:focus .icon-arrow-right,
  .btn:focus .swiper-button-next svg,
  .btn:hover .icon-arrow-right,
  .btn:hover .swiper-button-next svg,
  .card-service.card-hover-zoom:focus .btn .icon-arrow-right,
  .card-service.card-hover-zoom:focus .btn .swiper-button-next svg,
  .card-service.card-hover-zoom:hover .btn .icon-arrow-right,
  .card-service.card-hover-zoom:hover .btn .swiper-button-next svg,
  .icon-arrow-right-hover,
  .nav .nav-link.active .icon-arrow-right,
  .nav .nav-link.active .swiper-button-next svg,
  .nav .nav-link:focus .icon-arrow-right,
  .nav .nav-link:focus .swiper-button-next svg,
  .nav .nav-link:hover .icon-arrow-right,
  .nav .nav-link:hover .swiper-button-next svg,
  .swiper-button-next .btn:focus svg,
  .swiper-button-next .btn:hover svg,
  .swiper-button-next .card-service.card-hover-zoom:focus .btn svg,
  .swiper-button-next .card-service.card-hover-zoom:hover .btn svg,
  .swiper-button-next .nav .nav-link.active svg,
  .swiper-button-next .nav .nav-link:focus svg,
  .swiper-button-next .nav .nav-link:hover svg,
  .swiper-button-next:hover svg {
    -webkit-transform: translateX(22%);
    -ms-transform: translateX(22%);
    transform: translateX(22%);
  }
  .btn:focus .icon-arrow-right path:last-child,
  .btn:focus .swiper-button-next svg path:last-child,
  .btn:hover .icon-arrow-right path:last-child,
  .btn:hover .swiper-button-next svg path:last-child,
  .card-service.card-hover-zoom:focus .btn .icon-arrow-right path:last-child,
  .card-service.card-hover-zoom:focus
    .btn
    .swiper-button-next
    svg
    path:last-child,
  .card-service.card-hover-zoom:hover .btn .icon-arrow-right path:last-child,
  .card-service.card-hover-zoom:hover
    .btn
    .swiper-button-next
    svg
    path:last-child,
  .icon-arrow-right-hover path:last-child,
  .nav .nav-link.active .icon-arrow-right path:last-child,
  .nav .nav-link.active .swiper-button-next svg path:last-child,
  .nav .nav-link:focus .icon-arrow-right path:last-child,
  .nav .nav-link:focus .swiper-button-next svg path:last-child,
  .nav .nav-link:hover .icon-arrow-right path:last-child,
  .nav .nav-link:hover .swiper-button-next svg path:last-child,
  .swiper-button-next .btn:focus svg path:last-child,
  .swiper-button-next .btn:hover svg path:last-child,
  .swiper-button-next
    .card-service.card-hover-zoom:focus
    .btn
    svg
    path:last-child,
  .swiper-button-next
    .card-service.card-hover-zoom:hover
    .btn
    svg
    path:last-child,
  .swiper-button-next .nav .nav-link.active svg path:last-child,
  .swiper-button-next .nav .nav-link:focus svg path:last-child,
  .swiper-button-next .nav .nav-link:hover svg path:last-child,
  .swiper-button-next:hover svg path:last-child {
    -webkit-transform: scaleX(1.3);
    -ms-transform: scaleX(1.3);
    transform: scaleX(1.3);
  } /*!
   * Bootstrap v4.5.0 (https://getbootstrap.com/)
   * Copyright 2011-2020 The Bootstrap Authors
   * Copyright 2011-2020 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
   */
  :root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #969696;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #969696;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f5f5f5;
    --dark: #0e0e0e;
    --breakpoint-xs: 0;
    --breakpoint-sm: 620px;
    --breakpoint-md: 828px;
    --breakpoint-lg: 1024px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
      "Liberation Mono", "Courier New", monospace;
  }
  *,
  ::after,
  ::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(14, 14, 14, 0);
  }
  article,
  aside,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display: block;
  }
  body {
    margin: 0;
    font-family: Inter, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #0e0e0e;
    text-align: left;
    background-color: #fff;
  }
  [tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
  }
  hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 1.66667rem;
  }
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  abbr[data-original-title],
  abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
  }
  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }
  dl,
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0;
  }
  dt {
    font-weight: 700;
  }
  dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
  }
  blockquote {
    margin: 0 0 1rem;
  }
  b,
  strong {
    font-weight: bolder;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
  a:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  a:not([href]) {
    color: inherit;
    text-decoration: none;
  }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none;
  }
  code,
  kbd,
  pre,
  samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
      "Courier New", monospace;
    font-size: 1em;
  }
  pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
  }
  figure {
    margin: 0 0 1rem;
  }
  img {
    vertical-align: middle;
    border-style: none;
  }
  svg {
    overflow: hidden;
    vertical-align: middle;
  }
  table {
    border-collapse: collapse;
  }
  caption {
    padding-top: 30px 0 23px;
    padding-bottom: 30px 0 23px;
    color: #969696;
    text-align: left;
    caption-side: bottom;
  }
  th {
    text-align: inherit;
  }
  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
  button {
    border-radius: 0;
  }
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  [role="button"] {
    cursor: pointer;
  }
  select {
    word-wrap: normal;
  }
  [type="button"],
  [type="reset"],
  [type="submit"],
  button {
    -webkit-appearance: button;
  }
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled),
  button:not(:disabled) {
    cursor: pointer;
  }
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner,
  button::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  input[type="checkbox"],
  input[type="radio"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
  }
  textarea {
    overflow: auto;
    resize: vertical;
  }
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
  }
  progress {
    vertical-align: baseline;
  }
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }
  output {
    display: inline-block;
  }
  summary {
    display: list-item;
    cursor: pointer;
  }
  template {
    display: none;
  }
  [hidden] {
    display: none !important;
  }
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1.66667rem;
    font-family: Inter, sans-serif;
    font-weight: 500;
    line-height: 1.2;
  }
  .h1,
  h1 {
    font-size: 2.5rem;
  }
  .h2,
  h2 {
    font-size: 2rem;
  }
  .h3,
  h3 {
    font-size: 1.75rem;
  }
  .h4,
  h4 {
    font-size: 1.5rem;
  }
  .h5,
  h5 {
    font-size: 1.25rem;
  }
  .h6,
  h6 {
    font-size: 1rem;
  }
  .lead {
    font-size: 1.25em;
    font-weight: 300;
  }
  .display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2;
  }
  .display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  .display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  .display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  hr {
    margin-top: 3.33333rem;
    margin-bottom: 3.33333rem;
    border: 0;
    border-top: 1px solid rgba(14, 14, 14, 0.1);
  }
  .small,
  small {
    font-size: 80%;
    font-weight: 400;
  }
  .mark,
  mark {
    padding: 0.2em;
    background-color: #fcf8e3;
  }
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }
  .list-inline {
    padding-left: 0;
    list-style: none;
  }
  .list-inline-item {
    display: inline-block;
  }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  .initialism {
    font-size: 90%;
    text-transform: uppercase;
  }
  .blockquote {
    margin-bottom: 3.33333rem;
    font-size: 1.25rem;
  }
  .blockquote-footer {
    display: block;
    font-size: 80%;
    color: #969696;
  }
  .blockquote-footer::before {
    content: "\2014\00A0";
  }
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  .img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0;
    max-width: 100%;
    height: auto;
  }
  .figure {
    display: inline-block;
  }
  .figure-img {
    margin-bottom: 1.66667rem;
    line-height: 1;
  }
  .figure-caption {
    font-size: 90%;
    color: #969696;
  }
  code {
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word;
  }
  a > code {
    color: inherit;
  }
  kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem;
  }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
  }
  pre {
    display: block;
    font-size: 87.5%;
    color: #212529;
  }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }
  .pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
  }
  .container {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 620px) {
    .container {
      max-width: 540px;
    }
  }
  @media (min-width: 828px) {
    .container {
      max-width: 720px;
    }
  }
  @media (min-width: 1024px) {
    .container {
      max-width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .container {
      max-width: 1170px;
    }
  }
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 620px) {
    .container,
    .container-sm {
      max-width: 540px;
    }
  }
  @media (min-width: 828px) {
    .container,
    .container-md,
    .container-sm {
      max-width: 720px;
    }
  }
  @media (min-width: 1024px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
      max-width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      max-width: 1170px;
    }
  }
  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
  }
  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  .col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-1 {
    margin-left: 8.33333%;
  }
  .offset-2 {
    margin-left: 16.66667%;
  }
  .offset-3 {
    margin-left: 25%;
  }
  .offset-4 {
    margin-left: 33.33333%;
  }
  .offset-5 {
    margin-left: 41.66667%;
  }
  .offset-6 {
    margin-left: 50%;
  }
  .offset-7 {
    margin-left: 58.33333%;
  }
  .offset-8 {
    margin-left: 66.66667%;
  }
  .offset-9 {
    margin-left: 75%;
  }
  .offset-10 {
    margin-left: 83.33333%;
  }
  .offset-11 {
    margin-left: 91.66667%;
  }
  @media (min-width: 620px) {
    .col-sm {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      min-width: 0;
      max-width: 100%;
    }
    .row-cols-sm-1 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-sm-2 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-sm-3 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .row-cols-sm-4 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-sm-5 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-sm-6 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-sm-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-sm-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-sm-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-sm-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-sm-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-sm-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-sm-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-sm-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-sm-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-sm-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-sm-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-sm-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-sm-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-sm-first {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1;
    }
    .order-sm-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13;
    }
    .order-sm-0 {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0;
    }
    .order-sm-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
    }
    .order-sm-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
    }
    .order-sm-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3;
    }
    .order-sm-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4;
    }
    .order-sm-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5;
    }
    .order-sm-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6;
    }
    .order-sm-7 {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7;
    }
    .order-sm-8 {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8;
    }
    .order-sm-9 {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9;
    }
    .order-sm-10 {
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10;
    }
    .order-sm-11 {
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11;
    }
    .order-sm-12 {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12;
    }
    .offset-sm-0 {
      margin-left: 0;
    }
    .offset-sm-1 {
      margin-left: 8.33333%;
    }
    .offset-sm-2 {
      margin-left: 16.66667%;
    }
    .offset-sm-3 {
      margin-left: 25%;
    }
    .offset-sm-4 {
      margin-left: 33.33333%;
    }
    .offset-sm-5 {
      margin-left: 41.66667%;
    }
    .offset-sm-6 {
      margin-left: 50%;
    }
    .offset-sm-7 {
      margin-left: 58.33333%;
    }
    .offset-sm-8 {
      margin-left: 66.66667%;
    }
    .offset-sm-9 {
      margin-left: 75%;
    }
    .offset-sm-10 {
      margin-left: 83.33333%;
    }
    .offset-sm-11 {
      margin-left: 91.66667%;
    }
  }
  @media (min-width: 828px) {
    .col-md {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      min-width: 0;
      max-width: 100%;
    }
    .row-cols-md-1 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-md-2 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-md-3 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .row-cols-md-4 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-md-5 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-md-6 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-md-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-md-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-md-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-md-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-md-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-md-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-md-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-md-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-md-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-md-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-md-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-md-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-md-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-md-first {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1;
    }
    .order-md-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13;
    }
    .order-md-0 {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0;
    }
    .order-md-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
    }
    .order-md-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
    }
    .order-md-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3;
    }
    .order-md-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4;
    }
    .order-md-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5;
    }
    .order-md-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6;
    }
    .order-md-7 {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7;
    }
    .order-md-8 {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8;
    }
    .order-md-9 {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9;
    }
    .order-md-10 {
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10;
    }
    .order-md-11 {
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11;
    }
    .order-md-12 {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12;
    }
    .offset-md-0 {
      margin-left: 0;
    }
    .offset-md-1 {
      margin-left: 8.33333%;
    }
    .offset-md-2 {
      margin-left: 16.66667%;
    }
    .offset-md-3 {
      margin-left: 25%;
    }
    .offset-md-4 {
      margin-left: 33.33333%;
    }
    .offset-md-5 {
      margin-left: 41.66667%;
    }
    .offset-md-6 {
      margin-left: 50%;
    }
    .offset-md-7 {
      margin-left: 58.33333%;
    }
    .offset-md-8 {
      margin-left: 66.66667%;
    }
    .offset-md-9 {
      margin-left: 75%;
    }
    .offset-md-10 {
      margin-left: 83.33333%;
    }
    .offset-md-11 {
      margin-left: 91.66667%;
    }
  }
  @media (min-width: 1024px) {
    .col-lg {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      min-width: 0;
      max-width: 100%;
    }
    .row-cols-lg-1 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-lg-2 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-lg-3 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .row-cols-lg-4 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-lg-5 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-lg-6 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-lg-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-lg-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-lg-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-lg-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-lg-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-lg-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-lg-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-lg-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-lg-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-lg-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-lg-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-lg-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-lg-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-lg-first {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1;
    }
    .order-lg-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13;
    }
    .order-lg-0 {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0;
    }
    .order-lg-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
    }
    .order-lg-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
    }
    .order-lg-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3;
    }
    .order-lg-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4;
    }
    .order-lg-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5;
    }
    .order-lg-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6;
    }
    .order-lg-7 {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7;
    }
    .order-lg-8 {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8;
    }
    .order-lg-9 {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9;
    }
    .order-lg-10 {
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10;
    }
    .order-lg-11 {
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11;
    }
    .order-lg-12 {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12;
    }
    .offset-lg-0 {
      margin-left: 0;
    }
    .offset-lg-1 {
      margin-left: 8.33333%;
    }
    .offset-lg-2 {
      margin-left: 16.66667%;
    }
    .offset-lg-3 {
      margin-left: 25%;
    }
    .offset-lg-4 {
      margin-left: 33.33333%;
    }
    .offset-lg-5 {
      margin-left: 41.66667%;
    }
    .offset-lg-6 {
      margin-left: 50%;
    }
    .offset-lg-7 {
      margin-left: 58.33333%;
    }
    .offset-lg-8 {
      margin-left: 66.66667%;
    }
    .offset-lg-9 {
      margin-left: 75%;
    }
    .offset-lg-10 {
      margin-left: 83.33333%;
    }
    .offset-lg-11 {
      margin-left: 91.66667%;
    }
  }
  @media (min-width: 1200px) {
    .col-xl {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      min-width: 0;
      max-width: 100%;
    }
    .row-cols-xl-1 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-xl-2 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-xl-3 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .row-cols-xl-4 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-xl-5 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-xl-6 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-xl-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-xl-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-xl-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-xl-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-xl-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-xl-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-xl-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-xl-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-xl-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-xl-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-xl-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-xl-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-xl-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-xl-first {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1;
    }
    .order-xl-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13;
    }
    .order-xl-0 {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0;
    }
    .order-xl-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
    }
    .order-xl-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
    }
    .order-xl-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3;
    }
    .order-xl-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4;
    }
    .order-xl-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5;
    }
    .order-xl-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6;
    }
    .order-xl-7 {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7;
    }
    .order-xl-8 {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8;
    }
    .order-xl-9 {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9;
    }
    .order-xl-10 {
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10;
    }
    .order-xl-11 {
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11;
    }
    .order-xl-12 {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12;
    }
    .offset-xl-0 {
      margin-left: 0;
    }
    .offset-xl-1 {
      margin-left: 8.33333%;
    }
    .offset-xl-2 {
      margin-left: 16.66667%;
    }
    .offset-xl-3 {
      margin-left: 25%;
    }
    .offset-xl-4 {
      margin-left: 33.33333%;
    }
    .offset-xl-5 {
      margin-left: 41.66667%;
    }
    .offset-xl-6 {
      margin-left: 50%;
    }
    .offset-xl-7 {
      margin-left: 58.33333%;
    }
    .offset-xl-8 {
      margin-left: 66.66667%;
    }
    .offset-xl-9 {
      margin-left: 75%;
    }
    .offset-xl-10 {
      margin-left: 83.33333%;
    }
    .offset-xl-11 {
      margin-left: 91.66667%;
    }
  }
  .table {
    width: 100%;
    margin-bottom: 3.33333rem;
    color: #0e0e0e;
    background-color: transparent;
  }
  .table td,
  .table th {
    padding: 30px 0 23px;
    vertical-align: top;
    border-top: 1px solid #ddd;
  }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
  }
  .table tbody + tbody {
    border-top: 2px solid #ddd;
  }
  .table-sm td,
  .table-sm th {
    padding: 0.3rem;
  }
  .table-bordered {
    border: 1px solid #ddd;
  }
  .table-bordered td,
  .table-bordered th {
    border: 1px solid #ddd;
  }
  .table-bordered thead td,
  .table-bordered thead th {
    border-bottom-width: 2px;
  }
  .table-borderless tbody + tbody,
  .table-borderless td,
  .table-borderless th,
  .table-borderless thead th {
    border: 0;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(14, 14, 14, 0.05);
  }
  .table-hover tbody tr:hover {
    color: #0e0e0e;
    background-color: transparent;
  }
  .table-primary,
  .table-primary > td,
  .table-primary > th {
    background-color: #b8daff;
  }
  .table-primary tbody + tbody,
  .table-primary td,
  .table-primary th,
  .table-primary thead th {
    border-color: #7abaff;
  }
  .table-hover .table-primary:hover {
    background-color: #9fcdff;
  }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff;
  }
  .table-secondary,
  .table-secondary > td,
  .table-secondary > th {
    background-color: #e2e2e2;
  }
  .table-secondary tbody + tbody,
  .table-secondary td,
  .table-secondary th,
  .table-secondary thead th {
    border-color: #c8c8c8;
  }
  .table-hover .table-secondary:hover {
    background-color: #d5d5d5;
  }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #d5d5d5;
  }
  .table-success,
  .table-success > td,
  .table-success > th {
    background-color: #c3e6cb;
  }
  .table-success tbody + tbody,
  .table-success td,
  .table-success th,
  .table-success thead th {
    border-color: #8fd19e;
  }
  .table-hover .table-success:hover {
    background-color: #b1dfbb;
  }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb;
  }
  .table-info,
  .table-info > td,
  .table-info > th {
    background-color: #bee5eb;
  }
  .table-info tbody + tbody,
  .table-info td,
  .table-info th,
  .table-info thead th {
    border-color: #86cfda;
  }
  .table-hover .table-info:hover {
    background-color: #abdde5;
  }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5;
  }
  .table-warning,
  .table-warning > td,
  .table-warning > th {
    background-color: #ffeeba;
  }
  .table-warning tbody + tbody,
  .table-warning td,
  .table-warning th,
  .table-warning thead th {
    border-color: #ffdf7e;
  }
  .table-hover .table-warning:hover {
    background-color: #ffe8a1;
  }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1;
  }
  .table-danger,
  .table-danger > td,
  .table-danger > th {
    background-color: #f5c6cb;
  }
  .table-danger tbody + tbody,
  .table-danger td,
  .table-danger th,
  .table-danger thead th {
    border-color: #ed969e;
  }
  .table-hover .table-danger:hover {
    background-color: #f1b0b7;
  }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7;
  }
  .table-light,
  .table-light > td,
  .table-light > th {
    background-color: #fcfcfc;
  }
  .table-light tbody + tbody,
  .table-light td,
  .table-light th,
  .table-light thead th {
    border-color: #fafafa;
  }
  .table-hover .table-light:hover {
    background-color: #efefef;
  }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #efefef;
  }
  .table-dark,
  .table-dark > td,
  .table-dark > th {
    background-color: #bcbcbc;
  }
  .table-dark tbody + tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #828282;
  }
  .table-hover .table-dark:hover {
    background-color: #afafaf;
  }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #afafaf;
  }
  .table-active,
  .table-active > td,
  .table-active > th {
    background-color: transparent;
  }
  .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0);
  }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0);
  }
  .table .thead-dark th {
    color: #fff;
    background-color: transparent;
    border-color: rgba(19, 19, 19, 0);
  }
  .table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #ddd;
  }
  .table-dark {
    color: #fff;
    background-color: transparent;
  }
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: rgba(19, 19, 19, 0);
  }
  .table-dark.table-bordered {
    border: 0;
  }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
  }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: transparent;
  }
  @media (max-width: 619.98px) {
    .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-sm > .table-bordered {
      border: 0;
    }
  }
  @media (max-width: 827.98px) {
    .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-md > .table-bordered {
      border: 0;
    }
  }
  @media (max-width: 1023.98px) {
    .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-lg > .table-bordered {
      border: 0;
    }
  }
  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xl > .table-bordered {
      border: 0;
    }
  }
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .form-control {
    display: block;
    width: 100%;
    height: auto;
    padding: 0 0;
    font-family: Inter, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #0e0e0e;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0;
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #0e0e0e;
  }
  .form-control:focus {
    color: #0e0e0e;
    background-color: transparent;
    border-color: #0e0e0e;
    outline: 0;
    -webkit-box-shadow: 0;
    box-shadow: 0;
  }
  .form-control::-webkit-input-placeholder {
    color: #0e0e0e;
    opacity: 1;
  }
  .form-control::-moz-placeholder {
    color: #0e0e0e;
    opacity: 1;
  }
  .form-control:-ms-input-placeholder {
    color: #0e0e0e;
    opacity: 1;
  }
  .form-control::-ms-input-placeholder {
    color: #0e0e0e;
    opacity: 1;
  }
  .form-control::placeholder {
    color: #0e0e0e;
    opacity: 1;
  }
  .form-control:disabled,
  .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }
  input[type="date"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control,
  input[type="time"].form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  select.form-control:focus::-ms-value {
    color: #0e0e0e;
    background-color: transparent;
  }
  .form-control-file,
  .form-control-range {
    display: block;
    width: 100%;
  }
  .col-form-label {
    padding-top: 1px;
    padding-bottom: 1px;
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.6;
  }
  .col-form-label-lg {
    padding-top: 1px;
    padding-bottom: 1px;
    font-size: 1.25rem;
    line-height: 1.5;
  }
  .col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5;
  }
  .form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.6;
    color: #0e0e0e;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
  }
  .form-control-plaintext.form-control-lg,
  .form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0;
  }
  .form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  .form-control-lg {
    height: auto;
    padding: 0 0;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0;
  }
  select.form-control[multiple],
  select.form-control[size] {
    height: auto;
  }
  textarea.form-control {
    height: auto;
  }
  .form-group {
    margin-bottom: 1rem;
  }
  .form-text {
    display: block;
    margin-top: 0.25rem;
  }
  .form-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
  }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
  }
  .form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
  }
  .form-check-input:disabled ~ .form-check-label,
  .form-check-input[disabled] ~ .form-check-label {
    color: #969696;
  }
  .form-check-label {
    margin-bottom: 0;
  }
  .form-check-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
  }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
  }
  .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #28a745;
  }
  .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1.6;
    color: #fff;
    background-color: rgba(40, 167, 69, 0.9);
    border-radius: 0;
  }
  .is-valid ~ .valid-feedback,
  .is-valid ~ .valid-tooltip,
  .was-validated :valid ~ .valid-feedback,
  .was-validated :valid ~ .valid-tooltip {
    display: block;
  }
  .form-control.is-valid,
  .was-validated .form-control:valid {
    border-color: #28a745;
    padding-right: 1.6em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.4em center;
    background-size: 0.8em 0.8em;
  }
  .form-control.is-valid:focus,
  .was-validated .form-control:valid:focus {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25);
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25);
  }
  .was-validated textarea.form-control:valid,
  textarea.form-control.is-valid {
    padding-right: 1.6em;
    background-position: top 0.4em right 0.4em;
  }
  .custom-select.is-valid,
  .was-validated .custom-select:valid {
    border-color: #28a745;
    padding-right: calc(0.75em + 1rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        no-repeat right 0 center/8px 10px,
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
        transparent no-repeat center right 1rem/0.8em 0.8em;
  }
  .custom-select.is-valid:focus,
  .was-validated .custom-select:valid:focus {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25);
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25);
  }
  .form-check-input.is-valid ~ .form-check-label,
  .was-validated .form-check-input:valid ~ .form-check-label {
    color: #28a745;
  }
  .form-check-input.is-valid ~ .valid-feedback,
  .form-check-input.is-valid ~ .valid-tooltip,
  .was-validated .form-check-input:valid ~ .valid-feedback,
  .was-validated .form-check-input:valid ~ .valid-tooltip {
    display: block;
  }
  .custom-control-input.is-valid ~ .custom-control-label,
  .was-validated .custom-control-input:valid ~ .custom-control-label {
    color: #28a745;
  }
  .custom-control-input.is-valid ~ .custom-control-label::before,
  .was-validated .custom-control-input:valid ~ .custom-control-label::before {
    border-color: #28a745;
  }
  .custom-control-input.is-valid:checked ~ .custom-control-label::before,
  .was-validated
    .custom-control-input:valid:checked
    ~ .custom-control-label::before {
    border-color: #34ce57;
    background-color: #34ce57;
  }
  .custom-control-input.is-valid:focus ~ .custom-control-label::before,
  .was-validated
    .custom-control-input:valid:focus
    ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25);
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25);
  }
  .custom-control-input.is-valid:focus:not(:checked)
    ~ .custom-control-label::before,
  .was-validated
    .custom-control-input:valid:focus:not(:checked)
    ~ .custom-control-label::before {
    border-color: #28a745;
  }
  .custom-file-input.is-valid ~ .custom-file-label,
  .was-validated .custom-file-input:valid ~ .custom-file-label {
    border-color: #28a745;
  }
  .custom-file-input.is-valid:focus ~ .custom-file-label,
  .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25);
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25);
  }
  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
  .invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1.6;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 0;
  }
  .is-invalid ~ .invalid-feedback,
  .is-invalid ~ .invalid-tooltip,
  .was-validated :invalid ~ .invalid-feedback,
  .was-validated :invalid ~ .invalid-tooltip {
    display: block;
  }
  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    border-color: #dc3545;
    padding-right: 1.6em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.4em center;
    background-size: 0.8em 0.8em;
  }
  .form-control.is-invalid:focus,
  .was-validated .form-control:invalid:focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
  }
  .was-validated textarea.form-control:invalid,
  textarea.form-control.is-invalid {
    padding-right: 1.6em;
    background-position: top 0.4em right 0.4em;
  }
  .custom-select.is-invalid,
  .was-validated .custom-select:invalid {
    border-color: #dc3545;
    padding-right: calc(0.75em + 1rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        no-repeat right 0 center/8px 10px,
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
        transparent no-repeat center right 1rem/0.8em 0.8em;
  }
  .custom-select.is-invalid:focus,
  .was-validated .custom-select:invalid:focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
  }
  .form-check-input.is-invalid ~ .form-check-label,
  .was-validated .form-check-input:invalid ~ .form-check-label {
    color: #dc3545;
  }
  .form-check-input.is-invalid ~ .invalid-feedback,
  .form-check-input.is-invalid ~ .invalid-tooltip,
  .was-validated .form-check-input:invalid ~ .invalid-feedback,
  .was-validated .form-check-input:invalid ~ .invalid-tooltip {
    display: block;
  }
  .custom-control-input.is-invalid ~ .custom-control-label,
  .was-validated .custom-control-input:invalid ~ .custom-control-label {
    color: #dc3545;
  }
  .custom-control-input.is-invalid ~ .custom-control-label::before,
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before {
    border-color: #dc3545;
  }
  .custom-control-input.is-invalid:checked ~ .custom-control-label::before,
  .was-validated
    .custom-control-input:invalid:checked
    ~ .custom-control-label::before {
    border-color: #e4606d;
    background-color: #e4606d;
  }
  .custom-control-input.is-invalid:focus ~ .custom-control-label::before,
  .was-validated
    .custom-control-input:invalid:focus
    ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
  }
  .custom-control-input.is-invalid:focus:not(:checked)
    ~ .custom-control-label::before,
  .was-validated
    .custom-control-input:invalid:focus:not(:checked)
    ~ .custom-control-label::before {
    border-color: #dc3545;
  }
  .custom-file-input.is-invalid ~ .custom-file-label,
  .was-validated .custom-file-input:invalid ~ .custom-file-label {
    border-color: #dc3545;
  }
  .custom-file-input.is-invalid:focus ~ .custom-file-label,
  .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
  }
  .form-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .form-inline .form-check {
    width: 100%;
  }
  @media (min-width: 620px) {
    .form-inline label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-bottom: 0;
    }
    .form-inline .form-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 0;
    }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
    .form-inline .form-control-plaintext {
      display: inline-block;
    }
    .form-inline .custom-select,
    .form-inline .input-group {
      width: auto;
    }
    .form-inline .form-check {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }
    .form-inline .form-check-input {
      position: relative;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0;
    }
    .form-inline .custom-control {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .form-inline .custom-control-label {
      margin-bottom: 0;
    }
  }
  .btn {
    display: inline-block;
    font-family: Inter, sans-serif;
    font-weight: 400;
    color: #0e0e0e;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 0 solid transparent;
    padding: 15px 40px;
    font-size: 1rem;
    line-height: 1.6;
    border-radius: 0;
    -webkit-transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  .btn:hover {
    color: #0e0e0e;
    text-decoration: none;
  }
  .btn.focus,
  .btn:focus {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .btn.disabled,
  .btn:disabled {
    opacity: 0.65;
  }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  a.btn.disabled,
  fieldset:disabled a.btn {
    pointer-events: none;
  }
  .btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
  }
  .btn-primary.focus,
  .btn-primary:focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    -webkit-box-shadow: 0 0 0 0 rgba(38, 143, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(38, 143, 255, 0.5);
  }
  .btn-primary.disabled,
  .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
  }
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(38, 143, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(38, 143, 255, 0.5);
  }
  .btn-secondary {
    color: #212529;
    background-color: #969696;
    border-color: #969696;
  }
  .btn-secondary:hover {
    color: #fff;
    background-color: #838383;
    border-color: #7d7d7d;
  }
  .btn-secondary.focus,
  .btn-secondary:focus {
    color: #fff;
    background-color: #838383;
    border-color: #7d7d7d;
    -webkit-box-shadow: 0 0 0 0 rgba(132, 133, 134, 0.5);
    box-shadow: 0 0 0 0 rgba(132, 133, 134, 0.5);
  }
  .btn-secondary.disabled,
  .btn-secondary:disabled {
    color: #212529;
    background-color: #969696;
    border-color: #969696;
  }
  .btn-secondary:not(:disabled):not(.disabled).active,
  .btn-secondary:not(:disabled):not(.disabled):active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #7d7d7d;
    border-color: #767676;
  }
  .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .btn-secondary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(132, 133, 134, 0.5);
    box-shadow: 0 0 0 0 rgba(132, 133, 134, 0.5);
  }
  .btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
  }
  .btn-success.focus,
  .btn-success:focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    -webkit-box-shadow: 0 0 0 0 rgba(72, 180, 97, 0.5);
    box-shadow: 0 0 0 0 rgba(72, 180, 97, 0.5);
  }
  .btn-success.disabled,
  .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  .btn-success:not(:disabled):not(.disabled).active,
  .btn-success:not(:disabled):not(.disabled):active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430;
  }
  .btn-success:not(:disabled):not(.disabled).active:focus,
  .btn-success:not(:disabled):not(.disabled):active:focus,
  .show > .btn-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(72, 180, 97, 0.5);
    box-shadow: 0 0 0 0 rgba(72, 180, 97, 0.5);
  }
  .btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
  }
  .btn-info.focus,
  .btn-info:focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    -webkit-box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5);
    box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5);
  }
  .btn-info.disabled,
  .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  .btn-info:not(:disabled):not(.disabled).active,
  .btn-info:not(:disabled):not(.disabled):active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f;
  }
  .btn-info:not(:disabled):not(.disabled).active:focus,
  .btn-info:not(:disabled):not(.disabled):active:focus,
  .show > .btn-info.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5);
    box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5);
  }
  .btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
  }
  .btn-warning.focus,
  .btn-warning:focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    -webkit-box-shadow: 0 0 0 0 rgba(222, 170, 12, 0.5);
    box-shadow: 0 0 0 0 rgba(222, 170, 12, 0.5);
  }
  .btn-warning.disabled,
  .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  .btn-warning:not(:disabled):not(.disabled).active,
  .btn-warning:not(:disabled):not(.disabled):active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500;
  }
  .btn-warning:not(:disabled):not(.disabled).active:focus,
  .btn-warning:not(:disabled):not(.disabled):active:focus,
  .show > .btn-warning.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(222, 170, 12, 0.5);
    box-shadow: 0 0 0 0 rgba(222, 170, 12, 0.5);
  }
  .btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
  }
  .btn-danger.focus,
  .btn-danger:focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    -webkit-box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5);
    box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5);
  }
  .btn-danger.disabled,
  .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  .btn-danger:not(:disabled):not(.disabled).active,
  .btn-danger:not(:disabled):not(.disabled):active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d;
  }
  .btn-danger:not(:disabled):not(.disabled).active:focus,
  .btn-danger:not(:disabled):not(.disabled):active:focus,
  .show > .btn-danger.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5);
    box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5);
  }
  .btn-light {
    color: #212529;
    background-color: #f5f5f5;
    border-color: #f5f5f5;
  }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e2e2;
    border-color: #dcdcdc;
  }
  .btn-light.focus,
  .btn-light:focus {
    color: #212529;
    background-color: #e2e2e2;
    border-color: #dcdcdc;
    -webkit-box-shadow: 0 0 0 0 rgba(213, 214, 214, 0.5);
    box-shadow: 0 0 0 0 rgba(213, 214, 214, 0.5);
  }
  .btn-light.disabled,
  .btn-light:disabled {
    color: #212529;
    background-color: #f5f5f5;
    border-color: #f5f5f5;
  }
  .btn-light:not(:disabled):not(.disabled).active,
  .btn-light:not(:disabled):not(.disabled):active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dcdcdc;
    border-color: #d5d5d5;
  }
  .btn-light:not(:disabled):not(.disabled).active:focus,
  .btn-light:not(:disabled):not(.disabled):active:focus,
  .show > .btn-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(213, 214, 214, 0.5);
    box-shadow: 0 0 0 0 rgba(213, 214, 214, 0.5);
  }
  .btn-dark {
    color: #fff;
    background-color: #0e0e0e;
    border-color: #0e0e0e;
  }
  .btn-dark:hover {
    color: #fff;
    background-color: #000;
    border-color: #000;
  }
  .btn-dark.focus,
  .btn-dark:focus {
    color: #fff;
    background-color: #000;
    border-color: #000;
    -webkit-box-shadow: 0 0 0 0 rgba(50, 50, 50, 0.5);
    box-shadow: 0 0 0 0 rgba(50, 50, 50, 0.5);
  }
  .btn-dark.disabled,
  .btn-dark:disabled {
    color: #fff;
    background-color: #0e0e0e;
    border-color: #0e0e0e;
  }
  .btn-dark:not(:disabled):not(.disabled).active,
  .btn-dark:not(:disabled):not(.disabled):active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #000;
    border-color: #000;
  }
  .btn-dark:not(:disabled):not(.disabled).active:focus,
  .btn-dark:not(:disabled):not(.disabled):active:focus,
  .show > .btn-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(50, 50, 50, 0.5);
    box-shadow: 0 0 0 0 rgba(50, 50, 50, 0.5);
  }
  .btn-outline-primary {
    color: #007bff;
    border-color: #007bff;
  }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  .btn-outline-primary.focus,
  .btn-outline-primary:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.5);
  }
  .btn-outline-primary.disabled,
  .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent;
  }
  .btn-outline-primary:not(:disabled):not(.disabled).active,
  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
  .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.5);
  }
  .btn-outline-secondary {
    color: #969696;
    border-color: #969696;
  }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #969696;
    border-color: #969696;
  }
  .btn-outline-secondary.focus,
  .btn-outline-secondary:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(150, 150, 150, 0.5);
    box-shadow: 0 0 0 0 rgba(150, 150, 150, 0.5);
  }
  .btn-outline-secondary.disabled,
  .btn-outline-secondary:disabled {
    color: #969696;
    background-color: transparent;
  }
  .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .btn-outline-secondary:not(:disabled):not(.disabled):active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #969696;
    border-color: #969696;
  }
  .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
  .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(150, 150, 150, 0.5);
    box-shadow: 0 0 0 0 rgba(150, 150, 150, 0.5);
  }
  .btn-outline-success {
    color: #28a745;
    border-color: #28a745;
  }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  .btn-outline-success.focus,
  .btn-outline-success:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5);
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5);
  }
  .btn-outline-success.disabled,
  .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent;
  }
  .btn-outline-success:not(:disabled):not(.disabled).active,
  .btn-outline-success:not(:disabled):not(.disabled):active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  .btn-outline-success:not(:disabled):not(.disabled).active:focus,
  .btn-outline-success:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5);
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5);
  }
  .btn-outline-info {
    color: #17a2b8;
    border-color: #17a2b8;
  }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  .btn-outline-info.focus,
  .btn-outline-info:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
  }
  .btn-outline-info.disabled,
  .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent;
  }
  .btn-outline-info:not(:disabled):not(.disabled).active,
  .btn-outline-info:not(:disabled):not(.disabled):active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  .btn-outline-info:not(:disabled):not(.disabled).active:focus,
  .btn-outline-info:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-info.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
  }
  .btn-outline-warning {
    color: #ffc107;
    border-color: #ffc107;
  }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  .btn-outline-warning.focus,
  .btn-outline-warning:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
  }
  .btn-outline-warning.disabled,
  .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent;
  }
  .btn-outline-warning:not(:disabled):not(.disabled).active,
  .btn-outline-warning:not(:disabled):not(.disabled):active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
  .btn-outline-warning:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-warning.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
  }
  .btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545;
  }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  .btn-outline-danger.focus,
  .btn-outline-danger:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
  }
  .btn-outline-danger.disabled,
  .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent;
  }
  .btn-outline-danger:not(:disabled):not(.disabled).active,
  .btn-outline-danger:not(:disabled):not(.disabled):active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
  .btn-outline-danger:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-danger.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
  }
  .btn-outline-light {
    color: #f5f5f5;
    border-color: #f5f5f5;
  }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f5f5f5;
    border-color: #f5f5f5;
  }
  .btn-outline-light.focus,
  .btn-outline-light:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(245, 245, 245, 0.5);
    box-shadow: 0 0 0 0 rgba(245, 245, 245, 0.5);
  }
  .btn-outline-light.disabled,
  .btn-outline-light:disabled {
    color: #f5f5f5;
    background-color: transparent;
  }
  .btn-outline-light:not(:disabled):not(.disabled).active,
  .btn-outline-light:not(:disabled):not(.disabled):active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f5f5f5;
    border-color: #f5f5f5;
  }
  .btn-outline-light:not(:disabled):not(.disabled).active:focus,
  .btn-outline-light:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(245, 245, 245, 0.5);
    box-shadow: 0 0 0 0 rgba(245, 245, 245, 0.5);
  }
  .btn-outline-dark {
    color: #0e0e0e;
    border-color: #0e0e0e;
  }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #0e0e0e;
    border-color: #0e0e0e;
  }
  .btn-outline-dark.focus,
  .btn-outline-dark:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(14, 14, 14, 0.5);
    box-shadow: 0 0 0 0 rgba(14, 14, 14, 0.5);
  }
  .btn-outline-dark.disabled,
  .btn-outline-dark:disabled {
    color: #0e0e0e;
    background-color: transparent;
  }
  .btn-outline-dark:not(:disabled):not(.disabled).active,
  .btn-outline-dark:not(:disabled):not(.disabled):active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #0e0e0e;
    border-color: #0e0e0e;
  }
  .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
  .btn-outline-dark:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(14, 14, 14, 0.5);
    box-shadow: 0 0 0 0 rgba(14, 14, 14, 0.5);
  }
  .btn-link {
    font-weight: 400;
    color: #007bff;
    text-decoration: none;
  }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  .btn-link.focus,
  .btn-link:focus {
    text-decoration: underline;
  }
  .btn-link.disabled,
  .btn-link:disabled {
    color: #969696;
    pointer-events: none;
  }
  .btn-group-lg > .btn,
  .btn-lg {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }
  .btn-group-sm > .btn,
  .btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  .btn-block {
    display: block;
    width: 100%;
  }
  .btn-block + .btn-block {
    margin-top: 0.5rem;
  }
  input[type="button"].btn-block,
  input[type="reset"].btn-block,
  input[type="submit"].btn-block {
    width: 100%;
  }
  .fade {
    -webkit-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
  }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  .fade:not(.show) {
    opacity: 0;
  }
  .collapse:not(.show) {
    display: none;
  }
  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0.35s ease;
    -o-transition: height 0.35s ease;
    transition: height 0.35s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  .dropdown,
  .dropleft,
  .dropright,
  .dropup {
    position: relative;
  }
  .dropdown-toggle {
    white-space: nowrap;
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 200px;
    padding: 20px 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #0e0e0e;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(14, 14, 14, 0.15);
    border-radius: 0;
  }
  .dropdown-menu-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  @media (min-width: 620px) {
    .dropdown-menu-sm-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-sm-right {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 828px) {
    .dropdown-menu-md-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-md-right {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 1024px) {
    .dropdown-menu-lg-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-lg-right {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 1200px) {
    .dropdown-menu-xl-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-xl-right {
      right: 0;
      left: auto;
    }
  }
  .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
  }
  .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
  }
  .dropup .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
  }
  .dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }
  .dropright .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropright .dropdown-toggle::after {
    vertical-align: 0;
  }
  .dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
  }
  .dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
  }
  .dropleft .dropdown-toggle::after {
    display: none;
  }
  .dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
  }
  .dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropleft .dropdown-toggle::before {
    vertical-align: 0;
  }
  .dropdown-menu[x-placement^="bottom"],
  .dropdown-menu[x-placement^="left"],
  .dropdown-menu[x-placement^="right"],
  .dropdown-menu[x-placement^="top"] {
    right: auto;
    bottom: auto;
  }
  .dropdown-divider {
    height: 0;
    margin: 1.66667rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
  }
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  .dropdown-item:focus,
  .dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff;
  }
  .dropdown-item.disabled,
  .dropdown-item:disabled {
    color: #969696;
    pointer-events: none;
    background-color: transparent;
  }
  .dropdown-menu.show {
    display: block;
  }
  .dropdown-header {
    display: block;
    padding: 20px 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #969696;
    white-space: nowrap;
  }
  .dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #212529;
  }
  .btn-group,
  .btn-group-vertical {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
  }
  .btn-group-vertical > .btn,
  .btn-group > .btn {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .btn-group-vertical > .btn:hover,
  .btn-group > .btn:hover {
    z-index: 1;
  }
  .btn-group-vertical > .btn.active,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn:focus,
  .btn-group > .btn.active,
  .btn-group > .btn:active,
  .btn-group > .btn:focus {
    z-index: 1;
  }
  .btn-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .btn-toolbar .input-group {
    width: auto;
  }
  .btn-group > .btn-group:not(:first-child),
  .btn-group > .btn:not(:first-child) {
    margin-left: 0;
  }
  .btn-group > .btn-group:not(:last-child) > .btn,
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .btn-group > .btn-group:not(:first-child) > .btn,
  .btn-group > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .dropdown-toggle-split {
    padding-right: 30px;
    padding-left: 30px;
  }
  .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after {
    margin-left: 0;
  }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0;
  }
  .btn-group-sm > .btn + .dropdown-toggle-split,
  .btn-sm + .dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
  }
  .btn-group-lg > .btn + .dropdown-toggle-split,
  .btn-lg + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .btn-group-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%;
  }
  .btn-group-vertical > .btn-group:not(:first-child),
  .btn-group-vertical > .btn:not(:first-child) {
    margin-top: 0;
  }
  .btn-group-vertical > .btn-group:not(:last-child) > .btn,
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .btn-group-vertical > .btn-group:not(:first-child) > .btn,
  .btn-group-vertical > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .btn-group-toggle > .btn,
  .btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0;
  }
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }
  .input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
  }
  .input-group > .custom-file,
  .input-group > .custom-select,
  .input-group > .form-control,
  .input-group > .form-control-plaintext {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
  }
  .input-group > .custom-file + .custom-file,
  .input-group > .custom-file + .custom-select,
  .input-group > .custom-file + .form-control,
  .input-group > .custom-select + .custom-file,
  .input-group > .custom-select + .custom-select,
  .input-group > .custom-select + .form-control,
  .input-group > .form-control + .custom-file,
  .input-group > .form-control + .custom-select,
  .input-group > .form-control + .form-control,
  .input-group > .form-control-plaintext + .custom-file,
  .input-group > .form-control-plaintext + .custom-select,
  .input-group > .form-control-plaintext + .form-control {
    margin-left: -1px;
  }
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
  .input-group > .custom-select:focus,
  .input-group > .form-control:focus {
    z-index: 3;
  }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4;
  }
  .input-group > .custom-select:not(:last-child),
  .input-group > .form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group > .custom-select:not(:first-child),
  .input-group > .form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group > .custom-file {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .input-group > .custom-file:not(:last-child) .custom-file-label,
  .input-group > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group > .custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-append,
  .input-group-prepend {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .input-group-append .btn,
  .input-group-prepend .btn {
    position: relative;
    z-index: 2;
  }
  .input-group-append .btn:focus,
  .input-group-prepend .btn:focus {
    z-index: 3;
  }
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .input-group-text + .input-group-text {
    margin-left: -1px;
  }
  .input-group-prepend {
    margin-right: -1px;
  }
  .input-group-append {
    margin-left: -1px;
  }
  .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 0;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #0e0e0e;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0;
  }
  .input-group-text input[type="checkbox"],
  .input-group-text input[type="radio"] {
    margin-top: 0;
  }
  .input-group-lg > .custom-select,
  .input-group-lg > .form-control:not(textarea) {
    height: auto;
  }
  .input-group-lg > .custom-select,
  .input-group-lg > .form-control,
  .input-group-lg > .input-group-append > .btn,
  .input-group-lg > .input-group-append > .input-group-text,
  .input-group-lg > .input-group-prepend > .btn,
  .input-group-lg > .input-group-prepend > .input-group-text {
    padding: 0 0;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0;
  }
  .input-group-sm > .custom-select,
  .input-group-sm > .form-control:not(textarea) {
    height: calc(1.5em + 0.5rem + 2px);
  }
  .input-group-sm > .custom-select,
  .input-group-sm > .form-control,
  .input-group-sm > .input-group-append > .btn,
  .input-group-sm > .input-group-append > .input-group-text,
  .input-group-sm > .input-group-prepend > .btn,
  .input-group-sm > .input-group-prepend > .input-group-text {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  .input-group-lg > .custom-select,
  .input-group-sm > .custom-select {
    padding-right: 1rem;
  }
  .input-group
    > .input-group-append:last-child
    > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group
    > .input-group-append:last-child
    > .input-group-text:not(:last-child),
  .input-group > .input-group-append:not(:last-child) > .btn,
  .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group > .input-group-append > .btn,
  .input-group > .input-group-append > .input-group-text,
  .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .input-group
    > .input-group-prepend:first-child
    > .input-group-text:not(:first-child),
  .input-group > .input-group-prepend:not(:first-child) > .btn,
  .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .custom-control {
    position: relative;
    display: block;
    min-height: 1.6rem;
    padding-left: 1.5rem;
  }
  .custom-control-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 1rem;
  }
  .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.3rem;
    opacity: 0;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0;
    box-shadow: 0;
  }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #0e0e0e;
  }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff;
  }
  .custom-control-input:disabled ~ .custom-control-label,
  .custom-control-input[disabled] ~ .custom-control-label {
    color: #969696;
  }
  .custom-control-input:disabled ~ .custom-control-label::before,
  .custom-control-input[disabled] ~ .custom-control-label::before {
    background-color: #e9ecef;
  }
  .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
  }
  .custom-control-label::before {
    position: absolute;
    top: 0.3rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: transparent;
    border: #adb5bd solid 1px;
  }
  .custom-control-label::after {
    position: absolute;
    top: 0.3rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%;
  }
  .custom-checkbox .custom-control-label::before {
    border-radius: 0;
  }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
  }
  .custom-checkbox
    .custom-control-input:indeterminate
    ~ .custom-control-label::before {
    border-color: #007bff;
    background-color: #007bff;
  }
  .custom-checkbox
    .custom-control-input:indeterminate
    ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
  }
  .custom-checkbox
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
  }
  .custom-checkbox
    .custom-control-input:disabled:indeterminate
    ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
  }
  .custom-radio .custom-control-label::before {
    border-radius: 50%;
  }
  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  }
  .custom-radio
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
  }
  .custom-switch {
    padding-left: 2.25rem;
  }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem;
  }
  .custom-switch .custom-control-label::after {
    top: calc(0.3rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    -webkit-transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .custom-switch .custom-control-label::after {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: transparent;
    -webkit-transform: translateX(0.75rem);
    -ms-transform: translateX(0.75rem);
    transform: translateX(0.75rem);
  }
  .custom-switch
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
  }
  .custom-select {
    display: inline-block;
    width: 100%;
    height: auto;
    padding: 0 1rem 0 0;
    font-family: Inter, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #0e0e0e;
    vertical-align: middle;
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0 center/8px 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .custom-select:focus {
    border-color: #0e0e0e;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.25);
  }
  .custom-select:focus::-ms-value {
    color: #0e0e0e;
    background-color: transparent;
  }
  .custom-select[multiple],
  .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0;
    background-image: none;
  }
  .custom-select:disabled {
    color: #969696;
    background-color: #e9ecef;
  }
  .custom-select::-ms-expand {
    display: none;
  }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #0e0e0e;
  }
  .custom-select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
  }
  .custom-select-lg {
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    font-size: 1.25rem;
  }
  .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: auto;
    margin-bottom: 0;
  }
  .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: auto;
    margin: 0;
    opacity: 0;
  }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #0e0e0e;
    -webkit-box-shadow: 0;
    box-shadow: 0;
  }
  .custom-file-input:disabled ~ .custom-file-label,
  .custom-file-input[disabled] ~ .custom-file-label {
    background-color: #e9ecef;
  }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse";
  }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse);
  }
  .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: auto;
    padding: 0 0;
    font-family: Inter, sans-serif;
    font-weight: 400;
    line-height: 1.6;
    color: #0e0e0e;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0;
  }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 1.6em;
    padding: 0 0;
    line-height: 1.6;
    color: #0e0e0e;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0;
  }
  .custom-range {
    width: 100%;
    height: 1rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .custom-range:focus {
    outline: 0;
  }
  .custom-range:focus::-webkit-slider-thumb {
    -webkit-box-shadow: 0 0 0 1px #fff, 0;
    box-shadow: 0 0 0 1px #fff, 0;
  }
  .custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0;
  }
  .custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0;
  }
  .custom-range::-moz-focus-outer {
    border: 0;
  }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
      -webkit-transition: none;
      transition: none;
    }
  }
  .custom-range::-webkit-slider-thumb:active {
    background-color: #b3d7ff;
  }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
  }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
      -moz-transition: none;
      transition: none;
    }
  }
  .custom-range::-moz-range-thumb:active {
    background-color: #b3d7ff;
  }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
  }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    .custom-range::-ms-thumb {
      -ms-transition: none;
      transition: none;
    }
  }
  .custom-range::-ms-thumb:active {
    background-color: #b3d7ff;
  }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
  }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem;
  }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem;
  }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd;
  }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
  }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd;
  }
  .custom-range:disabled::-moz-range-track {
    cursor: default;
  }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd;
  }
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    -webkit-transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .nav-link {
    display: block;
    padding: 0 0;
  }
  .nav-link:focus,
  .nav-link:hover {
    text-decoration: none;
  }
  .nav-link.disabled {
    color: #969696;
    pointer-events: none;
    cursor: default;
  }
  .nav-tabs {
    border-bottom: 0 solid #dee2e6;
  }
  .nav-tabs .nav-item {
    margin-bottom: 0;
  }
  .nav-tabs .nav-link {
    border: 0 solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #dee2e6;
  }
  .nav-tabs .nav-link.disabled {
    color: #969696;
    background-color: transparent;
    border-color: transparent;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: inherit;
    background-color: transparent;
    border-color: #dee2e6 #dee2e6 transparent;
  }
  .nav-tabs .dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .nav-pills .nav-link {
    border-radius: 0;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #f5f5f5;
  }
  .nav-fill .nav-item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center;
  }
  .nav-justified .nav-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
  }
  .tab-content > .tab-pane {
    display: none;
  }
  .tab-content > .active {
    display: block;
  }
  .navbar {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 0;
  }
  .navbar .container,
  .navbar .container-fluid,
  .navbar .container-lg,
  .navbar .container-md,
  .navbar .container-sm,
  .navbar .container-xl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .navbar-brand {
    display: inline-block;
    margin-right: 0;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
  }
  .navbar-brand:focus,
  .navbar-brand:hover {
    text-decoration: none;
  }
  .navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .navbar-text {
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0;
  }
  .navbar-toggler:focus,
  .navbar-toggler:hover {
    text-decoration: none;
  }
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
  }
  @media (max-width: 619.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .container-lg,
    .navbar-expand-sm > .container-md,
    .navbar-expand-sm > .container-sm,
    .navbar-expand-sm > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 620px) {
    .navbar-expand-sm {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .container-lg,
    .navbar-expand-sm > .container-md,
    .navbar-expand-sm > .container-sm,
    .navbar-expand-sm > .container-xl {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }
    .navbar-expand-sm .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler {
      display: none;
    }
  }
  @media (max-width: 827.98px) {
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid,
    .navbar-expand-md > .container-lg,
    .navbar-expand-md > .container-md,
    .navbar-expand-md > .container-sm,
    .navbar-expand-md > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 828px) {
    .navbar-expand-md {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid,
    .navbar-expand-md > .container-lg,
    .navbar-expand-md > .container-md,
    .navbar-expand-md > .container-sm,
    .navbar-expand-md > .container-xl {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }
    .navbar-expand-md .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler {
      display: none;
    }
  }
  @media (max-width: 1023.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 1024px) {
    .navbar-expand-lg {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-xl {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
      display: none;
    }
  }
  @media (max-width: 1199.98px) {
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 1200px) {
    .navbar-expand-xl {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-xl {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
      display: none;
    }
  }
  .navbar-expand {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid,
  .navbar-expand > .container-lg,
  .navbar-expand > .container-md,
  .navbar-expand > .container-sm,
  .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid,
  .navbar-expand > .container-lg,
  .navbar-expand > .container-md,
  .navbar-expand > .container-sm,
  .navbar-expand > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand .navbar-toggler {
    display: none;
  }
  .navbar-light .navbar-brand {
    color: rgba(14, 14, 14, 0.9);
  }
  .navbar-light .navbar-brand:focus,
  .navbar-light .navbar-brand:hover {
    color: rgba(14, 14, 14, 0.9);
  }
  .navbar-light .navbar-nav .nav-link {
    color: rgba(14, 14, 14, 0.5);
  }
  .navbar-light .navbar-nav .nav-link:focus,
  .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(14, 14, 14, 0.7);
  }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(14, 14, 14, 0.3);
  }
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.active,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .show > .nav-link {
    color: rgba(14, 14, 14, 0.9);
  }
  .navbar-light .navbar-toggler {
    color: rgba(14, 14, 14, 0.5);
    border-color: rgba(14, 14, 14, 0.1);
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2814, 14, 14, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  .navbar-light .navbar-text {
    color: rgba(14, 14, 14, 0.5);
  }
  .navbar-light .navbar-text a {
    color: rgba(14, 14, 14, 0.9);
  }
  .navbar-light .navbar-text a:focus,
  .navbar-light .navbar-text a:hover {
    color: rgba(14, 14, 14, 0.9);
  }
  .navbar-dark .navbar-brand {
    color: #fff;
  }
  .navbar-dark .navbar-brand:focus,
  .navbar-dark .navbar-brand:hover {
    color: #fff;
  }
  .navbar-dark .navbar-nav .nav-link {
    color: #fff;
  }
  .navbar-dark .navbar-nav .nav-link:focus,
  .navbar-dark .navbar-nav .nav-link:hover {
    color: #fff;
  }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
  }
  .navbar-dark .navbar-nav .active > .nav-link,
  .navbar-dark .navbar-nav .nav-link.active,
  .navbar-dark .navbar-nav .nav-link.show,
  .navbar-dark .navbar-nav .show > .nav-link {
    color: #fff;
  }
  .navbar-dark .navbar-toggler {
    color: #fff;
    border-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23fff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  .navbar-dark .navbar-text {
    color: #fff;
  }
  .navbar-dark .navbar-text a {
    color: #fff;
  }
  .navbar-dark .navbar-text a:focus,
  .navbar-dark .navbar-text a:hover {
    color: #fff;
  }
  .card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent;
    background-clip: border-box;
    border: 0 solid rgba(14, 14, 14, 0.125);
    border-radius: 0;
  }
  .card > hr {
    margin-right: 0;
    margin-left: 0;
  }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit;
  }
  .card > .list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
  }
  .card-title {
    margin-bottom: 0.75rem;
  }
  .card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
  }
  .card-text:last-child {
    margin-bottom: 0;
  }
  .card-link:hover {
    text-decoration: none;
  }
  .card-link + .card-link {
    margin-left: 1.25rem;
  }
  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(14, 14, 14, 0.03);
    border-bottom: 0 solid rgba(14, 14, 14, 0.125);
  }
  .card-header:first-child {
    border-radius: 0;
  }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0;
  }
  .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(14, 14, 14, 0.03);
    border-top: 0 solid rgba(14, 14, 14, 0.125);
  }
  .card-footer:last-child {
    border-radius: 0;
  }
  .card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
  }
  .card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
  }
  .card-img,
  .card-img-bottom,
  .card-img-top {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
  }
  .card-img,
  .card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-img,
  .card-img-bottom {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-deck .card {
    margin-bottom: 0;
  }
  @media (min-width: 620px) {
    .card-deck {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      margin-right: 0;
      margin-left: 0;
    }
    .card-deck .card {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      margin-right: 0;
      margin-bottom: 0;
      margin-left: 0;
    }
  }
  .card-group > .card {
    margin-bottom: 0;
  }
  @media (min-width: 620px) {
    .card-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
    }
    .card-group > .card {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      margin-bottom: 0;
    }
    .card-group > .card + .card {
      margin-left: 0;
      border-left: 0;
    }
    .card-group > .card:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-header,
    .card-group > .card:not(:last-child) .card-img-top {
      border-top-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-footer,
    .card-group > .card:not(:last-child) .card-img-bottom {
      border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-header,
    .card-group > .card:not(:first-child) .card-img-top {
      border-top-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-footer,
    .card-group > .card:not(:first-child) .card-img-bottom {
      border-bottom-left-radius: 0;
    }
  }
  .card-columns .card {
    margin-bottom: 0.75rem;
  }
  @media (min-width: 620px) {
    .card-columns {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      -webkit-column-gap: 1.25rem;
      -moz-column-gap: 1.25rem;
      column-gap: 1.25rem;
      orphans: 1;
      widows: 1;
    }
    .card-columns .card {
      display: inline-block;
      width: 100%;
    }
  }
  .accordion > .card {
    overflow: hidden;
  }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: 0;
  }
  .breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0;
  }
  .breadcrumb-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #969696;
    content: "/";
  }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
  }
  .breadcrumb-item.active {
    color: #969696;
  }
  .pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0;
  }
  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
  }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
  }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .page-item:last-child .page-link {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  .page-item.disabled .page-link {
    color: #969696;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
  }
  .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }
  .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
  }
  .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
  }
  .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }
  .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0;
    -webkit-transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  a.badge:focus,
  a.badge:hover {
    text-decoration: none;
  }
  .badge:empty {
    display: none;
  }
  .btn .badge {
    position: relative;
    top: -1px;
  }
  .badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
  }
  .badge-primary {
    color: #fff;
    background-color: #007bff;
  }
  a.badge-primary:focus,
  a.badge-primary:hover {
    color: #fff;
    background-color: #0062cc;
  }
  a.badge-primary.focus,
  a.badge-primary:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.5);
  }
  .badge-secondary {
    color: #212529;
    background-color: #969696;
  }
  a.badge-secondary:focus,
  a.badge-secondary:hover {
    color: #212529;
    background-color: #7d7d7d;
  }
  a.badge-secondary.focus,
  a.badge-secondary:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(150, 150, 150, 0.5);
    box-shadow: 0 0 0 0 rgba(150, 150, 150, 0.5);
  }
  .badge-success {
    color: #fff;
    background-color: #28a745;
  }
  a.badge-success:focus,
  a.badge-success:hover {
    color: #fff;
    background-color: #1e7e34;
  }
  a.badge-success.focus,
  a.badge-success:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5);
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5);
  }
  .badge-info {
    color: #fff;
    background-color: #17a2b8;
  }
  a.badge-info:focus,
  a.badge-info:hover {
    color: #fff;
    background-color: #117a8b;
  }
  a.badge-info.focus,
  a.badge-info:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
  }
  .badge-warning {
    color: #212529;
    background-color: #ffc107;
  }
  a.badge-warning:focus,
  a.badge-warning:hover {
    color: #212529;
    background-color: #d39e00;
  }
  a.badge-warning.focus,
  a.badge-warning:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
  }
  .badge-danger {
    color: #fff;
    background-color: #dc3545;
  }
  a.badge-danger:focus,
  a.badge-danger:hover {
    color: #fff;
    background-color: #bd2130;
  }
  a.badge-danger.focus,
  a.badge-danger:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
  }
  .badge-light {
    color: #212529;
    background-color: #f5f5f5;
  }
  a.badge-light:focus,
  a.badge-light:hover {
    color: #212529;
    background-color: #dcdcdc;
  }
  a.badge-light.focus,
  a.badge-light:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(245, 245, 245, 0.5);
    box-shadow: 0 0 0 0 rgba(245, 245, 245, 0.5);
  }
  .badge-dark {
    color: #fff;
    background-color: #0e0e0e;
  }
  a.badge-dark:focus,
  a.badge-dark:hover {
    color: #fff;
    background-color: #000;
  }
  a.badge-dark.focus,
  a.badge-dark:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(14, 14, 14, 0.5);
    box-shadow: 0 0 0 0 rgba(14, 14, 14, 0.5);
  }
  .jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: 0.3rem;
  }
  @media (min-width: 620px) {
    .jumbotron {
      padding: 4rem 2rem;
    }
  }
  .jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
  }
  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0;
  }
  .alert-heading {
    color: inherit;
  }
  .alert-link {
    font-weight: 700;
  }
  .alert-dismissible {
    padding-right: 4rem;
  }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
  }
  .alert-primary {
    color: #07478b;
    background-color: #cce5ff;
    border-color: #b8daff;
  }
  .alert-primary hr {
    border-top-color: #9fcdff;
  }
  .alert-primary .alert-link {
    color: #052e5a;
  }
  .alert-secondary {
    color: #555;
    background-color: #eaeaea;
    border-color: #e2e2e2;
  }
  .alert-secondary hr {
    border-top-color: #d5d5d5;
  }
  .alert-secondary .alert-link {
    color: #3c3c3c;
  }
  .alert-success {
    color: #1c5e2b;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
  .alert-success hr {
    border-top-color: #b1dfbb;
  }
  .alert-success .alert-link {
    color: #103719;
  }
  .alert-info {
    color: #135b66;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }
  .alert-info hr {
    border-top-color: #abdde5;
  }
  .alert-info .alert-link {
    color: #0b353b;
  }
  .alert-warning {
    color: #8b6b0a;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
  .alert-warning hr {
    border-top-color: #ffe8a1;
  }
  .alert-warning .alert-link {
    color: #5b4607;
  }
  .alert-danger {
    color: #79222b;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  .alert-danger hr {
    border-top-color: #f1b0b7;
  }
  .alert-danger .alert-link {
    color: #51171d;
  }
  .alert-light {
    color: #868686;
    background-color: #fdfdfd;
    border-color: #fcfcfc;
  }
  .alert-light hr {
    border-top-color: #efefef;
  }
  .alert-light .alert-link {
    color: #6d6d6d;
  }
  .alert-dark {
    color: #0e0e0e;
    background-color: #cfcfcf;
    border-color: #bcbcbc;
  }
  .alert-dark hr {
    border-top-color: #afafaf;
  }
  .alert-dark .alert-link {
    color: #000;
  }
  @-webkit-keyframes progress-bar-stripes {
    from {
      background-position: 1rem 0;
    }
    to {
      background-position: 0 0;
    }
  }
  @keyframes progress-bar-stripes {
    from {
      background-position: 1rem 0;
    }
    to {
      background-position: 0 0;
    }
  }
  .progress {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0;
  }
  .progress-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007bff;
    -webkit-transition: width 0.6s ease;
    -o-transition: width 0.6s ease;
    transition: width 0.6s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  .progress-bar-striped {
    background-image: -o-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 1rem 1rem;
  }
  .progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;
  }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
      animation: none;
    }
  }
  .media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .list-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0;
  }
  .list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
  }
  .list-group-item-action:focus,
  .list-group-item-action:hover {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
  }
  .list-group-item-action:active {
    color: #0e0e0e;
    background-color: #e9ecef;
  }
  .list-group-item {
    position: relative;
    display: block;
    padding: 0 0;
    background-color: transparent;
    border: 1px solid rgba(14, 14, 14, 0.125);
  }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }
  .list-group-item.disabled,
  .list-group-item:disabled {
    color: #969696;
    pointer-events: none;
    background-color: transparent;
  }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  .list-group-item + .list-group-item {
    border-top-width: 0;
  }
  .list-group-item + .list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px;
  }
  .list-group-horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
  @media (min-width: 620px) {
    .list-group-horizontal-sm {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }
  @media (min-width: 828px) {
    .list-group-horizontal-md {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }
  @media (min-width: 1024px) {
    .list-group-horizontal-lg {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }
  @media (min-width: 1200px) {
    .list-group-horizontal-xl {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }
  .list-group-flush {
    border-radius: 0;
  }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px;
  }
  .list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0;
  }
  .list-group-item-primary {
    color: #07478b;
    background-color: #b8daff;
  }
  .list-group-item-primary.list-group-item-action:focus,
  .list-group-item-primary.list-group-item-action:hover {
    color: #07478b;
    background-color: #9fcdff;
  }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #07478b;
    border-color: #07478b;
  }
  .list-group-item-secondary {
    color: #555;
    background-color: #e2e2e2;
  }
  .list-group-item-secondary.list-group-item-action:focus,
  .list-group-item-secondary.list-group-item-action:hover {
    color: #555;
    background-color: #d5d5d5;
  }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #555;
    border-color: #555;
  }
  .list-group-item-success {
    color: #1c5e2b;
    background-color: #c3e6cb;
  }
  .list-group-item-success.list-group-item-action:focus,
  .list-group-item-success.list-group-item-action:hover {
    color: #1c5e2b;
    background-color: #b1dfbb;
  }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #1c5e2b;
    border-color: #1c5e2b;
  }
  .list-group-item-info {
    color: #135b66;
    background-color: #bee5eb;
  }
  .list-group-item-info.list-group-item-action:focus,
  .list-group-item-info.list-group-item-action:hover {
    color: #135b66;
    background-color: #abdde5;
  }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #135b66;
    border-color: #135b66;
  }
  .list-group-item-warning {
    color: #8b6b0a;
    background-color: #ffeeba;
  }
  .list-group-item-warning.list-group-item-action:focus,
  .list-group-item-warning.list-group-item-action:hover {
    color: #8b6b0a;
    background-color: #ffe8a1;
  }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #8b6b0a;
    border-color: #8b6b0a;
  }
  .list-group-item-danger {
    color: #79222b;
    background-color: #f5c6cb;
  }
  .list-group-item-danger.list-group-item-action:focus,
  .list-group-item-danger.list-group-item-action:hover {
    color: #79222b;
    background-color: #f1b0b7;
  }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #79222b;
    border-color: #79222b;
  }
  .list-group-item-light {
    color: #868686;
    background-color: #fcfcfc;
  }
  .list-group-item-light.list-group-item-action:focus,
  .list-group-item-light.list-group-item-action:hover {
    color: #868686;
    background-color: #efefef;
  }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #868686;
    border-color: #868686;
  }
  .list-group-item-dark {
    color: #0e0e0e;
    background-color: #bcbcbc;
  }
  .list-group-item-dark.list-group-item-action:focus,
  .list-group-item-dark.list-group-item-action:hover {
    color: #0e0e0e;
    background-color: #afafaf;
  }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #0e0e0e;
    border-color: #0e0e0e;
  }
  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #0e0e0e;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
  }
  .close:hover {
    color: #0e0e0e;
    text-decoration: none;
  }
  .close:not(:disabled):not(.disabled):focus,
  .close:not(:disabled):not(.disabled):hover {
    opacity: 0.75;
  }
  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
  }
  a.close.disabled {
    pointer-events: none;
  }
  .toast {
    max-width: 350px;
    overflow: hidden;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0.25rem 0.75rem rgba(14, 14, 14, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(14, 14, 14, 0.1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: 0.25rem;
  }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem;
  }
  .toast.showing {
    opacity: 1;
  }
  .toast.show {
    display: block;
    opacity: 1;
  }
  .toast.hide {
    display: none;
  }
  .toast-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #969696;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  .toast-body {
    padding: 0.75rem;
  }
  .modal-open {
    overflow: hidden;
  }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
  }
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
  }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    -o-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    -ms-transform: translate(0, -50px);
    transform: translate(0, -50px);
  }
  @media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  .modal.show .modal-dialog {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);
  }
  .modal-dialog-scrollable {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-height: calc(100% - 1rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
  }
  .modal-dialog-scrollable .modal-footer,
  .modal-dialog-scrollable .modal-header {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto;
  }
  .modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 1rem);
  }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: "";
  }
  .modal-dialog-centered.modal-dialog-scrollable {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
  }
  .modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
  }
  .modal-dialog-centered.modal-dialog-scrollable::before {
    content: none;
  }
  .modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(14, 14, 14, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #0e0e0e;
  }
  .modal-backdrop.fade {
    opacity: 0;
  }
  .modal-backdrop.show {
    opacity: 0.5;
  }
  .modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
  }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
  }
  .modal-title {
    margin-bottom: 0;
    line-height: 1.6;
  }
  // .modal-body {
  //   position: relative;
  //   -webkit-box-flex: 1;
  //   -ms-flex: 1 1 auto;
  //   flex: 1 1 auto;
  //   padding: 1rem;
  // }
  .modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
  }
  .modal-footer > * {
    margin: 0.25rem;
  }
  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }
  @media (min-width: 620px) {
    .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto;
    }
    .modal-dialog-scrollable {
      max-height: calc(100% - 3.5rem);
    }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem);
    }
    .modal-dialog-centered {
      min-height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content;
    }
    .modal-sm {
      max-width: 300px;
    }
  }
  @media (min-width: 1024px) {
    .modal-lg,
    .modal-xl {
      max-width: 800px;
    }
  }
  @media (min-width: 1200px) {
    .modal-xl {
      max-width: 1140px;
    }
  }
  .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;
  }
  .tooltip.show {
    opacity: 0.9;
  }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
  }
  .tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
  .bs-tooltip-auto[x-placement^="top"],
  .bs-tooltip-top {
    padding: 0.4rem 0;
  }
  .bs-tooltip-auto[x-placement^="top"] .arrow,
  .bs-tooltip-top .arrow {
    bottom: 0;
  }
  .bs-tooltip-auto[x-placement^="top"] .arrow::before,
  .bs-tooltip-top .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #0e0e0e;
  }
  .bs-tooltip-auto[x-placement^="right"],
  .bs-tooltip-right {
    padding: 0 0.4rem;
  }
  .bs-tooltip-auto[x-placement^="right"] .arrow,
  .bs-tooltip-right .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
  }
  .bs-tooltip-auto[x-placement^="right"] .arrow::before,
  .bs-tooltip-right .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #0e0e0e;
  }
  .bs-tooltip-auto[x-placement^="bottom"],
  .bs-tooltip-bottom {
    padding: 0.4rem 0;
  }
  .bs-tooltip-auto[x-placement^="bottom"] .arrow,
  .bs-tooltip-bottom .arrow {
    top: 0;
  }
  .bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
  .bs-tooltip-bottom .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #0e0e0e;
  }
  .bs-tooltip-auto[x-placement^="left"],
  .bs-tooltip-left {
    padding: 0 0.4rem;
  }
  .bs-tooltip-auto[x-placement^="left"] .arrow,
  .bs-tooltip-left .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
  }
  .bs-tooltip-auto[x-placement^="left"] .arrow::before,
  .bs-tooltip-left .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #0e0e0e;
  }
  .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #0e0e0e;
    border-radius: 0;
  }
  .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(14, 14, 14, 0.2);
    border-radius: 0.3rem;
  }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
  }
  .popover .arrow::after,
  .popover .arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
  .bs-popover-auto[x-placement^="top"],
  .bs-popover-top {
    margin-bottom: 0.5rem;
  }
  .bs-popover-auto[x-placement^="top"] > .arrow,
  .bs-popover-top > .arrow {
    bottom: calc(-0.5rem - 1px);
  }
  .bs-popover-auto[x-placement^="top"] > .arrow::before,
  .bs-popover-top > .arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(14, 14, 14, 0.25);
  }
  .bs-popover-auto[x-placement^="top"] > .arrow::after,
  .bs-popover-top > .arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff;
  }
  .bs-popover-auto[x-placement^="right"],
  .bs-popover-right {
    margin-left: 0.5rem;
  }
  .bs-popover-auto[x-placement^="right"] > .arrow,
  .bs-popover-right > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
  }
  .bs-popover-auto[x-placement^="right"] > .arrow::before,
  .bs-popover-right > .arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(14, 14, 14, 0.25);
  }
  .bs-popover-auto[x-placement^="right"] > .arrow::after,
  .bs-popover-right > .arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
  }
  .bs-popover-auto[x-placement^="bottom"],
  .bs-popover-bottom {
    margin-top: 0.5rem;
  }
  .bs-popover-auto[x-placement^="bottom"] > .arrow,
  .bs-popover-bottom > .arrow {
    top: calc(-0.5rem - 1px);
  }
  .bs-popover-auto[x-placement^="bottom"] > .arrow::before,
  .bs-popover-bottom > .arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(14, 14, 14, 0.25);
  }
  .bs-popover-auto[x-placement^="bottom"] > .arrow::after,
  .bs-popover-bottom > .arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff;
  }
  .bs-popover-auto[x-placement^="bottom"] .popover-header::before,
  .bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
  }
  .bs-popover-auto[x-placement^="left"],
  .bs-popover-left {
    margin-right: 0.5rem;
  }
  .bs-popover-auto[x-placement^="left"] > .arrow,
  .bs-popover-left > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
  }
  .bs-popover-auto[x-placement^="left"] > .arrow::before,
  .bs-popover-left > .arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(14, 14, 14, 0.25);
  }
  .bs-popover-auto[x-placement^="left"] > .arrow::after,
  .bs-popover-left > .arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
  }
  .popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
  }
  .popover-header:empty {
    display: none;
  }
  .popover-body {
    padding: 0.5rem 0.75rem;
    color: #0e0e0e;
  }
  .carousel {
    position: relative;
  }
  .carousel.pointer-event {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
  }
  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: "";
  }
  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    transition: -webkit-transform 0.6s ease-in-out;
    -o-transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  .carousel-item-next,
  .carousel-item-prev,
  .carousel-item.active {
    display: block;
  }
  .active.carousel-item-right,
  .carousel-item-next:not(.carousel-item-left) {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
  }
  .active.carousel-item-left,
  .carousel-item-prev:not(.carousel-item-right) {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  .carousel-fade .carousel-item {
    opacity: 0;
    -webkit-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .carousel-fade .carousel-item-next.carousel-item-left,
  .carousel-fade .carousel-item-prev.carousel-item-right,
  .carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1;
  }
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    -webkit-transition: opacity 0s 0.6s;
    -o-transition: opacity 0s 0.6s;
    transition: opacity 0s 0.6s;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  .carousel-control-next,
  .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    -webkit-transition: opacity 0.15s ease;
    -o-transition: opacity 0.15s ease;
    transition: opacity 0.15s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-next,
    .carousel-control-prev {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  .carousel-control-next:focus,
  .carousel-control-next:hover,
  .carousel-control-prev:focus,
  .carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }
  .carousel-control-prev {
    left: 0;
  }
  .carousel-control-next {
    right: 0;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50%/100% 100%;
  }
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
  }
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
  }
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
  }
  .carousel-indicators li {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    -webkit-transition: opacity 0.6s ease;
    -o-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  .carousel-indicators .active {
    opacity: 1;
  }
  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
  }
  @-webkit-keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border 0.75s linear infinite;
    animation: spinner-border 0.75s linear infinite;
  }
  .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
  }
  @-webkit-keyframes spinner-grow {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    50% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @keyframes spinner-grow {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    50% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow 0.75s linear infinite;
    animation: spinner-grow 0.75s linear infinite;
  }
  .spinner-grow-sm {
    width: 1rem;
    height: 1rem;
  }
  .align-baseline {
    vertical-align: baseline !important;
  }
  .align-top {
    vertical-align: top !important;
  }
  .align-middle {
    vertical-align: middle !important;
  }
  .align-bottom {
    vertical-align: bottom !important;
  }
  .align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .align-text-top {
    vertical-align: text-top !important;
  }
  .bg-primary {
    background-color: #007bff !important;
  }
  a.bg-primary:focus,
  a.bg-primary:hover,
  button.bg-primary:focus,
  button.bg-primary:hover {
    background-color: #0062cc !important;
  }
  .bg-secondary {
    background-color: #969696 !important;
  }
  a.bg-secondary:focus,
  a.bg-secondary:hover,
  button.bg-secondary:focus,
  button.bg-secondary:hover {
    background-color: #7d7d7d !important;
  }
  .bg-success {
    background-color: #28a745 !important;
  }
  a.bg-success:focus,
  a.bg-success:hover,
  button.bg-success:focus,
  button.bg-success:hover {
    background-color: #1e7e34 !important;
  }
  .bg-info {
    background-color: #17a2b8 !important;
  }
  a.bg-info:focus,
  a.bg-info:hover,
  button.bg-info:focus,
  button.bg-info:hover {
    background-color: #117a8b !important;
  }
  .bg-warning {
    background-color: #ffc107 !important;
  }
  a.bg-warning:focus,
  a.bg-warning:hover,
  button.bg-warning:focus,
  button.bg-warning:hover {
    background-color: #d39e00 !important;
  }
  .bg-danger {
    background-color: #dc3545 !important;
  }
  a.bg-danger:focus,
  a.bg-danger:hover,
  button.bg-danger:focus,
  button.bg-danger:hover {
    background-color: #bd2130 !important;
  }
  .bg-light {
    background-color: #f5f5f5 !important;
  }
  a.bg-light:focus,
  a.bg-light:hover,
  button.bg-light:focus,
  button.bg-light:hover {
    background-color: #dcdcdc !important;
  }
  .bg-dark {
    background-color: #0e0e0e !important;
  }
  a.bg-dark:focus,
  a.bg-dark:hover,
  button.bg-dark:focus,
  button.bg-dark:hover {
    background-color: #000 !important;
  }
  .bg-white {
    background-color: #fff !important;
  }
  .bg-transparent {
    background-color: transparent !important;
  }
  .border {
    border: 1px solid #dee2e6 !important;
  }
  .border-top {
    border-top: 1px solid #dee2e6 !important;
  }
  .border-right {
    border-right: 1px solid #dee2e6 !important;
  }
  .border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
  }
  .border-left {
    border-left: 1px solid #dee2e6 !important;
  }
  .border-0 {
    border: 0 !important;
  }
  .border-top-0 {
    border-top: 0 !important;
  }
  .border-right-0 {
    border-right: 0 !important;
  }
  .border-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-left-0 {
    border-left: 0 !important;
  }
  .border-primary {
    border-color: #007bff !important;
  }
  .border-secondary {
    border-color: #969696 !important;
  }
  .border-success {
    border-color: #28a745 !important;
  }
  .border-info {
    border-color: #17a2b8 !important;
  }
  .border-warning {
    border-color: #ffc107 !important;
  }
  .border-danger {
    border-color: #dc3545 !important;
  }
  .border-light {
    border-color: #f5f5f5 !important;
  }
  .border-dark {
    border-color: #0e0e0e !important;
  }
  .border-white {
    border-color: #fff !important;
  }
  .rounded-sm {
    border-radius: 0.2rem !important;
  }
  .rounded {
    border-radius: 0 !important;
  }
  .rounded-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .rounded-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .rounded-bottom {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-lg {
    border-radius: 0.3rem !important;
  }
  .rounded-circle {
    border-radius: 50% !important;
  }
  .rounded-pill {
    border-radius: 50rem !important;
  }
  .rounded-0 {
    border-radius: 0 !important;
  }
  .clearfix::after {
    display: block;
    clear: both;
    content: "";
  }
  .d-none {
    display: none !important;
  }
  .d-inline {
    display: inline !important;
  }
  .d-inline-block {
    display: inline-block !important;
  }
  .d-block {
    display: block !important;
  }
  .d-table {
    display: table !important;
  }
  .d-table-row {
    display: table-row !important;
  }
  .d-table-cell {
    display: table-cell !important;
  }
  .d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  @media (min-width: 620px) {
    .d-sm-none {
      display: none !important;
    }
    .d-sm-inline {
      display: inline !important;
    }
    .d-sm-inline-block {
      display: inline-block !important;
    }
    .d-sm-block {
      display: block !important;
    }
    .d-sm-table {
      display: table !important;
    }
    .d-sm-table-row {
      display: table-row !important;
    }
    .d-sm-table-cell {
      display: table-cell !important;
    }
    .d-sm-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-sm-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
  }
  @media (min-width: 828px) {
    .d-md-none {
      display: none !important;
    }
    .d-md-inline {
      display: inline !important;
    }
    .d-md-inline-block {
      display: inline-block !important;
    }
    .d-md-block {
      display: block !important;
    }
    .d-md-table {
      display: table !important;
    }
    .d-md-table-row {
      display: table-row !important;
    }
    .d-md-table-cell {
      display: table-cell !important;
    }
    .d-md-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-md-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
  }
  @media (min-width: 1024px) {
    .d-lg-none {
      display: none !important;
    }
    .d-lg-inline {
      display: inline !important;
    }
    .d-lg-inline-block {
      display: inline-block !important;
    }
    .d-lg-block {
      display: block !important;
    }
    .d-lg-table {
      display: table !important;
    }
    .d-lg-table-row {
      display: table-row !important;
    }
    .d-lg-table-cell {
      display: table-cell !important;
    }
    .d-lg-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-lg-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
  }
  @media (min-width: 1200px) {
    .d-xl-none {
      display: none !important;
    }
    .d-xl-inline {
      display: inline !important;
    }
    .d-xl-inline-block {
      display: inline-block !important;
    }
    .d-xl-block {
      display: block !important;
    }
    .d-xl-table {
      display: table !important;
    }
    .d-xl-table-row {
      display: table-row !important;
    }
    .d-xl-table-cell {
      display: table-cell !important;
    }
    .d-xl-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-xl-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
  }
  @media print {
    .d-print-none {
      display: none !important;
    }
    .d-print-inline {
      display: inline !important;
    }
    .d-print-inline-block {
      display: inline-block !important;
    }
    .d-print-block {
      display: block !important;
    }
    .d-print-table {
      display: table !important;
    }
    .d-print-table-row {
      display: table-row !important;
    }
    .d-print-table-cell {
      display: table-cell !important;
    }
    .d-print-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-print-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
  }
  .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }
  .embed-responsive::before {
    display: block;
    content: "";
  }
  .embed-responsive .embed-responsive-item,
  .embed-responsive embed,
  .embed-responsive iframe,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  .embed-responsive-21by9::before {
    padding-top: 42.85714%;
  }
  .embed-responsive-16by9::before {
    padding-top: 56.25%;
  }
  .embed-responsive-4by3::before {
    padding-top: 75%;
  }
  .embed-responsive-1by1::before {
    padding-top: 100%;
  }
  .flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  @media (min-width: 620px) {
    .flex-sm-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-sm-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-sm-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-sm-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .flex-sm-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }
    .flex-sm-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }
    .justify-content-sm-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-sm-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-sm-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-sm-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-sm-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .align-items-sm-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-sm-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-sm-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-sm-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-sm-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-sm-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-sm-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-sm-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-sm-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-sm-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-sm-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-sm-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-sm-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-sm-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-sm-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-sm-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-sm-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
  }
  @media (min-width: 828px) {
    .flex-md-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-md-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-md-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-md-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .flex-md-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }
    .flex-md-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }
    .justify-content-md-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-md-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-md-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-md-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-md-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .align-items-md-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-md-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-md-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-md-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-md-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-md-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-md-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-md-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-md-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-md-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-md-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-md-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-md-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-md-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-md-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-md-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-md-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
  }
  @media (min-width: 1024px) {
    .flex-lg-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-lg-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-lg-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-lg-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .flex-lg-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }
    .flex-lg-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }
    .justify-content-lg-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-lg-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-lg-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-lg-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-lg-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .align-items-lg-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-lg-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-lg-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-lg-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-lg-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-lg-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-lg-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-lg-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-lg-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-lg-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-lg-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-lg-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-lg-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-lg-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-lg-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-lg-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-lg-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
  }
  @media (min-width: 1200px) {
    .flex-xl-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-xl-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-xl-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-xl-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .flex-xl-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }
    .flex-xl-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }
    .justify-content-xl-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-xl-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-xl-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-xl-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-xl-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .align-items-xl-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-xl-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-xl-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-xl-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-xl-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-xl-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-xl-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-xl-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-xl-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-xl-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-xl-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-xl-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-xl-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-xl-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-xl-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-xl-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-xl-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
  }
  .float-left {
    float: left !important;
  }
  .float-right {
    float: right !important;
  }
  .float-none {
    float: none !important;
  }
  @media (min-width: 620px) {
    .float-sm-left {
      float: left !important;
    }
    .float-sm-right {
      float: right !important;
    }
    .float-sm-none {
      float: none !important;
    }
  }
  @media (min-width: 828px) {
    .float-md-left {
      float: left !important;
    }
    .float-md-right {
      float: right !important;
    }
    .float-md-none {
      float: none !important;
    }
  }
  @media (min-width: 1024px) {
    .float-lg-left {
      float: left !important;
    }
    .float-lg-right {
      float: right !important;
    }
    .float-lg-none {
      float: none !important;
    }
  }
  @media (min-width: 1200px) {
    .float-xl-left {
      float: left !important;
    }
    .float-xl-right {
      float: right !important;
    }
    .float-xl-none {
      float: none !important;
    }
  }
  .user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    -ms-user-select: all !important;
    user-select: all !important;
  }
  .user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;
  }
  .user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
  .overflow-auto {
    overflow: auto !important;
  }
  .overflow-hidden {
    overflow: hidden !important;
  }
  .position-static {
    position: static !important;
  }
  .position-relative {
    position: relative !important;
  }
  .position-absolute {
    position: absolute !important;
  }
  .position-fixed {
    position: fixed !important;
  }
  .position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .sticky-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  .sr-only-focusable:active,
  .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .shadow-sm {
    -webkit-box-shadow: 0 0.125rem 0.25rem rgba(14, 14, 14, 0.075) !important;
    box-shadow: 0 0.125rem 0.25rem rgba(14, 14, 14, 0.075) !important;
  }
  .shadow {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(14, 14, 14, 0.15) !important;
    box-shadow: 0 0.5rem 1rem rgba(14, 14, 14, 0.15) !important;
  }
  .shadow-lg {
    -webkit-box-shadow: 0 1rem 3rem rgba(14, 14, 14, 0.175) !important;
    box-shadow: 0 1rem 3rem rgba(14, 14, 14, 0.175) !important;
  }
  .shadow-none {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .w-25 {
    width: 25% !important;
  }
  .w-50 {
    width: 50% !important;
  }
  .w-75 {
    width: 75% !important;
  }
  .w-100 {
    width: 100% !important;
  }
  .w-auto {
    width: auto !important;
  }
  .h-25 {
    height: 25% !important;
  }
  .h-50 {
    height: 50% !important;
  }
  .h-75 {
    height: 75% !important;
  }
  .h-100 {
    height: 100% !important;
  }
  .h-auto {
    height: auto !important;
  }
  .mw-100 {
    max-width: 100% !important;
  }
  .mh-100 {
    max-height: 100% !important;
  }
  .min-vw-100 {
    min-width: 100vw !important;
  }
  .min-vh-100 {
    min-height: 100vh !important;
  }
  .vw-100 {
    width: 100vw !important;
  }
  .vh-100 {
    height: 100vh !important;
  }
  .m-0 {
    margin: 0 !important;
  }
  .mt-0,
  .my-0 {
    margin-top: 0 !important;
  }
  .mr-0,
  .mx-0 {
    margin-right: 0 !important;
  }
  .mb-0,
  .my-0 {
    margin-bottom: 0 !important;
  }
  .ml-0,
  .mx-0 {
    margin-left: 0 !important;
  }
  .m-1 {
    margin: 1px !important;
  }
  .mt-1,
  .my-1 {
    margin-top: 1px !important;
  }
  .mr-1,
  .mx-1 {
    margin-right: 1px !important;
  }
  .mb-1,
  .my-1 {
    margin-bottom: 1px !important;
  }
  .ml-1,
  .mx-1 {
    margin-left: 1px !important;
  }
  .m-2 {
    margin: 2px !important;
  }
  .mt-2,
  .my-2 {
    margin-top: 2px !important;
  }
  .mr-2,
  .mx-2 {
    margin-right: 2px !important;
  }
  .mb-2,
  .my-2 {
    margin-bottom: 2px !important;
  }
  .ml-2,
  .mx-2 {
    margin-left: 2px !important;
  }
  .m-3 {
    margin: 3px !important;
  }
  .mt-3,
  .my-3 {
    margin-top: 3px !important;
  }
  .mr-3,
  .mx-3 {
    margin-right: 3px !important;
  }
  .mb-3,
  .my-3 {
    margin-bottom: 3px !important;
  }
  .ml-3,
  .mx-3 {
    margin-left: 3px !important;
  }
  .m-4 {
    margin: 4px !important;
  }
  .mt-4,
  .my-4 {
    margin-top: 4px !important;
  }
  .mr-4,
  .mx-4 {
    margin-right: 4px !important;
  }
  .mb-4,
  .my-4 {
    margin-bottom: 4px !important;
  }
  .ml-4,
  .mx-4 {
    margin-left: 4px !important;
  }
  .m-5 {
    margin: 5px !important;
  }
  .mt-5,
  .my-5 {
    margin-top: 5px !important;
  }
  .mr-5,
  .mx-5 {
    margin-right: 5px !important;
  }
  .mb-5,
  .my-5 {
    margin-bottom: 5px !important;
  }
  .ml-5,
  .mx-5 {
    margin-left: 5px !important;
  }
  .m-6 {
    margin: 6px !important;
  }
  .mt-6,
  .my-6 {
    margin-top: 6px !important;
  }
  .mr-6,
  .mx-6 {
    margin-right: 6px !important;
  }
  .mb-6,
  .my-6 {
    margin-bottom: 6px !important;
  }
  .ml-6,
  .mx-6 {
    margin-left: 6px !important;
  }
  .m-7 {
    margin: 7px !important;
  }
  .mt-7,
  .my-7 {
    margin-top: 7px !important;
  }
  .mr-7,
  .mx-7 {
    margin-right: 7px !important;
  }
  .mb-7,
  .my-7 {
    margin-bottom: 7px !important;
  }
  .ml-7,
  .mx-7 {
    margin-left: 7px !important;
  }
  .m-8 {
    margin: 8px !important;
  }
  .mt-8,
  .my-8 {
    margin-top: 8px !important;
  }
  .mr-8,
  .mx-8 {
    margin-right: 8px !important;
  }
  .mb-8,
  .my-8 {
    margin-bottom: 8px !important;
  }
  .ml-8,
  .mx-8 {
    margin-left: 8px !important;
  }
  .m-9 {
    margin: 9px !important;
  }
  .mt-9,
  .my-9 {
    margin-top: 9px !important;
  }
  .mr-9,
  .mx-9 {
    margin-right: 9px !important;
  }
  .mb-9,
  .my-9 {
    margin-bottom: 9px !important;
  }
  .ml-9,
  .mx-9 {
    margin-left: 9px !important;
  }
  .m-10 {
    margin: 10px !important;
  }
  .mt-10,
  .my-10 {
    margin-top: 10px !important;
  }
  .mr-10,
  .mx-10 {
    margin-right: 10px !important;
  }
  .mb-10,
  .my-10 {
    margin-bottom: 10px !important;
  }
  .ml-10,
  .mx-10 {
    margin-left: 10px !important;
  }
  .m-11 {
    margin: 11px !important;
  }
  .mt-11,
  .my-11 {
    margin-top: 11px !important;
  }
  .mr-11,
  .mx-11 {
    margin-right: 11px !important;
  }
  .mb-11,
  .my-11 {
    margin-bottom: 11px !important;
  }
  .ml-11,
  .mx-11 {
    margin-left: 11px !important;
  }
  .m-12 {
    margin: 12px !important;
  }
  .mt-12,
  .my-12 {
    margin-top: 12px !important;
  }
  .mr-12,
  .mx-12 {
    margin-right: 12px !important;
  }
  .mb-12,
  .my-12 {
    margin-bottom: 12px !important;
  }
  .ml-12,
  .mx-12 {
    margin-left: 12px !important;
  }
  .m-13 {
    margin: 13px !important;
  }
  .mt-13,
  .my-13 {
    margin-top: 13px !important;
  }
  .mr-13,
  .mx-13 {
    margin-right: 13px !important;
  }
  .mb-13,
  .my-13 {
    margin-bottom: 13px !important;
  }
  .ml-13,
  .mx-13 {
    margin-left: 13px !important;
  }
  .m-15 {
    margin: 15px !important;
  }
  .mt-15,
  .my-15 {
    margin-top: 15px !important;
  }
  .mr-15,
  .mx-15 {
    margin-right: 15px !important;
  }
  .mb-15,
  .my-15 {
    margin-bottom: 15px !important;
  }
  .ml-15,
  .mx-15 {
    margin-left: 15px !important;
  }
  .m-17 {
    margin: 17px !important;
  }
  .mt-17,
  .my-17 {
    margin-top: 17px !important;
  }
  .mr-17,
  .mx-17 {
    margin-right: 17px !important;
  }
  .mb-17,
  .my-17 {
    margin-bottom: 17px !important;
  }
  .ml-17,
  .mx-17 {
    margin-left: 17px !important;
  }
  .m-18 {
    margin: 18px !important;
  }
  .mt-18,
  .my-18 {
    margin-top: 18px !important;
  }
  .mr-18,
  .mx-18 {
    margin-right: 18px !important;
  }
  .mb-18,
  .my-18 {
    margin-bottom: 18px !important;
  }
  .ml-18,
  .mx-18 {
    margin-left: 18px !important;
  }
  .m-20 {
    margin: 20px !important;
  }
  .mt-20,
  .my-20 {
    margin-top: 20px !important;
  }
  .mr-20,
  .mx-20 {
    margin-right: 20px !important;
  }
  .mb-20,
  .my-20 {
    margin-bottom: 20px !important;
  }
  .ml-20,
  .mx-20 {
    margin-left: 20px !important;
  }
  .m-25 {
    margin: 25px !important;
  }
  .mt-25,
  .my-25 {
    margin-top: 25px !important;
  }
  .mr-25,
  .mx-25 {
    margin-right: 25px !important;
  }
  .mb-25,
  .my-25 {
    margin-bottom: 25px !important;
  }
  .ml-25,
  .mx-25 {
    margin-left: 25px !important;
  }
  .m-30 {
    margin: 30px !important;
  }
  .mt-30,
  .my-30 {
    margin-top: 30px !important;
  }
  .mr-30,
  .mx-30 {
    margin-right: 30px !important;
  }
  .mb-30,
  .my-30 {
    margin-bottom: 30px !important;
  }
  .ml-30,
  .mx-30 {
    margin-left: 30px !important;
  }
  .m-40 {
    margin: 40px !important;
  }
  .mt-40,
  .my-40 {
    margin-top: 40px !important;
  }
  .mr-40,
  .mx-40 {
    margin-right: 40px !important;
  }
  .mb-40,
  .my-40 {
    margin-bottom: 40px !important;
  }
  .ml-40,
  .mx-40 {
    margin-left: 40px !important;
  }
  .m-60 {
    margin: 60px !important;
  }
  .mt-60,
  .my-60 {
    margin-top: 60px !important;
  }
  .mr-60,
  .mx-60 {
    margin-right: 60px !important;
  }
  .mb-60,
  .my-60 {
    margin-bottom: 60px !important;
  }
  .ml-60,
  .mx-60 {
    margin-left: 60px !important;
  }
  .m-70 {
    margin: 70px !important;
  }
  .mt-70,
  .my-70 {
    margin-top: 70px !important;
  }
  .mr-70,
  .mx-70 {
    margin-right: 70px !important;
  }
  .mb-70,
  .my-70 {
    margin-bottom: 70px !important;
  }
  .ml-70,
  .mx-70 {
    margin-left: 70px !important;
  }
  .m-90 {
    margin: 90px !important;
  }
  .mt-90,
  .my-90 {
    margin-top: 90px !important;
  }
  .mr-90,
  .mx-90 {
    margin-right: 90px !important;
  }
  .mb-90,
  .my-90 {
    margin-bottom: 90px !important;
  }
  .ml-90,
  .mx-90 {
    margin-left: 90px !important;
  }
  .m-100 {
    margin: 100px !important;
  }
  .mt-100,
  .my-100 {
    margin-top: 100px !important;
  }
  .mr-100,
  .mx-100 {
    margin-right: 100px !important;
  }
  .mb-100,
  .my-100 {
    margin-bottom: 100px !important;
  }
  .ml-100,
  .mx-100 {
    margin-left: 100px !important;
  }
  .m-110 {
    margin: 110px !important;
  }
  .mt-110,
  .my-110 {
    margin-top: 110px !important;
  }
  .mr-110,
  .mx-110 {
    margin-right: 110px !important;
  }
  .mb-110,
  .my-110 {
    margin-bottom: 110px !important;
  }
  .ml-110,
  .mx-110 {
    margin-left: 110px !important;
  }
  .m-130 {
    margin: 130px !important;
  }
  .mt-130,
  .my-130 {
    margin-top: 130px !important;
  }
  .mr-130,
  .mx-130 {
    margin-right: 130px !important;
  }
  .mb-130,
  .my-130 {
    margin-bottom: 130px !important;
  }
  .ml-130,
  .mx-130 {
    margin-left: 130px !important;
  }
  .m-160 {
    margin: 160px !important;
  }
  .mt-160,
  .my-160 {
    margin-top: 160px !important;
  }
  .mr-160,
  .mx-160 {
    margin-right: 160px !important;
  }
  .mb-160,
  .my-160 {
    margin-bottom: 160px !important;
  }
  .ml-160,
  .mx-160 {
    margin-left: 160px !important;
  }
  .p-0 {
    padding: 0 !important;
  }
  .pt-0,
  .py-0 {
    padding-top: 0 !important;
  }
  .pr-0,
  .px-0 {
    padding-right: 0 !important;
  }
  .pb-0,
  .py-0 {
    padding-bottom: 0 !important;
  }
  .pl-0,
  .px-0 {
    padding-left: 0 !important;
  }
  .p-1 {
    padding: 1px !important;
  }
  .pt-1,
  .py-1 {
    padding-top: 1px !important;
  }
  .pr-1,
  .px-1 {
    padding-right: 1px !important;
  }
  .pb-1,
  .py-1 {
    padding-bottom: 1px !important;
  }
  .pl-1,
  .px-1 {
    padding-left: 1px !important;
  }
  .p-2 {
    padding: 2px !important;
  }
  .pt-2,
  .py-2 {
    padding-top: 2px !important;
  }
  .pr-2,
  .px-2 {
    padding-right: 2px !important;
  }
  .pb-2,
  .py-2 {
    padding-bottom: 2px !important;
  }
  .pl-2,
  .px-2 {
    padding-left: 2px !important;
  }
  .p-3 {
    padding: 3px !important;
  }
  .pt-3,
  .py-3 {
    padding-top: 3px !important;
  }
  .pr-3,
  .px-3 {
    padding-right: 3px !important;
  }
  .pb-3,
  .py-3 {
    padding-bottom: 3px !important;
  }
  .pl-3,
  .px-3 {
    padding-left: 3px !important;
  }
  .p-4 {
    padding: 4px !important;
  }
  .pt-4,
  .py-4 {
    padding-top: 4px !important;
  }
  .pr-4,
  .px-4 {
    padding-right: 4px !important;
  }
  .pb-4,
  .py-4 {
    padding-bottom: 4px !important;
  }
  .pl-4,
  .px-4 {
    padding-left: 4px !important;
  }
  .p-5 {
    padding: 5px !important;
  }
  .pt-5,
  .py-5 {
    padding-top: 5px !important;
  }
  .pr-5,
  .px-5 {
    padding-right: 5px !important;
  }
  .pb-5,
  .py-5 {
    padding-bottom: 5px !important;
  }
  .pl-5,
  .px-5 {
    padding-left: 5px !important;
  }
  .p-6 {
    padding: 6px !important;
  }
  .pt-6,
  .py-6 {
    padding-top: 6px !important;
  }
  .pr-6,
  .px-6 {
    padding-right: 6px !important;
  }
  .pb-6,
  .py-6 {
    padding-bottom: 6px !important;
  }
  .pl-6,
  .px-6 {
    padding-left: 6px !important;
  }
  .p-7 {
    padding: 7px !important;
  }
  .pt-7,
  .py-7 {
    padding-top: 7px !important;
  }
  .pr-7,
  .px-7 {
    padding-right: 7px !important;
  }
  .pb-7,
  .py-7 {
    padding-bottom: 7px !important;
  }
  .pl-7,
  .px-7 {
    padding-left: 7px !important;
  }
  .p-8 {
    padding: 8px !important;
  }
  .pt-8,
  .py-8 {
    padding-top: 8px !important;
  }
  .pr-8,
  .px-8 {
    padding-right: 8px !important;
  }
  .pb-8,
  .py-8 {
    padding-bottom: 8px !important;
  }
  .pl-8,
  .px-8 {
    padding-left: 8px !important;
  }
  .p-9 {
    padding: 9px !important;
  }
  .pt-9,
  .py-9 {
    padding-top: 9px !important;
  }
  .pr-9,
  .px-9 {
    padding-right: 9px !important;
  }
  .pb-9,
  .py-9 {
    padding-bottom: 9px !important;
  }
  .pl-9,
  .px-9 {
    padding-left: 9px !important;
  }
  .p-10 {
    padding: 10px !important;
  }
  .pt-10,
  .py-10 {
    padding-top: 10px !important;
  }
  .pr-10,
  .px-10 {
    padding-right: 10px !important;
  }
  .pb-10,
  .py-10 {
    padding-bottom: 10px !important;
  }
  .pl-10,
  .px-10 {
    padding-left: 10px !important;
  }
  .p-11 {
    padding: 11px !important;
  }
  .pt-11,
  .py-11 {
    padding-top: 11px !important;
  }
  .pr-11,
  .px-11 {
    padding-right: 11px !important;
  }
  .pb-11,
  .py-11 {
    padding-bottom: 11px !important;
  }
  .pl-11,
  .px-11 {
    padding-left: 11px !important;
  }
  .p-12 {
    padding: 12px !important;
  }
  .pt-12,
  .py-12 {
    padding-top: 12px !important;
  }
  .pr-12,
  .px-12 {
    padding-right: 12px !important;
  }
  .pb-12,
  .py-12 {
    padding-bottom: 12px !important;
  }
  .pl-12,
  .px-12 {
    padding-left: 12px !important;
  }
  .p-13 {
    padding: 13px !important;
  }
  .pt-13,
  .py-13 {
    padding-top: 13px !important;
  }
  .pr-13,
  .px-13 {
    padding-right: 13px !important;
  }
  .pb-13,
  .py-13 {
    padding-bottom: 13px !important;
  }
  .pl-13,
  .px-13 {
    padding-left: 13px !important;
  }
  .p-15 {
    padding: 15px !important;
  }
  .pt-15,
  .py-15 {
    padding-top: 15px !important;
  }
  .pr-15,
  .px-15 {
    padding-right: 15px !important;
  }
  .pb-15,
  .py-15 {
    padding-bottom: 15px !important;
  }
  .pl-15,
  .px-15 {
    padding-left: 15px !important;
  }
  .p-17 {
    padding: 17px !important;
  }
  .pt-17,
  .py-17 {
    padding-top: 17px !important;
  }
  .pr-17,
  .px-17 {
    padding-right: 17px !important;
  }
  .pb-17,
  .py-17 {
    padding-bottom: 17px !important;
  }
  .pl-17,
  .px-17 {
    padding-left: 17px !important;
  }
  .p-18 {
    padding: 18px !important;
  }
  .pt-18,
  .py-18 {
    padding-top: 18px !important;
  }
  .pr-18,
  .px-18 {
    padding-right: 18px !important;
  }
  .pb-18,
  .py-18 {
    padding-bottom: 18px !important;
  }
  .pl-18,
  .px-18 {
    padding-left: 18px !important;
  }
  .p-20 {
    padding: 20px !important;
  }
  .pt-20,
  .py-20 {
    padding-top: 20px !important;
  }
  .pr-20,
  .px-20 {
    padding-right: 20px !important;
  }
  .pb-20,
  .py-20 {
    padding-bottom: 20px !important;
  }
  .pl-20,
  .px-20 {
    padding-left: 20px !important;
  }
  .p-25 {
    padding: 25px !important;
  }
  .pt-25,
  .py-25 {
    padding-top: 25px !important;
  }
  .pr-25,
  .px-25 {
    padding-right: 25px !important;
  }
  .pb-25,
  .py-25 {
    padding-bottom: 25px !important;
  }
  .pl-25,
  .px-25 {
    padding-left: 25px !important;
  }
  .p-30 {
    padding: 30px !important;
  }
  .pt-30,
  .py-30 {
    padding-top: 30px !important;
  }
  .pr-30,
  .px-30 {
    padding-right: 30px !important;
  }
  .pb-30,
  .py-30 {
    padding-bottom: 30px !important;
  }
  .pl-30,
  .px-30 {
    padding-left: 30px !important;
  }
  .p-40 {
    padding: 40px !important;
  }
  .pt-40,
  .py-40 {
    padding-top: 40px !important;
  }
  .pr-40,
  .px-40 {
    padding-right: 40px !important;
  }
  .pb-40,
  .py-40 {
    padding-bottom: 40px !important;
  }
  .pl-40,
  .px-40 {
    padding-left: 40px !important;
  }
  .p-60 {
    padding: 60px !important;
  }
  .pt-60,
  .py-60 {
    padding-top: 60px !important;
  }
  .pr-60,
  .px-60 {
    padding-right: 60px !important;
  }
  .pb-60,
  .py-60 {
    padding-bottom: 60px !important;
  }
  .pl-60,
  .px-60 {
    padding-left: 60px !important;
  }
  .p-70 {
    padding: 70px !important;
  }
  .pt-70,
  .py-70 {
    padding-top: 70px !important;
  }
  .pr-70,
  .px-70 {
    padding-right: 70px !important;
  }
  .pb-70,
  .py-70 {
    padding-bottom: 70px !important;
  }
  .pl-70,
  .px-70 {
    padding-left: 70px !important;
  }
  .p-90 {
    padding: 90px !important;
  }
  .pt-90,
  .py-90 {
    padding-top: 90px !important;
  }
  .pr-90,
  .px-90 {
    padding-right: 90px !important;
  }
  .pb-90,
  .py-90 {
    padding-bottom: 90px !important;
  }
  .pl-90,
  .px-90 {
    padding-left: 90px !important;
  }
  .p-100 {
    padding: 100px !important;
  }
  .pt-100,
  .py-100 {
    padding-top: 100px !important;
  }
  .pr-100,
  .px-100 {
    padding-right: 100px !important;
  }
  .pb-100,
  .py-100 {
    padding-bottom: 100px !important;
  }
  .pl-100,
  .px-100 {
    padding-left: 100px !important;
  }
  .p-110 {
    padding: 110px !important;
  }
  .pt-110,
  .py-110 {
    padding-top: 110px !important;
  }
  .pr-110,
  .px-110 {
    padding-right: 110px !important;
  }
  .pb-110,
  .py-110 {
    padding-bottom: 110px !important;
  }
  .pl-110,
  .px-110 {
    padding-left: 110px !important;
  }
  .p-130 {
    padding: 130px !important;
  }
  .pt-130,
  .py-130 {
    padding-top: 130px !important;
  }
  .pr-130,
  .px-130 {
    padding-right: 130px !important;
  }
  .pb-130,
  .py-130 {
    padding-bottom: 130px !important;
  }
  .pl-130,
  .px-130 {
    padding-left: 130px !important;
  }
  .p-160 {
    padding: 160px !important;
  }
  .pt-160,
  .py-160 {
    padding-top: 160px !important;
  }
  .pr-160,
  .px-160 {
    padding-right: 160px !important;
  }
  .pb-160,
  .py-160 {
    padding-bottom: 160px !important;
  }
  .pl-160,
  .px-160 {
    padding-left: 160px !important;
  }
  .m-n1 {
    margin: -1px !important;
  }
  .mt-n1,
  .my-n1 {
    margin-top: -1px !important;
  }
  .mr-n1,
  .mx-n1 {
    margin-right: -1px !important;
  }
  .mb-n1,
  .my-n1 {
    margin-bottom: -1px !important;
  }
  .ml-n1,
  .mx-n1 {
    margin-left: -1px !important;
  }
  .m-n2 {
    margin: -2px !important;
  }
  .mt-n2,
  .my-n2 {
    margin-top: -2px !important;
  }
  .mr-n2,
  .mx-n2 {
    margin-right: -2px !important;
  }
  .mb-n2,
  .my-n2 {
    margin-bottom: -2px !important;
  }
  .ml-n2,
  .mx-n2 {
    margin-left: -2px !important;
  }
  .m-n3 {
    margin: -3px !important;
  }
  .mt-n3,
  .my-n3 {
    margin-top: -3px !important;
  }
  .mr-n3,
  .mx-n3 {
    margin-right: -3px !important;
  }
  .mb-n3,
  .my-n3 {
    margin-bottom: -3px !important;
  }
  .ml-n3,
  .mx-n3 {
    margin-left: -3px !important;
  }
  .m-n4 {
    margin: -4px !important;
  }
  .mt-n4,
  .my-n4 {
    margin-top: -4px !important;
  }
  .mr-n4,
  .mx-n4 {
    margin-right: -4px !important;
  }
  .mb-n4,
  .my-n4 {
    margin-bottom: -4px !important;
  }
  .ml-n4,
  .mx-n4 {
    margin-left: -4px !important;
  }
  .m-n5 {
    margin: -5px !important;
  }
  .mt-n5,
  .my-n5 {
    margin-top: -5px !important;
  }
  .mr-n5,
  .mx-n5 {
    margin-right: -5px !important;
  }
  .mb-n5,
  .my-n5 {
    margin-bottom: -5px !important;
  }
  .ml-n5,
  .mx-n5 {
    margin-left: -5px !important;
  }
  .m-n6 {
    margin: -6px !important;
  }
  .mt-n6,
  .my-n6 {
    margin-top: -6px !important;
  }
  .mr-n6,
  .mx-n6 {
    margin-right: -6px !important;
  }
  .mb-n6,
  .my-n6 {
    margin-bottom: -6px !important;
  }
  .ml-n6,
  .mx-n6 {
    margin-left: -6px !important;
  }
  .m-n7 {
    margin: -7px !important;
  }
  .mt-n7,
  .my-n7 {
    margin-top: -7px !important;
  }
  .mr-n7,
  .mx-n7 {
    margin-right: -7px !important;
  }
  .mb-n7,
  .my-n7 {
    margin-bottom: -7px !important;
  }
  .ml-n7,
  .mx-n7 {
    margin-left: -7px !important;
  }
  .m-n8 {
    margin: -8px !important;
  }
  .mt-n8,
  .my-n8 {
    margin-top: -8px !important;
  }
  .mr-n8,
  .mx-n8 {
    margin-right: -8px !important;
  }
  .mb-n8,
  .my-n8 {
    margin-bottom: -8px !important;
  }
  .ml-n8,
  .mx-n8 {
    margin-left: -8px !important;
  }
  .m-n9 {
    margin: -9px !important;
  }
  .mt-n9,
  .my-n9 {
    margin-top: -9px !important;
  }
  .mr-n9,
  .mx-n9 {
    margin-right: -9px !important;
  }
  .mb-n9,
  .my-n9 {
    margin-bottom: -9px !important;
  }
  .ml-n9,
  .mx-n9 {
    margin-left: -9px !important;
  }
  .m-n10 {
    margin: -10px !important;
  }
  .mt-n10,
  .my-n10 {
    margin-top: -10px !important;
  }
  .mr-n10,
  .mx-n10 {
    margin-right: -10px !important;
  }
  .mb-n10,
  .my-n10 {
    margin-bottom: -10px !important;
  }
  .ml-n10,
  .mx-n10 {
    margin-left: -10px !important;
  }
  .m-n11 {
    margin: -11px !important;
  }
  .mt-n11,
  .my-n11 {
    margin-top: -11px !important;
  }
  .mr-n11,
  .mx-n11 {
    margin-right: -11px !important;
  }
  .mb-n11,
  .my-n11 {
    margin-bottom: -11px !important;
  }
  .ml-n11,
  .mx-n11 {
    margin-left: -11px !important;
  }
  .m-n12 {
    margin: -12px !important;
  }
  .mt-n12,
  .my-n12 {
    margin-top: -12px !important;
  }
  .mr-n12,
  .mx-n12 {
    margin-right: -12px !important;
  }
  .mb-n12,
  .my-n12 {
    margin-bottom: -12px !important;
  }
  .ml-n12,
  .mx-n12 {
    margin-left: -12px !important;
  }
  .m-n13 {
    margin: -13px !important;
  }
  .mt-n13,
  .my-n13 {
    margin-top: -13px !important;
  }
  .mr-n13,
  .mx-n13 {
    margin-right: -13px !important;
  }
  .mb-n13,
  .my-n13 {
    margin-bottom: -13px !important;
  }
  .ml-n13,
  .mx-n13 {
    margin-left: -13px !important;
  }
  .m-n15 {
    margin: -15px !important;
  }
  .mt-n15,
  .my-n15 {
    margin-top: -15px !important;
  }
  .mr-n15,
  .mx-n15 {
    margin-right: -15px !important;
  }
  .mb-n15,
  .my-n15 {
    margin-bottom: -15px !important;
  }
  .ml-n15,
  .mx-n15 {
    margin-left: -15px !important;
  }
  .m-n17 {
    margin: -17px !important;
  }
  .mt-n17,
  .my-n17 {
    margin-top: -17px !important;
  }
  .mr-n17,
  .mx-n17 {
    margin-right: -17px !important;
  }
  .mb-n17,
  .my-n17 {
    margin-bottom: -17px !important;
  }
  .ml-n17,
  .mx-n17 {
    margin-left: -17px !important;
  }
  .m-n18 {
    margin: -18px !important;
  }
  .mt-n18,
  .my-n18 {
    margin-top: -18px !important;
  }
  .mr-n18,
  .mx-n18 {
    margin-right: -18px !important;
  }
  .mb-n18,
  .my-n18 {
    margin-bottom: -18px !important;
  }
  .ml-n18,
  .mx-n18 {
    margin-left: -18px !important;
  }
  .m-n20 {
    margin: -20px !important;
  }
  .mt-n20,
  .my-n20 {
    margin-top: -20px !important;
  }
  .mr-n20,
  .mx-n20 {
    margin-right: -20px !important;
  }
  .mb-n20,
  .my-n20 {
    margin-bottom: -20px !important;
  }
  .ml-n20,
  .mx-n20 {
    margin-left: -20px !important;
  }
  .m-n25 {
    margin: -25px !important;
  }
  .mt-n25,
  .my-n25 {
    margin-top: -25px !important;
  }
  .mr-n25,
  .mx-n25 {
    margin-right: -25px !important;
  }
  .mb-n25,
  .my-n25 {
    margin-bottom: -25px !important;
  }
  .ml-n25,
  .mx-n25 {
    margin-left: -25px !important;
  }
  .m-n30 {
    margin: -30px !important;
  }
  .mt-n30,
  .my-n30 {
    margin-top: -30px !important;
  }
  .mr-n30,
  .mx-n30 {
    margin-right: -30px !important;
  }
  .mb-n30,
  .my-n30 {
    margin-bottom: -30px !important;
  }
  .ml-n30,
  .mx-n30 {
    margin-left: -30px !important;
  }
  .m-n40 {
    margin: -40px !important;
  }
  .mt-n40,
  .my-n40 {
    margin-top: -40px !important;
  }
  .mr-n40,
  .mx-n40 {
    margin-right: -40px !important;
  }
  .mb-n40,
  .my-n40 {
    margin-bottom: -40px !important;
  }
  .ml-n40,
  .mx-n40 {
    margin-left: -40px !important;
  }
  .m-n60 {
    margin: -60px !important;
  }
  .mt-n60,
  .my-n60 {
    margin-top: -60px !important;
  }
  .mr-n60,
  .mx-n60 {
    margin-right: -60px !important;
  }
  .mb-n60,
  .my-n60 {
    margin-bottom: -60px !important;
  }
  .ml-n60,
  .mx-n60 {
    margin-left: -60px !important;
  }
  .m-n70 {
    margin: -70px !important;
  }
  .mt-n70,
  .my-n70 {
    margin-top: -70px !important;
  }
  .mr-n70,
  .mx-n70 {
    margin-right: -70px !important;
  }
  .mb-n70,
  .my-n70 {
    margin-bottom: -70px !important;
  }
  .ml-n70,
  .mx-n70 {
    margin-left: -70px !important;
  }
  .m-n90 {
    margin: -90px !important;
  }
  .mt-n90,
  .my-n90 {
    margin-top: -90px !important;
  }
  .mr-n90,
  .mx-n90 {
    margin-right: -90px !important;
  }
  .mb-n90,
  .my-n90 {
    margin-bottom: -90px !important;
  }
  .ml-n90,
  .mx-n90 {
    margin-left: -90px !important;
  }
  .m-n100 {
    margin: -100px !important;
  }
  .mt-n100,
  .my-n100 {
    margin-top: -100px !important;
  }
  .mr-n100,
  .mx-n100 {
    margin-right: -100px !important;
  }
  .mb-n100,
  .my-n100 {
    margin-bottom: -100px !important;
  }
  .ml-n100,
  .mx-n100 {
    margin-left: -100px !important;
  }
  .m-n110 {
    margin: -110px !important;
  }
  .mt-n110,
  .my-n110 {
    margin-top: -110px !important;
  }
  .mr-n110,
  .mx-n110 {
    margin-right: -110px !important;
  }
  .mb-n110,
  .my-n110 {
    margin-bottom: -110px !important;
  }
  .ml-n110,
  .mx-n110 {
    margin-left: -110px !important;
  }
  .m-n130 {
    margin: -130px !important;
  }
  .mt-n130,
  .my-n130 {
    margin-top: -130px !important;
  }
  .mr-n130,
  .mx-n130 {
    margin-right: -130px !important;
  }
  .mb-n130,
  .my-n130 {
    margin-bottom: -130px !important;
  }
  .ml-n130,
  .mx-n130 {
    margin-left: -130px !important;
  }
  .m-n160 {
    margin: -160px !important;
  }
  .mt-n160,
  .my-n160 {
    margin-top: -160px !important;
  }
  .mr-n160,
  .mx-n160 {
    margin-right: -160px !important;
  }
  .mb-n160,
  .my-n160 {
    margin-bottom: -160px !important;
  }
  .ml-n160,
  .mx-n160 {
    margin-left: -160px !important;
  }
  .m-auto {
    margin: auto !important;
  }
  .mt-auto,
  .my-auto {
    margin-top: auto !important;
  }
  .mr-auto,
  .mx-auto {
    margin-right: auto !important;
  }
  .mb-auto,
  .my-auto {
    margin-bottom: auto !important;
  }
  .ml-auto,
  .mx-auto {
    margin-left: auto !important;
  }
  @media (min-width: 620px) {
    .m-sm-0 {
      margin: 0 !important;
    }
    .mt-sm-0,
    .my-sm-0 {
      margin-top: 0 !important;
    }
    .mr-sm-0,
    .mx-sm-0 {
      margin-right: 0 !important;
    }
    .mb-sm-0,
    .my-sm-0 {
      margin-bottom: 0 !important;
    }
    .ml-sm-0,
    .mx-sm-0 {
      margin-left: 0 !important;
    }
    .m-sm-1 {
      margin: 1px !important;
    }
    .mt-sm-1,
    .my-sm-1 {
      margin-top: 1px !important;
    }
    .mr-sm-1,
    .mx-sm-1 {
      margin-right: 1px !important;
    }
    .mb-sm-1,
    .my-sm-1 {
      margin-bottom: 1px !important;
    }
    .ml-sm-1,
    .mx-sm-1 {
      margin-left: 1px !important;
    }
    .m-sm-2 {
      margin: 2px !important;
    }
    .mt-sm-2,
    .my-sm-2 {
      margin-top: 2px !important;
    }
    .mr-sm-2,
    .mx-sm-2 {
      margin-right: 2px !important;
    }
    .mb-sm-2,
    .my-sm-2 {
      margin-bottom: 2px !important;
    }
    .ml-sm-2,
    .mx-sm-2 {
      margin-left: 2px !important;
    }
    .m-sm-3 {
      margin: 3px !important;
    }
    .mt-sm-3,
    .my-sm-3 {
      margin-top: 3px !important;
    }
    .mr-sm-3,
    .mx-sm-3 {
      margin-right: 3px !important;
    }
    .mb-sm-3,
    .my-sm-3 {
      margin-bottom: 3px !important;
    }
    .ml-sm-3,
    .mx-sm-3 {
      margin-left: 3px !important;
    }
    .m-sm-4 {
      margin: 4px !important;
    }
    .mt-sm-4,
    .my-sm-4 {
      margin-top: 4px !important;
    }
    .mr-sm-4,
    .mx-sm-4 {
      margin-right: 4px !important;
    }
    .mb-sm-4,
    .my-sm-4 {
      margin-bottom: 4px !important;
    }
    .ml-sm-4,
    .mx-sm-4 {
      margin-left: 4px !important;
    }
    .m-sm-5 {
      margin: 5px !important;
    }
    .mt-sm-5,
    .my-sm-5 {
      margin-top: 5px !important;
    }
    .mr-sm-5,
    .mx-sm-5 {
      margin-right: 5px !important;
    }
    .mb-sm-5,
    .my-sm-5 {
      margin-bottom: 5px !important;
    }
    .ml-sm-5,
    .mx-sm-5 {
      margin-left: 5px !important;
    }
    .m-sm-6 {
      margin: 6px !important;
    }
    .mt-sm-6,
    .my-sm-6 {
      margin-top: 6px !important;
    }
    .mr-sm-6,
    .mx-sm-6 {
      margin-right: 6px !important;
    }
    .mb-sm-6,
    .my-sm-6 {
      margin-bottom: 6px !important;
    }
    .ml-sm-6,
    .mx-sm-6 {
      margin-left: 6px !important;
    }
    .m-sm-7 {
      margin: 7px !important;
    }
    .mt-sm-7,
    .my-sm-7 {
      margin-top: 7px !important;
    }
    .mr-sm-7,
    .mx-sm-7 {
      margin-right: 7px !important;
    }
    .mb-sm-7,
    .my-sm-7 {
      margin-bottom: 7px !important;
    }
    .ml-sm-7,
    .mx-sm-7 {
      margin-left: 7px !important;
    }
    .m-sm-8 {
      margin: 8px !important;
    }
    .mt-sm-8,
    .my-sm-8 {
      margin-top: 8px !important;
    }
    .mr-sm-8,
    .mx-sm-8 {
      margin-right: 8px !important;
    }
    .mb-sm-8,
    .my-sm-8 {
      margin-bottom: 8px !important;
    }
    .ml-sm-8,
    .mx-sm-8 {
      margin-left: 8px !important;
    }
    .m-sm-9 {
      margin: 9px !important;
    }
    .mt-sm-9,
    .my-sm-9 {
      margin-top: 9px !important;
    }
    .mr-sm-9,
    .mx-sm-9 {
      margin-right: 9px !important;
    }
    .mb-sm-9,
    .my-sm-9 {
      margin-bottom: 9px !important;
    }
    .ml-sm-9,
    .mx-sm-9 {
      margin-left: 9px !important;
    }
    .m-sm-10 {
      margin: 10px !important;
    }
    .mt-sm-10,
    .my-sm-10 {
      margin-top: 10px !important;
    }
    .mr-sm-10,
    .mx-sm-10 {
      margin-right: 10px !important;
    }
    .mb-sm-10,
    .my-sm-10 {
      margin-bottom: 10px !important;
    }
    .ml-sm-10,
    .mx-sm-10 {
      margin-left: 10px !important;
    }
    .m-sm-11 {
      margin: 11px !important;
    }
    .mt-sm-11,
    .my-sm-11 {
      margin-top: 11px !important;
    }
    .mr-sm-11,
    .mx-sm-11 {
      margin-right: 11px !important;
    }
    .mb-sm-11,
    .my-sm-11 {
      margin-bottom: 11px !important;
    }
    .ml-sm-11,
    .mx-sm-11 {
      margin-left: 11px !important;
    }
    .m-sm-12 {
      margin: 12px !important;
    }
    .mt-sm-12,
    .my-sm-12 {
      margin-top: 12px !important;
    }
    .mr-sm-12,
    .mx-sm-12 {
      margin-right: 12px !important;
    }
    .mb-sm-12,
    .my-sm-12 {
      margin-bottom: 12px !important;
    }
    .ml-sm-12,
    .mx-sm-12 {
      margin-left: 12px !important;
    }
    .m-sm-13 {
      margin: 13px !important;
    }
    .mt-sm-13,
    .my-sm-13 {
      margin-top: 13px !important;
    }
    .mr-sm-13,
    .mx-sm-13 {
      margin-right: 13px !important;
    }
    .mb-sm-13,
    .my-sm-13 {
      margin-bottom: 13px !important;
    }
    .ml-sm-13,
    .mx-sm-13 {
      margin-left: 13px !important;
    }
    .m-sm-15 {
      margin: 15px !important;
    }
    .mt-sm-15,
    .my-sm-15 {
      margin-top: 15px !important;
    }
    .mr-sm-15,
    .mx-sm-15 {
      margin-right: 15px !important;
    }
    .mb-sm-15,
    .my-sm-15 {
      margin-bottom: 15px !important;
    }
    .ml-sm-15,
    .mx-sm-15 {
      margin-left: 15px !important;
    }
    .m-sm-17 {
      margin: 17px !important;
    }
    .mt-sm-17,
    .my-sm-17 {
      margin-top: 17px !important;
    }
    .mr-sm-17,
    .mx-sm-17 {
      margin-right: 17px !important;
    }
    .mb-sm-17,
    .my-sm-17 {
      margin-bottom: 17px !important;
    }
    .ml-sm-17,
    .mx-sm-17 {
      margin-left: 17px !important;
    }
    .m-sm-18 {
      margin: 18px !important;
    }
    .mt-sm-18,
    .my-sm-18 {
      margin-top: 18px !important;
    }
    .mr-sm-18,
    .mx-sm-18 {
      margin-right: 18px !important;
    }
    .mb-sm-18,
    .my-sm-18 {
      margin-bottom: 18px !important;
    }
    .ml-sm-18,
    .mx-sm-18 {
      margin-left: 18px !important;
    }
    .m-sm-20 {
      margin: 20px !important;
    }
    .mt-sm-20,
    .my-sm-20 {
      margin-top: 20px !important;
    }
    .mr-sm-20,
    .mx-sm-20 {
      margin-right: 20px !important;
    }
    .mb-sm-20,
    .my-sm-20 {
      margin-bottom: 20px !important;
    }
    .ml-sm-20,
    .mx-sm-20 {
      margin-left: 20px !important;
    }
    .m-sm-25 {
      margin: 25px !important;
    }
    .mt-sm-25,
    .my-sm-25 {
      margin-top: 25px !important;
    }
    .mr-sm-25,
    .mx-sm-25 {
      margin-right: 25px !important;
    }
    .mb-sm-25,
    .my-sm-25 {
      margin-bottom: 25px !important;
    }
    .ml-sm-25,
    .mx-sm-25 {
      margin-left: 25px !important;
    }
    .m-sm-30 {
      margin: 30px !important;
    }
    .mt-sm-30,
    .my-sm-30 {
      margin-top: 30px !important;
    }
    .mr-sm-30,
    .mx-sm-30 {
      margin-right: 30px !important;
    }
    .mb-sm-30,
    .my-sm-30 {
      margin-bottom: 30px !important;
    }
    .ml-sm-30,
    .mx-sm-30 {
      margin-left: 30px !important;
    }
    .m-sm-40 {
      margin: 40px !important;
    }
    .mt-sm-40,
    .my-sm-40 {
      margin-top: 40px !important;
    }
    .mr-sm-40,
    .mx-sm-40 {
      margin-right: 40px !important;
    }
    .mb-sm-40,
    .my-sm-40 {
      margin-bottom: 40px !important;
    }
    .ml-sm-40,
    .mx-sm-40 {
      margin-left: 40px !important;
    }
    .m-sm-60 {
      margin: 60px !important;
    }
    .mt-sm-60,
    .my-sm-60 {
      margin-top: 60px !important;
    }
    .mr-sm-60,
    .mx-sm-60 {
      margin-right: 60px !important;
    }
    .mb-sm-60,
    .my-sm-60 {
      margin-bottom: 60px !important;
    }
    .ml-sm-60,
    .mx-sm-60 {
      margin-left: 60px !important;
    }
    .m-sm-70 {
      margin: 70px !important;
    }
    .mt-sm-70,
    .my-sm-70 {
      margin-top: 70px !important;
    }
    .mr-sm-70,
    .mx-sm-70 {
      margin-right: 70px !important;
    }
    .mb-sm-70,
    .my-sm-70 {
      margin-bottom: 70px !important;
    }
    .ml-sm-70,
    .mx-sm-70 {
      margin-left: 70px !important;
    }
    .m-sm-90 {
      margin: 90px !important;
    }
    .mt-sm-90,
    .my-sm-90 {
      margin-top: 90px !important;
    }
    .mr-sm-90,
    .mx-sm-90 {
      margin-right: 90px !important;
    }
    .mb-sm-90,
    .my-sm-90 {
      margin-bottom: 90px !important;
    }
    .ml-sm-90,
    .mx-sm-90 {
      margin-left: 90px !important;
    }
    .m-sm-100 {
      margin: 100px !important;
    }
    .mt-sm-100,
    .my-sm-100 {
      margin-top: 100px !important;
    }
    .mr-sm-100,
    .mx-sm-100 {
      margin-right: 100px !important;
    }
    .mb-sm-100,
    .my-sm-100 {
      margin-bottom: 100px !important;
    }
    .ml-sm-100,
    .mx-sm-100 {
      margin-left: 100px !important;
    }
    .m-sm-110 {
      margin: 110px !important;
    }
    .mt-sm-110,
    .my-sm-110 {
      margin-top: 110px !important;
    }
    .mr-sm-110,
    .mx-sm-110 {
      margin-right: 110px !important;
    }
    .mb-sm-110,
    .my-sm-110 {
      margin-bottom: 110px !important;
    }
    .ml-sm-110,
    .mx-sm-110 {
      margin-left: 110px !important;
    }
    .m-sm-130 {
      margin: 130px !important;
    }
    .mt-sm-130,
    .my-sm-130 {
      margin-top: 130px !important;
    }
    .mr-sm-130,
    .mx-sm-130 {
      margin-right: 130px !important;
    }
    .mb-sm-130,
    .my-sm-130 {
      margin-bottom: 130px !important;
    }
    .ml-sm-130,
    .mx-sm-130 {
      margin-left: 130px !important;
    }
    .m-sm-160 {
      margin: 160px !important;
    }
    .mt-sm-160,
    .my-sm-160 {
      margin-top: 160px !important;
    }
    .mr-sm-160,
    .mx-sm-160 {
      margin-right: 160px !important;
    }
    .mb-sm-160,
    .my-sm-160 {
      margin-bottom: 160px !important;
    }
    .ml-sm-160,
    .mx-sm-160 {
      margin-left: 160px !important;
    }
    .p-sm-0 {
      padding: 0 !important;
    }
    .pt-sm-0,
    .py-sm-0 {
      padding-top: 0 !important;
    }
    .pr-sm-0,
    .px-sm-0 {
      padding-right: 0 !important;
    }
    .pb-sm-0,
    .py-sm-0 {
      padding-bottom: 0 !important;
    }
    .pl-sm-0,
    .px-sm-0 {
      padding-left: 0 !important;
    }
    .p-sm-1 {
      padding: 1px !important;
    }
    .pt-sm-1,
    .py-sm-1 {
      padding-top: 1px !important;
    }
    .pr-sm-1,
    .px-sm-1 {
      padding-right: 1px !important;
    }
    .pb-sm-1,
    .py-sm-1 {
      padding-bottom: 1px !important;
    }
    .pl-sm-1,
    .px-sm-1 {
      padding-left: 1px !important;
    }
    .p-sm-2 {
      padding: 2px !important;
    }
    .pt-sm-2,
    .py-sm-2 {
      padding-top: 2px !important;
    }
    .pr-sm-2,
    .px-sm-2 {
      padding-right: 2px !important;
    }
    .pb-sm-2,
    .py-sm-2 {
      padding-bottom: 2px !important;
    }
    .pl-sm-2,
    .px-sm-2 {
      padding-left: 2px !important;
    }
    .p-sm-3 {
      padding: 3px !important;
    }
    .pt-sm-3,
    .py-sm-3 {
      padding-top: 3px !important;
    }
    .pr-sm-3,
    .px-sm-3 {
      padding-right: 3px !important;
    }
    .pb-sm-3,
    .py-sm-3 {
      padding-bottom: 3px !important;
    }
    .pl-sm-3,
    .px-sm-3 {
      padding-left: 3px !important;
    }
    .p-sm-4 {
      padding: 4px !important;
    }
    .pt-sm-4,
    .py-sm-4 {
      padding-top: 4px !important;
    }
    .pr-sm-4,
    .px-sm-4 {
      padding-right: 4px !important;
    }
    .pb-sm-4,
    .py-sm-4 {
      padding-bottom: 4px !important;
    }
    .pl-sm-4,
    .px-sm-4 {
      padding-left: 4px !important;
    }
    .p-sm-5 {
      padding: 5px !important;
    }
    .pt-sm-5,
    .py-sm-5 {
      padding-top: 5px !important;
    }
    .pr-sm-5,
    .px-sm-5 {
      padding-right: 5px !important;
    }
    .pb-sm-5,
    .py-sm-5 {
      padding-bottom: 5px !important;
    }
    .pl-sm-5,
    .px-sm-5 {
      padding-left: 5px !important;
    }
    .p-sm-6 {
      padding: 6px !important;
    }
    .pt-sm-6,
    .py-sm-6 {
      padding-top: 6px !important;
    }
    .pr-sm-6,
    .px-sm-6 {
      padding-right: 6px !important;
    }
    .pb-sm-6,
    .py-sm-6 {
      padding-bottom: 6px !important;
    }
    .pl-sm-6,
    .px-sm-6 {
      padding-left: 6px !important;
    }
    .p-sm-7 {
      padding: 7px !important;
    }
    .pt-sm-7,
    .py-sm-7 {
      padding-top: 7px !important;
    }
    .pr-sm-7,
    .px-sm-7 {
      padding-right: 7px !important;
    }
    .pb-sm-7,
    .py-sm-7 {
      padding-bottom: 7px !important;
    }
    .pl-sm-7,
    .px-sm-7 {
      padding-left: 7px !important;
    }
    .p-sm-8 {
      padding: 8px !important;
    }
    .pt-sm-8,
    .py-sm-8 {
      padding-top: 8px !important;
    }
    .pr-sm-8,
    .px-sm-8 {
      padding-right: 8px !important;
    }
    .pb-sm-8,
    .py-sm-8 {
      padding-bottom: 8px !important;
    }
    .pl-sm-8,
    .px-sm-8 {
      padding-left: 8px !important;
    }
    .p-sm-9 {
      padding: 9px !important;
    }
    .pt-sm-9,
    .py-sm-9 {
      padding-top: 9px !important;
    }
    .pr-sm-9,
    .px-sm-9 {
      padding-right: 9px !important;
    }
    .pb-sm-9,
    .py-sm-9 {
      padding-bottom: 9px !important;
    }
    .pl-sm-9,
    .px-sm-9 {
      padding-left: 9px !important;
    }
    .p-sm-10 {
      padding: 10px !important;
    }
    .pt-sm-10,
    .py-sm-10 {
      padding-top: 10px !important;
    }
    .pr-sm-10,
    .px-sm-10 {
      padding-right: 10px !important;
    }
    .pb-sm-10,
    .py-sm-10 {
      padding-bottom: 10px !important;
    }
    .pl-sm-10,
    .px-sm-10 {
      padding-left: 10px !important;
    }
    .p-sm-11 {
      padding: 11px !important;
    }
    .pt-sm-11,
    .py-sm-11 {
      padding-top: 11px !important;
    }
    .pr-sm-11,
    .px-sm-11 {
      padding-right: 11px !important;
    }
    .pb-sm-11,
    .py-sm-11 {
      padding-bottom: 11px !important;
    }
    .pl-sm-11,
    .px-sm-11 {
      padding-left: 11px !important;
    }
    .p-sm-12 {
      padding: 12px !important;
    }
    .pt-sm-12,
    .py-sm-12 {
      padding-top: 12px !important;
    }
    .pr-sm-12,
    .px-sm-12 {
      padding-right: 12px !important;
    }
    .pb-sm-12,
    .py-sm-12 {
      padding-bottom: 12px !important;
    }
    .pl-sm-12,
    .px-sm-12 {
      padding-left: 12px !important;
    }
    .p-sm-13 {
      padding: 13px !important;
    }
    .pt-sm-13,
    .py-sm-13 {
      padding-top: 13px !important;
    }
    .pr-sm-13,
    .px-sm-13 {
      padding-right: 13px !important;
    }
    .pb-sm-13,
    .py-sm-13 {
      padding-bottom: 13px !important;
    }
    .pl-sm-13,
    .px-sm-13 {
      padding-left: 13px !important;
    }
    .p-sm-15 {
      padding: 15px !important;
    }
    .pt-sm-15,
    .py-sm-15 {
      padding-top: 15px !important;
    }
    .pr-sm-15,
    .px-sm-15 {
      padding-right: 15px !important;
    }
    .pb-sm-15,
    .py-sm-15 {
      padding-bottom: 15px !important;
    }
    .pl-sm-15,
    .px-sm-15 {
      padding-left: 15px !important;
    }
    .p-sm-17 {
      padding: 17px !important;
    }
    .pt-sm-17,
    .py-sm-17 {
      padding-top: 17px !important;
    }
    .pr-sm-17,
    .px-sm-17 {
      padding-right: 17px !important;
    }
    .pb-sm-17,
    .py-sm-17 {
      padding-bottom: 17px !important;
    }
    .pl-sm-17,
    .px-sm-17 {
      padding-left: 17px !important;
    }
    .p-sm-18 {
      padding: 18px !important;
    }
    .pt-sm-18,
    .py-sm-18 {
      padding-top: 18px !important;
    }
    .pr-sm-18,
    .px-sm-18 {
      padding-right: 18px !important;
    }
    .pb-sm-18,
    .py-sm-18 {
      padding-bottom: 18px !important;
    }
    .pl-sm-18,
    .px-sm-18 {
      padding-left: 18px !important;
    }
    .p-sm-20 {
      padding: 20px !important;
    }
    .pt-sm-20,
    .py-sm-20 {
      padding-top: 20px !important;
    }
    .pr-sm-20,
    .px-sm-20 {
      padding-right: 20px !important;
    }
    .pb-sm-20,
    .py-sm-20 {
      padding-bottom: 20px !important;
    }
    .pl-sm-20,
    .px-sm-20 {
      padding-left: 20px !important;
    }
    .p-sm-25 {
      padding: 25px !important;
    }
    .pt-sm-25,
    .py-sm-25 {
      padding-top: 25px !important;
    }
    .pr-sm-25,
    .px-sm-25 {
      padding-right: 25px !important;
    }
    .pb-sm-25,
    .py-sm-25 {
      padding-bottom: 25px !important;
    }
    .pl-sm-25,
    .px-sm-25 {
      padding-left: 25px !important;
    }
    .p-sm-30 {
      padding: 30px !important;
    }
    .pt-sm-30,
    .py-sm-30 {
      padding-top: 30px !important;
    }
    .pr-sm-30,
    .px-sm-30 {
      padding-right: 30px !important;
    }
    .pb-sm-30,
    .py-sm-30 {
      padding-bottom: 30px !important;
    }
    .pl-sm-30,
    .px-sm-30 {
      padding-left: 30px !important;
    }
    .p-sm-40 {
      padding: 40px !important;
    }
    .pt-sm-40,
    .py-sm-40 {
      padding-top: 40px !important;
    }
    .pr-sm-40,
    .px-sm-40 {
      padding-right: 40px !important;
    }
    .pb-sm-40,
    .py-sm-40 {
      padding-bottom: 40px !important;
    }
    .pl-sm-40,
    .px-sm-40 {
      padding-left: 40px !important;
    }
    .p-sm-60 {
      padding: 60px !important;
    }
    .pt-sm-60,
    .py-sm-60 {
      padding-top: 60px !important;
    }
    .pr-sm-60,
    .px-sm-60 {
      padding-right: 60px !important;
    }
    .pb-sm-60,
    .py-sm-60 {
      padding-bottom: 60px !important;
    }
    .pl-sm-60,
    .px-sm-60 {
      padding-left: 60px !important;
    }
    .p-sm-70 {
      padding: 70px !important;
    }
    .pt-sm-70,
    .py-sm-70 {
      padding-top: 70px !important;
    }
    .pr-sm-70,
    .px-sm-70 {
      padding-right: 70px !important;
    }
    .pb-sm-70,
    .py-sm-70 {
      padding-bottom: 70px !important;
    }
    .pl-sm-70,
    .px-sm-70 {
      padding-left: 70px !important;
    }
    .p-sm-90 {
      padding: 90px !important;
    }
    .pt-sm-90,
    .py-sm-90 {
      padding-top: 90px !important;
    }
    .pr-sm-90,
    .px-sm-90 {
      padding-right: 90px !important;
    }
    .pb-sm-90,
    .py-sm-90 {
      padding-bottom: 90px !important;
    }
    .pl-sm-90,
    .px-sm-90 {
      padding-left: 90px !important;
    }
    .p-sm-100 {
      padding: 100px !important;
    }
    .pt-sm-100,
    .py-sm-100 {
      padding-top: 100px !important;
    }
    .pr-sm-100,
    .px-sm-100 {
      padding-right: 100px !important;
    }
    .pb-sm-100,
    .py-sm-100 {
      padding-bottom: 100px !important;
    }
    .pl-sm-100,
    .px-sm-100 {
      padding-left: 100px !important;
    }
    .p-sm-110 {
      padding: 110px !important;
    }
    .pt-sm-110,
    .py-sm-110 {
      padding-top: 110px !important;
    }
    .pr-sm-110,
    .px-sm-110 {
      padding-right: 110px !important;
    }
    .pb-sm-110,
    .py-sm-110 {
      padding-bottom: 110px !important;
    }
    .pl-sm-110,
    .px-sm-110 {
      padding-left: 110px !important;
    }
    .p-sm-130 {
      padding: 130px !important;
    }
    .pt-sm-130,
    .py-sm-130 {
      padding-top: 130px !important;
    }
    .pr-sm-130,
    .px-sm-130 {
      padding-right: 130px !important;
    }
    .pb-sm-130,
    .py-sm-130 {
      padding-bottom: 130px !important;
    }
    .pl-sm-130,
    .px-sm-130 {
      padding-left: 130px !important;
    }
    .p-sm-160 {
      padding: 160px !important;
    }
    .pt-sm-160,
    .py-sm-160 {
      padding-top: 160px !important;
    }
    .pr-sm-160,
    .px-sm-160 {
      padding-right: 160px !important;
    }
    .pb-sm-160,
    .py-sm-160 {
      padding-bottom: 160px !important;
    }
    .pl-sm-160,
    .px-sm-160 {
      padding-left: 160px !important;
    }
    .m-sm-n1 {
      margin: -1px !important;
    }
    .mt-sm-n1,
    .my-sm-n1 {
      margin-top: -1px !important;
    }
    .mr-sm-n1,
    .mx-sm-n1 {
      margin-right: -1px !important;
    }
    .mb-sm-n1,
    .my-sm-n1 {
      margin-bottom: -1px !important;
    }
    .ml-sm-n1,
    .mx-sm-n1 {
      margin-left: -1px !important;
    }
    .m-sm-n2 {
      margin: -2px !important;
    }
    .mt-sm-n2,
    .my-sm-n2 {
      margin-top: -2px !important;
    }
    .mr-sm-n2,
    .mx-sm-n2 {
      margin-right: -2px !important;
    }
    .mb-sm-n2,
    .my-sm-n2 {
      margin-bottom: -2px !important;
    }
    .ml-sm-n2,
    .mx-sm-n2 {
      margin-left: -2px !important;
    }
    .m-sm-n3 {
      margin: -3px !important;
    }
    .mt-sm-n3,
    .my-sm-n3 {
      margin-top: -3px !important;
    }
    .mr-sm-n3,
    .mx-sm-n3 {
      margin-right: -3px !important;
    }
    .mb-sm-n3,
    .my-sm-n3 {
      margin-bottom: -3px !important;
    }
    .ml-sm-n3,
    .mx-sm-n3 {
      margin-left: -3px !important;
    }
    .m-sm-n4 {
      margin: -4px !important;
    }
    .mt-sm-n4,
    .my-sm-n4 {
      margin-top: -4px !important;
    }
    .mr-sm-n4,
    .mx-sm-n4 {
      margin-right: -4px !important;
    }
    .mb-sm-n4,
    .my-sm-n4 {
      margin-bottom: -4px !important;
    }
    .ml-sm-n4,
    .mx-sm-n4 {
      margin-left: -4px !important;
    }
    .m-sm-n5 {
      margin: -5px !important;
    }
    .mt-sm-n5,
    .my-sm-n5 {
      margin-top: -5px !important;
    }
    .mr-sm-n5,
    .mx-sm-n5 {
      margin-right: -5px !important;
    }
    .mb-sm-n5,
    .my-sm-n5 {
      margin-bottom: -5px !important;
    }
    .ml-sm-n5,
    .mx-sm-n5 {
      margin-left: -5px !important;
    }
    .m-sm-n6 {
      margin: -6px !important;
    }
    .mt-sm-n6,
    .my-sm-n6 {
      margin-top: -6px !important;
    }
    .mr-sm-n6,
    .mx-sm-n6 {
      margin-right: -6px !important;
    }
    .mb-sm-n6,
    .my-sm-n6 {
      margin-bottom: -6px !important;
    }
    .ml-sm-n6,
    .mx-sm-n6 {
      margin-left: -6px !important;
    }
    .m-sm-n7 {
      margin: -7px !important;
    }
    .mt-sm-n7,
    .my-sm-n7 {
      margin-top: -7px !important;
    }
    .mr-sm-n7,
    .mx-sm-n7 {
      margin-right: -7px !important;
    }
    .mb-sm-n7,
    .my-sm-n7 {
      margin-bottom: -7px !important;
    }
    .ml-sm-n7,
    .mx-sm-n7 {
      margin-left: -7px !important;
    }
    .m-sm-n8 {
      margin: -8px !important;
    }
    .mt-sm-n8,
    .my-sm-n8 {
      margin-top: -8px !important;
    }
    .mr-sm-n8,
    .mx-sm-n8 {
      margin-right: -8px !important;
    }
    .mb-sm-n8,
    .my-sm-n8 {
      margin-bottom: -8px !important;
    }
    .ml-sm-n8,
    .mx-sm-n8 {
      margin-left: -8px !important;
    }
    .m-sm-n9 {
      margin: -9px !important;
    }
    .mt-sm-n9,
    .my-sm-n9 {
      margin-top: -9px !important;
    }
    .mr-sm-n9,
    .mx-sm-n9 {
      margin-right: -9px !important;
    }
    .mb-sm-n9,
    .my-sm-n9 {
      margin-bottom: -9px !important;
    }
    .ml-sm-n9,
    .mx-sm-n9 {
      margin-left: -9px !important;
    }
    .m-sm-n10 {
      margin: -10px !important;
    }
    .mt-sm-n10,
    .my-sm-n10 {
      margin-top: -10px !important;
    }
    .mr-sm-n10,
    .mx-sm-n10 {
      margin-right: -10px !important;
    }
    .mb-sm-n10,
    .my-sm-n10 {
      margin-bottom: -10px !important;
    }
    .ml-sm-n10,
    .mx-sm-n10 {
      margin-left: -10px !important;
    }
    .m-sm-n11 {
      margin: -11px !important;
    }
    .mt-sm-n11,
    .my-sm-n11 {
      margin-top: -11px !important;
    }
    .mr-sm-n11,
    .mx-sm-n11 {
      margin-right: -11px !important;
    }
    .mb-sm-n11,
    .my-sm-n11 {
      margin-bottom: -11px !important;
    }
    .ml-sm-n11,
    .mx-sm-n11 {
      margin-left: -11px !important;
    }
    .m-sm-n12 {
      margin: -12px !important;
    }
    .mt-sm-n12,
    .my-sm-n12 {
      margin-top: -12px !important;
    }
    .mr-sm-n12,
    .mx-sm-n12 {
      margin-right: -12px !important;
    }
    .mb-sm-n12,
    .my-sm-n12 {
      margin-bottom: -12px !important;
    }
    .ml-sm-n12,
    .mx-sm-n12 {
      margin-left: -12px !important;
    }
    .m-sm-n13 {
      margin: -13px !important;
    }
    .mt-sm-n13,
    .my-sm-n13 {
      margin-top: -13px !important;
    }
    .mr-sm-n13,
    .mx-sm-n13 {
      margin-right: -13px !important;
    }
    .mb-sm-n13,
    .my-sm-n13 {
      margin-bottom: -13px !important;
    }
    .ml-sm-n13,
    .mx-sm-n13 {
      margin-left: -13px !important;
    }
    .m-sm-n15 {
      margin: -15px !important;
    }
    .mt-sm-n15,
    .my-sm-n15 {
      margin-top: -15px !important;
    }
    .mr-sm-n15,
    .mx-sm-n15 {
      margin-right: -15px !important;
    }
    .mb-sm-n15,
    .my-sm-n15 {
      margin-bottom: -15px !important;
    }
    .ml-sm-n15,
    .mx-sm-n15 {
      margin-left: -15px !important;
    }
    .m-sm-n17 {
      margin: -17px !important;
    }
    .mt-sm-n17,
    .my-sm-n17 {
      margin-top: -17px !important;
    }
    .mr-sm-n17,
    .mx-sm-n17 {
      margin-right: -17px !important;
    }
    .mb-sm-n17,
    .my-sm-n17 {
      margin-bottom: -17px !important;
    }
    .ml-sm-n17,
    .mx-sm-n17 {
      margin-left: -17px !important;
    }
    .m-sm-n18 {
      margin: -18px !important;
    }
    .mt-sm-n18,
    .my-sm-n18 {
      margin-top: -18px !important;
    }
    .mr-sm-n18,
    .mx-sm-n18 {
      margin-right: -18px !important;
    }
    .mb-sm-n18,
    .my-sm-n18 {
      margin-bottom: -18px !important;
    }
    .ml-sm-n18,
    .mx-sm-n18 {
      margin-left: -18px !important;
    }
    .m-sm-n20 {
      margin: -20px !important;
    }
    .mt-sm-n20,
    .my-sm-n20 {
      margin-top: -20px !important;
    }
    .mr-sm-n20,
    .mx-sm-n20 {
      margin-right: -20px !important;
    }
    .mb-sm-n20,
    .my-sm-n20 {
      margin-bottom: -20px !important;
    }
    .ml-sm-n20,
    .mx-sm-n20 {
      margin-left: -20px !important;
    }
    .m-sm-n25 {
      margin: -25px !important;
    }
    .mt-sm-n25,
    .my-sm-n25 {
      margin-top: -25px !important;
    }
    .mr-sm-n25,
    .mx-sm-n25 {
      margin-right: -25px !important;
    }
    .mb-sm-n25,
    .my-sm-n25 {
      margin-bottom: -25px !important;
    }
    .ml-sm-n25,
    .mx-sm-n25 {
      margin-left: -25px !important;
    }
    .m-sm-n30 {
      margin: -30px !important;
    }
    .mt-sm-n30,
    .my-sm-n30 {
      margin-top: -30px !important;
    }
    .mr-sm-n30,
    .mx-sm-n30 {
      margin-right: -30px !important;
    }
    .mb-sm-n30,
    .my-sm-n30 {
      margin-bottom: -30px !important;
    }
    .ml-sm-n30,
    .mx-sm-n30 {
      margin-left: -30px !important;
    }
    .m-sm-n40 {
      margin: -40px !important;
    }
    .mt-sm-n40,
    .my-sm-n40 {
      margin-top: -40px !important;
    }
    .mr-sm-n40,
    .mx-sm-n40 {
      margin-right: -40px !important;
    }
    .mb-sm-n40,
    .my-sm-n40 {
      margin-bottom: -40px !important;
    }
    .ml-sm-n40,
    .mx-sm-n40 {
      margin-left: -40px !important;
    }
    .m-sm-n60 {
      margin: -60px !important;
    }
    .mt-sm-n60,
    .my-sm-n60 {
      margin-top: -60px !important;
    }
    .mr-sm-n60,
    .mx-sm-n60 {
      margin-right: -60px !important;
    }
    .mb-sm-n60,
    .my-sm-n60 {
      margin-bottom: -60px !important;
    }
    .ml-sm-n60,
    .mx-sm-n60 {
      margin-left: -60px !important;
    }
    .m-sm-n70 {
      margin: -70px !important;
    }
    .mt-sm-n70,
    .my-sm-n70 {
      margin-top: -70px !important;
    }
    .mr-sm-n70,
    .mx-sm-n70 {
      margin-right: -70px !important;
    }
    .mb-sm-n70,
    .my-sm-n70 {
      margin-bottom: -70px !important;
    }
    .ml-sm-n70,
    .mx-sm-n70 {
      margin-left: -70px !important;
    }
    .m-sm-n90 {
      margin: -90px !important;
    }
    .mt-sm-n90,
    .my-sm-n90 {
      margin-top: -90px !important;
    }
    .mr-sm-n90,
    .mx-sm-n90 {
      margin-right: -90px !important;
    }
    .mb-sm-n90,
    .my-sm-n90 {
      margin-bottom: -90px !important;
    }
    .ml-sm-n90,
    .mx-sm-n90 {
      margin-left: -90px !important;
    }
    .m-sm-n100 {
      margin: -100px !important;
    }
    .mt-sm-n100,
    .my-sm-n100 {
      margin-top: -100px !important;
    }
    .mr-sm-n100,
    .mx-sm-n100 {
      margin-right: -100px !important;
    }
    .mb-sm-n100,
    .my-sm-n100 {
      margin-bottom: -100px !important;
    }
    .ml-sm-n100,
    .mx-sm-n100 {
      margin-left: -100px !important;
    }
    .m-sm-n110 {
      margin: -110px !important;
    }
    .mt-sm-n110,
    .my-sm-n110 {
      margin-top: -110px !important;
    }
    .mr-sm-n110,
    .mx-sm-n110 {
      margin-right: -110px !important;
    }
    .mb-sm-n110,
    .my-sm-n110 {
      margin-bottom: -110px !important;
    }
    .ml-sm-n110,
    .mx-sm-n110 {
      margin-left: -110px !important;
    }
    .m-sm-n130 {
      margin: -130px !important;
    }
    .mt-sm-n130,
    .my-sm-n130 {
      margin-top: -130px !important;
    }
    .mr-sm-n130,
    .mx-sm-n130 {
      margin-right: -130px !important;
    }
    .mb-sm-n130,
    .my-sm-n130 {
      margin-bottom: -130px !important;
    }
    .ml-sm-n130,
    .mx-sm-n130 {
      margin-left: -130px !important;
    }
    .m-sm-n160 {
      margin: -160px !important;
    }
    .mt-sm-n160,
    .my-sm-n160 {
      margin-top: -160px !important;
    }
    .mr-sm-n160,
    .mx-sm-n160 {
      margin-right: -160px !important;
    }
    .mb-sm-n160,
    .my-sm-n160 {
      margin-bottom: -160px !important;
    }
    .ml-sm-n160,
    .mx-sm-n160 {
      margin-left: -160px !important;
    }
    .m-sm-auto {
      margin: auto !important;
    }
    .mt-sm-auto,
    .my-sm-auto {
      margin-top: auto !important;
    }
    .mr-sm-auto,
    .mx-sm-auto {
      margin-right: auto !important;
    }
    .mb-sm-auto,
    .my-sm-auto {
      margin-bottom: auto !important;
    }
    .ml-sm-auto,
    .mx-sm-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width: 828px) {
    .m-md-0 {
      margin: 0 !important;
    }
    .mt-md-0,
    .my-md-0 {
      margin-top: 0 !important;
    }
    .mr-md-0,
    .mx-md-0 {
      margin-right: 0 !important;
    }
    .mb-md-0,
    .my-md-0 {
      margin-bottom: 0 !important;
    }
    .ml-md-0,
    .mx-md-0 {
      margin-left: 0 !important;
    }
    .m-md-1 {
      margin: 1px !important;
    }
    .mt-md-1,
    .my-md-1 {
      margin-top: 1px !important;
    }
    .mr-md-1,
    .mx-md-1 {
      margin-right: 1px !important;
    }
    .mb-md-1,
    .my-md-1 {
      margin-bottom: 1px !important;
    }
    .ml-md-1,
    .mx-md-1 {
      margin-left: 1px !important;
    }
    .m-md-2 {
      margin: 2px !important;
    }
    .mt-md-2,
    .my-md-2 {
      margin-top: 2px !important;
    }
    .mr-md-2,
    .mx-md-2 {
      margin-right: 2px !important;
    }
    .mb-md-2,
    .my-md-2 {
      margin-bottom: 2px !important;
    }
    .ml-md-2,
    .mx-md-2 {
      margin-left: 2px !important;
    }
    .m-md-3 {
      margin: 3px !important;
    }
    .mt-md-3,
    .my-md-3 {
      margin-top: 3px !important;
    }
    .mr-md-3,
    .mx-md-3 {
      margin-right: 3px !important;
    }
    .mb-md-3,
    .my-md-3 {
      margin-bottom: 3px !important;
    }
    .ml-md-3,
    .mx-md-3 {
      margin-left: 3px !important;
    }
    .m-md-4 {
      margin: 4px !important;
    }
    .mt-md-4,
    .my-md-4 {
      margin-top: 4px !important;
    }
    .mr-md-4,
    .mx-md-4 {
      margin-right: 4px !important;
    }
    .mb-md-4,
    .my-md-4 {
      margin-bottom: 4px !important;
    }
    .ml-md-4,
    .mx-md-4 {
      margin-left: 4px !important;
    }
    .m-md-5 {
      margin: 5px !important;
    }
    .mt-md-5,
    .my-md-5 {
      margin-top: 5px !important;
    }
    .mr-md-5,
    .mx-md-5 {
      margin-right: 5px !important;
    }
    .mb-md-5,
    .my-md-5 {
      margin-bottom: 5px !important;
    }
    .ml-md-5,
    .mx-md-5 {
      margin-left: 5px !important;
    }
    .m-md-6 {
      margin: 6px !important;
    }
    .mt-md-6,
    .my-md-6 {
      margin-top: 6px !important;
    }
    .mr-md-6,
    .mx-md-6 {
      margin-right: 6px !important;
    }
    .mb-md-6,
    .my-md-6 {
      margin-bottom: 6px !important;
    }
    .ml-md-6,
    .mx-md-6 {
      margin-left: 6px !important;
    }
    .m-md-7 {
      margin: 7px !important;
    }
    .mt-md-7,
    .my-md-7 {
      margin-top: 7px !important;
    }
    .mr-md-7,
    .mx-md-7 {
      margin-right: 7px !important;
    }
    .mb-md-7,
    .my-md-7 {
      margin-bottom: 7px !important;
    }
    .ml-md-7,
    .mx-md-7 {
      margin-left: 7px !important;
    }
    .m-md-8 {
      margin: 8px !important;
    }
    .mt-md-8,
    .my-md-8 {
      margin-top: 8px !important;
    }
    .mr-md-8,
    .mx-md-8 {
      margin-right: 8px !important;
    }
    .mb-md-8,
    .my-md-8 {
      margin-bottom: 8px !important;
    }
    .ml-md-8,
    .mx-md-8 {
      margin-left: 8px !important;
    }
    .m-md-9 {
      margin: 9px !important;
    }
    .mt-md-9,
    .my-md-9 {
      margin-top: 9px !important;
    }
    .mr-md-9,
    .mx-md-9 {
      margin-right: 9px !important;
    }
    .mb-md-9,
    .my-md-9 {
      margin-bottom: 9px !important;
    }
    .ml-md-9,
    .mx-md-9 {
      margin-left: 9px !important;
    }
    .m-md-10 {
      margin: 10px !important;
    }
    .mt-md-10,
    .my-md-10 {
      margin-top: 10px !important;
    }
    .mr-md-10,
    .mx-md-10 {
      margin-right: 10px !important;
    }
    .mb-md-10,
    .my-md-10 {
      margin-bottom: 10px !important;
    }
    .ml-md-10,
    .mx-md-10 {
      margin-left: 10px !important;
    }
    .m-md-11 {
      margin: 11px !important;
    }
    .mt-md-11,
    .my-md-11 {
      margin-top: 11px !important;
    }
    .mr-md-11,
    .mx-md-11 {
      margin-right: 11px !important;
    }
    .mb-md-11,
    .my-md-11 {
      margin-bottom: 11px !important;
    }
    .ml-md-11,
    .mx-md-11 {
      margin-left: 11px !important;
    }
    .m-md-12 {
      margin: 12px !important;
    }
    .mt-md-12,
    .my-md-12 {
      margin-top: 12px !important;
    }
    .mr-md-12,
    .mx-md-12 {
      margin-right: 12px !important;
    }
    .mb-md-12,
    .my-md-12 {
      margin-bottom: 12px !important;
    }
    .ml-md-12,
    .mx-md-12 {
      margin-left: 12px !important;
    }
    .m-md-13 {
      margin: 13px !important;
    }
    .mt-md-13,
    .my-md-13 {
      margin-top: 13px !important;
    }
    .mr-md-13,
    .mx-md-13 {
      margin-right: 13px !important;
    }
    .mb-md-13,
    .my-md-13 {
      margin-bottom: 13px !important;
    }
    .ml-md-13,
    .mx-md-13 {
      margin-left: 13px !important;
    }
    .m-md-15 {
      margin: 15px !important;
    }
    .mt-md-15,
    .my-md-15 {
      margin-top: 15px !important;
    }
    .mr-md-15,
    .mx-md-15 {
      margin-right: 15px !important;
    }
    .mb-md-15,
    .my-md-15 {
      margin-bottom: 15px !important;
    }
    .ml-md-15,
    .mx-md-15 {
      margin-left: 15px !important;
    }
    .m-md-17 {
      margin: 17px !important;
    }
    .mt-md-17,
    .my-md-17 {
      margin-top: 17px !important;
    }
    .mr-md-17,
    .mx-md-17 {
      margin-right: 17px !important;
    }
    .mb-md-17,
    .my-md-17 {
      margin-bottom: 17px !important;
    }
    .ml-md-17,
    .mx-md-17 {
      margin-left: 17px !important;
    }
    .m-md-18 {
      margin: 18px !important;
    }
    .mt-md-18,
    .my-md-18 {
      margin-top: 18px !important;
    }
    .mr-md-18,
    .mx-md-18 {
      margin-right: 18px !important;
    }
    .mb-md-18,
    .my-md-18 {
      margin-bottom: 18px !important;
    }
    .ml-md-18,
    .mx-md-18 {
      margin-left: 18px !important;
    }
    .m-md-20 {
      margin: 20px !important;
    }
    .mt-md-20,
    .my-md-20 {
      margin-top: 20px !important;
    }
    .mr-md-20,
    .mx-md-20 {
      margin-right: 20px !important;
    }
    .mb-md-20,
    .my-md-20 {
      margin-bottom: 20px !important;
    }
    .ml-md-20,
    .mx-md-20 {
      margin-left: 20px !important;
    }
    .m-md-25 {
      margin: 25px !important;
    }
    .mt-md-25,
    .my-md-25 {
      margin-top: 25px !important;
    }
    .mr-md-25,
    .mx-md-25 {
      margin-right: 25px !important;
    }
    .mb-md-25,
    .my-md-25 {
      margin-bottom: 25px !important;
    }
    .ml-md-25,
    .mx-md-25 {
      margin-left: 25px !important;
    }
    .m-md-30 {
      margin: 30px !important;
    }
    .mt-md-30,
    .my-md-30 {
      margin-top: 30px !important;
    }
    .mr-md-30,
    .mx-md-30 {
      margin-right: 30px !important;
    }
    .mb-md-30,
    .my-md-30 {
      margin-bottom: 30px !important;
    }
    .ml-md-30,
    .mx-md-30 {
      margin-left: 30px !important;
    }
    .m-md-40 {
      margin: 40px !important;
    }
    .mt-md-40,
    .my-md-40 {
      margin-top: 40px !important;
    }
    .mr-md-40,
    .mx-md-40 {
      margin-right: 40px !important;
    }
    .mb-md-40,
    .my-md-40 {
      margin-bottom: 40px !important;
    }
    .ml-md-40,
    .mx-md-40 {
      margin-left: 40px !important;
    }
    .m-md-60 {
      margin: 60px !important;
    }
    .mt-md-60,
    .my-md-60 {
      margin-top: 60px !important;
    }
    .mr-md-60,
    .mx-md-60 {
      margin-right: 60px !important;
    }
    .mb-md-60,
    .my-md-60 {
      margin-bottom: 60px !important;
    }
    .ml-md-60,
    .mx-md-60 {
      margin-left: 60px !important;
    }
    .m-md-70 {
      margin: 70px !important;
    }
    .mt-md-70,
    .my-md-70 {
      margin-top: 70px !important;
    }
    .mr-md-70,
    .mx-md-70 {
      margin-right: 70px !important;
    }
    .mb-md-70,
    .my-md-70 {
      margin-bottom: 70px !important;
    }
    .ml-md-70,
    .mx-md-70 {
      margin-left: 70px !important;
    }
    .m-md-90 {
      margin: 90px !important;
    }
    .mt-md-90,
    .my-md-90 {
      margin-top: 90px !important;
    }
    .mr-md-90,
    .mx-md-90 {
      margin-right: 90px !important;
    }
    .mb-md-90,
    .my-md-90 {
      margin-bottom: 90px !important;
    }
    .ml-md-90,
    .mx-md-90 {
      margin-left: 90px !important;
    }
    .m-md-100 {
      margin: 100px !important;
    }
    .mt-md-100,
    .my-md-100 {
      margin-top: 100px !important;
    }
    .mr-md-100,
    .mx-md-100 {
      margin-right: 100px !important;
    }
    .mb-md-100,
    .my-md-100 {
      margin-bottom: 100px !important;
    }
    .ml-md-100,
    .mx-md-100 {
      margin-left: 100px !important;
    }
    .m-md-110 {
      margin: 110px !important;
    }
    .mt-md-110,
    .my-md-110 {
      margin-top: 110px !important;
    }
    .mr-md-110,
    .mx-md-110 {
      margin-right: 110px !important;
    }
    .mb-md-110,
    .my-md-110 {
      margin-bottom: 110px !important;
    }
    .ml-md-110,
    .mx-md-110 {
      margin-left: 110px !important;
    }
    .m-md-130 {
      margin: 130px !important;
    }
    .mt-md-130,
    .my-md-130 {
      margin-top: 130px !important;
    }
    .mr-md-130,
    .mx-md-130 {
      margin-right: 130px !important;
    }
    .mb-md-130,
    .my-md-130 {
      margin-bottom: 130px !important;
    }
    .ml-md-130,
    .mx-md-130 {
      margin-left: 130px !important;
    }
    .m-md-160 {
      margin: 160px !important;
    }
    .mt-md-160,
    .my-md-160 {
      margin-top: 160px !important;
    }
    .mr-md-160,
    .mx-md-160 {
      margin-right: 160px !important;
    }
    .mb-md-160,
    .my-md-160 {
      margin-bottom: 160px !important;
    }
    .ml-md-160,
    .mx-md-160 {
      margin-left: 160px !important;
    }
    .p-md-0 {
      padding: 0 !important;
    }
    .pt-md-0,
    .py-md-0 {
      padding-top: 0 !important;
    }
    .pr-md-0,
    .px-md-0 {
      padding-right: 0 !important;
    }
    .pb-md-0,
    .py-md-0 {
      padding-bottom: 0 !important;
    }
    .pl-md-0,
    .px-md-0 {
      padding-left: 0 !important;
    }
    .p-md-1 {
      padding: 1px !important;
    }
    .pt-md-1,
    .py-md-1 {
      padding-top: 1px !important;
    }
    .pr-md-1,
    .px-md-1 {
      padding-right: 1px !important;
    }
    .pb-md-1,
    .py-md-1 {
      padding-bottom: 1px !important;
    }
    .pl-md-1,
    .px-md-1 {
      padding-left: 1px !important;
    }
    .p-md-2 {
      padding: 2px !important;
    }
    .pt-md-2,
    .py-md-2 {
      padding-top: 2px !important;
    }
    .pr-md-2,
    .px-md-2 {
      padding-right: 2px !important;
    }
    .pb-md-2,
    .py-md-2 {
      padding-bottom: 2px !important;
    }
    .pl-md-2,
    .px-md-2 {
      padding-left: 2px !important;
    }
    .p-md-3 {
      padding: 3px !important;
    }
    .pt-md-3,
    .py-md-3 {
      padding-top: 3px !important;
    }
    .pr-md-3,
    .px-md-3 {
      padding-right: 3px !important;
    }
    .pb-md-3,
    .py-md-3 {
      padding-bottom: 3px !important;
    }
    .pl-md-3,
    .px-md-3 {
      padding-left: 3px !important;
    }
    .p-md-4 {
      padding: 4px !important;
    }
    .pt-md-4,
    .py-md-4 {
      padding-top: 4px !important;
    }
    .pr-md-4,
    .px-md-4 {
      padding-right: 4px !important;
    }
    .pb-md-4,
    .py-md-4 {
      padding-bottom: 4px !important;
    }
    .pl-md-4,
    .px-md-4 {
      padding-left: 4px !important;
    }
    .p-md-5 {
      padding: 5px !important;
    }
    .pt-md-5,
    .py-md-5 {
      padding-top: 5px !important;
    }
    .pr-md-5,
    .px-md-5 {
      padding-right: 5px !important;
    }
    .pb-md-5,
    .py-md-5 {
      padding-bottom: 5px !important;
    }
    .pl-md-5,
    .px-md-5 {
      padding-left: 5px !important;
    }
    .p-md-6 {
      padding: 6px !important;
    }
    .pt-md-6,
    .py-md-6 {
      padding-top: 6px !important;
    }
    .pr-md-6,
    .px-md-6 {
      padding-right: 6px !important;
    }
    .pb-md-6,
    .py-md-6 {
      padding-bottom: 6px !important;
    }
    .pl-md-6,
    .px-md-6 {
      padding-left: 6px !important;
    }
    .p-md-7 {
      padding: 7px !important;
    }
    .pt-md-7,
    .py-md-7 {
      padding-top: 7px !important;
    }
    .pr-md-7,
    .px-md-7 {
      padding-right: 7px !important;
    }
    .pb-md-7,
    .py-md-7 {
      padding-bottom: 7px !important;
    }
    .pl-md-7,
    .px-md-7 {
      padding-left: 7px !important;
    }
    .p-md-8 {
      padding: 8px !important;
    }
    .pt-md-8,
    .py-md-8 {
      padding-top: 8px !important;
    }
    .pr-md-8,
    .px-md-8 {
      padding-right: 8px !important;
    }
    .pb-md-8,
    .py-md-8 {
      padding-bottom: 8px !important;
    }
    .pl-md-8,
    .px-md-8 {
      padding-left: 8px !important;
    }
    .p-md-9 {
      padding: 9px !important;
    }
    .pt-md-9,
    .py-md-9 {
      padding-top: 9px !important;
    }
    .pr-md-9,
    .px-md-9 {
      padding-right: 9px !important;
    }
    .pb-md-9,
    .py-md-9 {
      padding-bottom: 9px !important;
    }
    .pl-md-9,
    .px-md-9 {
      padding-left: 9px !important;
    }
    .p-md-10 {
      padding: 10px !important;
    }
    .pt-md-10,
    .py-md-10 {
      padding-top: 10px !important;
    }
    .pr-md-10,
    .px-md-10 {
      padding-right: 10px !important;
    }
    .pb-md-10,
    .py-md-10 {
      padding-bottom: 10px !important;
    }
    .pl-md-10,
    .px-md-10 {
      padding-left: 10px !important;
    }
    .p-md-11 {
      padding: 11px !important;
    }
    .pt-md-11,
    .py-md-11 {
      padding-top: 11px !important;
    }
    .pr-md-11,
    .px-md-11 {
      padding-right: 11px !important;
    }
    .pb-md-11,
    .py-md-11 {
      padding-bottom: 11px !important;
    }
    .pl-md-11,
    .px-md-11 {
      padding-left: 11px !important;
    }
    .p-md-12 {
      padding: 12px !important;
    }
    .pt-md-12,
    .py-md-12 {
      padding-top: 12px !important;
    }
    .pr-md-12,
    .px-md-12 {
      padding-right: 12px !important;
    }
    .pb-md-12,
    .py-md-12 {
      padding-bottom: 12px !important;
    }
    .pl-md-12,
    .px-md-12 {
      padding-left: 12px !important;
    }
    .p-md-13 {
      padding: 13px !important;
    }
    .pt-md-13,
    .py-md-13 {
      padding-top: 13px !important;
    }
    .pr-md-13,
    .px-md-13 {
      padding-right: 13px !important;
    }
    .pb-md-13,
    .py-md-13 {
      padding-bottom: 13px !important;
    }
    .pl-md-13,
    .px-md-13 {
      padding-left: 13px !important;
    }
    .p-md-15 {
      padding: 15px !important;
    }
    .pt-md-15,
    .py-md-15 {
      padding-top: 15px !important;
    }
    .pr-md-15,
    .px-md-15 {
      padding-right: 15px !important;
    }
    .pb-md-15,
    .py-md-15 {
      padding-bottom: 15px !important;
    }
    .pl-md-15,
    .px-md-15 {
      padding-left: 15px !important;
    }
    .p-md-17 {
      padding: 17px !important;
    }
    .pt-md-17,
    .py-md-17 {
      padding-top: 17px !important;
    }
    .pr-md-17,
    .px-md-17 {
      padding-right: 17px !important;
    }
    .pb-md-17,
    .py-md-17 {
      padding-bottom: 17px !important;
    }
    .pl-md-17,
    .px-md-17 {
      padding-left: 17px !important;
    }
    .p-md-18 {
      padding: 18px !important;
    }
    .pt-md-18,
    .py-md-18 {
      padding-top: 18px !important;
    }
    .pr-md-18,
    .px-md-18 {
      padding-right: 18px !important;
    }
    .pb-md-18,
    .py-md-18 {
      padding-bottom: 18px !important;
    }
    .pl-md-18,
    .px-md-18 {
      padding-left: 18px !important;
    }
    .p-md-20 {
      padding: 20px !important;
    }
    .pt-md-20,
    .py-md-20 {
      padding-top: 20px !important;
    }
    .pr-md-20,
    .px-md-20 {
      padding-right: 20px !important;
    }
    .pb-md-20,
    .py-md-20 {
      padding-bottom: 20px !important;
    }
    .pl-md-20,
    .px-md-20 {
      padding-left: 20px !important;
    }
    .p-md-25 {
      padding: 25px !important;
    }
    .pt-md-25,
    .py-md-25 {
      padding-top: 25px !important;
    }
    .pr-md-25,
    .px-md-25 {
      padding-right: 25px !important;
    }
    .pb-md-25,
    .py-md-25 {
      padding-bottom: 25px !important;
    }
    .pl-md-25,
    .px-md-25 {
      padding-left: 25px !important;
    }
    .p-md-30 {
      padding: 30px !important;
    }
    .pt-md-30,
    .py-md-30 {
      padding-top: 30px !important;
    }
    .pr-md-30,
    .px-md-30 {
      padding-right: 30px !important;
    }
    .pb-md-30,
    .py-md-30 {
      padding-bottom: 30px !important;
    }
    .pl-md-30,
    .px-md-30 {
      padding-left: 30px !important;
    }
    .p-md-40 {
      padding: 40px !important;
    }
    .pt-md-40,
    .py-md-40 {
      padding-top: 40px !important;
    }
    .pr-md-40,
    .px-md-40 {
      padding-right: 40px !important;
    }
    .pb-md-40,
    .py-md-40 {
      padding-bottom: 40px !important;
    }
    .pl-md-40,
    .px-md-40 {
      padding-left: 40px !important;
    }
    .p-md-60 {
      padding: 60px !important;
    }
    .pt-md-60,
    .py-md-60 {
      padding-top: 60px !important;
    }
    .pr-md-60,
    .px-md-60 {
      padding-right: 60px !important;
    }
    .pb-md-60,
    .py-md-60 {
      padding-bottom: 60px !important;
    }
    .pl-md-60,
    .px-md-60 {
      padding-left: 60px !important;
    }
    .p-md-70 {
      padding: 70px !important;
    }
    .pt-md-70,
    .py-md-70 {
      padding-top: 70px !important;
    }
    .pr-md-70,
    .px-md-70 {
      padding-right: 70px !important;
    }
    .pb-md-70,
    .py-md-70 {
      padding-bottom: 70px !important;
    }
    .pl-md-70,
    .px-md-70 {
      padding-left: 70px !important;
    }
    .p-md-90 {
      padding: 90px !important;
    }
    .pt-md-90,
    .py-md-90 {
      padding-top: 90px !important;
    }
    .pr-md-90,
    .px-md-90 {
      padding-right: 90px !important;
    }
    .pb-md-90,
    .py-md-90 {
      padding-bottom: 90px !important;
    }
    .pl-md-90,
    .px-md-90 {
      padding-left: 90px !important;
    }
    .p-md-100 {
      padding: 100px !important;
    }
    .pt-md-100,
    .py-md-100 {
      padding-top: 100px !important;
    }
    .pr-md-100,
    .px-md-100 {
      padding-right: 100px !important;
    }
    .pb-md-100,
    .py-md-100 {
      padding-bottom: 100px !important;
    }
    .pl-md-100,
    .px-md-100 {
      padding-left: 100px !important;
    }
    .p-md-110 {
      padding: 110px !important;
    }
    .pt-md-110,
    .py-md-110 {
      padding-top: 110px !important;
    }
    .pr-md-110,
    .px-md-110 {
      padding-right: 110px !important;
    }
    .pb-md-110,
    .py-md-110 {
      padding-bottom: 110px !important;
    }
    .pl-md-110,
    .px-md-110 {
      padding-left: 110px !important;
    }
    .p-md-130 {
      padding: 130px !important;
    }
    .pt-md-130,
    .py-md-130 {
      padding-top: 130px !important;
    }
    .pr-md-130,
    .px-md-130 {
      padding-right: 130px !important;
    }
    .pb-md-130,
    .py-md-130 {
      padding-bottom: 130px !important;
    }
    .pl-md-130,
    .px-md-130 {
      padding-left: 130px !important;
    }
    .p-md-160 {
      padding: 160px !important;
    }
    .pt-md-160,
    .py-md-160 {
      padding-top: 160px !important;
    }
    .pr-md-160,
    .px-md-160 {
      padding-right: 160px !important;
    }
    .pb-md-160,
    .py-md-160 {
      padding-bottom: 160px !important;
    }
    .pl-md-160,
    .px-md-160 {
      padding-left: 160px !important;
    }
    .m-md-n1 {
      margin: -1px !important;
    }
    .mt-md-n1,
    .my-md-n1 {
      margin-top: -1px !important;
    }
    .mr-md-n1,
    .mx-md-n1 {
      margin-right: -1px !important;
    }
    .mb-md-n1,
    .my-md-n1 {
      margin-bottom: -1px !important;
    }
    .ml-md-n1,
    .mx-md-n1 {
      margin-left: -1px !important;
    }
    .m-md-n2 {
      margin: -2px !important;
    }
    .mt-md-n2,
    .my-md-n2 {
      margin-top: -2px !important;
    }
    .mr-md-n2,
    .mx-md-n2 {
      margin-right: -2px !important;
    }
    .mb-md-n2,
    .my-md-n2 {
      margin-bottom: -2px !important;
    }
    .ml-md-n2,
    .mx-md-n2 {
      margin-left: -2px !important;
    }
    .m-md-n3 {
      margin: -3px !important;
    }
    .mt-md-n3,
    .my-md-n3 {
      margin-top: -3px !important;
    }
    .mr-md-n3,
    .mx-md-n3 {
      margin-right: -3px !important;
    }
    .mb-md-n3,
    .my-md-n3 {
      margin-bottom: -3px !important;
    }
    .ml-md-n3,
    .mx-md-n3 {
      margin-left: -3px !important;
    }
    .m-md-n4 {
      margin: -4px !important;
    }
    .mt-md-n4,
    .my-md-n4 {
      margin-top: -4px !important;
    }
    .mr-md-n4,
    .mx-md-n4 {
      margin-right: -4px !important;
    }
    .mb-md-n4,
    .my-md-n4 {
      margin-bottom: -4px !important;
    }
    .ml-md-n4,
    .mx-md-n4 {
      margin-left: -4px !important;
    }
    .m-md-n5 {
      margin: -5px !important;
    }
    .mt-md-n5,
    .my-md-n5 {
      margin-top: -5px !important;
    }
    .mr-md-n5,
    .mx-md-n5 {
      margin-right: -5px !important;
    }
    .mb-md-n5,
    .my-md-n5 {
      margin-bottom: -5px !important;
    }
    .ml-md-n5,
    .mx-md-n5 {
      margin-left: -5px !important;
    }
    .m-md-n6 {
      margin: -6px !important;
    }
    .mt-md-n6,
    .my-md-n6 {
      margin-top: -6px !important;
    }
    .mr-md-n6,
    .mx-md-n6 {
      margin-right: -6px !important;
    }
    .mb-md-n6,
    .my-md-n6 {
      margin-bottom: -6px !important;
    }
    .ml-md-n6,
    .mx-md-n6 {
      margin-left: -6px !important;
    }
    .m-md-n7 {
      margin: -7px !important;
    }
    .mt-md-n7,
    .my-md-n7 {
      margin-top: -7px !important;
    }
    .mr-md-n7,
    .mx-md-n7 {
      margin-right: -7px !important;
    }
    .mb-md-n7,
    .my-md-n7 {
      margin-bottom: -7px !important;
    }
    .ml-md-n7,
    .mx-md-n7 {
      margin-left: -7px !important;
    }
    .m-md-n8 {
      margin: -8px !important;
    }
    .mt-md-n8,
    .my-md-n8 {
      margin-top: -8px !important;
    }
    .mr-md-n8,
    .mx-md-n8 {
      margin-right: -8px !important;
    }
    .mb-md-n8,
    .my-md-n8 {
      margin-bottom: -8px !important;
    }
    .ml-md-n8,
    .mx-md-n8 {
      margin-left: -8px !important;
    }
    .m-md-n9 {
      margin: -9px !important;
    }
    .mt-md-n9,
    .my-md-n9 {
      margin-top: -9px !important;
    }
    .mr-md-n9,
    .mx-md-n9 {
      margin-right: -9px !important;
    }
    .mb-md-n9,
    .my-md-n9 {
      margin-bottom: -9px !important;
    }
    .ml-md-n9,
    .mx-md-n9 {
      margin-left: -9px !important;
    }
    .m-md-n10 {
      margin: -10px !important;
    }
    .mt-md-n10,
    .my-md-n10 {
      margin-top: -10px !important;
    }
    .mr-md-n10,
    .mx-md-n10 {
      margin-right: -10px !important;
    }
    .mb-md-n10,
    .my-md-n10 {
      margin-bottom: -10px !important;
    }
    .ml-md-n10,
    .mx-md-n10 {
      margin-left: -10px !important;
    }
    .m-md-n11 {
      margin: -11px !important;
    }
    .mt-md-n11,
    .my-md-n11 {
      margin-top: -11px !important;
    }
    .mr-md-n11,
    .mx-md-n11 {
      margin-right: -11px !important;
    }
    .mb-md-n11,
    .my-md-n11 {
      margin-bottom: -11px !important;
    }
    .ml-md-n11,
    .mx-md-n11 {
      margin-left: -11px !important;
    }
    .m-md-n12 {
      margin: -12px !important;
    }
    .mt-md-n12,
    .my-md-n12 {
      margin-top: -12px !important;
    }
    .mr-md-n12,
    .mx-md-n12 {
      margin-right: -12px !important;
    }
    .mb-md-n12,
    .my-md-n12 {
      margin-bottom: -12px !important;
    }
    .ml-md-n12,
    .mx-md-n12 {
      margin-left: -12px !important;
    }
    .m-md-n13 {
      margin: -13px !important;
    }
    .mt-md-n13,
    .my-md-n13 {
      margin-top: -13px !important;
    }
    .mr-md-n13,
    .mx-md-n13 {
      margin-right: -13px !important;
    }
    .mb-md-n13,
    .my-md-n13 {
      margin-bottom: -13px !important;
    }
    .ml-md-n13,
    .mx-md-n13 {
      margin-left: -13px !important;
    }
    .m-md-n15 {
      margin: -15px !important;
    }
    .mt-md-n15,
    .my-md-n15 {
      margin-top: -15px !important;
    }
    .mr-md-n15,
    .mx-md-n15 {
      margin-right: -15px !important;
    }
    .mb-md-n15,
    .my-md-n15 {
      margin-bottom: -15px !important;
    }
    .ml-md-n15,
    .mx-md-n15 {
      margin-left: -15px !important;
    }
    .m-md-n17 {
      margin: -17px !important;
    }
    .mt-md-n17,
    .my-md-n17 {
      margin-top: -17px !important;
    }
    .mr-md-n17,
    .mx-md-n17 {
      margin-right: -17px !important;
    }
    .mb-md-n17,
    .my-md-n17 {
      margin-bottom: -17px !important;
    }
    .ml-md-n17,
    .mx-md-n17 {
      margin-left: -17px !important;
    }
    .m-md-n18 {
      margin: -18px !important;
    }
    .mt-md-n18,
    .my-md-n18 {
      margin-top: -18px !important;
    }
    .mr-md-n18,
    .mx-md-n18 {
      margin-right: -18px !important;
    }
    .mb-md-n18,
    .my-md-n18 {
      margin-bottom: -18px !important;
    }
    .ml-md-n18,
    .mx-md-n18 {
      margin-left: -18px !important;
    }
    .m-md-n20 {
      margin: -20px !important;
    }
    .mt-md-n20,
    .my-md-n20 {
      margin-top: -20px !important;
    }
    .mr-md-n20,
    .mx-md-n20 {
      margin-right: -20px !important;
    }
    .mb-md-n20,
    .my-md-n20 {
      margin-bottom: -20px !important;
    }
    .ml-md-n20,
    .mx-md-n20 {
      margin-left: -20px !important;
    }
    .m-md-n25 {
      margin: -25px !important;
    }
    .mt-md-n25,
    .my-md-n25 {
      margin-top: -25px !important;
    }
    .mr-md-n25,
    .mx-md-n25 {
      margin-right: -25px !important;
    }
    .mb-md-n25,
    .my-md-n25 {
      margin-bottom: -25px !important;
    }
    .ml-md-n25,
    .mx-md-n25 {
      margin-left: -25px !important;
    }
    .m-md-n30 {
      margin: -30px !important;
    }
    .mt-md-n30,
    .my-md-n30 {
      margin-top: -30px !important;
    }
    .mr-md-n30,
    .mx-md-n30 {
      margin-right: -30px !important;
    }
    .mb-md-n30,
    .my-md-n30 {
      margin-bottom: -30px !important;
    }
    .ml-md-n30,
    .mx-md-n30 {
      margin-left: -30px !important;
    }
    .m-md-n40 {
      margin: -40px !important;
    }
    .mt-md-n40,
    .my-md-n40 {
      margin-top: -40px !important;
    }
    .mr-md-n40,
    .mx-md-n40 {
      margin-right: -40px !important;
    }
    .mb-md-n40,
    .my-md-n40 {
      margin-bottom: -40px !important;
    }
    .ml-md-n40,
    .mx-md-n40 {
      margin-left: -40px !important;
    }
    .m-md-n60 {
      margin: -60px !important;
    }
    .mt-md-n60,
    .my-md-n60 {
      margin-top: -60px !important;
    }
    .mr-md-n60,
    .mx-md-n60 {
      margin-right: -60px !important;
    }
    .mb-md-n60,
    .my-md-n60 {
      margin-bottom: -60px !important;
    }
    .ml-md-n60,
    .mx-md-n60 {
      margin-left: -60px !important;
    }
    .m-md-n70 {
      margin: -70px !important;
    }
    .mt-md-n70,
    .my-md-n70 {
      margin-top: -70px !important;
    }
    .mr-md-n70,
    .mx-md-n70 {
      margin-right: -70px !important;
    }
    .mb-md-n70,
    .my-md-n70 {
      margin-bottom: -70px !important;
    }
    .ml-md-n70,
    .mx-md-n70 {
      margin-left: -70px !important;
    }
    .m-md-n90 {
      margin: -90px !important;
    }
    .mt-md-n90,
    .my-md-n90 {
      margin-top: -90px !important;
    }
    .mr-md-n90,
    .mx-md-n90 {
      margin-right: -90px !important;
    }
    .mb-md-n90,
    .my-md-n90 {
      margin-bottom: -90px !important;
    }
    .ml-md-n90,
    .mx-md-n90 {
      margin-left: -90px !important;
    }
    .m-md-n100 {
      margin: -100px !important;
    }
    .mt-md-n100,
    .my-md-n100 {
      margin-top: -100px !important;
    }
    .mr-md-n100,
    .mx-md-n100 {
      margin-right: -100px !important;
    }
    .mb-md-n100,
    .my-md-n100 {
      margin-bottom: -100px !important;
    }
    .ml-md-n100,
    .mx-md-n100 {
      margin-left: -100px !important;
    }
    .m-md-n110 {
      margin: -110px !important;
    }
    .mt-md-n110,
    .my-md-n110 {
      margin-top: -110px !important;
    }
    .mr-md-n110,
    .mx-md-n110 {
      margin-right: -110px !important;
    }
    .mb-md-n110,
    .my-md-n110 {
      margin-bottom: -110px !important;
    }
    .ml-md-n110,
    .mx-md-n110 {
      margin-left: -110px !important;
    }
    .m-md-n130 {
      margin: -130px !important;
    }
    .mt-md-n130,
    .my-md-n130 {
      margin-top: -130px !important;
    }
    .mr-md-n130,
    .mx-md-n130 {
      margin-right: -130px !important;
    }
    .mb-md-n130,
    .my-md-n130 {
      margin-bottom: -130px !important;
    }
    .ml-md-n130,
    .mx-md-n130 {
      margin-left: -130px !important;
    }
    .m-md-n160 {
      margin: -160px !important;
    }
    .mt-md-n160,
    .my-md-n160 {
      margin-top: -160px !important;
    }
    .mr-md-n160,
    .mx-md-n160 {
      margin-right: -160px !important;
    }
    .mb-md-n160,
    .my-md-n160 {
      margin-bottom: -160px !important;
    }
    .ml-md-n160,
    .mx-md-n160 {
      margin-left: -160px !important;
    }
    .m-md-auto {
      margin: auto !important;
    }
    .mt-md-auto,
    .my-md-auto {
      margin-top: auto !important;
    }
    .mr-md-auto,
    .mx-md-auto {
      margin-right: auto !important;
    }
    .mb-md-auto,
    .my-md-auto {
      margin-bottom: auto !important;
    }
    .ml-md-auto,
    .mx-md-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width: 1024px) {
    .m-lg-0 {
      margin: 0 !important;
    }
    .mt-lg-0,
    .my-lg-0 {
      margin-top: 0 !important;
    }
    .mr-lg-0,
    .mx-lg-0 {
      margin-right: 0 !important;
    }
    .mb-lg-0,
    .my-lg-0 {
      margin-bottom: 0 !important;
    }
    .ml-lg-0,
    .mx-lg-0 {
      margin-left: 0 !important;
    }
    .m-lg-1 {
      margin: 1px !important;
    }
    .mt-lg-1,
    .my-lg-1 {
      margin-top: 1px !important;
    }
    .mr-lg-1,
    .mx-lg-1 {
      margin-right: 1px !important;
    }
    .mb-lg-1,
    .my-lg-1 {
      margin-bottom: 1px !important;
    }
    .ml-lg-1,
    .mx-lg-1 {
      margin-left: 1px !important;
    }
    .m-lg-2 {
      margin: 2px !important;
    }
    .mt-lg-2,
    .my-lg-2 {
      margin-top: 2px !important;
    }
    .mr-lg-2,
    .mx-lg-2 {
      margin-right: 2px !important;
    }
    .mb-lg-2,
    .my-lg-2 {
      margin-bottom: 2px !important;
    }
    .ml-lg-2,
    .mx-lg-2 {
      margin-left: 2px !important;
    }
    .m-lg-3 {
      margin: 3px !important;
    }
    .mt-lg-3,
    .my-lg-3 {
      margin-top: 3px !important;
    }
    .mr-lg-3,
    .mx-lg-3 {
      margin-right: 3px !important;
    }
    .mb-lg-3,
    .my-lg-3 {
      margin-bottom: 3px !important;
    }
    .ml-lg-3,
    .mx-lg-3 {
      margin-left: 3px !important;
    }
    .m-lg-4 {
      margin: 4px !important;
    }
    .mt-lg-4,
    .my-lg-4 {
      margin-top: 4px !important;
    }
    .mr-lg-4,
    .mx-lg-4 {
      margin-right: 4px !important;
    }
    .mb-lg-4,
    .my-lg-4 {
      margin-bottom: 4px !important;
    }
    .ml-lg-4,
    .mx-lg-4 {
      margin-left: 4px !important;
    }
    .m-lg-5 {
      margin: 5px !important;
    }
    .mt-lg-5,
    .my-lg-5 {
      margin-top: 5px !important;
    }
    .mr-lg-5,
    .mx-lg-5 {
      margin-right: 5px !important;
    }
    .mb-lg-5,
    .my-lg-5 {
      margin-bottom: 5px !important;
    }
    .ml-lg-5,
    .mx-lg-5 {
      margin-left: 5px !important;
    }
    .m-lg-6 {
      margin: 6px !important;
    }
    .mt-lg-6,
    .my-lg-6 {
      margin-top: 6px !important;
    }
    .mr-lg-6,
    .mx-lg-6 {
      margin-right: 6px !important;
    }
    .mb-lg-6,
    .my-lg-6 {
      margin-bottom: 6px !important;
    }
    .ml-lg-6,
    .mx-lg-6 {
      margin-left: 6px !important;
    }
    .m-lg-7 {
      margin: 7px !important;
    }
    .mt-lg-7,
    .my-lg-7 {
      margin-top: 7px !important;
    }
    .mr-lg-7,
    .mx-lg-7 {
      margin-right: 7px !important;
    }
    .mb-lg-7,
    .my-lg-7 {
      margin-bottom: 7px !important;
    }
    .ml-lg-7,
    .mx-lg-7 {
      margin-left: 7px !important;
    }
    .m-lg-8 {
      margin: 8px !important;
    }
    .mt-lg-8,
    .my-lg-8 {
      margin-top: 8px !important;
    }
    .mr-lg-8,
    .mx-lg-8 {
      margin-right: 8px !important;
    }
    .mb-lg-8,
    .my-lg-8 {
      margin-bottom: 8px !important;
    }
    .ml-lg-8,
    .mx-lg-8 {
      margin-left: 8px !important;
    }
    .m-lg-9 {
      margin: 9px !important;
    }
    .mt-lg-9,
    .my-lg-9 {
      margin-top: 9px !important;
    }
    .mr-lg-9,
    .mx-lg-9 {
      margin-right: 9px !important;
    }
    .mb-lg-9,
    .my-lg-9 {
      margin-bottom: 9px !important;
    }
    .ml-lg-9,
    .mx-lg-9 {
      margin-left: 9px !important;
    }
    .m-lg-10 {
      margin: 10px !important;
    }
    .mt-lg-10,
    .my-lg-10 {
      margin-top: 10px !important;
    }
    .mr-lg-10,
    .mx-lg-10 {
      margin-right: 10px !important;
    }
    .mb-lg-10,
    .my-lg-10 {
      margin-bottom: 10px !important;
    }
    .ml-lg-10,
    .mx-lg-10 {
      margin-left: 10px !important;
    }
    .m-lg-11 {
      margin: 11px !important;
    }
    .mt-lg-11,
    .my-lg-11 {
      margin-top: 11px !important;
    }
    .mr-lg-11,
    .mx-lg-11 {
      margin-right: 11px !important;
    }
    .mb-lg-11,
    .my-lg-11 {
      margin-bottom: 11px !important;
    }
    .ml-lg-11,
    .mx-lg-11 {
      margin-left: 11px !important;
    }
    .m-lg-12 {
      margin: 12px !important;
    }
    .mt-lg-12,
    .my-lg-12 {
      margin-top: 12px !important;
    }
    .mr-lg-12,
    .mx-lg-12 {
      margin-right: 12px !important;
    }
    .mb-lg-12,
    .my-lg-12 {
      margin-bottom: 12px !important;
    }
    .ml-lg-12,
    .mx-lg-12 {
      margin-left: 12px !important;
    }
    .m-lg-13 {
      margin: 13px !important;
    }
    .mt-lg-13,
    .my-lg-13 {
      margin-top: 13px !important;
    }
    .mr-lg-13,
    .mx-lg-13 {
      margin-right: 13px !important;
    }
    .mb-lg-13,
    .my-lg-13 {
      margin-bottom: 13px !important;
    }
    .ml-lg-13,
    .mx-lg-13 {
      margin-left: 13px !important;
    }
    .m-lg-15 {
      margin: 15px !important;
    }
    .mt-lg-15,
    .my-lg-15 {
      margin-top: 15px !important;
    }
    .mr-lg-15,
    .mx-lg-15 {
      margin-right: 15px !important;
    }
    .mb-lg-15,
    .my-lg-15 {
      margin-bottom: 15px !important;
    }
    .ml-lg-15,
    .mx-lg-15 {
      margin-left: 15px !important;
    }
    .m-lg-17 {
      margin: 17px !important;
    }
    .mt-lg-17,
    .my-lg-17 {
      margin-top: 17px !important;
    }
    .mr-lg-17,
    .mx-lg-17 {
      margin-right: 17px !important;
    }
    .mb-lg-17,
    .my-lg-17 {
      margin-bottom: 17px !important;
    }
    .ml-lg-17,
    .mx-lg-17 {
      margin-left: 17px !important;
    }
    .m-lg-18 {
      margin: 18px !important;
    }
    .mt-lg-18,
    .my-lg-18 {
      margin-top: 18px !important;
    }
    .mr-lg-18,
    .mx-lg-18 {
      margin-right: 18px !important;
    }
    .mb-lg-18,
    .my-lg-18 {
      margin-bottom: 18px !important;
    }
    .ml-lg-18,
    .mx-lg-18 {
      margin-left: 18px !important;
    }
    .m-lg-20 {
      margin: 20px !important;
    }
    .mt-lg-20,
    .my-lg-20 {
      margin-top: 20px !important;
    }
    .mr-lg-20,
    .mx-lg-20 {
      margin-right: 20px !important;
    }
    .mb-lg-20,
    .my-lg-20 {
      margin-bottom: 20px !important;
    }
    .ml-lg-20,
    .mx-lg-20 {
      margin-left: 20px !important;
    }
    .m-lg-25 {
      margin: 25px !important;
    }
    .mt-lg-25,
    .my-lg-25 {
      margin-top: 25px !important;
    }
    .mr-lg-25,
    .mx-lg-25 {
      margin-right: 25px !important;
    }
    .mb-lg-25,
    .my-lg-25 {
      margin-bottom: 25px !important;
    }
    .ml-lg-25,
    .mx-lg-25 {
      margin-left: 25px !important;
    }
    .m-lg-30 {
      margin: 30px !important;
    }
    .mt-lg-30,
    .my-lg-30 {
      margin-top: 30px !important;
    }
    .mr-lg-30,
    .mx-lg-30 {
      margin-right: 30px !important;
    }
    .mb-lg-30,
    .my-lg-30 {
      margin-bottom: 30px !important;
    }
    .ml-lg-30,
    .mx-lg-30 {
      margin-left: 30px !important;
    }
    .m-lg-40 {
      margin: 40px !important;
    }
    .mt-lg-40,
    .my-lg-40 {
      margin-top: 40px !important;
    }
    .mr-lg-40,
    .mx-lg-40 {
      margin-right: 40px !important;
    }
    .mb-lg-40,
    .my-lg-40 {
      margin-bottom: 40px !important;
    }
    .ml-lg-40,
    .mx-lg-40 {
      margin-left: 40px !important;
    }
    .m-lg-60 {
      margin: 60px !important;
    }
    .mt-lg-60,
    .my-lg-60 {
      margin-top: 60px !important;
    }
    .mr-lg-60,
    .mx-lg-60 {
      margin-right: 60px !important;
    }
    .mb-lg-60,
    .my-lg-60 {
      margin-bottom: 60px !important;
    }
    .ml-lg-60,
    .mx-lg-60 {
      margin-left: 60px !important;
    }
    .m-lg-70 {
      margin: 70px !important;
    }
    .mt-lg-70,
    .my-lg-70 {
      margin-top: 70px !important;
    }
    .mr-lg-70,
    .mx-lg-70 {
      margin-right: 70px !important;
    }
    .mb-lg-70,
    .my-lg-70 {
      margin-bottom: 70px !important;
    }
    .ml-lg-70,
    .mx-lg-70 {
      margin-left: 70px !important;
    }
    .m-lg-90 {
      margin: 90px !important;
    }
    .mt-lg-90,
    .my-lg-90 {
      margin-top: 90px !important;
    }
    .mr-lg-90,
    .mx-lg-90 {
      margin-right: 90px !important;
    }
    .mb-lg-90,
    .my-lg-90 {
      margin-bottom: 90px !important;
    }
    .ml-lg-90,
    .mx-lg-90 {
      margin-left: 90px !important;
    }
    .m-lg-100 {
      margin: 100px !important;
    }
    .mt-lg-100,
    .my-lg-100 {
      margin-top: 100px !important;
    }
    .mr-lg-100,
    .mx-lg-100 {
      margin-right: 100px !important;
    }
    .mb-lg-100,
    .my-lg-100 {
      margin-bottom: 100px !important;
    }
    .ml-lg-100,
    .mx-lg-100 {
      margin-left: 100px !important;
    }
    .m-lg-110 {
      margin: 110px !important;
    }
    .mt-lg-110,
    .my-lg-110 {
      margin-top: 110px !important;
    }
    .mr-lg-110,
    .mx-lg-110 {
      margin-right: 110px !important;
    }
    .mb-lg-110,
    .my-lg-110 {
      margin-bottom: 110px !important;
    }
    .ml-lg-110,
    .mx-lg-110 {
      margin-left: 110px !important;
    }
    .m-lg-130 {
      margin: 130px !important;
    }
    .mt-lg-130,
    .my-lg-130 {
      margin-top: 130px !important;
    }
    .mr-lg-130,
    .mx-lg-130 {
      margin-right: 130px !important;
    }
    .mb-lg-130,
    .my-lg-130 {
      margin-bottom: 130px !important;
    }
    .ml-lg-130,
    .mx-lg-130 {
      margin-left: 130px !important;
    }
    .m-lg-160 {
      margin: 160px !important;
    }
    .mt-lg-160,
    .my-lg-160 {
      margin-top: 160px !important;
    }
    .mr-lg-160,
    .mx-lg-160 {
      margin-right: 160px !important;
    }
    .mb-lg-160,
    .my-lg-160 {
      margin-bottom: 160px !important;
    }
    .ml-lg-160,
    .mx-lg-160 {
      margin-left: 160px !important;
    }
    .p-lg-0 {
      padding: 0 !important;
    }
    .pt-lg-0,
    .py-lg-0 {
      padding-top: 0 !important;
    }
    .pr-lg-0,
    .px-lg-0 {
      padding-right: 0 !important;
    }
    .pb-lg-0,
    .py-lg-0 {
      padding-bottom: 0 !important;
    }
    .pl-lg-0,
    .px-lg-0 {
      padding-left: 0 !important;
    }
    .p-lg-1 {
      padding: 1px !important;
    }
    .pt-lg-1,
    .py-lg-1 {
      padding-top: 1px !important;
    }
    .pr-lg-1,
    .px-lg-1 {
      padding-right: 1px !important;
    }
    .pb-lg-1,
    .py-lg-1 {
      padding-bottom: 1px !important;
    }
    .pl-lg-1,
    .px-lg-1 {
      padding-left: 1px !important;
    }
    .p-lg-2 {
      padding: 2px !important;
    }
    .pt-lg-2,
    .py-lg-2 {
      padding-top: 2px !important;
    }
    .pr-lg-2,
    .px-lg-2 {
      padding-right: 2px !important;
    }
    .pb-lg-2,
    .py-lg-2 {
      padding-bottom: 2px !important;
    }
    .pl-lg-2,
    .px-lg-2 {
      padding-left: 2px !important;
    }
    .p-lg-3 {
      padding: 3px !important;
    }
    .pt-lg-3,
    .py-lg-3 {
      padding-top: 3px !important;
    }
    .pr-lg-3,
    .px-lg-3 {
      padding-right: 3px !important;
    }
    .pb-lg-3,
    .py-lg-3 {
      padding-bottom: 3px !important;
    }
    .pl-lg-3,
    .px-lg-3 {
      padding-left: 3px !important;
    }
    .p-lg-4 {
      padding: 4px !important;
    }
    .pt-lg-4,
    .py-lg-4 {
      padding-top: 4px !important;
    }
    .pr-lg-4,
    .px-lg-4 {
      padding-right: 4px !important;
    }
    .pb-lg-4,
    .py-lg-4 {
      padding-bottom: 4px !important;
    }
    .pl-lg-4,
    .px-lg-4 {
      padding-left: 4px !important;
    }
    .p-lg-5 {
      padding: 5px !important;
    }
    .pt-lg-5,
    .py-lg-5 {
      padding-top: 5px !important;
    }
    .pr-lg-5,
    .px-lg-5 {
      padding-right: 5px !important;
    }
    .pb-lg-5,
    .py-lg-5 {
      padding-bottom: 5px !important;
    }
    .pl-lg-5,
    .px-lg-5 {
      padding-left: 5px !important;
    }
    .p-lg-6 {
      padding: 6px !important;
    }
    .pt-lg-6,
    .py-lg-6 {
      padding-top: 6px !important;
    }
    .pr-lg-6,
    .px-lg-6 {
      padding-right: 6px !important;
    }
    .pb-lg-6,
    .py-lg-6 {
      padding-bottom: 6px !important;
    }
    .pl-lg-6,
    .px-lg-6 {
      padding-left: 6px !important;
    }
    .p-lg-7 {
      padding: 7px !important;
    }
    .pt-lg-7,
    .py-lg-7 {
      padding-top: 7px !important;
    }
    .pr-lg-7,
    .px-lg-7 {
      padding-right: 7px !important;
    }
    .pb-lg-7,
    .py-lg-7 {
      padding-bottom: 7px !important;
    }
    .pl-lg-7,
    .px-lg-7 {
      padding-left: 7px !important;
    }
    .p-lg-8 {
      padding: 8px !important;
    }
    .pt-lg-8,
    .py-lg-8 {
      padding-top: 8px !important;
    }
    .pr-lg-8,
    .px-lg-8 {
      padding-right: 8px !important;
    }
    .pb-lg-8,
    .py-lg-8 {
      padding-bottom: 8px !important;
    }
    .pl-lg-8,
    .px-lg-8 {
      padding-left: 8px !important;
    }
    .p-lg-9 {
      padding: 9px !important;
    }
    .pt-lg-9,
    .py-lg-9 {
      padding-top: 9px !important;
    }
    .pr-lg-9,
    .px-lg-9 {
      padding-right: 9px !important;
    }
    .pb-lg-9,
    .py-lg-9 {
      padding-bottom: 9px !important;
    }
    .pl-lg-9,
    .px-lg-9 {
      padding-left: 9px !important;
    }
    .p-lg-10 {
      padding: 10px !important;
    }
    .pt-lg-10,
    .py-lg-10 {
      padding-top: 10px !important;
    }
    .pr-lg-10,
    .px-lg-10 {
      padding-right: 10px !important;
    }
    .pb-lg-10,
    .py-lg-10 {
      padding-bottom: 10px !important;
    }
    .pl-lg-10,
    .px-lg-10 {
      padding-left: 10px !important;
    }
    .p-lg-11 {
      padding: 11px !important;
    }
    .pt-lg-11,
    .py-lg-11 {
      padding-top: 11px !important;
    }
    .pr-lg-11,
    .px-lg-11 {
      padding-right: 11px !important;
    }
    .pb-lg-11,
    .py-lg-11 {
      padding-bottom: 11px !important;
    }
    .pl-lg-11,
    .px-lg-11 {
      padding-left: 11px !important;
    }
    .p-lg-12 {
      padding: 12px !important;
    }
    .pt-lg-12,
    .py-lg-12 {
      padding-top: 12px !important;
    }
    .pr-lg-12,
    .px-lg-12 {
      padding-right: 12px !important;
    }
    .pb-lg-12,
    .py-lg-12 {
      padding-bottom: 12px !important;
    }
    .pl-lg-12,
    .px-lg-12 {
      padding-left: 12px !important;
    }
    .p-lg-13 {
      padding: 13px !important;
    }
    .pt-lg-13,
    .py-lg-13 {
      padding-top: 13px !important;
    }
    .pr-lg-13,
    .px-lg-13 {
      padding-right: 13px !important;
    }
    .pb-lg-13,
    .py-lg-13 {
      padding-bottom: 13px !important;
    }
    .pl-lg-13,
    .px-lg-13 {
      padding-left: 13px !important;
    }
    .p-lg-15 {
      padding: 15px !important;
    }
    .pt-lg-15,
    .py-lg-15 {
      padding-top: 15px !important;
    }
    .pr-lg-15,
    .px-lg-15 {
      padding-right: 15px !important;
    }
    .pb-lg-15,
    .py-lg-15 {
      padding-bottom: 15px !important;
    }
    .pl-lg-15,
    .px-lg-15 {
      padding-left: 15px !important;
    }
    .p-lg-17 {
      padding: 17px !important;
    }
    .pt-lg-17,
    .py-lg-17 {
      padding-top: 17px !important;
    }
    .pr-lg-17,
    .px-lg-17 {
      padding-right: 17px !important;
    }
    .pb-lg-17,
    .py-lg-17 {
      padding-bottom: 17px !important;
    }
    .pl-lg-17,
    .px-lg-17 {
      padding-left: 17px !important;
    }
    .p-lg-18 {
      padding: 18px !important;
    }
    .pt-lg-18,
    .py-lg-18 {
      padding-top: 18px !important;
    }
    .pr-lg-18,
    .px-lg-18 {
      padding-right: 18px !important;
    }
    .pb-lg-18,
    .py-lg-18 {
      padding-bottom: 18px !important;
    }
    .pl-lg-18,
    .px-lg-18 {
      padding-left: 18px !important;
    }
    .p-lg-20 {
      padding: 20px !important;
    }
    .pt-lg-20,
    .py-lg-20 {
      padding-top: 20px !important;
    }
    .pr-lg-20,
    .px-lg-20 {
      padding-right: 20px !important;
    }
    .pb-lg-20,
    .py-lg-20 {
      padding-bottom: 20px !important;
    }
    .pl-lg-20,
    .px-lg-20 {
      padding-left: 20px !important;
    }
    .p-lg-25 {
      padding: 25px !important;
    }
    .pt-lg-25,
    .py-lg-25 {
      padding-top: 25px !important;
    }
    .pr-lg-25,
    .px-lg-25 {
      padding-right: 25px !important;
    }
    .pb-lg-25,
    .py-lg-25 {
      padding-bottom: 25px !important;
    }
    .pl-lg-25,
    .px-lg-25 {
      padding-left: 25px !important;
    }
    .p-lg-30 {
      padding: 30px !important;
    }
    .pt-lg-30,
    .py-lg-30 {
      padding-top: 30px !important;
    }
    .pr-lg-30,
    .px-lg-30 {
      padding-right: 30px !important;
    }
    .pb-lg-30,
    .py-lg-30 {
      padding-bottom: 30px !important;
    }
    .pl-lg-30,
    .px-lg-30 {
      padding-left: 30px !important;
    }
    .p-lg-40 {
      padding: 40px !important;
    }
    .pt-lg-40,
    .py-lg-40 {
      padding-top: 40px !important;
    }
    .pr-lg-40,
    .px-lg-40 {
      padding-right: 40px !important;
    }
    .pb-lg-40,
    .py-lg-40 {
      padding-bottom: 40px !important;
    }
    .pl-lg-40,
    .px-lg-40 {
      padding-left: 40px !important;
    }
    .p-lg-60 {
      padding: 60px !important;
    }
    .pt-lg-60,
    .py-lg-60 {
      padding-top: 60px !important;
    }
    .pr-lg-60,
    .px-lg-60 {
      padding-right: 60px !important;
    }
    .pb-lg-60,
    .py-lg-60 {
      padding-bottom: 60px !important;
    }
    .pl-lg-60,
    .px-lg-60 {
      padding-left: 60px !important;
    }
    .p-lg-70 {
      padding: 70px !important;
    }
    .pt-lg-70,
    .py-lg-70 {
      padding-top: 70px !important;
    }
    .pr-lg-70,
    .px-lg-70 {
      padding-right: 70px !important;
    }
    .pb-lg-70,
    .py-lg-70 {
      padding-bottom: 70px !important;
    }
    .pl-lg-70,
    .px-lg-70 {
      padding-left: 70px !important;
    }
    .p-lg-90 {
      padding: 90px !important;
    }
    .pt-lg-90,
    .py-lg-90 {
      padding-top: 90px !important;
    }
    .pr-lg-90,
    .px-lg-90 {
      padding-right: 90px !important;
    }
    .pb-lg-90,
    .py-lg-90 {
      padding-bottom: 90px !important;
    }
    .pl-lg-90,
    .px-lg-90 {
      padding-left: 90px !important;
    }
    .p-lg-100 {
      padding: 100px !important;
    }
    .pt-lg-100,
    .py-lg-100 {
      padding-top: 100px !important;
    }
    .pr-lg-100,
    .px-lg-100 {
      padding-right: 100px !important;
    }
    .pb-lg-100,
    .py-lg-100 {
      padding-bottom: 100px !important;
    }
    .pl-lg-100,
    .px-lg-100 {
      padding-left: 100px !important;
    }
    .p-lg-110 {
      padding: 110px !important;
    }
    .pt-lg-110,
    .py-lg-110 {
      padding-top: 110px !important;
    }
    .pr-lg-110,
    .px-lg-110 {
      padding-right: 110px !important;
    }
    .pb-lg-110,
    .py-lg-110 {
      padding-bottom: 110px !important;
    }
    .pl-lg-110,
    .px-lg-110 {
      padding-left: 110px !important;
    }
    .p-lg-130 {
      padding: 130px !important;
    }
    .pt-lg-130,
    .py-lg-130 {
      padding-top: 130px !important;
    }
    .pr-lg-130,
    .px-lg-130 {
      padding-right: 130px !important;
    }
    .pb-lg-130,
    .py-lg-130 {
      padding-bottom: 130px !important;
    }
    .pl-lg-130,
    .px-lg-130 {
      padding-left: 130px !important;
    }
    .p-lg-160 {
      padding: 160px !important;
    }
    .pt-lg-160,
    .py-lg-160 {
      padding-top: 160px !important;
    }
    .pr-lg-160,
    .px-lg-160 {
      padding-right: 160px !important;
    }
    .pb-lg-160,
    .py-lg-160 {
      padding-bottom: 160px !important;
    }
    .pl-lg-160,
    .px-lg-160 {
      padding-left: 160px !important;
    }
    .m-lg-n1 {
      margin: -1px !important;
    }
    .mt-lg-n1,
    .my-lg-n1 {
      margin-top: -1px !important;
    }
    .mr-lg-n1,
    .mx-lg-n1 {
      margin-right: -1px !important;
    }
    .mb-lg-n1,
    .my-lg-n1 {
      margin-bottom: -1px !important;
    }
    .ml-lg-n1,
    .mx-lg-n1 {
      margin-left: -1px !important;
    }
    .m-lg-n2 {
      margin: -2px !important;
    }
    .mt-lg-n2,
    .my-lg-n2 {
      margin-top: -2px !important;
    }
    .mr-lg-n2,
    .mx-lg-n2 {
      margin-right: -2px !important;
    }
    .mb-lg-n2,
    .my-lg-n2 {
      margin-bottom: -2px !important;
    }
    .ml-lg-n2,
    .mx-lg-n2 {
      margin-left: -2px !important;
    }
    .m-lg-n3 {
      margin: -3px !important;
    }
    .mt-lg-n3,
    .my-lg-n3 {
      margin-top: -3px !important;
    }
    .mr-lg-n3,
    .mx-lg-n3 {
      margin-right: -3px !important;
    }
    .mb-lg-n3,
    .my-lg-n3 {
      margin-bottom: -3px !important;
    }
    .ml-lg-n3,
    .mx-lg-n3 {
      margin-left: -3px !important;
    }
    .m-lg-n4 {
      margin: -4px !important;
    }
    .mt-lg-n4,
    .my-lg-n4 {
      margin-top: -4px !important;
    }
    .mr-lg-n4,
    .mx-lg-n4 {
      margin-right: -4px !important;
    }
    .mb-lg-n4,
    .my-lg-n4 {
      margin-bottom: -4px !important;
    }
    .ml-lg-n4,
    .mx-lg-n4 {
      margin-left: -4px !important;
    }
    .m-lg-n5 {
      margin: -5px !important;
    }
    .mt-lg-n5,
    .my-lg-n5 {
      margin-top: -5px !important;
    }
    .mr-lg-n5,
    .mx-lg-n5 {
      margin-right: -5px !important;
    }
    .mb-lg-n5,
    .my-lg-n5 {
      margin-bottom: -5px !important;
    }
    .ml-lg-n5,
    .mx-lg-n5 {
      margin-left: -5px !important;
    }
    .m-lg-n6 {
      margin: -6px !important;
    }
    .mt-lg-n6,
    .my-lg-n6 {
      margin-top: -6px !important;
    }
    .mr-lg-n6,
    .mx-lg-n6 {
      margin-right: -6px !important;
    }
    .mb-lg-n6,
    .my-lg-n6 {
      margin-bottom: -6px !important;
    }
    .ml-lg-n6,
    .mx-lg-n6 {
      margin-left: -6px !important;
    }
    .m-lg-n7 {
      margin: -7px !important;
    }
    .mt-lg-n7,
    .my-lg-n7 {
      margin-top: -7px !important;
    }
    .mr-lg-n7,
    .mx-lg-n7 {
      margin-right: -7px !important;
    }
    .mb-lg-n7,
    .my-lg-n7 {
      margin-bottom: -7px !important;
    }
    .ml-lg-n7,
    .mx-lg-n7 {
      margin-left: -7px !important;
    }
    .m-lg-n8 {
      margin: -8px !important;
    }
    .mt-lg-n8,
    .my-lg-n8 {
      margin-top: -8px !important;
    }
    .mr-lg-n8,
    .mx-lg-n8 {
      margin-right: -8px !important;
    }
    .mb-lg-n8,
    .my-lg-n8 {
      margin-bottom: -8px !important;
    }
    .ml-lg-n8,
    .mx-lg-n8 {
      margin-left: -8px !important;
    }
    .m-lg-n9 {
      margin: -9px !important;
    }
    .mt-lg-n9,
    .my-lg-n9 {
      margin-top: -9px !important;
    }
    .mr-lg-n9,
    .mx-lg-n9 {
      margin-right: -9px !important;
    }
    .mb-lg-n9,
    .my-lg-n9 {
      margin-bottom: -9px !important;
    }
    .ml-lg-n9,
    .mx-lg-n9 {
      margin-left: -9px !important;
    }
    .m-lg-n10 {
      margin: -10px !important;
    }
    .mt-lg-n10,
    .my-lg-n10 {
      margin-top: -10px !important;
    }
    .mr-lg-n10,
    .mx-lg-n10 {
      margin-right: -10px !important;
    }
    .mb-lg-n10,
    .my-lg-n10 {
      margin-bottom: -10px !important;
    }
    .ml-lg-n10,
    .mx-lg-n10 {
      margin-left: -10px !important;
    }
    .m-lg-n11 {
      margin: -11px !important;
    }
    .mt-lg-n11,
    .my-lg-n11 {
      margin-top: -11px !important;
    }
    .mr-lg-n11,
    .mx-lg-n11 {
      margin-right: -11px !important;
    }
    .mb-lg-n11,
    .my-lg-n11 {
      margin-bottom: -11px !important;
    }
    .ml-lg-n11,
    .mx-lg-n11 {
      margin-left: -11px !important;
    }
    .m-lg-n12 {
      margin: -12px !important;
    }
    .mt-lg-n12,
    .my-lg-n12 {
      margin-top: -12px !important;
    }
    .mr-lg-n12,
    .mx-lg-n12 {
      margin-right: -12px !important;
    }
    .mb-lg-n12,
    .my-lg-n12 {
      margin-bottom: -12px !important;
    }
    .ml-lg-n12,
    .mx-lg-n12 {
      margin-left: -12px !important;
    }
    .m-lg-n13 {
      margin: -13px !important;
    }
    .mt-lg-n13,
    .my-lg-n13 {
      margin-top: -13px !important;
    }
    .mr-lg-n13,
    .mx-lg-n13 {
      margin-right: -13px !important;
    }
    .mb-lg-n13,
    .my-lg-n13 {
      margin-bottom: -13px !important;
    }
    .ml-lg-n13,
    .mx-lg-n13 {
      margin-left: -13px !important;
    }
    .m-lg-n15 {
      margin: -15px !important;
    }
    .mt-lg-n15,
    .my-lg-n15 {
      margin-top: -15px !important;
    }
    .mr-lg-n15,
    .mx-lg-n15 {
      margin-right: -15px !important;
    }
    .mb-lg-n15,
    .my-lg-n15 {
      margin-bottom: -15px !important;
    }
    .ml-lg-n15,
    .mx-lg-n15 {
      margin-left: -15px !important;
    }
    .m-lg-n17 {
      margin: -17px !important;
    }
    .mt-lg-n17,
    .my-lg-n17 {
      margin-top: -17px !important;
    }
    .mr-lg-n17,
    .mx-lg-n17 {
      margin-right: -17px !important;
    }
    .mb-lg-n17,
    .my-lg-n17 {
      margin-bottom: -17px !important;
    }
    .ml-lg-n17,
    .mx-lg-n17 {
      margin-left: -17px !important;
    }
    .m-lg-n18 {
      margin: -18px !important;
    }
    .mt-lg-n18,
    .my-lg-n18 {
      margin-top: -18px !important;
    }
    .mr-lg-n18,
    .mx-lg-n18 {
      margin-right: -18px !important;
    }
    .mb-lg-n18,
    .my-lg-n18 {
      margin-bottom: -18px !important;
    }
    .ml-lg-n18,
    .mx-lg-n18 {
      margin-left: -18px !important;
    }
    .m-lg-n20 {
      margin: -20px !important;
    }
    .mt-lg-n20,
    .my-lg-n20 {
      margin-top: -20px !important;
    }
    .mr-lg-n20,
    .mx-lg-n20 {
      margin-right: -20px !important;
    }
    .mb-lg-n20,
    .my-lg-n20 {
      margin-bottom: -20px !important;
    }
    .ml-lg-n20,
    .mx-lg-n20 {
      margin-left: -20px !important;
    }
    .m-lg-n25 {
      margin: -25px !important;
    }
    .mt-lg-n25,
    .my-lg-n25 {
      margin-top: -25px !important;
    }
    .mr-lg-n25,
    .mx-lg-n25 {
      margin-right: -25px !important;
    }
    .mb-lg-n25,
    .my-lg-n25 {
      margin-bottom: -25px !important;
    }
    .ml-lg-n25,
    .mx-lg-n25 {
      margin-left: -25px !important;
    }
    .m-lg-n30 {
      margin: -30px !important;
    }
    .mt-lg-n30,
    .my-lg-n30 {
      margin-top: -30px !important;
    }
    .mr-lg-n30,
    .mx-lg-n30 {
      margin-right: -30px !important;
    }
    .mb-lg-n30,
    .my-lg-n30 {
      margin-bottom: -30px !important;
    }
    .ml-lg-n30,
    .mx-lg-n30 {
      margin-left: -30px !important;
    }
    .m-lg-n40 {
      margin: -40px !important;
    }
    .mt-lg-n40,
    .my-lg-n40 {
      margin-top: -40px !important;
    }
    .mr-lg-n40,
    .mx-lg-n40 {
      margin-right: -40px !important;
    }
    .mb-lg-n40,
    .my-lg-n40 {
      margin-bottom: -40px !important;
    }
    .ml-lg-n40,
    .mx-lg-n40 {
      margin-left: -40px !important;
    }
    .m-lg-n60 {
      margin: -60px !important;
    }
    .mt-lg-n60,
    .my-lg-n60 {
      margin-top: -60px !important;
    }
    .mr-lg-n60,
    .mx-lg-n60 {
      margin-right: -60px !important;
    }
    .mb-lg-n60,
    .my-lg-n60 {
      margin-bottom: -60px !important;
    }
    .ml-lg-n60,
    .mx-lg-n60 {
      margin-left: -60px !important;
    }
    .m-lg-n70 {
      margin: -70px !important;
    }
    .mt-lg-n70,
    .my-lg-n70 {
      margin-top: -70px !important;
    }
    .mr-lg-n70,
    .mx-lg-n70 {
      margin-right: -70px !important;
    }
    .mb-lg-n70,
    .my-lg-n70 {
      margin-bottom: -70px !important;
    }
    .ml-lg-n70,
    .mx-lg-n70 {
      margin-left: -70px !important;
    }
    .m-lg-n90 {
      margin: -90px !important;
    }
    .mt-lg-n90,
    .my-lg-n90 {
      margin-top: -90px !important;
    }
    .mr-lg-n90,
    .mx-lg-n90 {
      margin-right: -90px !important;
    }
    .mb-lg-n90,
    .my-lg-n90 {
      margin-bottom: -90px !important;
    }
    .ml-lg-n90,
    .mx-lg-n90 {
      margin-left: -90px !important;
    }
    .m-lg-n100 {
      margin: -100px !important;
    }
    .mt-lg-n100,
    .my-lg-n100 {
      margin-top: -100px !important;
    }
    .mr-lg-n100,
    .mx-lg-n100 {
      margin-right: -100px !important;
    }
    .mb-lg-n100,
    .my-lg-n100 {
      margin-bottom: -100px !important;
    }
    .ml-lg-n100,
    .mx-lg-n100 {
      margin-left: -100px !important;
    }
    .m-lg-n110 {
      margin: -110px !important;
    }
    .mt-lg-n110,
    .my-lg-n110 {
      margin-top: -110px !important;
    }
    .mr-lg-n110,
    .mx-lg-n110 {
      margin-right: -110px !important;
    }
    .mb-lg-n110,
    .my-lg-n110 {
      margin-bottom: -110px !important;
    }
    .ml-lg-n110,
    .mx-lg-n110 {
      margin-left: -110px !important;
    }
    .m-lg-n130 {
      margin: -130px !important;
    }
    .mt-lg-n130,
    .my-lg-n130 {
      margin-top: -130px !important;
    }
    .mr-lg-n130,
    .mx-lg-n130 {
      margin-right: -130px !important;
    }
    .mb-lg-n130,
    .my-lg-n130 {
      margin-bottom: -130px !important;
    }
    .ml-lg-n130,
    .mx-lg-n130 {
      margin-left: -130px !important;
    }
    .m-lg-n160 {
      margin: -160px !important;
    }
    .mt-lg-n160,
    .my-lg-n160 {
      margin-top: -160px !important;
    }
    .mr-lg-n160,
    .mx-lg-n160 {
      margin-right: -160px !important;
    }
    .mb-lg-n160,
    .my-lg-n160 {
      margin-bottom: -160px !important;
    }
    .ml-lg-n160,
    .mx-lg-n160 {
      margin-left: -160px !important;
    }
    .m-lg-auto {
      margin: auto !important;
    }
    .mt-lg-auto,
    .my-lg-auto {
      margin-top: auto !important;
    }
    .mr-lg-auto,
    .mx-lg-auto {
      margin-right: auto !important;
    }
    .mb-lg-auto,
    .my-lg-auto {
      margin-bottom: auto !important;
    }
    .ml-lg-auto,
    .mx-lg-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width: 1200px) {
    .m-xl-0 {
      margin: 0 !important;
    }
    .mt-xl-0,
    .my-xl-0 {
      margin-top: 0 !important;
    }
    .mr-xl-0,
    .mx-xl-0 {
      margin-right: 0 !important;
    }
    .mb-xl-0,
    .my-xl-0 {
      margin-bottom: 0 !important;
    }
    .ml-xl-0,
    .mx-xl-0 {
      margin-left: 0 !important;
    }
    .m-xl-1 {
      margin: 1px !important;
    }
    .mt-xl-1,
    .my-xl-1 {
      margin-top: 1px !important;
    }
    .mr-xl-1,
    .mx-xl-1 {
      margin-right: 1px !important;
    }
    .mb-xl-1,
    .my-xl-1 {
      margin-bottom: 1px !important;
    }
    .ml-xl-1,
    .mx-xl-1 {
      margin-left: 1px !important;
    }
    .m-xl-2 {
      margin: 2px !important;
    }
    .mt-xl-2,
    .my-xl-2 {
      margin-top: 2px !important;
    }
    .mr-xl-2,
    .mx-xl-2 {
      margin-right: 2px !important;
    }
    .mb-xl-2,
    .my-xl-2 {
      margin-bottom: 2px !important;
    }
    .ml-xl-2,
    .mx-xl-2 {
      margin-left: 2px !important;
    }
    .m-xl-3 {
      margin: 3px !important;
    }
    .mt-xl-3,
    .my-xl-3 {
      margin-top: 3px !important;
    }
    .mr-xl-3,
    .mx-xl-3 {
      margin-right: 3px !important;
    }
    .mb-xl-3,
    .my-xl-3 {
      margin-bottom: 3px !important;
    }
    .ml-xl-3,
    .mx-xl-3 {
      margin-left: 3px !important;
    }
    .m-xl-4 {
      margin: 4px !important;
    }
    .mt-xl-4,
    .my-xl-4 {
      margin-top: 4px !important;
    }
    .mr-xl-4,
    .mx-xl-4 {
      margin-right: 4px !important;
    }
    .mb-xl-4,
    .my-xl-4 {
      margin-bottom: 4px !important;
    }
    .ml-xl-4,
    .mx-xl-4 {
      margin-left: 4px !important;
    }
    .m-xl-5 {
      margin: 5px !important;
    }
    .mt-xl-5,
    .my-xl-5 {
      margin-top: 5px !important;
    }
    .mr-xl-5,
    .mx-xl-5 {
      margin-right: 5px !important;
    }
    .mb-xl-5,
    .my-xl-5 {
      margin-bottom: 5px !important;
    }
    .ml-xl-5,
    .mx-xl-5 {
      margin-left: 5px !important;
    }
    .m-xl-6 {
      margin: 6px !important;
    }
    .mt-xl-6,
    .my-xl-6 {
      margin-top: 6px !important;
    }
    .mr-xl-6,
    .mx-xl-6 {
      margin-right: 6px !important;
    }
    .mb-xl-6,
    .my-xl-6 {
      margin-bottom: 6px !important;
    }
    .ml-xl-6,
    .mx-xl-6 {
      margin-left: 6px !important;
    }
    .m-xl-7 {
      margin: 7px !important;
    }
    .mt-xl-7,
    .my-xl-7 {
      margin-top: 7px !important;
    }
    .mr-xl-7,
    .mx-xl-7 {
      margin-right: 7px !important;
    }
    .mb-xl-7,
    .my-xl-7 {
      margin-bottom: 7px !important;
    }
    .ml-xl-7,
    .mx-xl-7 {
      margin-left: 7px !important;
    }
    .m-xl-8 {
      margin: 8px !important;
    }
    .mt-xl-8,
    .my-xl-8 {
      margin-top: 8px !important;
    }
    .mr-xl-8,
    .mx-xl-8 {
      margin-right: 8px !important;
    }
    .mb-xl-8,
    .my-xl-8 {
      margin-bottom: 8px !important;
    }
    .ml-xl-8,
    .mx-xl-8 {
      margin-left: 8px !important;
    }
    .m-xl-9 {
      margin: 9px !important;
    }
    .mt-xl-9,
    .my-xl-9 {
      margin-top: 9px !important;
    }
    .mr-xl-9,
    .mx-xl-9 {
      margin-right: 9px !important;
    }
    .mb-xl-9,
    .my-xl-9 {
      margin-bottom: 9px !important;
    }
    .ml-xl-9,
    .mx-xl-9 {
      margin-left: 9px !important;
    }
    .m-xl-10 {
      margin: 10px !important;
    }
    .mt-xl-10,
    .my-xl-10 {
      margin-top: 10px !important;
    }
    .mr-xl-10,
    .mx-xl-10 {
      margin-right: 10px !important;
    }
    .mb-xl-10,
    .my-xl-10 {
      margin-bottom: 10px !important;
    }
    .ml-xl-10,
    .mx-xl-10 {
      margin-left: 10px !important;
    }
    .m-xl-11 {
      margin: 11px !important;
    }
    .mt-xl-11,
    .my-xl-11 {
      margin-top: 11px !important;
    }
    .mr-xl-11,
    .mx-xl-11 {
      margin-right: 11px !important;
    }
    .mb-xl-11,
    .my-xl-11 {
      margin-bottom: 11px !important;
    }
    .ml-xl-11,
    .mx-xl-11 {
      margin-left: 11px !important;
    }
    .m-xl-12 {
      margin: 12px !important;
    }
    .mt-xl-12,
    .my-xl-12 {
      margin-top: 12px !important;
    }
    .mr-xl-12,
    .mx-xl-12 {
      margin-right: 12px !important;
    }
    .mb-xl-12,
    .my-xl-12 {
      margin-bottom: 12px !important;
    }
    .ml-xl-12,
    .mx-xl-12 {
      margin-left: 12px !important;
    }
    .m-xl-13 {
      margin: 13px !important;
    }
    .mt-xl-13,
    .my-xl-13 {
      margin-top: 13px !important;
    }
    .mr-xl-13,
    .mx-xl-13 {
      margin-right: 13px !important;
    }
    .mb-xl-13,
    .my-xl-13 {
      margin-bottom: 13px !important;
    }
    .ml-xl-13,
    .mx-xl-13 {
      margin-left: 13px !important;
    }
    .m-xl-15 {
      margin: 15px !important;
    }
    .mt-xl-15,
    .my-xl-15 {
      margin-top: 15px !important;
    }
    .mr-xl-15,
    .mx-xl-15 {
      margin-right: 15px !important;
    }
    .mb-xl-15,
    .my-xl-15 {
      margin-bottom: 15px !important;
    }
    .ml-xl-15,
    .mx-xl-15 {
      margin-left: 15px !important;
    }
    .m-xl-17 {
      margin: 17px !important;
    }
    .mt-xl-17,
    .my-xl-17 {
      margin-top: 17px !important;
    }
    .mr-xl-17,
    .mx-xl-17 {
      margin-right: 17px !important;
    }
    .mb-xl-17,
    .my-xl-17 {
      margin-bottom: 17px !important;
    }
    .ml-xl-17,
    .mx-xl-17 {
      margin-left: 17px !important;
    }
    .m-xl-18 {
      margin: 18px !important;
    }
    .mt-xl-18,
    .my-xl-18 {
      margin-top: 18px !important;
    }
    .mr-xl-18,
    .mx-xl-18 {
      margin-right: 18px !important;
    }
    .mb-xl-18,
    .my-xl-18 {
      margin-bottom: 18px !important;
    }
    .ml-xl-18,
    .mx-xl-18 {
      margin-left: 18px !important;
    }
    .m-xl-20 {
      margin: 20px !important;
    }
    .mt-xl-20,
    .my-xl-20 {
      margin-top: 20px !important;
    }
    .mr-xl-20,
    .mx-xl-20 {
      margin-right: 20px !important;
    }
    .mb-xl-20,
    .my-xl-20 {
      margin-bottom: 20px !important;
    }
    .ml-xl-20,
    .mx-xl-20 {
      margin-left: 20px !important;
    }
    .m-xl-25 {
      margin: 25px !important;
    }
    .mt-xl-25,
    .my-xl-25 {
      margin-top: 25px !important;
    }
    .mr-xl-25,
    .mx-xl-25 {
      margin-right: 25px !important;
    }
    .mb-xl-25,
    .my-xl-25 {
      margin-bottom: 25px !important;
    }
    .ml-xl-25,
    .mx-xl-25 {
      margin-left: 25px !important;
    }
    .m-xl-30 {
      margin: 30px !important;
    }
    .mt-xl-30,
    .my-xl-30 {
      margin-top: 30px !important;
    }
    .mr-xl-30,
    .mx-xl-30 {
      margin-right: 30px !important;
    }
    .mb-xl-30,
    .my-xl-30 {
      margin-bottom: 30px !important;
    }
    .ml-xl-30,
    .mx-xl-30 {
      margin-left: 30px !important;
    }
    .m-xl-40 {
      margin: 40px !important;
    }
    .mt-xl-40,
    .my-xl-40 {
      margin-top: 40px !important;
    }
    .mr-xl-40,
    .mx-xl-40 {
      margin-right: 40px !important;
    }
    .mb-xl-40,
    .my-xl-40 {
      margin-bottom: 40px !important;
    }
    .ml-xl-40,
    .mx-xl-40 {
      margin-left: 40px !important;
    }
    .m-xl-60 {
      margin: 60px !important;
    }
    .mt-xl-60,
    .my-xl-60 {
      margin-top: 60px !important;
    }
    .mr-xl-60,
    .mx-xl-60 {
      margin-right: 60px !important;
    }
    .mb-xl-60,
    .my-xl-60 {
      margin-bottom: 60px !important;
    }
    .ml-xl-60,
    .mx-xl-60 {
      margin-left: 60px !important;
    }
    .m-xl-70 {
      margin: 70px !important;
    }
    .mt-xl-70,
    .my-xl-70 {
      margin-top: 70px !important;
    }
    .mr-xl-70,
    .mx-xl-70 {
      margin-right: 70px !important;
    }
    .mb-xl-70,
    .my-xl-70 {
      margin-bottom: 70px !important;
    }
    .ml-xl-70,
    .mx-xl-70 {
      margin-left: 70px !important;
    }
    .m-xl-90 {
      margin: 90px !important;
    }
    .mt-xl-90,
    .my-xl-90 {
      margin-top: 90px !important;
    }
    .mr-xl-90,
    .mx-xl-90 {
      margin-right: 90px !important;
    }
    .mb-xl-90,
    .my-xl-90 {
      margin-bottom: 90px !important;
    }
    .ml-xl-90,
    .mx-xl-90 {
      margin-left: 90px !important;
    }
    .m-xl-100 {
      margin: 100px !important;
    }
    .mt-xl-100,
    .my-xl-100 {
      margin-top: 100px !important;
    }
    .mr-xl-100,
    .mx-xl-100 {
      margin-right: 100px !important;
    }
    .mb-xl-100,
    .my-xl-100 {
      margin-bottom: 100px !important;
    }
    .ml-xl-100,
    .mx-xl-100 {
      margin-left: 100px !important;
    }
    .m-xl-110 {
      margin: 110px !important;
    }
    .mt-xl-110,
    .my-xl-110 {
      margin-top: 110px !important;
    }
    .mr-xl-110,
    .mx-xl-110 {
      margin-right: 110px !important;
    }
    .mb-xl-110,
    .my-xl-110 {
      margin-bottom: 110px !important;
    }
    .ml-xl-110,
    .mx-xl-110 {
      margin-left: 110px !important;
    }
    .m-xl-130 {
      margin: 130px !important;
    }
    .mt-xl-130,
    .my-xl-130 {
      margin-top: 130px !important;
    }
    .mr-xl-130,
    .mx-xl-130 {
      margin-right: 130px !important;
    }
    .mb-xl-130,
    .my-xl-130 {
      margin-bottom: 130px !important;
    }
    .ml-xl-130,
    .mx-xl-130 {
      margin-left: 130px !important;
    }
    .m-xl-160 {
      margin: 160px !important;
    }
    .mt-xl-160,
    .my-xl-160 {
      margin-top: 160px !important;
    }
    .mr-xl-160,
    .mx-xl-160 {
      margin-right: 160px !important;
    }
    .mb-xl-160,
    .my-xl-160 {
      margin-bottom: 160px !important;
    }
    .ml-xl-160,
    .mx-xl-160 {
      margin-left: 160px !important;
    }
    .p-xl-0 {
      padding: 0 !important;
    }
    .pt-xl-0,
    .py-xl-0 {
      padding-top: 0 !important;
    }
    .pr-xl-0,
    .px-xl-0 {
      padding-right: 0 !important;
    }
    .pb-xl-0,
    .py-xl-0 {
      padding-bottom: 0 !important;
    }
    .pl-xl-0,
    .px-xl-0 {
      padding-left: 0 !important;
    }
    .p-xl-1 {
      padding: 1px !important;
    }
    .pt-xl-1,
    .py-xl-1 {
      padding-top: 1px !important;
    }
    .pr-xl-1,
    .px-xl-1 {
      padding-right: 1px !important;
    }
    .pb-xl-1,
    .py-xl-1 {
      padding-bottom: 1px !important;
    }
    .pl-xl-1,
    .px-xl-1 {
      padding-left: 1px !important;
    }
    .p-xl-2 {
      padding: 2px !important;
    }
    .pt-xl-2,
    .py-xl-2 {
      padding-top: 2px !important;
    }
    .pr-xl-2,
    .px-xl-2 {
      padding-right: 2px !important;
    }
    .pb-xl-2,
    .py-xl-2 {
      padding-bottom: 2px !important;
    }
    .pl-xl-2,
    .px-xl-2 {
      padding-left: 2px !important;
    }
    .p-xl-3 {
      padding: 3px !important;
    }
    .pt-xl-3,
    .py-xl-3 {
      padding-top: 3px !important;
    }
    .pr-xl-3,
    .px-xl-3 {
      padding-right: 3px !important;
    }
    .pb-xl-3,
    .py-xl-3 {
      padding-bottom: 3px !important;
    }
    .pl-xl-3,
    .px-xl-3 {
      padding-left: 3px !important;
    }
    .p-xl-4 {
      padding: 4px !important;
    }
    .pt-xl-4,
    .py-xl-4 {
      padding-top: 4px !important;
    }
    .pr-xl-4,
    .px-xl-4 {
      padding-right: 4px !important;
    }
    .pb-xl-4,
    .py-xl-4 {
      padding-bottom: 4px !important;
    }
    .pl-xl-4,
    .px-xl-4 {
      padding-left: 4px !important;
    }
    .p-xl-5 {
      padding: 5px !important;
    }
    .pt-xl-5,
    .py-xl-5 {
      padding-top: 5px !important;
    }
    .pr-xl-5,
    .px-xl-5 {
      padding-right: 5px !important;
    }
    .pb-xl-5,
    .py-xl-5 {
      padding-bottom: 5px !important;
    }
    .pl-xl-5,
    .px-xl-5 {
      padding-left: 5px !important;
    }
    .p-xl-6 {
      padding: 6px !important;
    }
    .pt-xl-6,
    .py-xl-6 {
      padding-top: 6px !important;
    }
    .pr-xl-6,
    .px-xl-6 {
      padding-right: 6px !important;
    }
    .pb-xl-6,
    .py-xl-6 {
      padding-bottom: 6px !important;
    }
    .pl-xl-6,
    .px-xl-6 {
      padding-left: 6px !important;
    }
    .p-xl-7 {
      padding: 7px !important;
    }
    .pt-xl-7,
    .py-xl-7 {
      padding-top: 7px !important;
    }
    .pr-xl-7,
    .px-xl-7 {
      padding-right: 7px !important;
    }
    .pb-xl-7,
    .py-xl-7 {
      padding-bottom: 7px !important;
    }
    .pl-xl-7,
    .px-xl-7 {
      padding-left: 7px !important;
    }
    .p-xl-8 {
      padding: 8px !important;
    }
    .pt-xl-8,
    .py-xl-8 {
      padding-top: 8px !important;
    }
    .pr-xl-8,
    .px-xl-8 {
      padding-right: 8px !important;
    }
    .pb-xl-8,
    .py-xl-8 {
      padding-bottom: 8px !important;
    }
    .pl-xl-8,
    .px-xl-8 {
      padding-left: 8px !important;
    }
    .p-xl-9 {
      padding: 9px !important;
    }
    .pt-xl-9,
    .py-xl-9 {
      padding-top: 9px !important;
    }
    .pr-xl-9,
    .px-xl-9 {
      padding-right: 9px !important;
    }
    .pb-xl-9,
    .py-xl-9 {
      padding-bottom: 9px !important;
    }
    .pl-xl-9,
    .px-xl-9 {
      padding-left: 9px !important;
    }
    .p-xl-10 {
      padding: 10px !important;
    }
    .pt-xl-10,
    .py-xl-10 {
      padding-top: 10px !important;
    }
    .pr-xl-10,
    .px-xl-10 {
      padding-right: 10px !important;
    }
    .pb-xl-10,
    .py-xl-10 {
      padding-bottom: 10px !important;
    }
    .pl-xl-10,
    .px-xl-10 {
      padding-left: 10px !important;
    }
    .p-xl-11 {
      padding: 11px !important;
    }
    .pt-xl-11,
    .py-xl-11 {
      padding-top: 11px !important;
    }
    .pr-xl-11,
    .px-xl-11 {
      padding-right: 11px !important;
    }
    .pb-xl-11,
    .py-xl-11 {
      padding-bottom: 11px !important;
    }
    .pl-xl-11,
    .px-xl-11 {
      padding-left: 11px !important;
    }
    .p-xl-12 {
      padding: 12px !important;
    }
    .pt-xl-12,
    .py-xl-12 {
      padding-top: 12px !important;
    }
    .pr-xl-12,
    .px-xl-12 {
      padding-right: 12px !important;
    }
    .pb-xl-12,
    .py-xl-12 {
      padding-bottom: 12px !important;
    }
    .pl-xl-12,
    .px-xl-12 {
      padding-left: 12px !important;
    }
    .p-xl-13 {
      padding: 13px !important;
    }
    .pt-xl-13,
    .py-xl-13 {
      padding-top: 13px !important;
    }
    .pr-xl-13,
    .px-xl-13 {
      padding-right: 13px !important;
    }
    .pb-xl-13,
    .py-xl-13 {
      padding-bottom: 13px !important;
    }
    .pl-xl-13,
    .px-xl-13 {
      padding-left: 13px !important;
    }
    .p-xl-15 {
      padding: 15px !important;
    }
    .pt-xl-15,
    .py-xl-15 {
      padding-top: 15px !important;
    }
    .pr-xl-15,
    .px-xl-15 {
      padding-right: 15px !important;
    }
    .pb-xl-15,
    .py-xl-15 {
      padding-bottom: 15px !important;
    }
    .pl-xl-15,
    .px-xl-15 {
      padding-left: 15px !important;
    }
    .p-xl-17 {
      padding: 17px !important;
    }
    .pt-xl-17,
    .py-xl-17 {
      padding-top: 17px !important;
    }
    .pr-xl-17,
    .px-xl-17 {
      padding-right: 17px !important;
    }
    .pb-xl-17,
    .py-xl-17 {
      padding-bottom: 17px !important;
    }
    .pl-xl-17,
    .px-xl-17 {
      padding-left: 17px !important;
    }
    .p-xl-18 {
      padding: 18px !important;
    }
    .pt-xl-18,
    .py-xl-18 {
      padding-top: 18px !important;
    }
    .pr-xl-18,
    .px-xl-18 {
      padding-right: 18px !important;
    }
    .pb-xl-18,
    .py-xl-18 {
      padding-bottom: 18px !important;
    }
    .pl-xl-18,
    .px-xl-18 {
      padding-left: 18px !important;
    }
    .p-xl-20 {
      padding: 20px !important;
    }
    .pt-xl-20,
    .py-xl-20 {
      padding-top: 20px !important;
    }
    .pr-xl-20,
    .px-xl-20 {
      padding-right: 20px !important;
    }
    .pb-xl-20,
    .py-xl-20 {
      padding-bottom: 20px !important;
    }
    .pl-xl-20,
    .px-xl-20 {
      padding-left: 20px !important;
    }
    .p-xl-25 {
      padding: 25px !important;
    }
    .pt-xl-25,
    .py-xl-25 {
      padding-top: 25px !important;
    }
    .pr-xl-25,
    .px-xl-25 {
      padding-right: 25px !important;
    }
    .pb-xl-25,
    .py-xl-25 {
      padding-bottom: 25px !important;
    }
    .pl-xl-25,
    .px-xl-25 {
      padding-left: 25px !important;
    }
    .p-xl-30 {
      padding: 30px !important;
    }
    .pt-xl-30,
    .py-xl-30 {
      padding-top: 30px !important;
    }
    .pr-xl-30,
    .px-xl-30 {
      padding-right: 30px !important;
    }
    .pb-xl-30,
    .py-xl-30 {
      padding-bottom: 30px !important;
    }
    .pl-xl-30,
    .px-xl-30 {
      padding-left: 30px !important;
    }
    .p-xl-40 {
      padding: 40px !important;
    }
    .pt-xl-40,
    .py-xl-40 {
      padding-top: 40px !important;
    }
    .pr-xl-40,
    .px-xl-40 {
      padding-right: 40px !important;
    }
    .pb-xl-40,
    .py-xl-40 {
      padding-bottom: 40px !important;
    }
    .pl-xl-40,
    .px-xl-40 {
      padding-left: 40px !important;
    }
    .p-xl-60 {
      padding: 60px !important;
    }
    .pt-xl-60,
    .py-xl-60 {
      padding-top: 60px !important;
    }
    .pr-xl-60,
    .px-xl-60 {
      padding-right: 60px !important;
    }
    .pb-xl-60,
    .py-xl-60 {
      padding-bottom: 60px !important;
    }
    .pl-xl-60,
    .px-xl-60 {
      padding-left: 60px !important;
    }
    .p-xl-70 {
      padding: 70px !important;
    }
    .pt-xl-70,
    .py-xl-70 {
      padding-top: 70px !important;
    }
    .pr-xl-70,
    .px-xl-70 {
      padding-right: 70px !important;
    }
    .pb-xl-70,
    .py-xl-70 {
      padding-bottom: 70px !important;
    }
    .pl-xl-70,
    .px-xl-70 {
      padding-left: 70px !important;
    }
    .p-xl-90 {
      padding: 90px !important;
    }
    .pt-xl-90,
    .py-xl-90 {
      padding-top: 90px !important;
    }
    .pr-xl-90,
    .px-xl-90 {
      padding-right: 90px !important;
    }
    .pb-xl-90,
    .py-xl-90 {
      padding-bottom: 90px !important;
    }
    .pl-xl-90,
    .px-xl-90 {
      padding-left: 90px !important;
    }
    .p-xl-100 {
      padding: 100px !important;
    }
    .pt-xl-100,
    .py-xl-100 {
      padding-top: 100px !important;
    }
    .pr-xl-100,
    .px-xl-100 {
      padding-right: 100px !important;
    }
    .pb-xl-100,
    .py-xl-100 {
      padding-bottom: 100px !important;
    }
    .pl-xl-100,
    .px-xl-100 {
      padding-left: 100px !important;
    }
    .p-xl-110 {
      padding: 110px !important;
    }
    .pt-xl-110,
    .py-xl-110 {
      padding-top: 110px !important;
    }
    .pr-xl-110,
    .px-xl-110 {
      padding-right: 110px !important;
    }
    .pb-xl-110,
    .py-xl-110 {
      padding-bottom: 110px !important;
    }
    .pl-xl-110,
    .px-xl-110 {
      padding-left: 110px !important;
    }
    .p-xl-130 {
      padding: 130px !important;
    }
    .pt-xl-130,
    .py-xl-130 {
      padding-top: 130px !important;
    }
    .pr-xl-130,
    .px-xl-130 {
      padding-right: 130px !important;
    }
    .pb-xl-130,
    .py-xl-130 {
      padding-bottom: 130px !important;
    }
    .pl-xl-130,
    .px-xl-130 {
      padding-left: 130px !important;
    }
    .p-xl-160 {
      padding: 160px !important;
    }
    .pt-xl-160,
    .py-xl-160 {
      padding-top: 160px !important;
    }
    .pr-xl-160,
    .px-xl-160 {
      padding-right: 160px !important;
    }
    .pb-xl-160,
    .py-xl-160 {
      padding-bottom: 160px !important;
    }
    .pl-xl-160,
    .px-xl-160 {
      padding-left: 160px !important;
    }
    .m-xl-n1 {
      margin: -1px !important;
    }
    .mt-xl-n1,
    .my-xl-n1 {
      margin-top: -1px !important;
    }
    .mr-xl-n1,
    .mx-xl-n1 {
      margin-right: -1px !important;
    }
    .mb-xl-n1,
    .my-xl-n1 {
      margin-bottom: -1px !important;
    }
    .ml-xl-n1,
    .mx-xl-n1 {
      margin-left: -1px !important;
    }
    .m-xl-n2 {
      margin: -2px !important;
    }
    .mt-xl-n2,
    .my-xl-n2 {
      margin-top: -2px !important;
    }
    .mr-xl-n2,
    .mx-xl-n2 {
      margin-right: -2px !important;
    }
    .mb-xl-n2,
    .my-xl-n2 {
      margin-bottom: -2px !important;
    }
    .ml-xl-n2,
    .mx-xl-n2 {
      margin-left: -2px !important;
    }
    .m-xl-n3 {
      margin: -3px !important;
    }
    .mt-xl-n3,
    .my-xl-n3 {
      margin-top: -3px !important;
    }
    .mr-xl-n3,
    .mx-xl-n3 {
      margin-right: -3px !important;
    }
    .mb-xl-n3,
    .my-xl-n3 {
      margin-bottom: -3px !important;
    }
    .ml-xl-n3,
    .mx-xl-n3 {
      margin-left: -3px !important;
    }
    .m-xl-n4 {
      margin: -4px !important;
    }
    .mt-xl-n4,
    .my-xl-n4 {
      margin-top: -4px !important;
    }
    .mr-xl-n4,
    .mx-xl-n4 {
      margin-right: -4px !important;
    }
    .mb-xl-n4,
    .my-xl-n4 {
      margin-bottom: -4px !important;
    }
    .ml-xl-n4,
    .mx-xl-n4 {
      margin-left: -4px !important;
    }
    .m-xl-n5 {
      margin: -5px !important;
    }
    .mt-xl-n5,
    .my-xl-n5 {
      margin-top: -5px !important;
    }
    .mr-xl-n5,
    .mx-xl-n5 {
      margin-right: -5px !important;
    }
    .mb-xl-n5,
    .my-xl-n5 {
      margin-bottom: -5px !important;
    }
    .ml-xl-n5,
    .mx-xl-n5 {
      margin-left: -5px !important;
    }
    .m-xl-n6 {
      margin: -6px !important;
    }
    .mt-xl-n6,
    .my-xl-n6 {
      margin-top: -6px !important;
    }
    .mr-xl-n6,
    .mx-xl-n6 {
      margin-right: -6px !important;
    }
    .mb-xl-n6,
    .my-xl-n6 {
      margin-bottom: -6px !important;
    }
    .ml-xl-n6,
    .mx-xl-n6 {
      margin-left: -6px !important;
    }
    .m-xl-n7 {
      margin: -7px !important;
    }
    .mt-xl-n7,
    .my-xl-n7 {
      margin-top: -7px !important;
    }
    .mr-xl-n7,
    .mx-xl-n7 {
      margin-right: -7px !important;
    }
    .mb-xl-n7,
    .my-xl-n7 {
      margin-bottom: -7px !important;
    }
    .ml-xl-n7,
    .mx-xl-n7 {
      margin-left: -7px !important;
    }
    .m-xl-n8 {
      margin: -8px !important;
    }
    .mt-xl-n8,
    .my-xl-n8 {
      margin-top: -8px !important;
    }
    .mr-xl-n8,
    .mx-xl-n8 {
      margin-right: -8px !important;
    }
    .mb-xl-n8,
    .my-xl-n8 {
      margin-bottom: -8px !important;
    }
    .ml-xl-n8,
    .mx-xl-n8 {
      margin-left: -8px !important;
    }
    .m-xl-n9 {
      margin: -9px !important;
    }
    .mt-xl-n9,
    .my-xl-n9 {
      margin-top: -9px !important;
    }
    .mr-xl-n9,
    .mx-xl-n9 {
      margin-right: -9px !important;
    }
    .mb-xl-n9,
    .my-xl-n9 {
      margin-bottom: -9px !important;
    }
    .ml-xl-n9,
    .mx-xl-n9 {
      margin-left: -9px !important;
    }
    .m-xl-n10 {
      margin: -10px !important;
    }
    .mt-xl-n10,
    .my-xl-n10 {
      margin-top: -10px !important;
    }
    .mr-xl-n10,
    .mx-xl-n10 {
      margin-right: -10px !important;
    }
    .mb-xl-n10,
    .my-xl-n10 {
      margin-bottom: -10px !important;
    }
    .ml-xl-n10,
    .mx-xl-n10 {
      margin-left: -10px !important;
    }
    .m-xl-n11 {
      margin: -11px !important;
    }
    .mt-xl-n11,
    .my-xl-n11 {
      margin-top: -11px !important;
    }
    .mr-xl-n11,
    .mx-xl-n11 {
      margin-right: -11px !important;
    }
    .mb-xl-n11,
    .my-xl-n11 {
      margin-bottom: -11px !important;
    }
    .ml-xl-n11,
    .mx-xl-n11 {
      margin-left: -11px !important;
    }
    .m-xl-n12 {
      margin: -12px !important;
    }
    .mt-xl-n12,
    .my-xl-n12 {
      margin-top: -12px !important;
    }
    .mr-xl-n12,
    .mx-xl-n12 {
      margin-right: -12px !important;
    }
    .mb-xl-n12,
    .my-xl-n12 {
      margin-bottom: -12px !important;
    }
    .ml-xl-n12,
    .mx-xl-n12 {
      margin-left: -12px !important;
    }
    .m-xl-n13 {
      margin: -13px !important;
    }
    .mt-xl-n13,
    .my-xl-n13 {
      margin-top: -13px !important;
    }
    .mr-xl-n13,
    .mx-xl-n13 {
      margin-right: -13px !important;
    }
    .mb-xl-n13,
    .my-xl-n13 {
      margin-bottom: -13px !important;
    }
    .ml-xl-n13,
    .mx-xl-n13 {
      margin-left: -13px !important;
    }
    .m-xl-n15 {
      margin: -15px !important;
    }
    .mt-xl-n15,
    .my-xl-n15 {
      margin-top: -15px !important;
    }
    .mr-xl-n15,
    .mx-xl-n15 {
      margin-right: -15px !important;
    }
    .mb-xl-n15,
    .my-xl-n15 {
      margin-bottom: -15px !important;
    }
    .ml-xl-n15,
    .mx-xl-n15 {
      margin-left: -15px !important;
    }
    .m-xl-n17 {
      margin: -17px !important;
    }
    .mt-xl-n17,
    .my-xl-n17 {
      margin-top: -17px !important;
    }
    .mr-xl-n17,
    .mx-xl-n17 {
      margin-right: -17px !important;
    }
    .mb-xl-n17,
    .my-xl-n17 {
      margin-bottom: -17px !important;
    }
    .ml-xl-n17,
    .mx-xl-n17 {
      margin-left: -17px !important;
    }
    .m-xl-n18 {
      margin: -18px !important;
    }
    .mt-xl-n18,
    .my-xl-n18 {
      margin-top: -18px !important;
    }
    .mr-xl-n18,
    .mx-xl-n18 {
      margin-right: -18px !important;
    }
    .mb-xl-n18,
    .my-xl-n18 {
      margin-bottom: -18px !important;
    }
    .ml-xl-n18,
    .mx-xl-n18 {
      margin-left: -18px !important;
    }
    .m-xl-n20 {
      margin: -20px !important;
    }
    .mt-xl-n20,
    .my-xl-n20 {
      margin-top: -20px !important;
    }
    .mr-xl-n20,
    .mx-xl-n20 {
      margin-right: -20px !important;
    }
    .mb-xl-n20,
    .my-xl-n20 {
      margin-bottom: -20px !important;
    }
    .ml-xl-n20,
    .mx-xl-n20 {
      margin-left: -20px !important;
    }
    .m-xl-n25 {
      margin: -25px !important;
    }
    .mt-xl-n25,
    .my-xl-n25 {
      margin-top: -25px !important;
    }
    .mr-xl-n25,
    .mx-xl-n25 {
      margin-right: -25px !important;
    }
    .mb-xl-n25,
    .my-xl-n25 {
      margin-bottom: -25px !important;
    }
    .ml-xl-n25,
    .mx-xl-n25 {
      margin-left: -25px !important;
    }
    .m-xl-n30 {
      margin: -30px !important;
    }
    .mt-xl-n30,
    .my-xl-n30 {
      margin-top: -30px !important;
    }
    .mr-xl-n30,
    .mx-xl-n30 {
      margin-right: -30px !important;
    }
    .mb-xl-n30,
    .my-xl-n30 {
      margin-bottom: -30px !important;
    }
    .ml-xl-n30,
    .mx-xl-n30 {
      margin-left: -30px !important;
    }
    .m-xl-n40 {
      margin: -40px !important;
    }
    .mt-xl-n40,
    .my-xl-n40 {
      margin-top: -40px !important;
    }
    .mr-xl-n40,
    .mx-xl-n40 {
      margin-right: -40px !important;
    }
    .mb-xl-n40,
    .my-xl-n40 {
      margin-bottom: -40px !important;
    }
    .ml-xl-n40,
    .mx-xl-n40 {
      margin-left: -40px !important;
    }
    .m-xl-n60 {
      margin: -60px !important;
    }
    .mt-xl-n60,
    .my-xl-n60 {
      margin-top: -60px !important;
    }
    .mr-xl-n60,
    .mx-xl-n60 {
      margin-right: -60px !important;
    }
    .mb-xl-n60,
    .my-xl-n60 {
      margin-bottom: -60px !important;
    }
    .ml-xl-n60,
    .mx-xl-n60 {
      margin-left: -60px !important;
    }
    .m-xl-n70 {
      margin: -70px !important;
    }
    .mt-xl-n70,
    .my-xl-n70 {
      margin-top: -70px !important;
    }
    .mr-xl-n70,
    .mx-xl-n70 {
      margin-right: -70px !important;
    }
    .mb-xl-n70,
    .my-xl-n70 {
      margin-bottom: -70px !important;
    }
    .ml-xl-n70,
    .mx-xl-n70 {
      margin-left: -70px !important;
    }
    .m-xl-n90 {
      margin: -90px !important;
    }
    .mt-xl-n90,
    .my-xl-n90 {
      margin-top: -90px !important;
    }
    .mr-xl-n90,
    .mx-xl-n90 {
      margin-right: -90px !important;
    }
    .mb-xl-n90,
    .my-xl-n90 {
      margin-bottom: -90px !important;
    }
    .ml-xl-n90,
    .mx-xl-n90 {
      margin-left: -90px !important;
    }
    .m-xl-n100 {
      margin: -100px !important;
    }
    .mt-xl-n100,
    .my-xl-n100 {
      margin-top: -100px !important;
    }
    .mr-xl-n100,
    .mx-xl-n100 {
      margin-right: -100px !important;
    }
    .mb-xl-n100,
    .my-xl-n100 {
      margin-bottom: -100px !important;
    }
    .ml-xl-n100,
    .mx-xl-n100 {
      margin-left: -100px !important;
    }
    .m-xl-n110 {
      margin: -110px !important;
    }
    .mt-xl-n110,
    .my-xl-n110 {
      margin-top: -110px !important;
    }
    .mr-xl-n110,
    .mx-xl-n110 {
      margin-right: -110px !important;
    }
    .mb-xl-n110,
    .my-xl-n110 {
      margin-bottom: -110px !important;
    }
    .ml-xl-n110,
    .mx-xl-n110 {
      margin-left: -110px !important;
    }
    .m-xl-n130 {
      margin: -130px !important;
    }
    .mt-xl-n130,
    .my-xl-n130 {
      margin-top: -130px !important;
    }
    .mr-xl-n130,
    .mx-xl-n130 {
      margin-right: -130px !important;
    }
    .mb-xl-n130,
    .my-xl-n130 {
      margin-bottom: -130px !important;
    }
    .ml-xl-n130,
    .mx-xl-n130 {
      margin-left: -130px !important;
    }
    .m-xl-n160 {
      margin: -160px !important;
    }
    .mt-xl-n160,
    .my-xl-n160 {
      margin-top: -160px !important;
    }
    .mr-xl-n160,
    .mx-xl-n160 {
      margin-right: -160px !important;
    }
    .mb-xl-n160,
    .my-xl-n160 {
      margin-bottom: -160px !important;
    }
    .ml-xl-n160,
    .mx-xl-n160 {
      margin-left: -160px !important;
    }
    .m-xl-auto {
      margin: auto !important;
    }
    .mt-xl-auto,
    .my-xl-auto {
      margin-top: auto !important;
    }
    .mr-xl-auto,
    .mx-xl-auto {
      margin-right: auto !important;
    }
    .mb-xl-auto,
    .my-xl-auto {
      margin-bottom: auto !important;
    }
    .ml-xl-auto,
    .mx-xl-auto {
      margin-left: auto !important;
    }
  }
  .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
  }
  .text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
      "Courier New", monospace !important;
  }
  .text-justify {
    text-align: justify !important;
  }
  .text-wrap {
    white-space: normal !important;
  }
  .text-nowrap {
    white-space: nowrap !important;
  }
  .text-truncate {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .text-left {
    text-align: left !important;
  }
  .text-right {
    text-align: right !important;
  }
  .text-center {
    text-align: center !important;
  }
  @media (min-width: 620px) {
    .text-sm-left {
      text-align: left !important;
    }
    .text-sm-right {
      text-align: right !important;
    }
    .text-sm-center {
      text-align: center !important;
    }
  }
  @media (min-width: 828px) {
    .text-md-left {
      text-align: left !important;
    }
    .text-md-right {
      text-align: right !important;
    }
    .text-md-center {
      text-align: center !important;
    }
  }
  @media (min-width: 1024px) {
    .text-lg-left {
      text-align: left !important;
    }
    .text-lg-right {
      text-align: right !important;
    }
    .text-lg-center {
      text-align: center !important;
    }
  }
  @media (min-width: 1200px) {
    .text-xl-left {
      text-align: left !important;
    }
    .text-xl-right {
      text-align: right !important;
    }
    .text-xl-center {
      text-align: center !important;
    }
  }
  .text-lowercase {
    text-transform: lowercase !important;
  }
  .text-uppercase {
    text-transform: uppercase !important;
  }
  .text-capitalize {
    text-transform: capitalize !important;
  }
  .font-weight-light {
    font-weight: 300 !important;
  }
  .font-weight-lighter {
    font-weight: lighter !important;
  }
  .font-weight-normal {
    font-weight: 400 !important;
  }
  .font-weight-bold {
    font-weight: 700 !important;
  }
  .font-weight-bolder {
    font-weight: bolder !important;
  }
  .font-italic {
    font-style: italic !important;
  }
  .text-white {
    color: #fff !important;
  }
  .text-primary {
    color: #007bff !important;
  }
  a.text-primary:focus,
  a.text-primary:hover {
    color: #0056b3 !important;
  }
  .text-secondary {
    color: #969696 !important;
  }
  a.text-secondary:focus,
  a.text-secondary:hover {
    color: #707070 !important;
  }
  .text-success {
    color: #28a745 !important;
  }
  a.text-success:focus,
  a.text-success:hover {
    color: #19692c !important;
  }
  .text-info {
    color: #17a2b8 !important;
  }
  a.text-info:focus,
  a.text-info:hover {
    color: #0f6674 !important;
  }
  .text-warning {
    color: #ffc107 !important;
  }
  a.text-warning:focus,
  a.text-warning:hover {
    color: #ba8b00 !important;
  }
  .text-danger {
    color: #dc3545 !important;
  }
  a.text-danger:focus,
  a.text-danger:hover {
    color: #a71d2a !important;
  }
  .text-light {
    color: #f5f5f5 !important;
  }
  a.text-light:focus,
  a.text-light:hover {
    color: #cfcfcf !important;
  }
  .text-dark {
    color: #0e0e0e !important;
  }
  a.text-dark:focus,
  a.text-dark:hover {
    color: #000 !important;
  }
  .text-body {
    color: #0e0e0e !important;
  }
  .text-muted {
    color: #969696 !important;
  }
  .text-black-50 {
    color: rgba(14, 14, 14, 0.5) !important;
  }
  .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
  }
  .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  .text-decoration-none {
    text-decoration: none !important;
  }
  .text-break {
    word-wrap: break-word !important;
  }
  .text-reset {
    color: inherit !important;
  }
  .visible {
    visibility: visible !important;
  }
  .invisible {
    visibility: hidden !important;
  }
  @media print {
    *,
    ::after,
    ::before {
      text-shadow: none !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
    a:not(.btn) {
      text-decoration: underline;
    }
    abbr[title]::after {
      content: " (" attr(title) ")";
    }
    pre {
      white-space: pre-wrap !important;
    }
    blockquote,
    pre {
      border: 1px solid #adb5bd;
      page-break-inside: avoid;
    }
    thead {
      display: table-header-group;
    }
    img,
    tr {
      page-break-inside: avoid;
    }
    h2,
    h3,
    p {
      orphans: 3;
      widows: 3;
    }
    h2,
    h3 {
      page-break-after: avoid;
    }
    @page {
      size: a3;
    }
    body {
      min-width: 1024px !important;
    }
    .container {
      min-width: 1024px !important;
    }
    .navbar {
      display: none;
    }
    .badge {
      border: 1px solid #0e0e0e;
    }
    .table {
      border-collapse: collapse !important;
    }
    .table td,
    .table th {
      background-color: #fff !important;
    }
    .table-bordered td,
    .table-bordered th {
      border: 1px solid #dee2e6 !important;
    }
    .table-dark {
      color: inherit;
    }
    .table-dark tbody + tbody,
    .table-dark td,
    .table-dark th,
    .table-dark thead th {
      border-color: #ddd;
    }
    .table .thead-dark th {
      color: inherit;
      border-color: #ddd;
    }
  }
  .row.gh-xs {
    margin-left: -20px;
  }
  .row.gh-xs > [class*="col"] {
    padding-left: 20px;
  }
  .row.gv-xs {
    margin-top: -20px;
  }
  .row.gv-xs > [class*="col"] {
    padding-top: 20px;
  }
  .row.gh-1 {
    margin-left: -30px;
  }
  .row.gh-1 > [class*="col"] {
    padding-left: 30px;
  }
  .row.gv-1 {
    margin-top: -30px;
  }
  .row.gv-1 > [class*="col"] {
    padding-top: 30px;
  }
  .row.gh-2 {
    margin-left: -40px;
  }
  .row.gh-2 > [class*="col"] {
    padding-left: 40px;
  }
  .row.gv-2 {
    margin-top: -40px;
  }
  .row.gv-2 > [class*="col"] {
    padding-top: 40px;
  }
  .row.gh-3 {
    margin-left: -60px;
  }
  .row.gh-3 > [class*="col"] {
    padding-left: 60px;
  }
  .row.gv-3 {
    margin-top: -60px;
  }
  .row.gv-3 > [class*="col"] {
    padding-top: 60px;
  }
  .row.gh-4 {
    margin-left: -80px;
  }
  .row.gh-4 > [class*="col"] {
    padding-left: 80px;
  }
  .row.gv-4 {
    margin-top: -80px;
  }
  .row.gv-4 > [class*="col"] {
    padding-top: 80px;
  }
  .row.gh-5 {
    margin-left: -100px;
  }
  .row.gh-5 > [class*="col"] {
    padding-left: 100px;
  }
  .row.gv-5 {
    margin-top: -100px;
  }
  .row.gv-5 > [class*="col"] {
    padding-top: 100px;
  }
  .row.gh-6 {
    margin-left: -140px;
  }
  .row.gh-6 > [class*="col"] {
    padding-left: 140px;
  }
  .row.gv-6 {
    margin-top: -140px;
  }
  .row.gv-6 > [class*="col"] {
    padding-top: 140px;
  }
  @media (max-width: 827.98px) {
    .container,
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      padding-right: 40px;
      padding-left: 40px;
    }
  }
  .btn {
    padding: 15px 40px;
    font-weight: 500;
  }
  .btn-name ~ svg {
    margin-left: 10px;
  }
  .show > .btn.dropdown-toggle:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .btn-with-ball .btn-ball {
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-left: 20px;
    vertical-align: middle;
    background-color: currentColor;
    border-radius: 100%;
  }
  .btn-clean {
    padding: 0;
    background-color: transparent;
  }
  .btn-clean .icon-arrow-right,
  .btn-clean .swiper-button-next svg,
  .swiper-button-next .btn-clean svg {
    margin-left: 17px;
  }
  .btn-clean .icon-arrow-left,
  .btn-clean .swiper-button-prev svg,
  .swiper-button-prev .btn-clean svg {
    margin-right: 17px;
  }
  .btn-circle {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 20px;
    font-size: 0.9375em;
    border-radius: 100%;
  }
  .btn-circle.btn-lg,
  .btn-group-lg > .btn-circle.btn {
    width: 110px;
    height: 110px;
  }
  .btn-circle.btn-md {
    width: 70px;
    height: 70px;
  }
  .btn-circle.btn-sm,
  .btn-group-sm > .btn-circle.btn {
    width: 50px;
    height: 50px;
  }
  .btn-circle.btn-xs {
    width: 44px;
    height: 44px;
    padding: 10px;
  }
  .btn-circle::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: inherit;
    border-radius: 100%;
    will-change: transform;
    -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
    transition: -webkit-transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.6s cubic-bezier(0.2, 1, 0.2, 1),
      -webkit-transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
    z-index: -1;
  }
  .btn-circle:focus::before,
  .btn-circle:hover::before {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
  .btn-link {
    font-weight: 500;
  }
  .btn-link.btn-white {
    color: #fff;
    background-color: transparent;
  }
  .btn-link.btn-dark,
  .btn-link.btn-dark.focus,
  .btn-link.btn-dark:focus,
  .btn-link.btn-dark:hover,
  .btn-link.btn-dark:not(:disabled):not(.disabled).active,
  .btn-link.btn-dark:not(:disabled):not(.disabled):active {
    color: #0e0e0e;
    background-color: transparent;
  }
  .btn-link:focus,
  .btn-link:hover {
    text-decoration: none;
  }
  .btn-white {
    color: #0e0e0e;
    background-color: #fff;
  }
  .btn-light.focus,
  .btn-light:focus,
  .btn-light:hover,
  .btn-light:not(:disabled):not(.disabled).active,
  .btn-light:not(:disabled):not(.disabled):active {
    color: #0e0e0e;
    background-color: #f5f5f5;
  }
  .btn-dark.focus,
  .btn-dark:focus,
  .btn-dark:hover,
  .btn-dark:not(:disabled):not(.disabled).active,
  .btn-dark:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #0e0e0e;
  }
  .form-control {
    padding-bottom: 17px;
    border-top: 0;
    border-right: 0;
    border-left: 0;
  }
  .form-control-white {
    color: #fff;
    border-color: rgba(255, 255, 255, 0.2);
  }
  .form-control-white::-webkit-input-placeholder {
    color: #fff;
  }
  .form-control-white::-moz-placeholder {
    color: #fff;
  }
  .form-control-white:-ms-input-placeholder {
    color: #fff;
  }
  .form-control-white::-ms-input-placeholder {
    color: #fff;
  }
  .form-control-white::placeholder {
    color: #fff;
  }
  .form-control-white:focus,
  .form-control-white:hover {
    color: #fff;
    border-color: #fff;
  }
  .form-control-white:focus::-webkit-input-placeholder,
  .form-control-white:hover::-webkit-input-placeholder {
    color: #fff;
  }
  .form-control-white:focus::-moz-placeholder,
  .form-control-white:hover::-moz-placeholder {
    color: #fff;
  }
  .form-control-white:focus:-ms-input-placeholder,
  .form-control-white:hover:-ms-input-placeholder {
    color: #fff;
  }
  .form-control-white:focus::-ms-input-placeholder,
  .form-control-white:hover::-ms-input-placeholder {
    color: #fff;
  }
  .form-control-white:focus::placeholder,
  .form-control-white:hover::placeholder {
    color: #fff;
  }
  .form-control-lg {
    padding-bottom: 19px;
  }
  select.form-control {
    background-image: url("data:image/svg+xml;utf8,%3csvg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1 1L5.5 5L10 1' stroke='%230E0E0E' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: 100% calc(50% - 8px);
    background-size: 11px 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .form-group-overlay {
    position: relative;
  }
  .form-group-overlay .btn {
    position: absolute;
    top: 0;
    right: 0;
  }
  .checkbox-btn {
    display: none;
  }
  .checkbox-btn + label {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 13.5px 40px;
    margin-bottom: 0;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.4);
    cursor: pointer;
    border: 1px solid #202020;
    -webkit-transition: border-color 0.15s, color 0.15s, background-color 0.15s;
    -o-transition: border-color 0.15s, color 0.15s, background-color 0.15s;
    transition: border-color 0.15s, color 0.15s, background-color 0.15s;
  }
  .checkbox-btn + label > span {
    will-change: transform;
    -webkit-transition: -webkit-transform 0.15s;
    transition: -webkit-transform 0.15s;
    -o-transition: transform 0.15s;
    transition: transform 0.15s;
    transition: transform 0.15s, -webkit-transform 0.15s;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  .checkbox-btn + label::after {
    content: "";
    position: absolute;
    -ms-flex-item-align: center;
    align-self: center;
    right: 27px;
    width: 13px;
    height: 10px;
    background-image: url("data:image/svg+xml;utf8,%3csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12 1L4.4375 9L1 5.36364' stroke='white' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
    background-size: cover;
    opacity: 0;
    will-change: opacity;
    -webkit-transition: opacity 0.15s;
    -o-transition: opacity 0.15s;
    transition: opacity 0.15s;
  }
  .checkbox-btn.checked + label,
  .checkbox-btn:checked + label {
    color: #fff;
    background-color: #202020;
  }
  .checkbox-btn.checked + label::after,
  .checkbox-btn:checked + label::after {
    opacity: 1;
  }
  .checkbox-btn.checked + label > span,
  .checkbox-btn:checked + label > span {
    -webkit-transform: translateX(-13px);
    -ms-transform: translateX(-13px);
    transform: translateX(-13px);
  }
  .checkbox-btn:focus + label,
  .checkbox-btn:hover + label {
    color: #fff;
    background-color: #202020;
  }
  .nav {
    margin-right: -15px;
    margin-left: -15px;
    font-size: 1em;
  }
  .nav .nav-link,
  .nav .nav-link.active {
    color: #0e0e0e;
  }
  .nav .nav-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 5px 15px;
    margin-bottom: 0;
    font-weight: 500;
    will-change: opacity;
    -webkit-transition: opacity 0.15s;
    -o-transition: opacity 0.15s;
    transition: opacity 0.15s;
  }
  .nav .nav-link > svg {
    -ms-flex-item-align: center;
    align-self: center;
  }
  .nav .nav-link > .icon-arrow-right,
  .nav .swiper-button-next .nav-link > svg,
  .swiper-button-next .nav .nav-link > svg {
    margin-left: auto;
  }
  .nav .nav-link > .icon-arrow-left,
  .nav .swiper-button-prev .nav-link > svg,
  .swiper-button-prev .nav .nav-link > svg {
    margin-right: auto;
  }
  .nav .nav-link-name {
    position: relative;
  }
  .nav .nav-link-name::before {
    content: "";
    position: absolute;
    top: 50%;
    right: -2px;
    left: -2px;
    border-bottom: 1px solid currentColor;
    will-change: transform;
    -webkit-transition: -webkit-transform 0.25s ease-in-out;
    transition: -webkit-transform 0.25s ease-in-out;
    -o-transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: center left;
    -ms-transform-origin: center left;
    transform-origin: center left;
  }
  .nav .active > .nav-link .nav-link-name::before,
  .nav .nav-link.active > .nav-link-name::before {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }
  .nav:not(.nav-opacity).focus .nav-link,
  .nav:not(.nav-opacity):hover .nav-link {
    opacity: 0.6;
  }
  .nav:not(.nav-opacity).focus .active > .nav-link,
  .nav:not(.nav-opacity).focus .nav-link.active,
  .nav:not(.nav-opacity):hover .active > .nav-link,
  .nav:not(.nav-opacity):hover .nav-link.active {
    opacity: 1;
  }
  .nav:not(.nav-opacity) .nav-link.active,
  .nav:not(.nav-opacity) .nav-link:focus,
  .nav:not(.nav-opacity) .nav-link:hover {
    opacity: 1;
  }
  .nav.nav-opacity .nav-link {
    opacity: 0.6;
  }
  .nav.nav-opacity .nav-link.active,
  .nav.nav-opacity .nav-link:focus,
  .nav.nav-opacity .nav-link:hover {
    opacity: 1;
  }
  .nav[class*="-column"] {
    margin: -2px 0;
    font-size: 1.0625em;
  }
  .nav[class*="-column"] .nav-item {
    display: block;
    max-width: 100%;
  }
  .nav[class*="-column"] .nav-link {
    padding: 2px 0;
  }
  .nav.text-white .nav-link,
  .nav.text-white .nav-link.active {
    color: #fff;
  }
  .nav-no-gap {
    margin: 0;
  }
  .nav-no-gap .nav-link {
    padding: 0;
  }
  .nav-no-gap[class*="-column"] {
    margin: 0;
  }
  .nav-no-gap[class*="-column"] .nav-link {
    padding: 0;
  }
  .nav-gap-sm {
    margin: 0 -9px;
  }
  .nav-gap-sm .nav-link {
    padding: 0 9px;
  }
  .nav-gap-sm[class*="-column"] {
    margin: -9px;
  }
  .nav-gap-sm[class*="-column"] .nav-link {
    padding: 9px;
  }
  .nav-gap-md {
    margin: 0 -11px;
  }
  .nav-gap-md .nav-link {
    padding: 0 11px;
  }
  .nav-gap-md[class*="-column"] {
    margin: -11px;
  }
  .nav-gap-md[class*="-column"] .nav-link {
    padding: 11px;
  }
  .nav-social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .nav-social .nav-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 100%;
  }
  .nav-social .nav-link {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 35px;
    max-width: 100%;
    height: 35px;
  }
  .nav-social svg {
    position: absolute;
    display: block;
    -ms-flex-item-align: start;
    align-self: flex-start;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .nav-tabs {
    font-weight: 500;
  }
  .nav-tabs[class*="-column"] {
    padding-left: 70px;
    margin-right: -11px;
    margin-bottom: -11px;
    font-size: 1.5em;
  }
  .nav-tabs[class*="-column"] .nav-link {
    position: relative;
    padding-top: 11px;
    padding-bottom: 11px;
  }
  .nav-tabs[class*="-column"] .nav-link .badge {
    padding: 0;
    margin-top: -4px;
    font-size: 0.5em;
    font-weight: 500;
    opacity: 0;
    will-change: opacity;
    -webkit-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
  }
  .nav-tabs[class*="-column"] .nav-link::after,
  .nav-tabs[class*="-column"] .nav-link::before {
    content: "";
    display: inline-block;
  }
  .nav-tabs[class*="-column"] .nav-link::after {
    position: absolute;
    top: 50%;
    left: -70px;
    width: 40px;
    border-top: 1px solid currentColor;
    will-change: transform;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    -o-transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center;
  }
  .nav-tabs[class*="-column"] .nav-link::before {
    width: 0;
    will-change: width;
    -webkit-transition: width 0.2s ease-in-out;
    -o-transition: width 0.2s ease-in-out;
    transition: width 0.2s ease-in-out;
  }
  .nav-tabs[class*="-column"] .nav-link.active::after {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }
  .nav-tabs[class*="-column"] .nav-link.active::before {
    width: 20px;
  }
  .nav-tabs[class*="-column"] .nav-link.active .badge {
    opacity: 1;
  }
  .nav-pills .nav-link {
    padding: 7px 20px;
  }
  .nav.nav-md {
    font-size: 1.25em;
  }
  .nav.nav-bordered {
    margin-bottom: 0;
  }
  .nav.nav-bordered .nav-link {
    padding-top: 17px;
    padding-bottom: 17px;
  }
  .nav-bordered .nav-link {
    border-bottom: 1px solid currentColor;
  }
  .nav-bordered .nav-item + .nav-item {
    margin-top: 15px;
  }
  .card-wrapper {
    position: relative;
  }
  .card {
    color: #0e0e0e;
  }
  .card .btn,
  .card .card-title,
  .card .card-title-decoration,
  .card ~ .card-social li,
  .card ~ .card-social-container li {
    will-change: transform, opacity;
  }
  .card .btn,
  .card .card-title {
    color: inherit;
  }
  .card > .background {
    z-index: 1;
  }
  .card .background-color {
    opacity: 0;
    will-change: opacity;
    -webkit-transition: opacity 0.25s ease-in-out;
    -o-transition: opacity 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out;
  }
  .card.card-bg-show .background-color {
    opacity: 1;
  }
  .card.card-image-lg .card-img,
  .card.card-image-md .card-img,
  .card.card-image-sm .card-img,
  .card.card-image-xl .card-img {
    position: relative;
    display: block;
    overflow: hidden;
  }
  .card.card-image-lg .card-img::before,
  .card.card-image-md .card-img::before,
  .card.card-image-sm .card-img::before,
  .card.card-image-xl .card-img::before {
    content: "";
    display: block;
  }
  .card.card-image-sm .card-img::before {
    padding-top: 50%;
  }
  .card.card-image-md .card-img::before {
    padding-top: 65%;
  }
  .card.card-image-lg .card-img::before {
    padding-top: 100%;
  }
  .card.card-image-xl .card-img::before {
    padding-top: 130%;
  }
  .card:focus .background-color,
  .card:hover .background-color {
    opacity: 1;
  }
  .card-hover-zoom .card-img {
    display: block;
    overflow: hidden;
  }
  .card-hover-zoom img {
    will-change: transform;
    -webkit-transition: -webkit-transform 1.8s cubic-bezier(0.2, 1, 0.2, 1);
    transition: -webkit-transform 1.8s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: transform 1.8s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 1.8s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 1.8s cubic-bezier(0.2, 1, 0.2, 1),
      -webkit-transform 1.8s cubic-bezier(0.2, 1, 0.2, 1);
  }
  .card-hover-zoom .card-img:focus img,
  .card-hover-zoom .card-img:hover img,
  .card-hover-zoom:focus img,
  .card-hover-zoom:hover img {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }
  .card-portfolio {
    position: relative;
  }
  .card-portfolio .card-img {
    display: block;
  }
  .card-portfolio .card-logo {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-bottom: auto;
  }
  .card-portfolio .card-title {
    position: relative;
    display: block;
    margin-bottom: 0;
  }
  .card-portfolio a.card-title {
    -webkit-transition: color 0.2s ease-in-out;
    -o-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
  }
  .card-portfolio a.card-title:focus,
  .card-portfolio a.card-title:hover {
    color: #000;
  }
  .card-portfolio .card-category {
    margin-top: 5px;
    margin-bottom: 0;
    font-weight: 500;
  }
  .card-portfolio .card-title-decoration {
    content: "";
    position: absolute;
    top: -36px;
    left: 0;
    width: 70px;
    border-top: 1px solid currentColor;
    will-change: width;
  }
  .card-portfolio .card-img-overlay {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    z-index: 1;
  }
  @media (min-width: 1024px) {
    .card-portfolio .card-img-overlay {
      padding: 50px 70px;
    }
  }
  @media (max-width: 1023.98px) {
    .card-portfolio .card-img-overlay {
      padding: 30px 50px;
    }
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .card-portfolio .card-img-overlay .card-logo {
      position: absolute;
      top: 50px;
      left: 70px;
    }
    .card-portfolio .card-img-overlay .card-logo ~ .card-title {
      margin-top: auto;
    }
    .card-portfolio .card-img-overlay .card-logo ~ .card-category {
      margin-bottom: auto;
    }
  }
  .card-portfolio .card-body {
    display: block;
    padding: 0;
  }
  .card-portfolio.card-portfolio-banner .card-img {
    min-height: 420px;
  }
  .card-portfolio.card-portfolio-banner .card-img-overlay {
    width: 910px;
  }
  @media (min-width: 620px) and (max-width: 827.98px) {
    .card-portfolio.card-portfolio-banner .h1 {
      font-size: 2.5em;
    }
    .card-portfolio.card-portfolio-banner .card-img-overlay {
      padding: 50px;
    }
  }
  @media (max-width: 619.98px) {
    .card-portfolio.card-portfolio-banner .h1 {
      font-size: 2.375em;
    }
    .card-portfolio.card-portfolio-banner .card-img-overlay {
      padding: 30px;
    }
  }
  .card-portfolio.card-default .card-title {
    margin-bottom: 0;
  }
  .card-portfolio.card-default .card-subtitle {
    display: block;
  }
  .card-portfolio.card-default .card-body {
    padding: 34px 0 0;
  }
  .card-portfolio.card-default:focus,
  .card-portfolio.card-default:hover {
    color: #040404;
  }
  .card-portfolio.card-overlay {
    overflow: hidden;
  }
  @media (max-width: 827.98px) {
    .card-portfolio.card-overlay .card-img {
      min-height: 300px;
    }
  }
  .card-portfolio.card-horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent;
  }
  @media (min-width: 828px) {
    .card-portfolio.card-horizontal.card-swap .card-img {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12;
      margin-right: 0;
      margin-left: 100px;
    }
    .card-portfolio.card-horizontal.card-swap .card-category {
      padding-left: calc(35% + 100px);
    }
  }
  .card-portfolio.card-horizontal .card-category {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 23px;
  }
  .card-portfolio.card-horizontal .card-category:focus,
  .card-portfolio.card-horizontal .card-category:hover {
    color: inherit;
  }
  .card-portfolio.card-horizontal .card-title {
    margin-bottom: 24px;
  }
  @media (min-width: 828px) {
    .card-portfolio.card-horizontal .card-img {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 calc(65% - 100px);
      flex: 0 0 calc(65% - 100px);
      max-width: calc(65% - 100px);
      margin-right: 100px;
    }
  }
  @media (max-width: 827.98px) {
    .card-portfolio.card-horizontal .card-img {
      margin-bottom: 50px;
    }
  }
  @media (min-width: 828px) {
    .card-portfolio.card-horizontal .card-body {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 35%;
      flex: 0 0 35%;
      max-width: 35%;
    }
    .card-portfolio.card-horizontal .card-body ~ .card-img {
      margin-left: 100px;
    }
  }
  .card-portfolio.card-horizontal .btn {
    margin-top: 35px;
  }
  .card-portfolio.text-white .nav .nav-link,
  .card-portfolio.text-white .nav .nav-link.active {
    color: #fff;
  }
  .card-portfolio.text-white a.card-title:focus,
  .card-portfolio.text-white a.card-title:hover {
    color: inherit;
  }
  .card-portfolio.text-white a.card-category {
    color: #fff;
  }
  .card-portfolio.card-hover-frame .card-img img {
    width: 100%;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    will-change: clip-path;
    -webkit-transition: -webkit-clip-path 0.2s ease-in-out;
    transition: -webkit-clip-path 0.2s ease-in-out;
    -o-transition: clip-path 0.2s ease-in-out;
    transition: clip-path 0.2s ease-in-out;
    transition: clip-path 0.2s ease-in-out, -webkit-clip-path 0.2s ease-in-out;
  }
  .card-portfolio.card-hover-frame .card-img:focus img,
  .card-portfolio.card-hover-frame .card-img:hover img {
    -webkit-clip-path: polygon(
      10px 10px,
      calc(100% - 10px) 10px,
      calc(100% - 10px) calc(100% - 10px),
      10px calc(100% - 10px)
    );
    clip-path: polygon(
      10px 10px,
      calc(100% - 10px) 10px,
      calc(100% - 10px) calc(100% - 10px),
      10px calc(100% - 10px)
    );
  }
  .card-portfolio.card-hover-frame:focus .card-img img,
  .card-portfolio.card-hover-frame:hover .card-img img {
    -webkit-clip-path: polygon(
      10px 10px,
      calc(100% - 10px) 10px,
      calc(100% - 10px) calc(100% - 10px),
      10px calc(100% - 10px)
    );
    clip-path: polygon(
      10px 10px,
      calc(100% - 10px) 10px,
      calc(100% - 10px) calc(100% - 10px),
      10px calc(100% - 10px)
    );
  }
  .card-portfolio.card-hover-appearance {
    overflow: hidden;
  }
  .card-portfolio.card-hover-appearance .card-img img {
    will-change: transform;
    -webkit-transition: -webkit-transform 0.25s ease-in-out;
    transition: -webkit-transform 0.25s ease-in-out;
    -o-transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  .card-portfolio.card-hover-appearance .card-img-overlay {
    opacity: 0;
    will-change: opacity;
    -webkit-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
  }
  .card-portfolio.card-hover-appearance .card-category,
  .card-portfolio.card-hover-appearance .card-title {
    opacity: 0;
    will-change: transform, opacity;
    -webkit-transition: opacity 0.2s ease-in-out,
      -webkit-transform 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    -o-transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out,
      -webkit-transform 0.2s ease-in-out;
    -webkit-transition-delay: 0.05s;
    -o-transition-delay: 0.05s;
    transition-delay: 0.05s;
  }
  .card-portfolio.card-hover-appearance .card-title {
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px);
  }
  .card-portfolio.card-hover-appearance .card-category {
    -webkit-transform: translateY(18px);
    -ms-transform: translateY(18px);
    transform: translateY(18px);
  }
  .card-portfolio.card-hover-appearance:focus .card-img-overlay,
  .card-portfolio.card-hover-appearance:hover .card-img-overlay {
    opacity: 1;
  }
  .card-portfolio.card-hover-appearance:focus .card-img img,
  .card-portfolio.card-hover-appearance:hover .card-img img {
    -webkit-transform: scale(1.04);
    -ms-transform: scale(1.04);
    transform: scale(1.04);
  }
  .card-portfolio.card-hover-appearance:focus .card-category,
  .card-portfolio.card-hover-appearance:focus .card-title,
  .card-portfolio.card-hover-appearance:hover .card-category,
  .card-portfolio.card-hover-appearance:hover .card-title {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .card-portfolio.card-hover-bar {
    overflow: hidden;
  }
  .card-portfolio.card-hover-bar .card-img img {
    will-change: transform;
    -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.2, 1, 0.2, 1);
    transition: -webkit-transform 0.7s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: transform 0.7s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.2, 1, 0.2, 1),
      -webkit-transform 0.7s cubic-bezier(0.2, 1, 0.2, 1);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .card-portfolio.card-hover-bar .card-category,
  .card-portfolio.card-hover-bar .card-title {
    opacity: 0;
    will-change: transform, opacity;
    -webkit-transition: opacity 0.25s ease-in-out,
      -webkit-transform 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
    -o-transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out,
      -webkit-transform 0.25s ease-in-out;
  }
  .card-portfolio.card-hover-bar .card-title {
    -webkit-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
    -webkit-transform: translateY(5px);
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }
  .card-portfolio.card-hover-bar .card-category {
    -webkit-transition-delay: 0.15s;
    -o-transition-delay: 0.15s;
    transition-delay: 0.15s;
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px);
  }
  .card-portfolio.card-hover-bar .card-img-overlay {
    top: auto;
    padding: 30px 50px;
    background-color: inherit;
    will-change: transform;
    -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.2, 1, 0.2, 1);
    transition: -webkit-transform 0.7s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: transform 0.7s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.2, 1, 0.2, 1),
      -webkit-transform 0.7s cubic-bezier(0.2, 1, 0.2, 1);
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }
  .card-portfolio.card-hover-bar:focus .card-img-overlay,
  .card-portfolio.card-hover-bar:hover .card-img-overlay {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .card-portfolio.card-hover-bar:focus .card-img img,
  .card-portfolio.card-hover-bar:hover .card-img img {
    -webkit-transform: translateY(-25px);
    -ms-transform: translateY(-25px);
    transform: translateY(-25px);
  }
  .card-portfolio.card-hover-bar:focus .card-category,
  .card-portfolio.card-hover-bar:focus .card-title,
  .card-portfolio.card-hover-bar:hover .card-category,
  .card-portfolio.card-hover-bar:hover .card-title {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .card-service {
    overflow: hidden;
  }
  .card-service .card-img-overlay {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 50px;
    z-index: 1;
  }
  .card-service .card-icon {
    margin-bottom: 24px;
  }
  .card-service .card-title {
    margin-bottom: 0;
  }
  .card-service .btn {
    margin-top: 22px;
  }
  .card-service.card-hover-zoom .card-icon {
    will-change: transform;
    -webkit-transition: -webkit-transform 0.65s cubic-bezier(0.2, 1, 0.2, 1);
    transition: -webkit-transform 0.65s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: transform 0.65s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.65s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.65s cubic-bezier(0.2, 1, 0.2, 1),
      -webkit-transform 0.65s cubic-bezier(0.2, 1, 0.2, 1);
    -webkit-transform: translateY(48px);
    -ms-transform: translateY(48px);
    transform: translateY(48px);
  }
  .card-service.card-hover-zoom .card-title {
    will-change: transform;
    -webkit-transition: -webkit-transform 0.65s cubic-bezier(0.2, 1, 0.2, 1);
    transition: -webkit-transform 0.65s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: transform 0.65s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.65s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.65s cubic-bezier(0.2, 1, 0.2, 1),
      -webkit-transform 0.65s cubic-bezier(0.2, 1, 0.2, 1);
    -webkit-transform: translateY(48px);
    -ms-transform: translateY(48px);
    transform: translateY(48px);
  }
  .card-service.card-hover-zoom .btn {
    opacity: 0;
    will-change: transform, opacity;
    -webkit-transition: opacity 0.3s ease-in-out,
      -webkit-transform 0.65s cubic-bezier(0.2, 1, 0.2, 1);
    transition: opacity 0.3s ease-in-out,
      -webkit-transform 0.65s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: transform 0.65s cubic-bezier(0.2, 1, 0.2, 1),
      opacity 0.3s ease-in-out;
    transition: transform 0.65s cubic-bezier(0.2, 1, 0.2, 1),
      opacity 0.3s ease-in-out;
    transition: transform 0.65s cubic-bezier(0.2, 1, 0.2, 1),
      opacity 0.3s ease-in-out,
      -webkit-transform 0.65s cubic-bezier(0.2, 1, 0.2, 1);
    -webkit-transform: translateY(48px);
    -ms-transform: translateY(48px);
    transform: translateY(48px);
  }
  .card-service.card-hover-zoom:focus .btn,
  .card-service.card-hover-zoom:focus .card-icon,
  .card-service.card-hover-zoom:focus .card-title,
  .card-service.card-hover-zoom:hover .btn,
  .card-service.card-hover-zoom:hover .card-icon,
  .card-service.card-hover-zoom:hover .card-title {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .card-service.card-hover-zoom:focus .btn,
  .card-service.card-hover-zoom:hover .btn {
    opacity: 1;
  }
  .card-blog {
    position: relative;
    color: #0e0e0e;
    border: 0;
  }
  .card-blog.card-vertical .card-img::before {
    padding-top: 80%;
  }
  .card-blog.card-vertical .card-title {
    margin-top: 27px;
  }
  .card-blog.card-vertical .card-date {
    position: absolute;
    top: 0;
    left: 0;
    padding: 11px 16px;
    font-size: 0.875em;
    color: #0e0e0e;
    background-color: #fff;
  }
  .card-blog.card-horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .card-blog.card-horizontal + .card-horizontal {
    margin-top: 30px;
  }
  .card-blog.card-horizontal.card-sm .card-date {
    display: block;
    margin-bottom: 18px;
    font-size: 0.875em;
  }
  @media (min-width: 620px) {
    .card-blog.card-horizontal.card-sm .card-img {
      width: 170px;
      margin-right: 40px;
    }
    .card-blog.card-horizontal.card-sm .card-img::before {
      padding-top: 100%;
    }
  }
  @media (max-width: 619.98px) {
    .card-blog.card-horizontal.card-sm {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .card-blog.card-horizontal.card-sm + .card-horizontal {
      margin-top: 60px;
    }
    .card-blog.card-horizontal.card-sm .card-img {
      margin-bottom: 25px;
    }
    .card-blog.card-horizontal.card-sm .card-img::before {
      padding-top: 64%;
    }
    .card-blog.card-horizontal.card-sm .card-body {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      width: 100%;
    }
  }
  .card-blog.card-horizontal.card-md {
    width: 100%;
  }
  .card-blog.card-horizontal.card-md .card-img::before {
    padding-top: 64%;
  }
  @media (min-width: 1200px) {
    .card-blog.card-horizontal.card-md .card-img {
      width: 500px;
      margin-right: 70px;
    }
  }
  @media (min-width: 1024px) and (max-width: 1199.98px) {
    .card-blog.card-horizontal.card-md .card-img {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .card-blog.card-horizontal.card-md .card-body {
      margin-left: 70px;
    }
  }
  @media (min-width: 828px) and (max-width: 1023.98px) {
    .card-blog.card-horizontal.card-md .card-img {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 45%;
      flex: 0 0 45%;
      max-width: 45%;
    }
    .card-blog.card-horizontal.card-md .card-body {
      margin-left: 50px;
    }
  }
  @media (max-width: 827.98px) {
    .card-blog.card-horizontal.card-md {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .card-blog.card-horizontal.card-md + .card-horizontal {
      margin-top: 60px;
    }
    .card-blog.card-horizontal.card-md .card-img {
      margin-bottom: 25px;
    }
  }
  .card-blog.card-horizontal.card-md .card-date {
    position: absolute;
    top: 0;
    left: 0;
    padding: 11px 16px;
    font-size: 0.875em;
    color: #0e0e0e;
    background-color: #fff;
  }
  .card-blog.card-overlay.card-image-sm .card-img {
    min-height: 400px;
  }
  .card-blog .card-img {
    position: relative;
    display: block;
    overflow: hidden;
  }
  .card-blog .card-img::before {
    content: "";
    display: block;
  }
  .card-blog .card-img img {
    will-change: transform;
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    -o-transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  .card-blog .card-title {
    display: block;
    margin-bottom: 0;
  }
  .card-blog .card-text {
    margin-top: 20px;
    margin-bottom: 0;
  }
  .card-blog .card-body {
    padding: 0;
  }
  .card-blog .btn {
    margin-top: 18px;
  }
  .card-blog:focus,
  .card-blog:hover {
    color: #0e0e0e;
  }
  .card-blog:focus .card-img img,
  .card-blog:hover .card-img img {
    -webkit-transform: scale(1.03);
    -ms-transform: scale(1.03);
    transform: scale(1.03);
  }
  .card-fullscreen {
    height: 100%;
  }
  .card-fullscreen ~ .card-social-container {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }
  .card-fullscreen ~ .card-social-container .nav {
    padding: 20px 0;
    margin-right: -20px;
    pointer-events: initial;
  }
  .card-fullscreen ~ .card-social-container .nav a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-right: 20px;
    padding-left: 20px;
  }
  .card-fullscreen .card-img-overlay {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  @media (min-width: 828px) {
    .card-fullscreen .card-img-overlay {
      padding: 0;
    }
  }
  .card-fullscreen .card-title {
    position: relative;
    padding-right: 100px;
  }
  @media (min-width: 828px) {
    .card-fullscreen .card-title {
      padding-left: 200px;
    }
  }
  @media (max-width: 827.98px) {
    .card-fullscreen .card-title {
      font-size: 3.125em;
    }
  }
  .card-fullscreen .card-title-decoration {
    position: absolute;
    left: 0;
    width: 70px;
    border-top: 1px solid currentColor;
    will-change: width;
  }
  @media (min-width: 828px) {
    .card-fullscreen .card-title-decoration {
      top: 0.6em;
    }
  }
  @media (max-width: 827.98px) {
    .card-fullscreen .card-title-decoration {
      top: -40px;
    }
  }
  .card-fullscreen .card-img {
    height: 100%;
  }
  .card-fullscreen .btn {
    position: absolute;
    bottom: 50px;
  }
  @media (min-width: 828px) {
    .card-fullscreen .btn {
      left: 0;
    }
  }
  @media (max-width: 827.98px) {
    .card-fullscreen .btn {
      left: 30px;
    }
  }
  .card-person .card-img {
    margin-bottom: -45px;
  }
  .card-person .card-body {
    padding: 32px 0 0;
    background-color: inherit;
    will-change: transform;
    -webkit-transition: -webkit-transform 0.65s cubic-bezier(0.2, 1, 0.2, 1);
    transition: -webkit-transform 0.65s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: transform 0.65s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.65s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.65s cubic-bezier(0.2, 1, 0.2, 1),
      -webkit-transform 0.65s cubic-bezier(0.2, 1, 0.2, 1);
    -webkit-transform: translateY(45px);
    -ms-transform: translateY(45px);
    transform: translateY(45px);
  }
  .card-person .card-title {
    display: block;
    margin-bottom: 10px;
  }
  .card-person .card-social {
    margin-top: 30px;
    opacity: 0;
    will-change: opacity;
    -webkit-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
  }
  .card-person .card-social li {
    will-change: opacity;
  }
  .card-person:focus .card-body,
  .card-person:hover .card-body {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .card-person:focus .card-social,
  .card-person:hover .card-social {
    opacity: 1;
  }
  .card-demo .card-img {
    position: relative;
    border: 1px solid #e2e2e2;
    border-radius: 3px 3px 0 0;
    will-change: transform;
    -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.2, 1, 0.2, 1);
    transition: -webkit-transform 0.7s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: transform 0.7s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.2, 1, 0.2, 1),
      -webkit-transform 0.7s cubic-bezier(0.2, 1, 0.2, 1);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .card-demo .card-img img {
    width: 100%;
  }
  .card-demo .card-img::before {
    content: "";
    display: block;
    width: 100%;
    height: 14px;
    background-color: #f1f1f1;
  }
  .card-demo .card-img::after {
    content: "";
    position: absolute;
    top: 5px;
    left: 8px;
    width: 4px;
    height: 4px;
    color: #cecece;
    background-color: #cecece;
    border-radius: 2px;
    -webkit-box-shadow: 7px 0 0 currentColor, 14px 0 0 currentColor;
    box-shadow: 7px 0 0 currentColor, 14px 0 0 currentColor;
  }
  .card-demo .card-body {
    padding: 0;
  }
  .card-demo .card-title {
    display: block;
    margin-top: 28px;
    margin-bottom: -7px;
  }
  .card-demo.text-white .card-img {
    border-color: #242424;
  }
  .card-demo.text-white .card-img::before {
    background-color: #202020;
  }
  .card-demo.text-white .card-img::after {
    color: #3e3e3e;
    background-color: #3e3e3e;
  }
  .card-demo:focus .card-img,
  .card-demo:hover .card-img {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  .table {
    font-weight: 500;
  }
  .table-underline td,
  .table-underline th {
    border-top: 0;
    border-bottom: 1px solid #ddd;
    -webkit-transition: border-color 0.15s;
    -o-transition: border-color 0.15s;
    transition: border-color 0.15s;
  }
  .table-underline tr:first-child td,
  .table-underline tr:first-child th {
    padding-top: 0;
    border-top: 0;
  }
  .table-underline.table-hover tr:hover td,
  .table-underline.table-hover tr:hover th {
    border-bottom-color: #0e0e0e;
  }
  .table-dark td,
  .table-dark th {
    border-color: #202020;
  }
  .table-dark.table-hover tr:hover td,
  .table-dark.table-hover tr:hover th {
    border-bottom-color: #fff;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      .table-projects table {
        display: block;
      }
      .table-projects tbody {
        display: block;
      }
      .table-projects tr {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
    }
  }
  .table-projects .table-projects-brand {
    width: 200px;
    min-width: 200px;
    -webkit-transition: color 0.15s, border-color 0.15s;
    -o-transition: color 0.15s, border-color 0.15s;
    transition: color 0.15s, border-color 0.15s;
  }
  .table-projects .table-projects-title {
    width: 100%;
    min-width: 200px;
  }
  .table-projects .table-projects-year {
    width: 130px;
    min-width: 130px;
  }
  .table-projects .table-projects-name {
    width: 100%;
    min-width: 200px;
  }
  .table-projects .table-projects-icon {
    width: auto;
  }
  .table-projects.table-hover tr:hover .table-projects-brand {
    color: #0e0e0e !important;
  }
  .table-projects.table-hover.table-dark tr:hover .table-projects-brand {
    color: #fff !important;
  }
  .swiper {
    position: relative;
  }
  .swiper .swiper-button-next,
  .swiper .swiper-button-prev {
    color: #0e0e0e;
  }
  .swiper .swiper-button-next:focus,
  .swiper .swiper-button-prev:focus {
    outline: 0;
  }
  .swiper.text-white .swiper-button-next,
  .swiper.text-white .swiper-button-prev,
  .swiper.text-white .swiper-pagination {
    color: #fff;
  }
  .swiper .card .background-color {
    opacity: 1;
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-image: none;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    display: none;
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }
  @media (max-width: 827.98px) {
    .swiper-button-style-1 .swiper-button-next,
    .swiper-button-style-1 .swiper-button-prev {
      top: auto;
      bottom: 0;
    }
  }
  .swiper-button-style-3 .swiper-button-next {
    top: auto;
    bottom: 0;
  }
  .swiper-button-style-3 .swiper-button-prev {
    top: auto;
    right: 60px;
    bottom: 0;
    left: auto;
  }
  .swiper-pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 0.875em;
    font-weight: 500;
    color: #0e0e0e;
    pointer-events: none;
  }
  .swiper-pagination::before {
    content: "";
    display: block;
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
    width: 20px;
    margin-left: 15px;
    border-top: 1px solid currentColor;
  }
  .swiper-pagination::after {
    content: attr(data-swiper-total);
    display: block;
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
    margin-left: 15px;
  }
  .swiper-pagination-bullet {
    width: auto;
    height: auto;
    background-color: transparent;
    will-change: transform, opacity;
    -webkit-transition: opacity 0.3s ease, -webkit-transform 0.35s ease;
    transition: opacity 0.3s ease, -webkit-transform 0.35s ease;
    -o-transition: transform 0.35s ease, opacity 0.3s ease;
    transition: transform 0.35s ease, opacity 0.3s ease;
    transition: transform 0.35s ease, opacity 0.3s ease,
      -webkit-transform 0.35s ease;
  }
  .swiper-pagination-bullet:not(:first-child) {
    position: absolute;
    top: 0;
    left: 0;
  }
  .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  .swiper-pagination-bullet-active ~ .swiper-pagination-bullet {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }
  .swiper-container-horizontal > .swiper-scrollbar {
    position: static;
    width: 370px;
    max-width: 100%;
    height: 2px;
    margin: 85px auto 0;
    border-radius: 0;
  }
  .swiper-scrollbar-drag {
    background-color: #0e0e0e;
    border-radius: 0;
  }
  .swiper-dark .swiper-scrollbar-drag {
    background-color: #fff;
  }
  .swiper-dark .swiper-scrollbar {
    background-color: rgba(255, 255, 255, 0.1);
  }
  [data-parallax] .swiper-slide {
    overflow-x: hidden;
  }
  .swiper-portfolio {
    position: relative;
  }
  @media (min-width: 1024px) {
    .swiper-portfolio .swiper-button-next,
    .swiper-portfolio .swiper-button-prev {
      -webkit-transform: translateY(-50px);
      -ms-transform: translateY(-50px);
      transform: translateY(-50px);
    }
  }
  @media screen and (min-width: 1300px) {
    .swiper-portfolio .swiper-button-prev {
      left: -60px;
    }
    .swiper-portfolio .swiper-button-next {
      right: -60px;
    }
  }
  @media (max-width: 1023.98px) {
    .swiper-portfolio .swiper-button-next,
    .swiper-portfolio .swiper-button-prev {
      top: auto;
      bottom: 32px;
      margin-top: 0;
    }
    .swiper-portfolio .swiper-button-prev {
      right: 80px;
      left: auto;
    }
    .swiper-portfolio .swiper-button-next {
      right: 50px;
    }
  }
  @media (max-width: 619.98px) {
    .swiper-portfolio.swiper-portfolio-banner.container {
      padding-right: 0;
      padding-left: 0;
    }
    .swiper-portfolio.swiper-portfolio-banner .swiper-button-next,
    .swiper-portfolio.swiper-portfolio-banner .swiper-button-prev {
      bottom: 12px;
    }
    .swiper-portfolio.swiper-portfolio-banner .swiper-button-prev {
      right: 42px;
    }
    .swiper-portfolio.swiper-portfolio-banner .swiper-button-next {
      right: 12px;
    }
    .swiper-portfolio.swiper-portfolio-banner .swiper-pagination {
      bottom: 30px;
    }
  }
  @media (min-width: 828px) and (max-width: 1023.98px) {
    .swiper-portfolio.swiper-portfolio-banner .swiper-pagination {
      margin-right: 50px;
      margin-left: 50px;
    }
  }
  .swiper-portfolio.swiper-portfolio-banner .card-img-overlay {
    opacity: 0;
    will-change: opacity;
    -webkit-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
    -webkit-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
    transition-delay: 0.4s;
  }
  .swiper-portfolio.swiper-portfolio-banner .card-title {
    -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.2, 1, 0.2, 1);
    transition: -webkit-transform 0.8s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: transform 0.8s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.8s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.8s cubic-bezier(0.2, 1, 0.2, 1),
      -webkit-transform 0.8s cubic-bezier(0.2, 1, 0.2, 1);
    -webkit-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
    transition-delay: 0.4s;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
  }
  .swiper-portfolio.swiper-portfolio-banner .card-category {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.2, 1, 0.2, 1);
    transition: -webkit-transform 1s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: transform 1s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 1s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 1s cubic-bezier(0.2, 1, 0.2, 1),
      -webkit-transform 1s cubic-bezier(0.2, 1, 0.2, 1);
    -webkit-transition-delay: 0.75s;
    -o-transition-delay: 0.75s;
    transition-delay: 0.75s;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
  .swiper-portfolio.swiper-portfolio-banner .card-title-decoration {
    width: 0;
    -webkit-transition: width 0.5s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: width 0.5s cubic-bezier(0.2, 1, 0.2, 1);
    transition: width 0.5s cubic-bezier(0.2, 1, 0.2, 1);
    -webkit-transition-delay: 0.55s;
    -o-transition-delay: 0.55s;
    transition-delay: 0.55s;
  }
  .swiper-portfolio.swiper-portfolio-banner .swiper-slide-active .card-title,
  .swiper-portfolio.swiper-portfolio-banner
    .swiper-slide-duplicate-active
    .card-title {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .swiper-portfolio.swiper-portfolio-banner
    .swiper-slide-active
    .card-title-decoration,
  .swiper-portfolio.swiper-portfolio-banner
    .swiper-slide-duplicate-active
    .card-title-decoration {
    width: 70px;
  }
  .swiper-portfolio.swiper-portfolio-banner .swiper-slide-active .card-category,
  .swiper-portfolio.swiper-portfolio-banner
    .swiper-slide-duplicate-active
    .card-category {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .swiper-portfolio.swiper-portfolio-banner
    .swiper-slide-active
    .card-img-overlay,
  .swiper-portfolio.swiper-portfolio-banner
    .swiper-slide-duplicate-active
    .card-img-overlay {
    opacity: 1;
  }
  .swiper-portfolio .swiper-pagination {
    bottom: 50px;
    max-width: 100%;
    text-align: left;
  }
  @media (min-width: 1024px) {
    .swiper-portfolio .swiper-pagination {
      left: 50%;
      width: 770px;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
  @media (min-width: 828px) and (max-width: 1023.98px) {
    .swiper-portfolio .swiper-pagination {
      margin-right: 70px;
      margin-left: 70px;
    }
  }
  @media (min-width: 620px) and (max-width: 827.98px) {
    .swiper-portfolio .swiper-pagination {
      margin-right: 50px;
      margin-left: 50px;
    }
  }
  @media (max-width: 619.98px) {
    .swiper-portfolio .swiper-pagination {
      margin-right: 30px;
      margin-left: 30px;
    }
  }
  .swiper-reviews .swiper-slide {
    padding-bottom: 34px;
    opacity: 0;
    will-change: opacity;
    -webkit-transition: opacity 0.4s ease-in-out;
    -o-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;
    -webkit-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
  .swiper-reviews .swiper-slide-active {
    opacity: 1;
  }
  .swiper-reviews .review {
    width: 700px;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .swiper-reviews .swiper-pagination {
    bottom: -105px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .swiper-review-2 {
    padding-bottom: 84px;
  }
  .swiper-review-2 .swiper-button-next,
  .swiper-review-2 .swiper-button-prev {
    top: auto;
    bottom: 0;
    width: 32px;
    height: 32px;
    color: inherit;
  }
  .swiper-review-2 .swiper-button-next::after,
  .swiper-review-2 .swiper-button-prev::after {
    display: none;
  }
  .swiper-review-2 .swiper-button-prev {
    left: 0;
  }
  .swiper-review-2 .swiper-button-next {
    right: auto;
    left: 46px;
  }
  .swiper-review-2 .swiper-slide {
    opacity: 0;
    will-change: opacity;
    -webkit-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    -webkit-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }
  .swiper-review-2 .swiper-slide.swiper-slide-active {
    opacity: 1;
  }
  .swiper-fullscreen-horizontal .swiper-pagination-wrapper,
  .swiper-fullscreen-vertical .swiper-pagination-wrapper {
    pointer-events: none;
  }
  .swiper-full-horizontal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .swiper-full-horizontal.swiper-full {
    min-height: 100vh;
    overflow: hidden;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .swiper-full-horizontal.swiper-full {
      height: 100vh;
    }
    .swiper-full-horizontal.swiper-full .swiper-container {
      margin-top: 0;
    }
  }
  .swiper-full-horizontal.swiper-full .swiper-pagination {
    bottom: 50px;
  }
  .swiper-full-horizontal.swiper-full .swiper-button-wrapper {
    position: absolute;
    bottom: 32px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .swiper-full-horizontal:not(.swiper-full) .swiper-pagination {
    position: relative;
    bottom: auto;
    left: auto;
    width: auto;
    margin-top: 70px;
  }
  .swiper-full-horizontal:not(.swiper-full) .swiper-button-wrapper {
    position: relative;
    z-index: 1;
  }
  .swiper-full-horizontal:not(.swiper-full)
    .swiper-button-wrapper
    + .swiper-pagination {
    margin-top: -35px;
    margin-bottom: 70px;
  }
  .swiper-full-horizontal .swiper-container {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }
  @media (min-width: 1024px) {
    .swiper-full-horizontal .swiper-slide {
      width: 770px;
    }
  }
  @media (min-width: 828px) and (max-width: 1023.98px) {
    .swiper-full-horizontal .swiper-slide {
      width: 600px;
    }
  }
  .swiper-full-horizontal .swiper-button-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .swiper-full-horizontal .swiper-button-wrapper .swiper-button-next,
  .swiper-full-horizontal .swiper-button-wrapper .swiper-button-prev {
    position: static;
    margin-top: 0;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .swiper-full-horizontal .card-title {
    opacity: 0;
    -webkit-transition: opacity 0.4s,
      -webkit-transform 0.8s cubic-bezier(0.2, 1, 0.2, 1);
    transition: opacity 0.4s, -webkit-transform 0.8s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: transform 0.8s cubic-bezier(0.2, 1, 0.2, 1), opacity 0.4s;
    transition: transform 0.8s cubic-bezier(0.2, 1, 0.2, 1), opacity 0.4s;
    transition: transform 0.8s cubic-bezier(0.2, 1, 0.2, 1), opacity 0.4s,
      -webkit-transform 0.8s cubic-bezier(0.2, 1, 0.2, 1);
    -webkit-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
    transition-delay: 0.4s;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
  }
  .swiper-full-horizontal .card-category {
    opacity: 0;
    -webkit-transition: opacity 0.4s,
      -webkit-transform 0.8s cubic-bezier(0.2, 1, 0.2, 1);
    transition: opacity 0.4s, -webkit-transform 0.8s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: transform 0.8s cubic-bezier(0.2, 1, 0.2, 1), opacity 0.4s;
    transition: transform 0.8s cubic-bezier(0.2, 1, 0.2, 1), opacity 0.4s;
    transition: transform 0.8s cubic-bezier(0.2, 1, 0.2, 1), opacity 0.4s,
      -webkit-transform 0.8s cubic-bezier(0.2, 1, 0.2, 1);
    -webkit-transition-delay: 0.55s;
    -o-transition-delay: 0.55s;
    transition-delay: 0.55s;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
  .swiper-full-horizontal .swiper-slide-active .card-category,
  .swiper-full-horizontal .swiper-slide-active .card-title,
  .swiper-full-horizontal .swiper-slide-duplicate-active .card-category,
  .swiper-full-horizontal .swiper-slide-duplicate-active .card-title {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .swiper-fullscreen-vertical {
    width: 100%;
    height: 100vh;
  }
  .swiper-fullscreen-vertical .card-overlay {
    height: 100%;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    -webkit-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }
  .swiper-fullscreen-vertical .card-overlay .card-img {
    height: 100%;
  }
  .swiper-fullscreen-vertical .swiper-slide-active .card-overlay {
    opacity: 1;
  }
  .swiper-fullscreen-vertical .swiper-slide-active {
    z-index: 100;
  }
  .swiper-fullscreen-vertical .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-fullscreen-vertical .swiper-pagination-wrapper {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    bottom: 50px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 1;
  }
  .swiper-fullscreen-vertical .swiper-pagination {
    position: relative;
    bottom: auto;
    left: auto;
    width: auto;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .swiper-fullscreen-vertical .card-img-overlay {
    opacity: 0;
    will-change: opacity;
    -webkit-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
    -webkit-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
    transition-delay: 0.4s;
  }
  .swiper-fullscreen-vertical .card-title {
    -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.2, 1, 0.2, 1);
    transition: -webkit-transform 0.8s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: transform 0.8s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.8s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.8s cubic-bezier(0.2, 1, 0.2, 1),
      -webkit-transform 0.8s cubic-bezier(0.2, 1, 0.2, 1);
    -webkit-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
    transition-delay: 0.4s;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
  }
  .swiper-fullscreen-vertical .card-title-decoration {
    width: 0;
    -webkit-transition: width 0.5s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: width 0.5s cubic-bezier(0.2, 1, 0.2, 1);
    transition: width 0.5s cubic-bezier(0.2, 1, 0.2, 1);
    -webkit-transition-delay: 0.55s;
    -o-transition-delay: 0.55s;
    transition-delay: 0.55s;
  }
  .swiper-fullscreen-vertical .btn {
    opacity: 0;
    -webkit-transition: opacity 0.4s,
      -webkit-transform 0.5s cubic-bezier(0.2, 1, 0.2, 1);
    transition: opacity 0.4s, -webkit-transform 0.5s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: transform 0.5s cubic-bezier(0.2, 1, 0.2, 1), opacity 0.4s;
    transition: transform 0.5s cubic-bezier(0.2, 1, 0.2, 1), opacity 0.4s;
    transition: transform 0.5s cubic-bezier(0.2, 1, 0.2, 1), opacity 0.4s,
      -webkit-transform 0.5s cubic-bezier(0.2, 1, 0.2, 1);
    -webkit-transition-delay: 0.7s;
    -o-transition-delay: 0.7s;
    transition-delay: 0.7s;
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px);
  }
  .swiper-fullscreen-vertical .swiper-slide-active .card-img-overlay,
  .swiper-fullscreen-vertical .swiper-slide-duplicate-active .card-img-overlay {
    opacity: 1;
  }
  .swiper-fullscreen-vertical .swiper-slide-active .card-title,
  .swiper-fullscreen-vertical .swiper-slide-duplicate-active .card-title {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .swiper-fullscreen-vertical .swiper-slide-active .card-title-decoration,
  .swiper-fullscreen-vertical
    .swiper-slide-duplicate-active
    .card-title-decoration {
    width: 70px;
  }
  .swiper-fullscreen-vertical .swiper-slide-active .btn,
  .swiper-fullscreen-vertical .swiper-slide-duplicate-active .btn {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .isotope-options {
    position: relative;
    padding-top: 30px;
  }
  .isotope-options::before {
    content: "";
    position: absolute;
    top: 0;
    right: 15px;
    left: 15px;
    border-top: 1px solid currentColor;
  }
  body.compensate-for-scrollbar {
    margin-right: 0;
  }
  .fancybox-bg {
    background-color: #0e0e0e;
  }
  .fancybox-is-open .fancybox-bg {
    opacity: 0.2;
  }
  .fancybox-navbar .fancybox-slide {
    padding: 0;
  }
  .countdown {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  @media (max-width: 1023.98px) {
    .countdown {
      margin-top: -30px;
    }
  }
  .countdown-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .countdown-item > span {
    display: block;
  }
  .countdown-item > span:nth-child(2) {
    font-size: 16px;
  }
  @media (max-width: 1023.98px) {
    .countdown-item {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
      padding-top: 30px;
      padding-right: 30px;
      padding-left: 30px;
    }
  }
  .countdown-separator {
    margin-top: -2px;
    margin-right: 45px;
    margin-left: 45px;
  }
  @media (max-width: 1023.98px) {
    .countdown-separator {
      display: none;
    }
  }
  html {
    min-height: 100%;
  }
//   body {
//     position: relative;
//     display: -webkit-box;
//     display: -ms-flexbox;
//     // display: flex;
//     -ms-flex-wrap: wrap;
//     flex-wrap: wrap;
//     padding-right: var(--tmb-scrollbar-width);
//     overflow-x: hidden;
//     overflow-y: initial;
//     word-break: break-word;
//   }
  .content-wrap {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    z-index: 1;
  }
  .sticky-top {
    top: 0;
    will-change: top;
    -webkit-transition: top 0.6s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: top 0.6s cubic-bezier(0.2, 1, 0.2, 1);
    transition: top 0.6s cubic-bezier(0.2, 1, 0.2, 1);
  }
  .navbar-show .sticky-top {
    top: 90px;
  }
  .gmaps {
    width: 100%;
    height: 570px;
  }
  .body-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }
  body {
    color: #0e0e0e;
  }
  p {
    margin-bottom: 3.05882em;
    font-size: 1.0625em;
  }
  .list {
    padding-left: 20px;
    font-weight: 500;
  }
  .list li {
    position: relative;
    list-style: none;
  }
  .list li::before {
    content: "";
    position: absolute;
    top: 0.625em;
    left: -20px;
    width: 4px;
    height: 4px;
    background-color: currentColor;
    border-radius: 2px;
  }
  .list li + li {
    margin-top: 20px;
  }
  .lead {
    line-height: 1.5;
  }
  a {
    color: #0e0e0e;
  }
  a:hover {
    text-decoration: none;
  }
  a:focus,
  a:hover {
    color: #0e0e0e;
  }
  .display-1,
  .display-2,
  .display-3,
  .display-4,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #0e0e0e;
  }
  .display-1 u,
  .display-2 u,
  .display-3 u,
  .display-4 u,
  .h1 u,
  .h2 u,
  .h3 u,
  .h4 u,
  .h5 u,
  .h6 u,
  h1 u,
  h2 u,
  h3 u,
  h4 u,
  h5 u,
  h6 u {
    text-decoration: none;
    border-bottom: 1px solid currentColor;
  }
  .h1,
  h1 {
    margin-bottom: 0.74286em;
    font-size: 4.375em;
    line-height: 1.2;
  }
  @media (max-width: 619.98px) {
    .h1,
    h1 {
      margin-bottom: 1.15385em;
      font-size: 3.25em;
    }
  }
  .h2,
  h2 {
    margin-bottom: 0.91667em;
    font-size: 3em;
    line-height: 1.2;
  }
  @media (max-width: 619.98px) {
    .h2,
    h2 {
      margin-bottom: 1.1em;
      font-size: 2.5em;
    }
  }
  .h3,
  h3 {
    margin-bottom: 0.76471em;
    font-size: 2.125em;
    line-height: 1.3;
  }
  @media (max-width: 619.98px) {
    .h3,
    h3 {
      margin-bottom: 0.86667em;
      font-size: 1.875em;
    }
  }
  .h4,
  h4 {
    margin-bottom: 0.75em;
    font-size: 1.5em;
    line-height: 1.4;
  }
  @media (max-width: 619.98px) {
    .h4,
    h4 {
      margin-bottom: 0.81818em;
      font-size: 1.375em;
    }
  }
  .h5,
  h5 {
    margin-bottom: 0.9em;
    font-size: 1.25em;
    line-height: 1.5;
  }
  .h6,
  h6 {
    margin-bottom: 1em;
    font-size: 1.125em;
    line-height: 1.6;
  }
  @media (max-width: 827.98px) {
    .h3-sm {
      font-size: 34px;
    }
  }
  .display-4 {
    margin-bottom: 0.4em;
    font-size: 6.25em;
  }
  @media (min-width: 620px) and (max-width: 827.98px) {
    .display-4 {
      margin-bottom: 0.5em;
      font-size: 5em;
    }
  }
  @media (max-width: 619.98px) {
    .display-4 {
      margin-bottom: 0.57143em;
      font-size: 4.375em;
    }
  }
  @media screen and (max-width: 440px) {
    .display-4 {
      margin-bottom: 0.72727em;
      font-size: 3.4375em;
    }
  }
  .display-3 {
    margin-bottom: 0.33333em;
    font-size: 7.5em;
  }
  .subtitle {
    margin-bottom: 1.2em;
    font-family: Inter, sans-serif;
    font-size: 0.9375em;
    font-weight: 500;
    line-height: 1.8;
  }
  .hr,
  hr {
    margin-top: 2.25em;
    margin-bottom: 2.25em;
    border-color: inherit;
  }
  .blockquote,
  blockquote {
    font-size: 1.5em;
    font-weight: 500;
  }
  .navbar {
    position: -webkit-sticky;
    position: sticky;
    will-change: transform;
    -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
    transition: -webkit-transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.6s cubic-bezier(0.2, 1, 0.2, 1),
      -webkit-transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
    z-index: 100;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .navbar {
      position: fixed;
    }
  }
  .navbar .navbar-nav {
    display: none;
  }
  .navbar .navbar-nav .nav-link,
  .navbar .navbar-nav .nav-link.active {
    color: #0e0e0e;
  }
  .navbar .navbar-toggle {
    color: #0e0e0e;
  }
  .navbar-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    min-height: 90px;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .navbar-top {
      height: 90px;
    }
    .navbar-top:not([class*="position-"]) ~ .content-wrap {
      padding-top: 90px;
    }
    .navbar-top:not([class*="position-"])
      ~ .content-wrap
      > .min-vh-100:first-child,
    .navbar-top:not([class*="position-"])
      ~ .content-wrap
      > .swiper-full:first-child,
    .navbar-top:not([class*="position-"])
      ~ .content-wrap
      > .swiper-fullscreen-vertical:first-child {
      margin-top: -90px;
    }
    .navbar-top:not([class*="position-"]).navbar-dark ~ .banner::before {
      content: "";
      display: block;
      padding-top: 90px;
    }
    .navbar-top:not([class*="position-"]).navbar-dark
      ~ .banner
      ~ .content-wrap::before {
      display: none;
    }
    .navbar-top:not([class*="position-"]).navbar-dark ~ .content-wrap::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 90px;
      background-color: #0e0e0e;
      z-index: -1;
    }
  }
  .navbar-top::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    will-change: transform;
    -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
    transition: -webkit-transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.6s cubic-bezier(0.2, 1, 0.2, 1),
      -webkit-transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    z-index: -1;
  }
  .navbar-top.navbar-dark::before {
    background-color: #0e0e0e;
  }
  @media (max-width: 827.98px) {
    .navbar-top .container {
      padding-right: 40px;
      padding-left: 40px;
    }
  }
  .navbar-top .navbar-brand {
    margin-right: 60px;
  }
  .navbar-top .navbar-nav {
    height: 100%;
  }
  .navbar-top .navbar-nav > .nav-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .navbar-top .navbar-nav > .nav-item > .nav-link {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-top .navbar-nav ~ .navbar-nav {
    margin-left: 60px;
  }
  .navbar-left {
    position: fixed;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-item-align: start;
    align-self: flex-start;
    top: 0;
    bottom: 0;
    left: 0;
    width: 80px;
    max-width: 100%;
    height: 100vh;
    padding: 20px;
    margin-top: auto;
    overflow-y: scroll;
    background-color: #fff;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      .navbar-left {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
      }
    }
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .navbar-left {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
  }
  .navbar-left::-webkit-scrollbar {
    width: 8px;
  }
  .navbar-left::-webkit-scrollbar-track {
    background-color: #fff;
  }
  .navbar-left::-webkit-scrollbar-thumb {
    background-color: #ddd;
  }
  .navbar-left.navbar-dark {
    background-color: #0e0e0e;
  }
  .navbar-left ~ .content-wrap {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 80px);
    flex: 0 0 calc(100% - 80px);
    max-width: calc(100% - 80px);
    margin-left: 80px;
  }
  .navbar-left ~ .footer {
    margin-left: 80px;
  }
  .navbar-left .navbar-brand {
    margin-right: 0;
    margin-bottom: 0;
  }
  .navbar-left .navbar-brand img {
    max-width: 100%;
  }
  .navbar-left .navbar-toggle {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 80px;
    height: 80px;
  }
  .navbar-left .container,
  .navbar-left .navbar-nav:not(.nav-social) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-left .container {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .navbar-left .container > .navbar-nav {
    margin-top: auto;
    margin-bottom: auto;
  }
  .navbar-left .container > .navbar-nav .nav {
    font-size: 16px;
  }
  .navbar-left .navbar-collapse .nav-link {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .navbar-left .navbar-nav:not(.nav-social) {
    margin-left: 0;
  }
  .navbar-left .navbar-nav:not(.nav-social) + .navbar-nav {
    margin-top: 50px;
  }
  .navbar-left .navbar-nav:not(.nav-social) + .nav-social {
    margin-top: 50px;
    margin-bottom: 0;
  }
  .navbar-left .nav-social {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    bottom: 21px;
    left: 50%;
    margin-left: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .navbar-left .navbar-copyright {
    display: none;
    padding-bottom: 20px;
    margin-top: 22px;
    margin-bottom: 0;
    font-size: 0.875em;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      .navbar-left .navbar-copyright {
        padding-bottom: 0;
      }
    }
  }
  .navbar-nav {
    margin-left: auto;
  }
  .navbar-nav:not(.nav-social) .nav-link {
    padding: 6px 20px;
  }
  @media (min-width: 620px) {
    .navbar-expand-sm.navbar-top .navbar-nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .navbar-expand-sm.navbar-top .navbar-toggle {
      display: none;
    }
    .navbar-expand-sm.navbar-left {
      width: 320px;
      max-width: 100%;
      padding: 50px 60px;
    }
    .navbar-expand-sm.navbar-left ~ .content-wrap {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 calc(100% - 320px);
      flex: 0 0 calc(100% - 320px);
      max-width: calc(100% - 320px);
    }
    .navbar-expand-sm.navbar-left ~ .content-wrap,
    .navbar-expand-sm.navbar-left ~ .footer {
      margin-left: 320px;
    }
    .navbar-expand-sm.navbar-left .navbar-brand {
      margin-bottom: 50px;
    }
    .navbar-expand-sm.navbar-left .navbar-toggle {
      display: none;
    }
    .navbar-expand-sm.navbar-left .navbar-nav:not(.nav-social) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .navbar-expand-sm.navbar-left .navbar-nav:not(.nav-social) .nav-link {
      padding: 11px 0;
    }
    .navbar-expand-sm.navbar-left .nav-social {
      position: static;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      margin-left: -9px;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
    .navbar-expand-sm.navbar-left .nav-social .nav-link {
      padding: 0 9px;
    }
    .navbar-expand-sm.navbar-left .navbar-copyright {
      display: block;
      padding-bottom: 50px;
    }
  }
  @media (max-width: 827.98px) {
    .navbar-expand-sm.navbar-left .navbar-toggle {
      position: absolute;
      top: 50%;
      left: 0;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
  @media (min-width: 828px) {
    .navbar-expand-md.navbar-top .navbar-nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .navbar-expand-md.navbar-top .navbar-toggle {
      display: none;
    }
    .navbar-expand-md.navbar-left {
      width: 320px;
      max-width: 100%;
      padding: 50px 60px;
    }
    .navbar-expand-md.navbar-left ~ .content-wrap {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 calc(100% - 320px);
      flex: 0 0 calc(100% - 320px);
      max-width: calc(100% - 320px);
    }
    .navbar-expand-md.navbar-left ~ .content-wrap,
    .navbar-expand-md.navbar-left ~ .footer {
      margin-left: 320px;
    }
    .navbar-expand-md.navbar-left .navbar-brand {
      margin-bottom: 50px;
    }
    .navbar-expand-md.navbar-left .navbar-toggle {
      display: none;
    }
    .navbar-expand-md.navbar-left .navbar-nav:not(.nav-social) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .navbar-expand-md.navbar-left .navbar-nav:not(.nav-social) .nav-link {
      padding: 11px 0;
    }
    .navbar-expand-md.navbar-left .nav-social {
      position: static;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      margin-left: -9px;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
    .navbar-expand-md.navbar-left .nav-social .nav-link {
      padding: 0 9px;
    }
    .navbar-expand-md.navbar-left .navbar-copyright {
      display: block;
      padding-bottom: 50px;
    }
  }
  @media (max-width: 1023.98px) {
    .navbar-expand-md.navbar-left .navbar-toggle {
      position: absolute;
      top: 50%;
      left: 0;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
  @media (min-width: 1024px) {
    .navbar-expand-lg.navbar-top .navbar-nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .navbar-expand-lg.navbar-top .navbar-toggle {
      display: none;
    }
    .navbar-expand-lg.navbar-left {
      width: 320px;
      max-width: 100%;
      padding: 50px 60px;
    }
    .navbar-expand-lg.navbar-left ~ .content-wrap {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 calc(100% - 320px);
      flex: 0 0 calc(100% - 320px);
      max-width: calc(100% - 320px);
    }
    .navbar-expand-lg.navbar-left ~ .content-wrap,
    .navbar-expand-lg.navbar-left ~ .footer {
      margin-left: 320px;
    }
    .navbar-expand-lg.navbar-left .navbar-brand {
      margin-bottom: 50px;
    }
    .navbar-expand-lg.navbar-left .navbar-toggle {
      display: none;
    }
    .navbar-expand-lg.navbar-left .navbar-nav:not(.nav-social) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .navbar-expand-lg.navbar-left .navbar-nav:not(.nav-social) .nav-link {
      padding: 11px 0;
    }
    .navbar-expand-lg.navbar-left .nav-social {
      position: static;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      margin-left: -9px;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
    .navbar-expand-lg.navbar-left .nav-social .nav-link {
      padding: 0 9px;
    }
    .navbar-expand-lg.navbar-left .navbar-copyright {
      display: block;
      padding-bottom: 50px;
    }
  }
  @media (max-width: 1199.98px) {
    .navbar-expand-lg.navbar-left .navbar-toggle {
      position: absolute;
      top: 50%;
      left: 0;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
  @media (min-width: 1200px) {
    .navbar-expand-xl.navbar-top .navbar-nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .navbar-expand-xl.navbar-top .navbar-toggle {
      display: none;
    }
    .navbar-expand-xl.navbar-left {
      width: 320px;
      max-width: 100%;
      padding: 50px 60px;
    }
    .navbar-expand-xl.navbar-left ~ .content-wrap {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 calc(100% - 320px);
      flex: 0 0 calc(100% - 320px);
      max-width: calc(100% - 320px);
    }
    .navbar-expand-xl.navbar-left ~ .content-wrap,
    .navbar-expand-xl.navbar-left ~ .footer {
      margin-left: 320px;
    }
    .navbar-expand-xl.navbar-left .navbar-brand {
      margin-bottom: 50px;
    }
    .navbar-expand-xl.navbar-left .navbar-toggle {
      display: none;
    }
    .navbar-expand-xl.navbar-left .navbar-nav:not(.nav-social) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .navbar-expand-xl.navbar-left .navbar-nav:not(.nav-social) .nav-link {
      padding: 11px 0;
    }
    .navbar-expand-xl.navbar-left .nav-social {
      position: static;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      margin-left: -9px;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
    .navbar-expand-xl.navbar-left .nav-social .nav-link {
      padding: 0 9px;
    }
    .navbar-expand-xl.navbar-left .navbar-copyright {
      display: block;
      padding-bottom: 50px;
    }
  }
  .navbar-expand-xl.navbar-left .navbar-toggle {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .navbar-dark .navbar-nav .nav-link,
  .navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
  }
  .navbar-dark .navbar-toggle {
    color: #fff;
  }
  .navbar-dark .dropdown-menu {
    background-color: #0e0e0e;
  }
  .navbar-fixed {
    position: fixed;
    width: calc(100% - var(--tmb-scrollbar-width));
  }
  .navbar-relative {
    position: relative;
  }
  .navbar-scroll .navbar-top::before {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .navbar-hide:not(.navbar-end) .navbar-top {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  .navbar-mobile {
    position: absolute;
  }
  .navbar-mobile:not(.fancybox-content) {
    display: none;
  }
  .navbar-mobile .navbar-head {
    position: -webkit-sticky;
    position: sticky;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 90px;
    z-index: 100;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .navbar-mobile .navbar-head {
      position: fixed;
      height: 90px;
    }
  }
  .navbar-mobile .navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .navbar-mobile .navbar-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 100%;
  }
  .navbar-mobile .navbar-body .navbar-nav {
    margin-top: 0;
    margin-bottom: 0;
  }
  .navbar-mobile .navbar-body .navbar-nav .nav-link {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-mobile .navbar-body .navbar-collapse-menu > .navbar-nav {
    padding: 5px 0;
  }
  .navbar-mobile .navbar-body > .navbar-nav {
    margin-top: -3px;
    margin-bottom: -3px;
    font-size: 34px;
    line-height: 1.46;
  }
  .navbar-mobile .navbar-body > .navbar-nav .nav-link {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .navbar-mobile .navbar-body > .navbar-nav .navbar-nav {
    line-height: 2;
  }
  .navbar-mobile .navbar-body > .navbar-nav > .nav-item > .nav-link svg {
    display: none;
  }
  .navbar-mobile .navbar-collapse-menu > .navbar-nav {
    font-size: 16px;
  }
  .navbar-mobile .nav-social {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-left: -9px;
  }
  .navbar-mobile.navbar-dark {
    background-color: #0e0e0e;
  }
  .navbar-mobile-style-1 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    overflow-y: scroll;
  }
  .navbar-mobile-style-1 .navbar-head {
    margin-bottom: 50px;
    background-color: #fff;
  }
  .navbar-mobile-style-1 .navbar-footer {
    margin-top: 64px;
    margin-bottom: 50px;
  }
  .navbar-mobile-style-1.navbar-dark .navbar-head {
    background-color: #0e0e0e;
  }
  .navbar-mobile-style-2 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    overflow-y: scroll;
  }
  .navbar-mobile-style-2 > .container {
    display: block;
    margin-bottom: 50px;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .navbar-mobile-style-2 > .container {
      margin-top: 90px;
    }
  }
  .navbar-mobile-style-2 .navbar-head {
    margin-bottom: 50px;
  }
  .navbar-mobile-style-2 .navbar-head .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  @media (max-width: 1199.98px) {
    .navbar-mobile-style-2 .navbar-head {
      background-color: #fff;
    }
    .navbar-mobile-style-2.navbar-dark .navbar-head {
      background-color: #0e0e0e;
    }
  }
  @media (min-width: 828px) {
    .navbar-mobile-style-2 .navbar-body {
      margin-top: -6px;
    }
    .navbar-mobile-style-2 .navbar-body > .navbar-nav {
      font-size: 70px;
    }
  }
  @media (max-width: 827.98px) {
    .navbar-mobile-style-2 .navbar-footer {
      margin-top: 57px;
    }
  }
  @media (min-width: 828px) {
    .navbar-mobile-style-2 .navbar-footer {
      margin-top: 33px;
    }
  }
  .navbar-mobile-style-3 {
    top: 0;
    right: 0;
    bottom: 0;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .navbar-mobile-style-3 .navbar-head {
      right: 0;
      left: auto;
      width: auto;
    }
    .navbar-mobile-style-3 .navbar-wrapper {
      padding-top: 90px;
    }
  }
  @media (min-width: 828px) {
    .navbar-mobile-style-3 {
      width: 568px;
      max-width: 100%;
      padding: 0 100px;
    }
  }
  @media all and (min-width: 828px) and (-ms-high-contrast: none),
    (min-width: 828px) and (-ms-high-contrast: active) {
    .navbar-mobile-style-3 .navbar-head {
      width: 568px;
      max-width: 100%;
      padding-right: 100px;
      padding-left: 100px;
    }
  }
  @media (min-width: 828px) {
    .navbar-mobile-style-3 .navbar-body > .navbar-nav {
      margin-right: -5px;
      margin-bottom: -5px;
      font-size: 48px;
    }
    .navbar-mobile-style-3 .navbar-body > .navbar-nav .nav-link {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  @media (max-width: 827.98px) {
    .navbar-mobile-style-3 {
      width: 100%;
      padding: 0 40px;
    }
    .navbar-mobile-style-3 .navbar-head {
      width: auto;
      padding: 0 40px;
      margin: 0 -40px;
    }
  }
  @media all and (max-width: 827.98px) and (-ms-high-contrast: none),
    (max-width: 827.98px) and (-ms-high-contrast: active) {
    .navbar-mobile-style-3 .navbar-head {
      left: 40px;
      width: 100%;
    }
    .navbar-mobile-style-3 .navbar-head .navbar-brand {
      margin-right: auto;
    }
  }
  .navbar-mobile-style-3 .navbar-body {
    margin-bottom: auto;
  }
  .navbar-mobile-style-3 .navbar-footer {
    margin-top: 70px;
    margin-bottom: 50px;
  }
  .navbar-mobile-style-3 .navbar-head {
    margin-bottom: 16px;
    background-color: #fff;
  }
  .navbar-mobile-style-3.navbar-dark .navbar-head {
    background-color: #0e0e0e;
  }
  .navbar-mobile-style-4 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 40px;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .navbar-mobile-style-4 {
      padding-top: 90px;
    }
  }
  .navbar-mobile-style-4 .navbar-nav-collapse {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
  }
  .navbar-mobile-style-4 .navbar-head {
    width: auto;
    padding: 0 40px;
    margin: 0 -40px 53px;
    background-color: #fff;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .navbar-mobile-style-4 .navbar-head {
      width: 100%;
      margin: 0;
    }
  }
  .navbar-mobile-style-4 .navbar-body {
    height: 100%;
  }
  @media (min-width: 1024px) {
    .navbar-mobile-style-4 .navbar-body > .navbar-nav {
      max-width: 50%;
    }
  }
  .navbar-mobile-style-4 .navbar-footer {
    margin-top: 66px;
  }
  .navbar-mobile-style-4 .navbar-footer .container {
    display: block;
  }
  .navbar-mobile-style-4 .navbar-copyright {
    margin-top: 23px;
  }
  .navbar-mobile-style-4.navbar-dark .navbar-head {
    background-color: #0e0e0e;
  }
  .navbar-top
    .container
    > .navbar-nav
    > .navbar-dropdown:not(.navbar-dropdown-mega)
    > .nav-link
    svg {
    display: none;
  }
  .navbar-top
    .container
    > .navbar-nav
    > .navbar-dropdown:not(.navbar-dropdown-mega)
    > .dropdown-menu {
    margin-top: 0;
    margin-left: -10px;
  }
  .navbar-dropdown {
    position: relative;
  }
  .navbar-dropdown .nav-link svg {
    margin-left: auto;
  }
  .navbar-dropdown .nav-link:focus ~ .dropdown-menu,
  .navbar-dropdown .nav-link:hover ~ .dropdown-menu {
    visibility: visible;
    opacity: 1;
  }
  .navbar-dropdown.focus > .dropdown-menu {
    visibility: visible;
    opacity: 1;
  }
  .navbar-dropdown.nav-item.focus > .nav-link,
  .navbar-dropdown.nav-item:hover > .nav-link {
    opacity: 1;
  }
  .navbar-dropdown .dropdown-menu {
    display: block;
    top: 100%;
    left: 0;
    width: 240px;
    margin-top: -12px;
    font-size: 15px;
    visibility: hidden;
    opacity: 0;
    will-change: opacity;
    -webkit-transition: visibility 0.15s ease-in-out, opacity 0.15s ease-in-out;
    -o-transition: visibility 0.15s ease-in-out, opacity 0.15s ease-in-out;
    transition: visibility 0.15s ease-in-out, opacity 0.15s ease-in-out;
  }
  .navbar-dropdown .dropdown-menu .navbar-nav {
    margin-right: 0;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      .navbar-dropdown .dropdown-menu .navbar-nav {
        width: 100%;
      }
    }
  }
  .navbar-dropdown .dropdown-menu .nav-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
  }
  .navbar-dropdown .dropdown-menu .nav-item .nav-link {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .navbar-dropdown .dropdown-menu .nav-item {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
    }
    .navbar-dropdown .dropdown-menu .nav-item .nav-link {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      width: auto;
    }
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      .navbar-dropdown .dropdown-menu .nav-item .nav-link-name {
        white-space: nowrap;
      }
    }
  }
  .navbar-dropdown .dropdown-menu:hover {
    visibility: visible;
    opacity: 1;
  }
  .navbar-dropdown .dropdown-menu .dropdown-menu:not(.dropdown-menu-drop-left) {
    top: -8px;
    left: 100%;
  }
  .navbar-dropdown .dropdown-menu-drop-left {
    top: -8px;
    right: 100%;
    left: auto;
  }
  .navbar-dropdown .navbar-nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .navbar-dropdown-mega {
    position: static;
  }
  .navbar-dropdown-mega > .nav-link {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .navbar-dropdown-mega > .nav-link svg {
    display: none;
  }
  .navbar-dropdown-mega .dropdown-menu {
    right: 0;
    width: auto;
    max-height: calc(100vh - 90px);
    padding: 60px 0;
    margin-top: 0;
    overflow-y: auto;
  }
  .navbar-dropdown-mega .dropdown-menu .nav-item .nav-link {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 0;
    opacity: 1;
  }
  .navbar-dropdown-mega .dropdown-menu .nav-link-name {
    margin-bottom: -6px;
  }
  .navbar-dropdown-mega .dropdown-menu .container {
    display: block;
    height: auto;
  }
  .navbar-dropdown-mega .dropdown-menu .container .nav-link {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .navbar-dropdown-mega .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .navbar-dropdown-mega .nav-link {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-dropdown-mega .nav-link:focus .nav-link-image,
  .navbar-dropdown-mega .nav-link:hover .nav-link-image {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  .navbar-dropdown-mega .active > .nav-link .nav-link-image {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  .navbar-dropdown-mega .nav-link-name {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .navbar-dropdown-mega .nav-link-image {
    display: block;
    height: 100%;
    margin-bottom: 22px;
    border: 1px solid #ddd;
    will-change: transform;
    -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
    transition: -webkit-transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 0.6s cubic-bezier(0.2, 1, 0.2, 1),
      -webkit-transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .navbar-dropdown-mega .nav-link-image img {
    display: block;
    width: 100%;
  }
  .navbar-hide .dropdown-menu {
    display: none;
  }
  .navbar-dark .navbar-dropdown-mega .nav-link-image {
    border-color: #202020;
  }
  .navbar-collapse {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 100%;
  }
  .navbar-collapse .nav-link {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
  }
  .navbar-collapse .navbar-nav {
    margin: -12px 0;
  }
  .navbar-collapse .navbar-nav .nav-link {
    padding: 12px 0;
  }
  .navbar-nav.navbar-nav-collapse {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin: -12px 0;
  }
  .navbar-nav.navbar-nav-collapse .nav-link {
    padding: 12px 0;
  }
  .navbar-collapse-menu {
    width: 100%;
  }
  .navbar-collapse-menu > .navbar-nav {
    margin-top: 0;
    margin-bottom: 0;
  }
  .navbar-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .navbar-toggle > span {
    width: 24px;
    height: 2px;
    margin-top: 3px;
    margin-bottom: 3px;
    background-color: currentColor;
    will-change: transform, opacity;
    -webkit-transition: opacity 0.2s ease-in-out,
      -webkit-transform 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    -o-transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out,
      -webkit-transform 0.2s ease-in-out;
    -webkit-transform-origin: center right;
    -ms-transform-origin: center right;
    transform-origin: center right;
  }
  .navbar-toggle > span:nth-child(3) {
    -webkit-transform: scaleX(0.5);
    -ms-transform: scaleX(0.5);
    transform: scaleX(0.5);
  }
  .navbar-toggle:hover > span:nth-child(3) {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }
  .fancybox-open .navbar-toggle > span:nth-child(1) {
    -webkit-transform: rotate(-45deg) translateX(0.5px);
    -ms-transform: rotate(-45deg) translateX(0.5px);
    transform: rotate(-45deg) translateX(0.5px);
  }
  .fancybox-open .navbar-toggle > span:nth-child(2) {
    opacity: 0;
  }
  .fancybox-open .navbar-toggle > span:nth-child(3) {
    -webkit-transform: rotate(45deg) translateX(0.5px);
    -ms-transform: rotate(45deg) translateX(0.5px);
    transform: rotate(45deg) translateX(0.5px);
  }
  .banner {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-top: 90px;
    margin-top: -90px;
  }
  @media (max-width: 1199.98px) {
    .banner {
      overflow: hidden;
    }
  }
  .banner-dark {
    position: relative;
    z-index: 1;
  }
  .banner-dark::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 100px;
    left: 0;
    background-color: #0e0e0e;
  }
  .footer {
    position: relative;
    width: 100%;
    margin-top: auto;
  }
  .footer-copyright {
    font-size: 0.875em;
    color: #969696;
  }
  .cursor {
    position: fixed;
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    opacity: 0;
    will-change: transform, width, height, top, left, background-color, opacity;
    -webkit-transition: width 0.2s ease, height 0.2s ease, top 0.2s ease,
      left 0.2s ease, background-color 0.2s ease, opacity 0.15s ease;
    -o-transition: width 0.2s ease, height 0.2s ease, top 0.2s ease,
      left 0.2s ease, background-color 0.2s ease, opacity 0.15s ease;
    transition: width 0.2s ease, height 0.2s ease, top 0.2s ease, left 0.2s ease,
      background-color 0.2s ease, opacity 0.15s ease;
    z-index: 9999999;
  }
  .cursor-hover {
    opacity: 1;
  }
  .cursor-circle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    top: -45px;
    left: -45px;
    width: 90px;
    height: 90px;
    font-size: 0.9375em;
    font-weight: 500;
    color: #0e0e0e;
  }
  .cursor-circle::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border-radius: 100%;
    will-change: transform;
    -webkit-transition: -webkit-transform 0.2s ease;
    transition: -webkit-transform 0.2s ease;
    -o-transition: transform 0.2s ease;
    transition: transform 0.2s ease;
    transition: transform 0.2s ease, -webkit-transform 0.2s ease;
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
    z-index: -1;
  }
  .cursor-hover::after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  .cursor-enabled [data-cursor-style="cursor-circle"],
  .cursor-enabled [data-cursor-style="cursor-circle"] * {
    cursor: none !important;
  }
  .cursor-text {
    top: 25px;
    left: 20px;
    padding: 9px 26px;
    font-size: 1.25em;
    font-weight: 500;
    color: #fff;
    background-color: #0e0e0e;
  }
  @media (max-width: 1199.98px) {
    [data-cursor-style="cursor-text"] {
      position: relative;
    }
    [data-cursor-style="cursor-text"]::after {
      content: attr(data-cursor-text);
      position: absolute;
      display: block;
      right: 0;
      bottom: 0;
      max-width: 100%;
      padding: 9px 26px;
      font-size: 1.25em;
      font-weight: 500;
      color: #fff;
      background-color: #0e0e0e;
      opacity: 0;
      will-change: opacity;
      -webkit-transition: opacity 0.2s ease-in-out;
      -o-transition: opacity 0.2s ease-in-out;
      transition: opacity 0.2s ease-in-out;
    }
    [data-cursor-style="cursor-text"]:focus::after,
    [data-cursor-style="cursor-text"]:hover::after {
      opacity: 1;
    }
  }
  .shape {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    display: none;
  }
  @media (min-width: 1200px) {
    .shape {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
  }
  .shape svg {
    -webkit-transition: -webkit-transform 1.3s cubic-bezier(0.2, 1, 0.2, 1);
    transition: -webkit-transform 1.3s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: transform 1.3s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 1.3s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 1.3s cubic-bezier(0.2, 1, 0.2, 1),
      -webkit-transform 1.3s cubic-bezier(0.2, 1, 0.2, 1);
    z-index: -1;
  }
  .shape-parent {
    position: relative;
    z-index: 0;
  }
  .feature {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .feature-icon {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    margin-right: 30px;
    font-size: 1.125em;
    font-weight: 500;
  }
  .feature-icon > svg:first-child {
    position: absolute;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .feature-icon > svg:first-child {
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
  .feature-body .feature-title {
    margin-bottom: 14px;
  }
  .feature-body .feature-text {
    margin-bottom: 0;
  }
  .feature-body p {
    margin-bottom: 1.125em;
    font-size: 1em;
  }
  .feature-body .btn {
    margin-top: 19px;
  }
  .review-icon {
    margin-bottom: 92px;
  }
  .review-text {
    font-size: 1.5em;
    font-weight: 500;
  }
  .review-author {
    margin-top: 33px;
    font-size: 0.9375em;
    opacity: 0.6;
  }
  @media (min-width: 1024px) {
    .lines-style-1 {
      position: relative;
    }
    .lines-style-1 .line {
      position: absolute;
      display: block;
      border-color: currentColor;
      border-style: solid;
      border-width: 0;
    }
    .lines-style-1 .line:nth-of-type(1) {
      top: 130px;
      left: 0;
      width: 170px;
      border-top-width: 1px;
    }
    .lines-style-1 .line:nth-of-type(2) {
      right: 200px;
      bottom: 130px;
      height: 70px;
      border-right-width: 1px;
    }
    .lines-style-2 .line {
      position: absolute;
      display: block;
      top: 50%;
      right: -35px;
      width: 70px;
      border-top: 1px solid currentColor;
      z-index: 1;
    }
    .lines-style-3 .line {
      position: absolute;
      display: block;
      right: 50%;
      bottom: 0;
      height: 70px;
      border-right: 1px solid currentColor;
      z-index: 1;
    }
    .lines-style-4 .line {
      position: absolute;
      display: block;
      border-color: currentColor;
      border-style: solid;
      border-width: 0;
      z-index: 1;
    }
    .lines-style-4 .line:nth-of-type(1) {
      top: 170px;
      right: 0;
      width: 170px;
      border-top-width: 1px;
    }
    .lines-style-4 .line:nth-of-type(2) {
      bottom: 0;
      left: 200px;
      height: 70px;
      border-right-width: 1px;
    }
    .lines-style-5 .line {
      position: absolute;
      display: block;
      top: 37px;
      right: -35px;
      width: 70px;
      border-top: 1px solid currentColor;
      z-index: 1;
    }
    .lines-style-6 .line {
      position: absolute;
      display: block;
    }
    .lines-style-6 .line:nth-child(1) {
      top: 72px;
      right: 89px;
      width: 168px;
      border-top: 1px solid currentColor;
    }
    .lines-style-6 .line:nth-child(2) {
      bottom: -66px;
      left: 133px;
      height: 70px;
      border-left: 1px solid currentColor;
    }
  }
  .interactive-links {
    position: relative;
    padding-left: 0;
    list-style: none;
  }
  .interactive-links.flex-column {
    font-size: 16px;
  }
  @media (max-width: 827.98px) {
    .interactive-links .h1 {
      font-size: 3.125em;
    }
  }
  .interactive-links .nav-item {
    position: relative;
  }
  .interactive-links .nav-link {
    position: relative;
    display: block;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 0;
    z-index: 1;
  }
  @media (min-width: 1200px) {
    .interactive-links .nav-link {
      padding-right: 100px;
      padding-left: 100px;
    }
  }
  .interactive-links .nav-link:focus,
  .interactive-links .nav-link:hover {
    color: inherit;
  }
  .interactive-links .nav-link:focus ~ .subtitle,
  .interactive-links .nav-link:hover ~ .subtitle {
    opacity: 1;
  }
  .interactive-links .subtitle {
    margin-top: -43px;
    margin-bottom: 0;
    margin-left: 15px;
    color: inherit;
    will-change: opacity, transform;
    -webkit-transition: opacity 0.15s, -webkit-transform 0.25s ease-in-out;
    transition: opacity 0.15s, -webkit-transform 0.25s ease-in-out;
    -o-transition: opacity 0.15s, transform 0.25s ease-in-out;
    transition: opacity 0.15s, transform 0.25s ease-in-out;
    transition: opacity 0.15s, transform 0.25s ease-in-out,
      -webkit-transform 0.25s ease-in-out;
  }
  .interactive-links.focus .subtitle,
  .interactive-links:hover .subtitle {
    opacity: 0.6;
  }
  .interactive-links-image {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    will-change: opacity;
    -webkit-transition: opacity 0.1s ease-in-out;
    -o-transition: opacity 0.1s ease-in-out;
    transition: opacity 0.1s ease-in-out;
  }
  .interactive-links-image.hover,
  .interactive-links-image.transition-end {
    position: fixed;
    display: block;
  }
  .interactive-links-image.hover {
    opacity: 1;
  }
  .interactive-links.text-white a {
    color: #fff;
  }
  .interactive-links-style-2 .interactive-links-image {
    top: 20%;
    right: 20%;
    left: auto;
    width: 40%;
  }
  .interactive-links-style-2.flex-column {
    margin: -45px -100px;
  }
  .interactive-links-style-2.flex-column .nav-link {
    padding: 45px 140px;
  }
  .interactive-links-style-2.flex-column .nav-link ~ .subtitle {
    margin-left: 140px;
  }
  @media (max-width: 827.98px) {
    .interactive-links-style-2.flex-column {
      margin-right: 0;
      margin-left: 0;
    }
    .interactive-links-style-2.flex-column .nav-link {
      padding-right: 0;
      padding-left: 0;
    }
    .interactive-links-style-2.flex-column .nav-link ~ .subtitle {
      margin-left: 0;
    }
  }
  @media (min-width: 828px) {
    .interactive-links-style-2.flex-column {
      padding-left: 10px;
    }
    .interactive-links-style-2.flex-column .subtitle {
      -webkit-transform: translateX(-40px);
      -ms-transform: translateX(-40px);
      transform: translateX(-40px);
    }
    .interactive-links-style-2.flex-column .nav-link {
      will-change: transform;
      -webkit-transition: opacity 0.25s, -webkit-transform 0.25s ease-in-out;
      transition: opacity 0.25s, -webkit-transform 0.25s ease-in-out;
      -o-transition: opacity 0.25s, transform 0.25s ease-in-out;
      transition: opacity 0.25s, transform 0.25s ease-in-out;
      transition: opacity 0.25s, transform 0.25s ease-in-out,
        -webkit-transform 0.25s ease-in-out;
      -webkit-transform: translateX(-40px);
      -ms-transform: translateX(-40px);
      transform: translateX(-40px);
    }
    .interactive-links-style-2.flex-column .nav-link::before {
      content: "";
      position: absolute;
      display: block;
      top: 1.21429em;
      left: 0;
      width: 70px;
      border-top: 1px solid currentColor;
      will-change: transform;
      -webkit-transition: -webkit-transform 0.25s ease-in-out;
      transition: -webkit-transform 0.25s ease-in-out;
      -o-transition: transform 0.25s ease-in-out;
      transition: transform 0.25s ease-in-out;
      transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
      -webkit-transform: scaleX(0);
      -ms-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transform-origin: left center;
      -ms-transform-origin: left center;
      transform-origin: left center;
    }
    .interactive-links-style-2.flex-column .nav-link:focus,
    .interactive-links-style-2.flex-column .nav-link:hover {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
    .interactive-links-style-2.flex-column .nav-link:focus::before,
    .interactive-links-style-2.flex-column .nav-link:hover::before {
      -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
      transform: scaleX(1);
    }
    .interactive-links-style-2.flex-column .nav-link:focus ~ .subtitle,
    .interactive-links-style-2.flex-column .nav-link:hover ~ .subtitle {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
  }
  .interactive-links-style-3 .interactive-links-image {
    position: absolute;
    top: 20%;
    right: 20%;
    left: auto;
    z-index: -1;
  }
  .interactive-links-style-3 .nav-item {
    position: relative;
  }
  .interactive-links-style-3.flex-column {
    margin: -45px 0;
  }
  .interactive-links-style-3.flex-column .subtitle {
    margin-left: 0;
  }
  .interactive-links-style-3.flex-column .nav-link {
    padding: 45px 0;
  }
  @media (max-width: 827.98px) {
    .interactive-links-style-3.flex-column {
      margin-right: 0;
      margin-left: 0;
    }
    .interactive-links-style-3.flex-column .nav-link {
      padding-right: 0;
      padding-left: 0;
    }
  }
  .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  .background-color,
  .background-image {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .number-box {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .number-box-title {
    margin-bottom: 0;
  }
  .number-box-subtitle {
    font-weight: 500;
  }
  [data-toggle="collapse"] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  [aria-expanded="true"] .collapse-icon {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .collapse-icon {
    margin-left: 30px;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    -o-transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  .accordion .collapse-content {
    padding-bottom: 13px;
    padding-left: 47px;
  }
  .accordion-item {
    padding-bottom: 10px;
    border-bottom: 1px solid #0e0e0e;
  }
  .accordion-item [data-toggle="collapse"] {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-top: 22px;
    padding-bottom: 12px;
    margin-bottom: 0;
  }
  .accordion-item [aria-expanded="true"] .accordion-item-plus path:nth-child(1) {
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
  }
  .accordion-item-plus {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: 4px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .accordion-item-plus path:nth-child(1) {
    will-change: transform;
    -webkit-transition: -webkit-transform 0.15s ease-in-out;
    transition: -webkit-transform 0.15s ease-in-out;
    -o-transition: transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
  }
  .accordion-item-icon {
    width: 24px;
    margin-right: 23px;
  }
  .list-group .list-group-highlight {
    font-weight: 500;
  }
  .list-group-gap .list-group-item {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .list-group.borderless .list-group-item {
    border: 0;
  }
  .media-review {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .media-review .media-img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 80px;
    flex: 0 0 80px;
    max-width: 80px;
    margin-right: 30px;
  }
  .media-review .media-img ~ .media-text {
    margin-top: 30px;
    margin-bottom: 0;
  }
  .media-review .media-body {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 100%;
  }
  .media-review .media-title {
    margin-bottom: 2px;
    color: inherit;
  }
  .media-review .media-subtitle {
    font-size: 0.875em;
  }
  .media-review .media-text {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 50px;
    font-weight: 500;
  }
  .gallery-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .gallery-item img {
    width: 100%;
    will-change: transform;
    -webkit-transition: -webkit-transform 1.8s cubic-bezier(0.2, 1, 0.2, 1);
    transition: -webkit-transform 1.8s cubic-bezier(0.2, 1, 0.2, 1);
    -o-transition: transform 1.8s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 1.8s cubic-bezier(0.2, 1, 0.2, 1);
    transition: transform 1.8s cubic-bezier(0.2, 1, 0.2, 1),
      -webkit-transform 1.8s cubic-bezier(0.2, 1, 0.2, 1);
  }
  .gallery-item .btn {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .gallery-item .gallery-item-icon {
    position: absolute;
    top: 50%;
    opacity: 0;
    will-change: transform, opacity;
    -webkit-transition: opacity 0.25s ease-in-out,
      -webkit-transform 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
    -o-transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out,
      -webkit-transform 0.25s ease-in-out;
    -webkit-transform: translateY(-50%) scale(1.1);
    -ms-transform: translateY(-50%) scale(1.1);
    transform: translateY(-50%) scale(1.1);
  }
  .gallery-item .background-color {
    opacity: 0;
    will-change: opacity;
    -webkit-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
  }
  .gallery-item:focus img,
  .gallery-item:hover img {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }
  .gallery-item:focus .gallery-item-icon,
  .gallery-item:hover .gallery-item-icon {
    opacity: 1;
    -webkit-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
  }
  .gallery-item:focus .background-color,
  .gallery-item:hover .background-color {
    opacity: 1;
  }
  .gallery-item-lg,
  .gallery-item-md,
  .gallery-item-sm,
  .gallery-item-xl {
    position: relative;
    overflow: hidden;
  }
  @media (max-width: 619.98px) {
    .gallery-item-lg,
    .gallery-item-md,
    .gallery-item-sm,
    .gallery-item-xl {
      min-height: 300px;
    }
  }
  .gallery-item-lg::before,
  .gallery-item-md::before,
  .gallery-item-sm::before,
  .gallery-item-xl::before {
    content: "";
    display: block;
  }
  .gallery-item-sm::before {
    padding-top: 50%;
  }
  .gallery-item-md::before {
    padding-top: 65%;
  }
  .gallery-item-lg::before {
    padding-top: 100%;
  }
  .gallery-item-xl::before {
    padding-top: 130%;
  }
  .brand {
    display: block;
    -webkit-filter: brightness(1);
    filter: brightness(1);
    will-change: filter;
    -webkit-transition: -webkit-filter 0.15s ease-in-out;
    transition: -webkit-filter 0.15s ease-in-out;
    -o-transition: filter 0.15s ease-in-out;
    transition: filter 0.15s ease-in-out;
    transition: filter 0.15s ease-in-out, -webkit-filter 0.15s ease-in-out;
  }
  .brand.text-grey:focus,
  .brand.text-grey:hover {
    -webkit-filter: brightness(0.8);
    filter: brightness(0.8);
  }
  .brand.text-grey-dark:focus,
  .brand.text-grey-dark:hover {
    -webkit-filter: brightness(1.4);
    filter: brightness(1.4);
  }
  .brand-bordered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-width: 178px;
    min-height: 130px;
    border: 1px solid #ddd;
    -webkit-transition: border-color 0.15s ease-in-out;
    -o-transition: border-color 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out;
  }
  .brand-bordered svg {
    display: block;
    -ms-flex-item-align: center;
    align-self: center;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .brand-bordered {
      position: relative;
    }
    .brand-bordered svg {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
  .brand-bordered:focus,
  .brand-bordered:hover {
    border-color: #0e0e0e;
  }
  .page-navigation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 40px 0;
    border-top: 1px solid currentColor;
  }
  .show-on-scroll {
    opacity: 0;
    -webkit-transition-timing-function: cubic-bezier(2, 0.1, 2, 0.1);
    -o-transition-timing-function: cubic-bezier(2, 0.1, 2, 0.1);
    transition-timing-function: cubic-bezier(2, 0.1, 2, 0.1);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    -o-transition-property: transform, opacity;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    will-change: opacity, transform;
  }
  .post-content > p {
    margin-bottom: 2.05882em;
  }
  .post-content > p:last-of-type {
    margin-bottom: 0;
  }
  .post-content > p + .h1,
  .post-content > p + h1 {
    margin-top: 0.62857em;
  }
  .post-content > p + .h2,
  .post-content > p + h2 {
    margin-top: 0.91667em;
  }
  .post-content > p + .h3,
  .post-content > p + h3 {
    margin-top: 1.29412em;
  }
  .post-content > p + .h4,
  .post-content > p + h4 {
    margin-top: 1.83333em;
  }
  .post-content > p + .h5,
  .post-content > p + h5 {
    margin-top: 2.2em;
  }
  .post-content > p + .h6,
  .post-content > p + h6 {
    margin-top: 2.44444em;
  }
  .post-content > .h1,
  .post-content > h1 {
    margin-bottom: 0.62857em;
  }
  .post-content > .h2,
  .post-content > h2 {
    margin-bottom: 0.91667em;
  }
  .post-content > .h3,
  .post-content > h3 {
    margin-bottom: 1.29412em;
  }
  .post-content > .h4,
  .post-content > h4 {
    margin-bottom: 1.83333em;
  }
  .post-content > .h5,
  .post-content > h5 {
    margin-bottom: 2.2em;
  }
  .post-content > .h6,
  .post-content > h6 {
    margin-bottom: 2.44444em;
  }
  .font-size-18 {
    font-size: 18px;
  }
  .font-size-17 {
    font-size: 17px;
  }
  .font-size-16 {
    font-size: 16px;
  }
  .font-size-14 {
    font-size: 14px;
  }
  .font-weight-medium {
    font-weight: 500;
  }
  .width-70px {
    width: 70px;
  }
  @media (min-width: 620px) {
    .w-sm-auto {
      width: auto !important;
    }
  }
  @media (max-width: 619.98px) {
    .w-xs-100 {
      width: 100% !important;
    }
  }
  .box-shadow {
    -webkit-box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
  }
  .overflow-initial {
    overflow: initial;
  }
  .text-grey {
    color: #bcbcbc !important;
  }
  .text-grey-dark {
    color: #535353 !important;
  }
