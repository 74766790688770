@import '../../../Style/variable.scss';

.EditeProfile-block {
  padding: 50px 0 65px 0;
  @media(max-width: 575px) {
    padding: 20px 0 10px 0;
  }
}
.profile-page{
  display: block !important;
}
.EditProfile-details {

  .ant-form-item-label {
    label {
      font-weight: 700;
      font-size: 12px;
      line-height: 12px;
      text-transform: uppercase;
      color: $darkgray;
    }
  }

  .ant-input,
  .ant-select-selector {
    border: 2px solid $secondary-strock;
    box-sizing: border-box;
    padding: 12px 16px;
    border-radius: 12px;
    height: 48px;
    color: $peach;
    background: transparent;
  }

  textarea.ant-input,
  .ant-select-selector {
    height: auto;
  }

  .anticon.ant-input-clear-icon,
  .anticon.ant-input-clear-icon:hover {
    color: $white;
  }
}

.ant-select-multiple .ant-select-selection-item {
  background: transparent;
  color: $peach;
  border-radius: 4px;
  border-color: $secondary-strock;
  height: 32px;
  line-height: 30px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-affix-wrapper {
  border: 2px solid $secondary-strock;
  box-sizing: border-box;
  border-radius: 12px;
  color: $peach;
  background-color: $blueInput;
}

.ant-select-multiple {
  .ant-select-selection-item-remove {
    svg {
      color: $peach;

    }
  }
}

.ant-input-prefix {
  svg {
    color: $grayWhite;
    margin-right: 10px;
  }
}

.site-form-item-icon {

  margin-bottom: 16px;
  border-color: $blueInput;
}

.submitBtn {
  border-top: 1px solid $secondary-strock;
  padding-top: 30px;
}

.EditProfile {
  display: flex;

  .Namedetail {
    p {
      font-weight: 600;
      color: $peach;
    }

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: $primary;
    }
  }

  .userpicWrapper {
    position: relative;
    width: 128px;
    height: 128px;
    margin-right: 30px;
    margin-bottom: 30px;

    .userpic {
      border: 2px solid $purple;
      box-sizing: border-box;
      border-radius: 100px;
      width: 128px;
      height: 128px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .EditIcon {
      position: absolute;
      background: $black;
      border: 2px solid $purple;
      box-sizing: border-box;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 6px;
      bottom: 0;

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;

      }
    }
  }
}

.socialinouts {
  .ant-form-item {
    margin-bottom: 0;
  }
}


.selectedTopics {
  border: 2px solid $blueInput;
  box-sizing: border-box;
  border-radius: 12px;
  color: $primary;
  background-color: $blueInput;
  padding: 5px 7px 5px 7px;
  min-height: 56px;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;

  span.selectedTags {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.21);
    box-sizing: border-box;
    box-shadow: 0px 64px 64px -48px rgb(31 47 70 / 12%);
    border-radius: 6px;
    padding: 6px 18px;
    margin: 9px 9px 9px 9px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }
}

.impritblock {
  margin-top: 20px;
  border-top: 1px solid $secondary-strock;
  padding-top: 20px;

  h4 {
    font-size: 16px;
  }
}

.ant-picker-header button,
.ant-picker-cell-in-view,
.ant-picker-cell {
  color: $peach;
}

.ant-picker-panel-container {
  background: $darkPurple;
}

.ant-picker-header {
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid #1B325B;
}

.ant-picker-header-view button:hover {
  color: $peach;
}

.title-btn {
  .ant-input-affix-wrapper {
    background-color: transparent;
  }
}