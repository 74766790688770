@import '../../Style/variable.scss';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,800&display=swap');

@font-face {
  font-family: "Atrament";
  src: url("../../Assets/fonts/Atrament Bold.woff.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Atrament";
  src: url("../../Assets/fonts/Atrament_font_family/AtramentStdMedium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Atrament";
  src: url("../../Assets/fonts/Atrament_font_family/AtramentStd-Light.otf");
  font-weight: lighter;
}

@font-face {
  font-family: "Oswald";
  src: url("../../Assets/fonts/Oswald-VariableFont_wght.ttf");
}

body {
  scroll-behavior: smooth;
}

h2 {
  font-family: 'Atrament';
  font-weight: 600;
  font-size: 84px;
  line-height: 98px;
  color: $peach;

  @media(max-width:1324px) {
    font-size: 58px;
    line-height: 62px;
  }

  @media(max-width:767px) {
    font-size: 57px;
    line-height: 55.86px;
  }
}

h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 34px;
  line-height: 42px;
  color: $peach;
}

p {
  font-family: 'Montserrat', sans-serif;
}



.seller-container {
  max-width: 1355px;
  margin: 0 auto;
  padding: 0 15px;
}



.seller-header {
  position: relative;
  z-index: 9;
  position: fixed;

  nav {
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(max-width:1248px) {
      padding: 0;
    }

    .seller-logo {
      img {
        max-width: 128px;

        @media(max-width:676px) {
          max-width: 100px;
        }
      }
    }
  }
}


.lan-switch .switch {
  position: relative;
  width: 80px;
  height: 38px;
  display: block;
  margin: auto;
  margin-bottom: auto;
  right: 0px;

  @media(max-width:767px) {
    width: 66px;
    height: 30px;
  }
}

.lan-switch .switch input {
  display: none;
}

.seller-wrapper {
  position: relative;


}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.lan-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
  border: 3px solid $themegreen;
  border-radius: 8px;
}

/*Moving SLider Section*/

.lan-switch .slider::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  left: 0;
  bottom: 0;
  background-color: $offwhite;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 5px;
}

/*Slider Text*/

.slider_text {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  width: 100%;
  font-weight: 600;
  text-align: center;

  >span {
    width: 50%;
    display: block;
    float: left;
    transition: 0.4s;
    font-weight: 600;
    font-size: 27px;
    line-height: 32px;
    color: $darkPurple;
    font-family: "Atrament";
    top: 1px;
    position: relative;

    @media(max-width:767px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

}



/*Changes on Slide*/


.lan-switch input:checked+.slider::before {
  -webkit-transform: translateX(38px);
  -ms-transform: translateX(38px);
  transform: translateX(38px);

  @media(max-width:767px) {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
  }

}

.lan-switch input:checked+.slider .off {
  color: $peach;
}

.lan-switch input:checked+.slider .on {
  color: $darkPurple;
}

.lan-switch .slider .slider_text .on {
  color: $peach;
}



.seller-banner {
  position: relative;
  padding-top: 110px;

  .s-banner-content {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    padding: 80px 0;
    text-align: center;

    @media(max-width:1440px) {
      padding: 34px 0 80px 0;
    }

    @media(max-width:1324px) {
      max-width: 782px;
    }

    @media(max-width:1024px) {
      max-width: 75%;
    }

    @media(max-width:991px) {
      padding-top: 0;
    }

    @media(max-width:767px) {
      padding-bottom: 30px;
    }

    .hmmaerImg {
      padding-bottom: 50px;
      text-align: left;


      img {
        animation: hammerRotate 0.8s;


        -moz-transition: ease-in-out 2s;
        -o-transition: ease-in-out 2s;
        -webkit-transition: ease-in-out 2s;
        transition: ease-in-out 2s;

      }

      @media(max-width:767px) {
        text-align: left;
      }

      img {
        max-width: 172px;

        @media(max-width:767px) {
          max-width: 100px;
        }
      }
    }

    h2 {
      text-align: center;

      @media(max-width:767px) {
        font-size: 56px;
        line-height: 66px;
      }
    }

    p {
      text-align: center;
      font-weight: 500px;
      font-size: 34px;
      line-height: 42px;
      color: $primary;

      @media(max-width:1324px) {
        font-size: 23px;
        line-height: 34px;
      }

      @media(max-width:992px) {
        max-width: 700px;
        margin: 0 auto;
      }

      @media(max-width:767px) {
        line-height: 26px;
        max-width: 320px;
        margin: 0 auto;
      }
    }

    .bannerDownarrow {
      text-align: center;
      margin-top: 50px;
      display: inline-block;
      transition: 0.5s ease-in-out;
      position: relative;
      bottom: 0;
      animation: arrowBottom 3s ease-in-out;

      &:hover {
        bottom: -10px;
      }
    }

    .banner-btns {
      text-align: center;
      margin-top: 100px;
      padding-left: 0;

      .ant-btn {
        height: auto;
        padding: 15px 18px !important;
        display: inline-block;

        &:first-child {
          margin-right: 16px;
        }
      }

      .ant-btn {
        color: $themegreen;
        border-radius: 16px;
        font-size: 31px;
        line-height: 36px;
        text-align: center;
        height: 68px;

        @media(max-width:428px) {
          padding: 3px 17px;
          font-size: 21px;
          line-height: 36px;
        }

        @media(max-width:380px) {
          width: 100%;
          margin-bottom: 10px;
        }

        &:hover {
          color: $darkPurple;
        }
      }
    }
  }
}

.mobileslider-section {
  padding: 80px 0;
  // background-image: url('../../Assets/Images/Seller/bannerBottom.png');
  background-position: 100% 100%;
  background-repeat: no-repeat;
  height: 3800px;
  padding-top: 200px;

  @media(max-width:767px) {
    height: 3460px;
  }

  .stickyMiddle {
    position: sticky;
    top: 0px;


    @media screen and (max-width: 995px),
    screen and (max-height: 700px) {
      top: 0;
    }

    @media(max-width:991px) {
      top: 132px;
    }


    @media(max-width:767px) {
      top: 100px;
    }

    >div:first-child {
      position: relative;
      z-index: 1;
    }

  }

  .scrolled {
    opacity: 1;

    @media(max-width:1324px) {
      padding-top: 100px !important;
    }

    @media(max-width:991px) {
      padding-top: 80px !important;
    }
  }

  .notscrolled {
    opacity: 0;
  }

  .bottomShape {
    text-align: right;
    align-content: flex-end;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    max-width: 100%;
    margin-top: -140px;
    z-index: -1;
    margin-right: calc((100vw - 1288px) / -2);
    margin-left: auto;

    @media(max-width:1324px) {
      margin-right: calc((100vw - 100%) / -2);
      max-width: 80%;
      margin-top: -132px;
    }

    @media(max-width:767px) {
      margin-right: 0;
      margin-top: -100px;
      position: relative;
      right: -15px;
    }

    @media(max-width:412px) {
      margin-top: 140px;
    }

    @media(max-width:375px) {
      margin-top: -128px;
    }

    @media(max-width:320px) {
      margin-top: -153px;
    }

    .desktopshow {
      @media(max-width:767px) {
        display: none;
      }
    }

    .mobileShow {
      display: none;

      @media(max-width: 767px) {
        display: block;

      }
    }

    img {
      width: auto;
      height: auto;
    }
  }

  .mobileslider-inner {
    position: relative;
    padding-top: 100px;
    z-index: 0;
    transition: 0.5s ease-in-out;

    @media(max-width:767px) {
      flex-direction: column;
    }

    .mobileslider-left {
      flex: 1;

      .lottieanimation {

        @media(max-width:767px) {
          text-align: center;
        }

        svg {
          max-width: 35%;


          @media(max-height:780px) {
            max-width: 400px;
          }

          @media screen and (max-width: 1300px) and (max-height: 650px) {
            max-width: 250px;
          }

          @media screen and (max-width: 1024px) and (max-height: 600px) {
            max-width: 250px;
          }

          @media(max-width:600px) {
            max-width: 55%;
          }

        }
      }
    }

    .mobileslider-right {
      top: 50%;
      flex: 1;
      max-width: 510px;
      position: absolute;
      text-align: center;
      right: 132px;
      transform: translateY(-50%);
      @media(max-width:1366px){
        right: 0;
      }
      @media(max-height:780px) {
        top: 56%;
      }

      @media(max-width:991px) {
        max-width: 382px;
      }

      @media(max-width:767px) {
        position: relative;
        top: 0;
        transform: none;
        text-align: left;
        margin: 0 auto;
      }

      h2 {
        font-size: 91px;
        line-height: 107px;

        @media(max-width:767px) {
          font-size: 50px;
          line-height: 101.5%;
        }
      }
    }
  }
}

.becomehost-section {
  padding: 100px 0 440px 0;
  position: relative;
  overflow: hidden;

  @media(max-width:767px) {
    padding-bottom: 240px;
  }

  // &::after {
  //   content: "";
  //   position: absolute;
  //   background-image: url('../../Assets/Images/Seller/mobilebottom.png');
  //   bottom: 0;
  //   left: 0;
  // }

  .becomehost-inner {
    text-align: center;

    .ant-btn {
      background: $themegreen;
      min-width: 332px;
      height: auto;
      font-size: 31px;
      line-height: 36px;
      text-align: center;
      color: $darkPurple;
      border-radius: 17px;
      font-family: "Atrament";
      padding: 15px 20px !important;

      @media(max-width:767px) {
        display: none;
      }
    }
  }

  .host-steps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 70px;

    @media(max-width:1440px) {
      padding: 0 30px;
    }

    @media(max-width:1324px) {
      flex-wrap: wrap;
      margin-top: 100px;
    }

    @media(max-width:1199px) {
      flex-direction: column;
    }

    @media(max-width:1024) {
      margin-top: 0;
    }

    @media(max-width:991px) {
      flex-direction: column;
    }

    @media(max-width:319px) {
      padding: 0 12px;
    }
  }

  .steps-containers {
    position: relative;
    max-width: 430px;
    flex: 1;

    @media(max-width:767px) {
      max-width: 100%;
    }

    .shap1Top {
      img {
        max-width: 400px;

        @media(max-width:1600px) {
          max-width: 350px;
        }

        @media(max-width:1428px) {
          max-width: 325px;
        }

        @media(max-width:1324px) {
          max-width: 378px;
        }

        @media(max-width:480px) {
          max-width: 340px;
        }
      }
    }

    .host-text {
      left: 50%;
      max-width: 400px;
      text-align: center;
      transform: translateX(-50%);
      h6{
        font-family: 'Montserrat', sans-serif;
      }
      @media(max-width:1324px) {
        right: -10px;
        top: 80px;
      }

      @media(max-width:425px) {
        top: 50px;
      }

      @media(max-width:374px) {
        right: 30px;
        left: auto;
        transform: none;
        max-width: 195px;
      }

      @media(max-width:340px) {
        right: 10px;
      }
    }
  }

  .mobileBtn {
    display: none;
    margin-top: 100px;

    .ant-btn {
      background: $themegreen;
      min-width: 332px;
      height: auto;
      font-size: 31px;
      line-height: 36px;
      text-align: center;
      color: $darkPurple;
      border-radius: 17px;
      font-family: "Atrament";
      padding: 12px 20px !important;

      @media(max-width:380px) {
        min-width: 264px;
      }
    }

    @media(max-width:767px) {
      display: block;
    }
  }

  .stepContainer1 {
    left: 0;

    .itemNo {
      left: -62px;

      @media(max-width:600px) {
        left: -28px;
      }

    }

    @media(max-width:1440px) {
      left: 40px;
    }

    @media(max-width:1324px) {
      left: 105px;
    }

    @media(max-width:1024px) {
      left: 50px;
    }

    @media(max-width:767px) {
      left: -30px;
    }

    @media(max-width:600px) {
      left: 0;
    }

    .hideinMobile {
      @media(max-width:1324px) {
        display: none;
      }
    }

    .topShape {
      position: absolute;
      top: 38px;
      right: 6px;
      @media(max-width:1600px){
        top: 7px;
      }
      @media(max-width:1299px) {
        top: 38px;
      }
      @media(max-width:1100px){
        top: 14px;
      }
      @media(max-width:375px){
        top: -33px;
      }

      img {
        @media(max-width:767px) {
          width: 68px;
        }
      }
    }

    .stepImg {
      @media(max-width:428px) {
        position: relative;
        left: -69px;
      }

      @media(max-width:320px) {
        left: -138px;
      }
    }

    .hideinDesktop {
      display: none;

      @media(max-width:1324px) {
        display: block;
      }
    }

    // .host-text {
    //   left: 50%;
    //   max-width: 400px;
    //   text-align: center;
    //   transform: translateX(-50%);

    //   @media(max-width:1324px) {
    //     right: -10px;
    //   }

    //   @media(max-width:425px) {
    //     top: 82px;
    //   }
    // }
  }

  .host-text {
    position: absolute;
    top: 85px;
    max-width: 300px;
    right: -20px;

    @media(max-width:1600px) {
      top: 54px;
    }

    @media(max-width:1324px) {
      top: 100px;
    }

    @media(max-width:991px) {
      top: 75px;
    }

    @media(max-width:480px) {
      top: 119px;
      right: -46px;
    }

    @media(max-width:320px) {
      max-width: 203px;
      right: -30px;
    }

    h6 {
      font-style: normal;
      font-weight: 700;
      font-size: 23px;
      line-height: 28px;
      color: $darkPurple;

      @media(max-width:1366px) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    p {
      font-weight: 500;
      color: $darkPurple;
      line-height: 20px;
    }
  }

  .shape1Cal {
    position: absolute;
    left: 60px;
    bottom: -35px;
  }


  .itemNo {
    font-family: "Atrament";
    font-size: 233px;
    line-height: 273px;
    -webkit-text-stroke: 4px $themegreen;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    position: absolute;
    top: -50px;
    left: -84px;

    @media(max-width:1440px) {
      left: -80px;
    }

    @media(max-width:1428px) {
      font-size: 194px;
    }

    @media(max-width:1324px) {
      left: -54px;
    }

    @media(max-width:600px) {
      font-size: 164px;
    }

    @media(max-width:480px) {
      left: -16px;
      top: -124px;
      font-size: 152px;
    }
  }

  .sendIcon {
    position: absolute;
    top: -44px;
    right: 70px;
  }

  .stepContainer2 {
    top: 307px;
    left: -58px;

    @media(max-width:1324px) {
      top: 212px;
      left: 284px;
    }

    @media(max-width:1199px) {
      top: 100px;
      left: 230px;
    }

    @media(max-width:1024px) {
      top: 0;
      left: -46px;
      margin-top: 80px;
    }

    @media(max-width:676px) {
      margin-top: 85px;
      left: 0;
    }

    .centershape1 {
      position: absolute;
      left: 161px;
      top: -220px;

      @media(max-width:767px) {
        display: none;
      }
    }

    .noteicon {
      position: absolute;
      right: -35px;
      bottom: 149px;

      @media(max-width:767px) {
        position: absolute;
        right: 232px;
        bottom: -55px;
        transform: rotate(308deg);
      }
      @media(max-width:400px) {
        bottom: -42px;
        right: 209px;
      }
      @media(max-width:360px) {
        right: 151px;
      }

svg{
  @media(max-width:475px) {
   width: 90px;
  }
}
      img {
        @media(max-width:480px) {
          width: 100px;
        }
      }
    }

    .stepImg {
      position: absolute;
      right: 0;
      bottom: 0;
      top: 100%;
      z-index: 1;
    }
  }

  .stepContainer3 {

    @media(max-width:1324px) {
      top: 527px;
      left: -600px;
    }

    @media(max-width:1199px) {
      top: 234px;
      left: 0;
    }

    @media(max-width:1199px) {
      top: 146px;
    }

    @media(max-width:1024px) {
      top: 34px;
      left: 95px;
      margin-top: -10px;
    }

    @media(max-width:991px) {
      margin-top: 30px;
    }

    @media(max-width:767px) {
      margin-top: 100px;
      left: 0;
    }

  }

  .stepContainer4 {
    position: relative;
    top: 330px;

    @media(max-width:1440px) {
      top: 300px;
    }

    @media(max-width:1324px) {
      right: -56%;
      top: 435px;
    }

    @media(max-width:1199px) {
      right: 0;
      top: 381px;
    }

    @media(max-width:1024px) {
      right: -54px;
      top: 214px;
    }

    @media(max-width:767px) {
      top: 0;
      margin-top: 100px;
    }

    @media(max-width:480px) {
      right: 0;
    }

    .itemNo {
      top: -98px;

      @media(max-width:2560px) {
        left: 0;
      }

      @media(max-width:1600px) {
        left: -65px;
      }

      @media(max-width:1199px) {
        left: -54px;
      }

      @media(max-width:991px) {
        left: -80px;
        top: -78px;
      }

      @media(max-width:480px) {
        left: -32px;
        top: -104px;
      }

    }


    .host-text {
      top: 44px;
      max-width: 192px;

      @media(max-width:362px) {
        right: 48px;
      }

      @media(max-width:362px) {
        right: 30px;
      }

      @media(max-width:319px) {
        right: 0;
        top: 52px;
      }
    }

    .ant-btn {
      background: $themegreen;
      height: auto;
      font-size: 31px;
      line-height: 36px;
      text-align: center;
      color: $darkPurple;
      border-radius: 17px;
      font-family: "Atrament";
      padding: 12px 20px !important;
      top: -356px;
      position: absolute;
      right: 0;

      @media(max-width:1428px) {
        right: 60px;
      }

      @media(max-width:1324px) {
        top: -144px;
        right: 100px;
      }

      @media(max-width:1199px) {
        top: -166px;
        right: 160px;
      }

      @media(max-width:991px) {
        display: none;
      }
    }

    .shap1Top {
      text-align: center;

      img {
        max-width: 282px;

        @media(max-width:1440px) {
          max-width: 300px;
        }
      }
    }
  }

  .centershape2 {
    position: absolute;
    right: 86px;
    top: -115px;

    @media(max-width:1600px) {
      right: 8px;
      top: -93px;
    }

    @media(max-width:1324px) {

    right: 38px;
    top: -75px;

    }

    @media(max-width:1199px) {
      right: -23px;
      top: -82px;
    }

    @media(max-width:991px) {
      right: -17px;
      top: -79px;
    }

    @media(max-width:767px) {
      display: none;
    }
  }


}

.formright {
  flex: 1;
  padding: 0 19px;

  .twin {
    input {
      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .formInputs {
    margin-bottom: 12px;
    display: flex;

    input {
      border-radius: 11px;
      height: 44px;
      padding: 16px;
      color: $primary;
      font-weight: 400;
      font-size: 15px;
      display: flex;
      align-items: center;
      font-weight: 700;
      background: $darkblack;
      transition: 0.5s ease-in-out;
      width: 100%;
      border: 0;
    }
  }

  .formBtngreen {
    .grenbtn {
      border: 3px solid $themegreen;
      border-radius: 11px;
      width: 100%;
      background: transparent;
      padding: 9px 22px;
      font-weight: 600;
      font-size: 15px;
      line-height: 19px;
      letter-spacing: 0.374px;
      color: $themegreen;
      transition: 0.5s ease-in-out;

      .hmmerspan {
        margin-right: 20px;
        top: -3px;
      }

    }
  }

}

.formFooter-section {
  background-image: url('../../Assets/Images/Seller/footerbg.svg');
  padding: 180px 0 135px 0;
  margin-top: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: top;

  @media(max-width:991px) {
    padding: 180px 0 120px 0;
  }

  @media(max-width:767px) {
    margin-top: 0
  }

  &::before {
    content: "";
    background-color: $primary;
    height: 100px;
    width: 100%;
    left: 0;
    bottom: 0;
    position: absolute;
  }

  .alignTop {
    position: relative;
    top: -56px;

    @media(max-width:991px) {
      top: 0;
    }
  }

  .apllication-inner {
    display: flex;

    @media(max-width:991px) {
      flex-direction: column;
    }

    .aplication-left {
      display: flex;
      align-items: center;
      @media(max-width:991px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .grenbtn {
        color: $darkPurple;
        font-size: 31px;
        line-height: 19px;
        letter-spacing: 0.374px;
        font-family: "Atrament";
        border-color: $themegreen;
        height: 60px;
        align-items: center;
        display: flex;
        background-color: $themegreen;

        @media(max-width:767px) {
          font-size: 23px;
        }
      }

      h4 {
        font-size: 83px;
        line-height: 97px;
        color: $darkPurple;
        font-family: "Atrament";
        padding-right: 42px;
        margin-bottom: 0;
        flex: 1;
        @media(max-width:991px) {
          margin-bottom: 30px;
          padding-right: 0;
        }
        @media(max-width:767px) {
          font-size: 50px;
          line-height: 69px;
        }
      }
    }


  }

}

.avrage-earning {
  display: flex;
  left: 50%;
  position: absolute;
  bottom: 70px;

  @media(max-width:1390px) {
    left: 46%;
    bottom: 0;
  }

  @media(max-width:1324px) {
    right: auto;
    left: 20px;
  }

  @media(max-width:1324px) {
    bottom: 0;
  }


  .earning-img {
    margin-right: 25px;

    @media(max-width:767px) {
      margin-right: 10px;
      position: relative;
      top: -10px;
    }

    svg {

      @media(max-width:767px) {
        max-width: 75px;
      }
    }
  }

  .earning-amount {
    text-align: center;

    p {
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      color: $primary;

    }

    h5 {
      span {
        border: 4px solid $themegreen;
        border-radius: 17px;
        font-size: 76px;
        line-height: 86px;
        color: $primary;
        font-family: "Atrament";
        display: inline-flex;
        align-items: center;
        padding: 6px;
        margin-right: 8px;

        @media(max-width:767px) {
          font-size: 40px;
          line-height: 42px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}


.sliderbtn {
  // margin-top: -220px;
  text-align: center;
  transition: 0.5s ease-in-out;
  position: relative;
  left: 0;
  top: 0;



  .ant-btn {
    color: $themegreen !important;
    border-radius: 16px;
    font-size: 28px;
    line-height: 33px;
    height: auto;
    padding: 15px 18px !important;
    display: inline-block;
    min-width: 260px;
    background-color: transparent !important;
    &:first-child {
      margin-right: 16px;

      @media(max-width:380px) {
        margin-right: 0;
      }
    }

    @media(max-width:1200px) {
      font-size: 24px;
      line-height: 36px;
      min-width: 226px;
    }

    @media(max-width:767px) {
      font-size: 26px;
      line-height: 36px;
      min-width: auto;
    }

    @media(max-width:480px) {
      margin-right: 4px !important;
      font-size: 16px;
      line-height: 22px;
      min-width: 166px;
    }


    @media(max-width:380px) {
      margin-right: 4px !important;
      font-size: 14px;
      line-height: 22px;
      min-width: 107px;
    }

    @media(max-width:319px) {
      padding-left: 8px !important;
      padding-right: 8px !important;
    }

    &:hover {
      color: $darkPurple;
    }
  }
  .filled {
    background: $themegreen  !important;
    color: $darkblack  !important;
  }
}

.banner-btns {
  padding-left: 50%;
  transition-delay: 1.5s;
  top: 200px;


  @media(max-width:991px) {
    padding-left: 0;
    top: 20px;
  }

  @media(max-height:700px) {
    top: 50px;
  }

  @media screen and (max-width: 995px) and (max-height: 700px) {
    top: 50px;
  }
}

.formLable {
  font-size: 18px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.374px;
  color: $darkPurple;
  font-weight: bold;
  margin-bottom: 28px;
  font-family: "Atrament";
  margin-top: 10px;
}


@keyframes mymoves {
  from {
    opacity: 0.5;
    transform: translate3d(100px, 200px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes mymoves2 {
  from {
    opacity: 0.5;
    transform: translate3d(-200px, 100px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes hammerRotate {
  0% {
    transform: rotate(-270deg) scale(0);
    transform-origin: 100% 100%;
  }

  25% {
    transform: rotate(-140deg) scale(0.10);
    transform-origin: 0% 75%;
  }

  50% {
    transform: rotate(-100deg) scale(0.20);
    transform-origin: 0% 50%;
  }

  60% {
    transform: rotate(-45deg) scale(0.40);
    transform-origin: 0% 50%;
  }

  75% {
    transform: rotate(-10deg) scale(0.60);
    transform-origin: 0% 25%;
  }

  100% {
    transform: rotate(0) scale(1);
    transform-origin: 0 0;
  }
}

@keyframes arrowBottom {
  0% {
    bottom: 0;
  }

  50% {
    bottom: -5px;
  }

  100% {
    bottom: -10px;
  }

  50% {
    bottom: 5px;
  }

  0% {
    bottom: 0;
  }
}


.shapheight {
  height: 3583px;
  position: absolute;
  top: 0;
  width: 100%;

  @media(max-width:767px) {
    height: 3400px;
  }

  .bannerShape {
    position: sticky;
    top: 0;

    .right-floater-shape {
      position: absolute;
      right: 0;
      top: 0;
      // animation: mymoves2 1s;

      @media(max-width:1024px) {
        width: 27%;
        top: -72px;
      }

      svg {
        max-width: 300px;

        @media(max-width:1024px) {
          width: 100%;
        }
      }
    }

    .left-floater-shape {
      position: absolute;
      top: 65vh;
      left: -131px;
      // animation: mymoves 0.8s;

      svg {
        @media(max-width:1024px) {
          width: 90%;
        }
      }

    }
  }
}