.icon-scroll,
.icon-scroll:before {
    position: absolute;
    left: 50%;
}

.icon-scroll {
    width: 40px;
    height: 70px;
    margin-left: -20px;
    bottom: 5%;
    margin-top: -35px;
    box-shadow: inset 0 0 0 1px #fff;
    border-radius: 5px;
}

.icon-scroll:before {
    content: "";
    width: 8px;
    height: 8px;
    background: #fff;
    margin-left: -4px;
    top: 8px;
    border-radius: 4px;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: scroll;
    animation-name: scroll;
}

@-webkit-keyframes scroll {
    0% {
        opacity: 1;
        transform: translateY(46px);
    }

    100% {
        opacity: 0;
    }
}

@keyframes scroll {
    0% {
        opacity: 1;
        transform: translateY(46px);
    }

    100% {
        opacity: 0;
    }
}

body {
    display: block;
    -webkit-font-smoothing: inherit;
}

.btn-with-ball {
    width: auto;
}

.interactive-links h3:hover {
    color: #000 !important;
}

.front {
    background-color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    -webkit-font-smoothing: subpixel-antialiased;
}

.fancybox-container {
    -webkit-font-smoothing: subpixel-antialiased;
    font-family: Inter, sans-serif;
}

.front .text-white .feature p,
.front .lead {
    color: #fff !important;
}

.front .lead {
    font-size: 20px;
    margin-bottom: 60px;
}

.front .h1,
.front h1 {
    margin-bottom: 0.74286em;
    font-size: 70px;
    line-height: 1.2;
    font-family: "Inter", sans-serif;
    font-weight: 500;
}

.front .container {
    padding: 0;
    max-width: 1170px;
}

.front .footer p {
    font-size: 17px;
    color: #fff;
    font-weight: 500;
}

.front .feature-title {
    padding-top: 0;
}

.front .navbar-footer p,
.list-group.borderless .list-group-item {
    color: #fff;
}

.fancybox-stage .navbar-footer p.lead {
    font-size: 20px;
    color: #fff;
    font-weight: 500;
}

.front .form-control-white:focus,
.front .form-control-white:hover {
    outline: none;
    box-shadow: none;
}

.front .card-text {
    font-weight: 400;
}

.front header {
    position: fixed;
    top: 0;
    transition: 0.5s ease-in-out;
}

.front header li {
    list-style: none;
    display: inline-block;
}

.front .nav-up {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    background-color: #000;
}

.nav-down {
    background-color: #000;
    transition: top 0.2s ease-in-out;
}

.rightHeader {
    display: flex;
    align-items: center;
}

.rightHeader .langlink {
    margin-left: 20px;
    color: #fff;
}

.rightHeader .langlink:hover {
    color: #3772ff;
}

.nav .nav-link-name,
.nav.text-white .nav-link,
.btn-white,
.card-portfolio.card-horizontal .btn {
    text-transform: capitalize;
}

.footer .col-lg-3:first-child .nav:not(.nav-opacity):hover .nav-link {
    opacity: 1;
    text-transform: lowercase;
}

.footer .col-lg-3:first-child .nav:not(.nav-opacity) .nav-link {

    text-transform: lowercase;
}

.inherit-font{
    text-transform: inherit !important;
}
.capitlize-font::placeholder{
    text-transform: capitalize;
}

@media(max-width:827px){
    .navbar-top .container {
        padding-right: 40px;
        padding-left: 40px;
    }
    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
        padding-right: 40px !important;
        padding-left: 40px !important;
    }
    .h1, h1 {
        margin-bottom: 1.15385em !important;
        font-size: 3.25em !important;
    }
    .sm-pt{
        padding-top: 40px !important;
    }
}
@media(max-width:625px){
    .langlink{
        overflow: hidden;
        display: inline-block;
        white-space: nowrap;
    }
    .mo_en{
        width: 20px;
    }
    .mo_de{
        width: 22px;
    }
    .navbar-top .navbar-brand {
    margin-right: 41px;
}
}
@media(max-width:320px){
    .navbar-brand img{
        width: 165px;
    }
    .navbar-top .navbar-brand {
        margin-right: 4px;
    }
}

.mo_de .mobile{display: none;}
@media(max-width:1200px){
    .front .container {
        padding: 0 20px;
    }
}

@media(max-width:768px){
    .mo_de .mobile{display: inline-block;}
    .mo_de .desktop{display: none;}
}