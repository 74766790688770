@import '../../Style/variable.scss';

.BreadCrumbs {
  padding: 20px 0;
  @media(max-width:425px){
    padding: 10px 0;
    }
  background: $black;
  border-bottom: 1px solid $secondary-strock;

  .btnRight {
    display: flex;
    @media(max-width:425px){
      flex-wrap: wrap;
     }
    .ant-btn {
      margin-left: 10px;
      @media(max-width:425px){
        margin-top: 10px;
        }
    }
  }

  .pagetopheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media(max-width:425px){
      flex-direction: column;
      align-items: flex-start;
     }

    h3 {
      margin-bottom: 0;
      line-height: normal;
      @media(max-width:425px){
       margin-bottom: 10px;
       font-size: 24px;
       margin-left: 10px;
       }
    }
  }
}


.chatmainWrapper {
  padding: 20px 0 50px 0;
  display: flex;
  @media(max-width:768px){
   flex-direction: column;
  }

  .chat-right {
    width: calc(100% - 450px);
    background: rgba(11, 24, 54, 0.65);
    // border: 1px solid $secondary-strock;
    box-sizing: border-box;
    border-radius: 0 16px 16px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-height: 80.2vh;
    height: 100vh;
    @media(max-width:1024px){
      width: 100%;
     }
     @media(max-width:768px){
      max-width: 100%;
      border-radius: 0px 0px 16px 16px;
      border-top: 1px solid $secondary-strock;
      margin-top: 1px;
    }
    .msginputarea {
      padding: 12px 14px;
      background: $darkblack;
      border: 1px solid #353945;
      box-sizing: border-box;
      border-radius: 0px 0px 16px 0px;
      display: flex;
      align-items: center;
      .msgInput {
        background: transparent;
        border: 0;
        outline: none;
        width: 100%;
      }
      .sharebtn {
        background: $themegreen;
        border-radius: 10px;
        border: none;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        // opacity: 1;
        transition: 0.3s all;
        &:hover{
          // opacity: 0.8;
          background: rgba($color: #0CFDE4, $alpha: 0.8);
        }
        &:focus,&:active{
          outline: none;
          border: none;
        }
        @media(max-width:425px){
          width: 33px;
          height: 33px;
         }
      }
    }
    .msgscrollbars{
      border-radius: 0 16px 0 0;
    }
    .msgarea {
      height: calc(100% - 70px);
      overflow-y: auto;
      min-height: 40vh;
      max-height: 80.2vh;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: rgb(250 232 220 / 50%);
        border-radius: 10px;
      }
      @media(max-width:425px){
        height: calc(100% - 59px);
       }
      .namingArea {
        background: $Headerbg;
        // border-bottom: 1px solid $secondary-strock;
        box-sizing: border-box;
        border-radius: 0px;
        padding: 12px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top-right-radius: 14px;
        position: sticky;
        width: 100%;
        top: 0;
        z-index: 99;
        p{
          margin-bottom: 0;
          margin-right: 24px;
          font-weight: 500;
          color: $offwhite;
          font-size: 16px;
          line-height: 21px;
        }
        .chat-settings{
          color: $peach;
        }
      }
    }
  }
}


.chatgroup-body{
  .ant-input{
    border: 2px solid rgba(255, 255, 255, 0.21);
    box-sizing: border-box;
    border-radius: 6px;
    height: 42px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border: 2px solid rgba(255, 255, 255, 0.21);
    box-sizing: border-box;
    border-radius: 6px;
    min-height: 42px;
  }

}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled), .rc-virtual-list-holder-inner,.ant-select-dropdown, .ant-select-item:hover{
  color:$peach;
  font-weight: 600;
  background-color: #303441;
}
.ant-select-item-option-content{
  color: $peach;
}
.usermodalBtn{
  border: 0;
  background-color: transparent;
  margin: 0 6px;
  cursor: pointer;
  img{
    max-width: 28px;
  }
}

.modle-btn{
  padding: 0 24px 35px 24px;
  display: flex;
  justify-content: flex-end;
  .ant-btn{
    margin: 0 6px;
  }
}
.light-btn{
  background-color: $purpleLight;
  border-color: $purpleLight;
}
.NoMessageStyle{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 46px);
  img{
    width: 117px;
    height: 109px;
    margin-bottom: 23px;
  }
  .NoMessageText{
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.374px;
    color: $peach;
    max-width: 345px;
  }
}