@import '../../../Style/variable.scss';

.chat-left {
  max-width: 450px;
  background: $darkblack;
  // border: 1px solid $secondary-strock;
  box-sizing: border-box;
  border-radius: 16px 0px 0px 16px;
  width: 100%;
  max-height: 80.2vh;
  height: 100vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgb(250 232 220 / 50%);
    border-radius: 10px;
  }
  @media(max-width:768px){
    max-width: 100%;
    border-radius: 16px 16px 0px 0px;
  }
  .chat-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s ease-in-out;
    padding: 15px 28px 15px 24px;

    &:hover,
    &.active {
      background: #0E1E43;
      cursor: pointer;
    }

    .chatbox-left {
      display: flex;
      width: calc(100% - 100px);
      @media(max-width:768px){
       padding-right: 10px;
      }
      @media(max-width:767px){
        width: calc(100% - 20px);
       }
      .chat-profile {
        overflow: hidden;
        border-radius: 50%;
        width: 44px;
        height: 44px;
        flex: 0 0 44px;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .username {
        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: $peach;
          margin-bottom: 0;
        }

        > span {
          font-weight: 300;
          font-size: 13px;
          line-height: 16px;
          color: $primary;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 250px;
          display: block;
        }
      }
    }

    .chatbox-right {
      display: flex;
      align-items: center;

      .time {
        font-weight: 300;
        font-size: 10px;
        line-height: 15px;
        text-align: right;
        color: $offwhite;
        display: inline-block;
      }

      .chatDropdown {
        width: 24px;
        height: 24px;
        padding: 0;
        border: 1px solid rgba(12, 253, 228, 0.5);
        border-radius: 6px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $primary;
        margin-left: 8px;
        svg{
          width: 15px;
          height: 14px;
        }
      }

    }
  }
}



.ant-dropdown {
  border-radius: 16px;

  .ant-dropdown-menu {
    background-color: $primary;
    padding: 10px 20px;
    min-width: 170px;

    .ant-dropdown-menu-item {
      color: $darkPurple;
      border-color: #A095DC;
      padding: 15px 5px !important;
      span{
        font-weight: 600;
      }

      .Link_btn {
        display: flex;
        align-items: center;
        border: none;
        border-color: transparent;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #202C47;
        padding: 0;
        width: 100%;
        height: auto;
        &:hover{
          background: transparent;
          color: #fff;
          svg {
            path{
              stroke: #fff;
            }
          }
        }
        svg {
          width: 20px;
          height: 20px;
          margin-right: 6px;
          path{
            transition: 0.3s all ease;
            stroke: #111;
          }
        }
      }
    }
  }
}
.deleteUserModal{
	padding-bottom: 30px;
	text-align: center;
	p{
		font-weight: 300 !important;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		color: $peach;
    max-width: 289px;
    margin: 0 auto;
    margin-bottom: 44px;
	}
  .deleteuserBtns{
    margin: 0 !important;
  }
}

.chat-left, .chat-right{
  position: relative;
  .LoadingSpinner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
