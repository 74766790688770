@import '../../../Style/variable.scss';
.pagefooter {
  background: $darkPurple;
  padding: 80px 0 0 0;
  border-top: 1px solid $secondary-strock;
  @media(max-width:767px){
    padding-top: 40px;
  }
  @media(max-width: 575px){
    padding-top: 10px;
  }
  .copyright{
    padding: 32px 0;
    margin-top: 60px;
    border-top: 1px solid $secondary-strock;
    font-size: 12px;
    line-height: 20px;
    color: $gray;
    @media(max-width:768px){
      margin-top: 30px;
    }
  }
  .footerColumn{
    @media(max-width:767px){
      margin-bottom: 20px;
    }
  >p{
    padding-bottom: 40px;
    font-weight: 600;
    color: $peach;
    @media(max-width:767px){
      padding-bottom: 10px;
    }
  }
  .appdownload-link a {
      display: inline-block;
      padding-right: 15px;
      &:last-child{
        padding-right: 0;
      }
      img{
        max-width: 128px;
      }
  }
  .footerLink{
      margin-bottom: 0;
      li{
        list-style: none;
        padding-bottom: 24px;
        &:last-child{
          padding-bottom: 0;
        }
      a{
        font-size: 14px;
        line-height: 16px;
        color: $gray;
        &:hover{
          color: $peach;
        }
        }
      }
    }

    .footerLogo{
      margin-bottom: 10px;
      img{
        width: 120px;
      }

    }
    h5{
      font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.01em;
    color: $borderColor;
    margin-bottom: 40px;
    }
  }
  .subscribeform{
    span{
      font-size: 14px;
      line-height: 24px;
      color: $borderColor;
      margin-bottom: 30px;
      display: block;
    }
  }
  .subscribe-div {
    border: 2px solid $secondary-strock;
    border-radius: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    transition: 0.5s ease-in-out;
    &:focus-within{
      border-color: $primary;
    }
    .emailInputWrapper{
      margin: 0;
      background-color: transparent !important;
      flex: 1;
    }
    input.emailinput {
      box-sizing: border-box;
      border-radius: 90px;
      height: 48px;
      color: $peach;
      background: transparent;
      padding: 20px;
      border: 0;
      outline: none;
      box-shadow: none;
      flex: 1;

    }
    .sendbtn {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 0;
      background: $primary;
      border: 1px solid $primary;
      color: #fff;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      &:hover{
        background-color: transparent;
        border-color:$primary ;
      }
    }
  }
  .contentright{
    display: flex;
    justify-content: flex-end;
    p{
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 0;
    }
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    -webkit-text-fill-color: $primary;
}

.appdownload-link {
 @media(max-width:425px){
  display: flex;
  flex-direction: column;
  a{
    margin-bottom: 10px;
  }
 }
}


.bottomfooter {
  padding: 0 0 0 0;
  @media(max-width:767px){
    padding: 100px 0 30px 0;
  }
  @media(max-width:767px){
    padding: 20px 0 30px 0;
  }
  @media(max-width: 575px){
    padding-top: 10px;
  }
  .finner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    @media(max-width:991px){
      flex-direction: column;
    }
    .footerlogo {
      max-width: 200px;
      @media(max-width:1024px){
       margin-right: 20px;
      }
      @media(max-width:991px){
        margin-bottom: 40px;
      }
      @media(max-width: 575px){
        margin-bottom: 10px;
      }
      img{
        max-width:132px;
        @media(max-width:767px){
         max-width: 100px;
        }
      }
    }
    .footerright {
      display: flex;
      justify-content: space-between;
      flex: 1;
      padding-left:75px;
      // padding-top: 29px;
     @media(max-width:1024px){
      padding-left: 0;
     }
      @media(max-width:991px){
       width: 100%;
      }
      @media(max-width:767px){
        flex-direction: column;
       }
       @media(max-width: 575px){
        padding-top: 10px;
      }
    }
    .linkssec{
      display: flex;
      width: calc(100% - 300px);
      justify-content: space-between;
      align-items: flex-start;
      @media(max-width:768px){
        width: 100%;
        padding: 0 40px;
      }
      @media(max-width:767px){
       padding: 0;
       justify-content: flex-start;
       }
    }
    .emailblock{
      label{
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #FAE8DC;
      }
      .ant-input{
        background-color: #0B1836;
        border-radius: 11px;
        height: 44.13px;
        width: 266.11px;
        border-color: #0B1836;
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 36px;
        color: $primary;
        cursor: pointer;
        &::placeholder{
          color: $primary;
        }
        @media(max-width:319px){
          width: 206.11px;
        }
      }
    }
      .f-downloadlogo {
        display: flex;
        flex-direction: column;
        top: 5px;
        position: relative;
        @media(max-width:768px){
          display: none;
        }
        // a{
        //   display: inline-block;
        //   margin-bottom: 8px;
        //   svg{
        //     width: 99px;
        //   }
        //   &:last-child{
        //     position: relative;
        //     top: -32px;
        //   }
        // }
     }
     .footerlinks {
      display: flex;
      flex-direction: column;
      @media(max-width:767px){
        padding-right: 12px;
      }
        a {
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          line-height: 169.9%;
          color: $primary;
          padding-bottom: 10px;
          transition: 0.5s ease-in-out;
          &:hover{
            color: $themegreen;
          }
        }
      }
      .socials {
        border: 4px solid $themegreen;
        display: flex;
        flex-direction: column;
        padding: 6px;
        border-radius: 16px;
        align-items: center;
        position: relative;
        top: -28px;
        @media(max-width:991px){
          width: 50px;
          position: absolute;
          top: 16px;
          right: 0;

        }
        @media(max-width:375px){
          top: 0;
        }
        a{
          display: inline-block;
          padding-bottom: 2px;
          transition: 0.5s ease-in-out;
          color: $primary;
          &:hover{
            color: $themegreen;
          }
        }
      }
      h4{
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
  }
}

.footersociallinks{
  a{
    color: $primary;
    padding-right: 30px;
    &:hover{
      color: $themegreen;
    }
  }
  @media(max-width:991px){
    margin-bottom: 30px;
  }
}

.footerlogo-container {
  display: flex;
  flex-direction: row;
  align-items: end;
}

.footerbeta-badge {
  display: flex;
  align-items: end;
  color: #0CFDE4;
  border-radius: 15px;
  border: #0CFDE4 1px solid;
  max-width: 70px;
  margin: 0px 0px 0px 5px;
  padding: 2.5px 10px;
  @media(max-width:767px){
    max-width: 65px;
    padding: 1px 8px;
  }
  @media(max-width:319px){
    max-width: 60px;
    padding: 0px 6px;
  }
}