@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import 'react-phone-input-2/lib/style.css';

@import './variable.scss';

@font-face {
  font-family: NotoColorEmojiLimited;
  unicode-range: U+1F1E6-1F1FF;
  src: url("../Assets/fonts/NotoColorEmoji.ttf") format('truetype');
}

body {
  margin: 0;
  // font-family: 'Work Sans', sans-serif;
  // font-family: 'Poppins', sans-serif;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  color: $peach;
  background-color: $black;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  ::selection {
    background-color: $primary;
    color: $peach;
  }
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding: 0 40px;

  @media (max-width: 767px) {
    padding: 0 20px !important;
  }
}

.container-fluid {
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  padding: 0 40px;

  @media (max-width: 767px) {
    padding: 0 20px;
  }
}

h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: -0.01em;
  color: $peach;

  @media(max-width:768px) {
    font-size: 28px;
    line-height: 36px;
  }

  @media(max-width:767px) {
    font-size: 24px;
    line-height: 32px;
  }
}

h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: $peach;

  @media(max-width:768px) {
    font-size: 26px;
    line-height: 36px;
  }

  @media(max-width:767px) {
    font-size: 20px;
    line-height: 26px;
  }
}

h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: $peach;

  @media(max-width:768px) {
    font-size: 24px;
    line-height: 34px;
  }

  @media(max-width:767px) {
    font-size: 18px;
    line-height: 24px;
  }
}

p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 0;

  @media(max-width:768px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.disable-modal-close {
  .ant-modal-close {
    cursor: not-allowed;
  }
}

.themeModal {
  .ant-modal-content {
    background: $darkblack;
    border: 1px solid $secondary;
    box-sizing: border-box;
    padding: 30px;
    box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
    border-radius: 20px;
    color: $peach;

    @media(max-width:767px) {
      padding: 20px;
    }

    .ant-modal-header {
      background-color: transparent;
      border: none;
      padding: 0 56px 0 0;

      .ant-modal-title {
        color: $peach;
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.01em;

        @media(max-width:767px) {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }

    .ant-modal-close {
      position: absolute;
      top: 30px;
      right: 30px;
      outline: none;

      @media(max-width:767px) {
        top: 30px;
        right: 20px;
      }

      .ant-modal-close-x {
        width: auto;
        height: auto;
        line-height: normal;

        svg {
          @media(max-width:767px) {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    .ant-modal-body {
      padding: 30px 0 0 0;

      .modalbtns {
        padding-top: 15px;
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
  }

  .modalbtn {
    background: transparent;
    border: 1px solid $themegreen;
    width: 100%;
    background: $themegreen;
    border-radius: 12px;
    padding: 12px 20px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    transition: 0.5s ease-in-out;
    color: $peach;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 15px;
    color: $darkPurple;
    height: 48px;

    @media(max-width:375px) {
      padding: 12px 11px;
      font-size: 14px
    }

    svg {
      margin-right: 15px;
    }

    &:hover {
      background-color: $hovered;
      color: $darkPurple;
    }

    &:focus {
      outline: none;
    }
  }

  .transparentbtn {
    border: 1px solid $white;
    color: $black00;
    background-color: $white;
    max-width: 350px;

    @media(max-width:414px) {
      max-width: 280px;
    }

    @media(max-width:375px) {
      max-width: 230px;
    }

    &:hover {
      border-color: $black00;
      background-color: $black00;
      color: $white;
    }
  }

  .whitebtn {
    border: 1px solid $themegreen;
    color: $themegreen;
    background-color: transparent;
    max-width: 350px;

    @media(max-width:414px) {
      max-width: 280px;
    }

    @media(max-width:414px) {
      max-width: 280px;
    }

    @media(max-width:375px) {
      max-width: 230px;
    }

    &:hover {
      border-color: $hovered2;
      color: $themegreen;
      background-color: $hovered2;
    }

    &:focus {
      border-color: $themegreen;
      color: $themegreen;
      background-color: $hovered2;
      outline: none;
    }
  }

  .modal-body {
    padding: 0;
    p{
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $offwhite;
    }
  }

  .popupform {
    margin-top: 30px;
  }

  .ant-input,
  .ant-input-password {
    border: 2px solid $darkgray;
    box-sizing: border-box;
    padding: 12px 16px;
    color: $peach;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    border-radius: 12px;
    background-color: transparent !important;
  }

  label {
    color: $peach;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 12px;
    text-transform: uppercase;
  }

  .phonebox {
    display: flex;

    .ant-select-selection-search-input {
      position: absolute;
      top: 9px;
    }

    .ant-row:first-child {
      margin-right: 15px;
    }

    .numberInput {
      display: flex;

      .ant-input {
        margin-left: 15px;
      }

      .ant-select-clear {
        background-color: transparent;
        color: $peach;
      }

      .countryFlag {
        font-family: 'NotoColorEmojiLimited';
      }
    }

    margin-top: 30px;

    .ant-form-item {
      &:last-child {
        width: 100%;
      }
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {

      border: 2px solid $darkgray;
      box-sizing: border-box;
      padding: 12px 9px;
      color: $peach;
      font-weight: 500;
      font-size: 14px;
      border-radius: 12px;
      background-color: transparent !important;
      height: 52px;
      line-height: normal;

      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        line-height: 22px;
      }
    }
  }

  .ant-spin-spinning {
    position: absolute;
    right: 18px;
    top: 46px;
  }

  #register-username {
    position: relative;
  }
}

.ant-message {
  top: 150px;
}

.usernameform {
  .ant-form-item-has-success :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    border-color: $themegreen;
  }
}

.react-emoji {
  flex: 1;
}

.ant-picker {
  border: 2px solid rgba(250, 232, 220, 0.2)  !important;
  box-sizing: border-box;
  padding: 12px 16px;
  border-radius: 12px;
  height: 60px;
  color: #FCFCFD;
  background: $blueInput;
  width: 100%;
  outline: none;

  .ant-picker-input>input {
    color: $peach;
  }

  .ant-picker-suffix {
    color: $peach;
  }

  &-focused {
    border-color: #40a9ff !important;
    border-right-width: 1px !important;
  }

}

.bold {
  font-weight: 600;
}

.topicsModal {
  .topics-box {
    margin-bottom: 20px;

    .checbox-group {
      margin-top: 10px;
    }

    .topicsList {
      position: relative;
      display: inline-block;
      margin: 0 12px 12px 0;

      .topic-btn {
        border: 1px solid rgba(255, 255, 255, 0.21);
        box-sizing: border-box;
        border-radius: 6px;
        padding: 7px 12px;
        display: inline-block;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        border-radius: 6px;
        color: $peach;
        cursor: pointer;
        background-color: $darkPurple;
        color: $primary;
        transition: 0.3s all ease-in-out;
      }

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
      }

      input:checked+.topic-btn {
        background: $primary;
        border-color: $primary;
        color: $darkPurple;
      }
    }

  }

  .ant-modal-content {
    .ant-modal-close {
      top: 40px;

      .ant-modal-close-x {
        font-size: 16px;
        font-weight: normal;
        line-height: 24px;
        color: $peach;
      }
    }
  }
}


.paymentmodal {
  text-align: center;

  .ant-modal-body {
    padding-top: 60px;
  }

  .paymentBody {
    max-width: 600px;
  }

  .payment-img {
    margin-bottom: 40px;
    text-align: center;

    img {
      max-width: 192px;
    }
  }

  .paymenttext {
    p {
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: $white;
      margin-bottom: 30px;
      @media(max-width:600px){
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  .payment-btns {
    text-align: center;

    a {
      display: inline-block;
      margin: 0 4px;
      @media(max-width:414px) {
        margin: 5px 4px;
      }

      img {
        max-width: 128px;
      }

      .playstorelogo {
        max-width: 136px;
      }
    }
  }
}

.creategroupModal {
  .ant-modal-header {
    border-bottom: 0;
  }

  .ant-form {
    padding: 0 30px;
  }

  .ant-modal-content {
    background: #141416;
  }

  .follower-right {
    a {
      color: $peach;
    }
  }

  .createbtn {
    position: absolute;
    top: 32px;
    right: 60px;
  }

  .ant-modal-content {
    .ant-modal-close {
      top: 40px;
      color: $peach;
    }
  }
}


.front-header {
  .navbar-brand {
    max-width: 178px;

    img {
      max-width: 100%;
    }
  }
}

.footer {
  .footerlogo {
    max-width: 178px;

    img {
      max-width: 100%;
    }
  }
}

.w100 {
  width: 100%;
}


.breakpointModal {
  .ant-modal-close {
    top: -8px !important;
    right: -6px !important;
    border: 1px solid $white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 10px !important;
      height: 22px !important;
      color: $peach;
    }
  }

  a {
    display: inline-block;
    margin: 0 4px;
  }

  .androidbtn {
    img {
      width: 100px;
    }
  }

  .iosbtn {
    img {
      width: 93px;
    }
  }
}

.ant-dropdown-menu-item {
  .ant-dropdown-menu-title-content {
    transition: 0.5s ease-in-out;
  }

  &:hover {
    .ant-dropdown-menu-title-content {
      color: $peach;
    }
  }
}

.logo {
  .ant-btn {
    border: 0;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}


.ant-modal-mask {
  background: rgb(32 44 71 / 90%);
}

.responsiveDrawer {
  .navbar-toggler-icon {
    border: 0;
  }
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: transparent  !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0px 1000px transparent ;
  -webkit-text-fill-color: $peach;
}

.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
.ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background-color: transparent;
  border-color: $blueInput;
}

.ant-btn {
  outline: none;
}

.followInner {
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 290px;
  margin: 0 auto;

  p {
    color: rgba(250, 232, 220, 0.5);
    font-weight: 300;
  }

  .NofollowImg {
    margin-bottom: 34px;

    img {
      width: 188px;
    }
  }
}

.usernameLoader {
  position: relative;

  .ant-spin-spinning {
    position: absolute;
    right: 35px;
    top: 46px;
    z-index: 1;
  }
}

.suggestedSection {
  .langBadge {
    top: 46px
  }
}

.ant-carousel .slick-list .slick-slide {
  pointer-events: auto;
}

.Breadcrumpdiv {
  padding: 24px 0;
  border-bottom: 1px solid #353945;

  a {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: $peach2;
    opacity: 0.5;
    transition: 0.3s all ease;
    &:hover{
      color: $peach2;
      opacity: 0.8;
    }
  }

  .ant-breadcrumb-separator {
    margin: 0 18px;
    opacity: 0.5;
    color: $peach2;
  }

  .ant-breadcrumb {
    >span:last-child {
      // font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: $peach2;
    }
  }
}

.resendlink {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #B1B5C3;
  text-align: center;
  padding-top: 30px;

  button,
  button:hover,
  button:focus {
    color: $themegreen  !important;
    font-weight: 600;
    background: none !important;
    border: none;
    box-shadow: none !important;
    padding-left: 0;
  }
}

.slick-arrow {
  transition: 0.5s ease-in-out;
}

.slick-arrow.slick-prev.slick-disabled,
.slick-arrow.slick-next.slick-disabled {
  opacity: 0;
  z-index: -1;
}

.ant-message-notice-content {
  background: $primary;
  opacity: 0.9;
  border-radius: 8px;

  .ant-message-custom-content {
    display: flex;
    align-items: center;
    color: $darkPurple;

    svg {
      color: $darkPurple;
    }
  }
}

.No_massge_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  height: 100%;
  text-align: center;
  .No_massge_img{
    img{
      width: 100px;
      height: 98px;
      margin-bottom: 20px;
    }
  }
  .Nomassge_content{
    h4{
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0.374px;
      color: $peach;
      margin-bottom: 6px;
    }
    p{
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: $peach2;
      max-width: 465px;
      margin: 0 auto;
      display: inline-block;
    }
  }
}


.ant-message-custom-content{
  span{
    padding-left: 8px;
  }
}

.modBadge {
  display: inline-block;
  font-size: 9px !important;
  padding: 4px 8px;
  border-radius: 13px;
  background: rgba(57, 71, 102, 0.4) !important;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  color: $themegreen !important;
  line-height: normal !important;
  margin-left: 0 0 0 3px !important;
  font-weight: normal !important;
  margin-left: 6px !important;
}